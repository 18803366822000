import {Ionicons} from '@expo/vector-icons';
import {useNavigation} from '@react-navigation/native';
import {doc, getDoc, updateDoc} from 'firebase/firestore';
import {useEffect, useLayoutEffect, useState} from 'react';
import {
  Dimensions,
  Linking,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from 'react-native';

import alert from '../components/container/alert';
import {useAuthContext} from '../context/AuthContext';
import {getFirestore} from '../firebase';
import userConverter from '../firestoreConverters/userConverter';
import {eligibleForPaymentPerUser} from '../helpers/util';
import {emptyUser, User} from '../types';

const length = '96%';

export default function JoinGroupPaymentScreen({route}: {route: any}) {
  const navigation: any = useNavigation();
  const {
    group,
    groupQrCodeUrl,
    hasTicketOptions,
    numOfNew,
    standAlone,
    totalSum,
  } = route.params;
  const [groupCreator, setGroupCreator] = useState<User>(emptyUser);
  const [venmoId, setVenmoId] = useState('');
  const [zelleId, setZelleId] = useState('');
  const {currentUser} = useAuthContext();
  const [scrollEnabled, setScrollEnabled] = useState(false);
  const {height} = useWindowDimensions();
  const [loadingFinished, setLoadingFinished] = useState(false);

  useEffect(() => {
    const getGroupCreator = async () => {
      const groupCreatorSnapshot = await getDoc(
        doc(getFirestore(), 'users', group.creator.id).withConverter(
          userConverter,
        ),
      );

      if (!groupCreatorSnapshot.exists()) {
        console.log('Data not exists');
        return;
      }
      console.log(groupCreatorSnapshot.data());
      setGroupCreator(groupCreatorSnapshot.data());
      setLoadingFinished(true);
    };

    getGroupCreator();
  }, [group.creator.id]);

  const TitleView = () => {
    return (
      <View style={styles.titleView}>
        <Ionicons color="#66BB6A" name="checkmark-circle-outline" size={24} />
        <Text style={styles.title}>欢迎您参与活动</Text>
      </View>
    );
  };

  const navigationBehavior = () => {
    if (standAlone) {
      navigation.getParent()?.goBack();
      navigation.navigate('AccountTab', {screen: 'AccountHome'});
    } else {
      navigation.navigate('JoinGroupSuccessfulScreen', {
        group: group,
        groupQrCodeUrl: groupQrCodeUrl,
      });
    }
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      headerBackVisible: false,
      headerTitle: () => <TitleView />,
    });
  });

  const openVenmoLink = async (url: any) => {
    // Check if the Venmo app is installed on the device
    const isVenmoInstalled = await Linking.canOpenURL(url);

    if (isVenmoInstalled) {
      // If the Venmo app is installed, open the Venmo link
      try {
        await Linking.openURL(url);
      } catch (error) {
        try {
          await Linking.openURL('https://venmo.com/');
        } catch (errorAgain) {
          alert(
            '支付错误',
            '对不起，Venmo无法打开，请稍后再试，或选择其他付款方式。',
            [{onPress: () => {}, text: '确定'}],
          );
        }
      }
    } else {
      // If the Venmo app is not installed, open the Venmo website in a browser
      try {
        await Linking.openURL('https://venmo.com/');
      } catch (errorAgain) {
        alert(
          '支付错误',
          '对不起，Venmo无法打开，请稍后再试，或选择其他付款方式。',
          [{onPress: () => {}, text: '确定'}],
        );
      }
    }
  };

  const [selectedOption, setSelectedOption] = useState(0);

  const options = [
    {id: 1, text: 'Option 1'},
    {id: 2, text: 'Option 2'},
  ];

  const handleOptionPress = (optionId: any) => {
    setSelectedOption(optionId);
    if (optionId == 1) {
      alert(
        '报名尚未付款',
        '请注意活动报名成功已成功付款为准，想确保自己有位子小伙伴，请尽快在线付款，谢谢配合。',
        [{onPress: () => {}, text: '我知道了'}],
      );
    }
  };

  // isLoading is used to prevent premature navigation change
  if (loadingFinished && !eligibleForPaymentPerUser(group, groupCreator)) {
    navigationBehavior();
  }

  // Check if there are legacy groups that still store the Venmo account ID in the group data
  const venmoAccountId =
    group?.creator?.venmoAccountId || group?.venmoAccountId;
  const venmoAmount = String(parseFloat(group?.bugetPerPerson) * numOfNew);

  const venmoNote =
    'yaytour activity, group Id: ' +
    group?.id +
    ', wechat_id:' +
    currentUser?.socialWechat;
  const venmoUrl = encodeURI(
    'https://venmo.com/' +
      venmoAccountId +
      '?txn=pay&note=' +
      venmoNote +
      '&amount=' +
      venmoAmount,
  );

  // const renderOptionOrComponent = (option: any) => {
  //   return (
  //     <TouchableOpacity
  //       key={option.id}
  //       onPress={() => handleOptionPress(option.id)}
  //       style={styles.text}>
  //       <>
  //         <Ionicons
  //           color={'black'}
  //           name={
  //             selectedOption === option.id
  //               ? 'checkbox-outline'
  //               : 'square-outline'
  //           }
  //           size={24}
  //           style={{alignSelf: 'center'}}
  //         />
  //         {option.id == 1 && (
  //           <Text style={styles.optionText}>
  //             线下支付：大部分活动不接受到付，请与组织者确认哦
  //           </Text>
  //         )}
  //         {option.id == 2 && <Text style={styles.optionText}>在线支付</Text>}
  //       </>
  //     </TouchableOpacity>
  //   );
  // };

  const PaymentInstruction = () => {
    return (
      <>
        {groupCreator.zelleAccountId && (
          <Text style={styles.venmoContainer}>
            使用Zelle付款：
            {groupCreator.zelleAccountId}
          </Text>
        )}

        {groupCreator.venmoAccountId && (
          <Text style={styles.venmoContainer}>
            使用Venmo付款：
            {groupCreator.venmoAccountId}
          </Text>
        )}
      </>
    );
  };

  const buttonDisabled = venmoId == '' && zelleId == '';
  const oldPayments = group?.payments
    ? new Map(Object.entries(group?.payments))
    : new Map();

  return (
    <ScrollView
      onContentSizeChange={(contentHeight) => {
        setScrollEnabled(contentHeight + 200 >= height);
      }}
      scrollEnabled={scrollEnabled}>
      <View style={styles.container}>
        <Text style={styles.text}>
          本次活动费用如下，也欢迎您为组织者添加小费。请选择您的付款方式：
        </Text>

        {/* {options.map((option) => renderOptionOrComponent(option))} */}

        <PaymentInstruction />

        <View style={styles.venmoContainer}>
          {hasTicketOptions ? (
            <Text style={styles.venmoAmount}>
              金额共 ${totalSum} （{String(numOfNew)} 张票） + 组织者小费
            </Text>
          ) : (
            <Text style={styles.venmoAmount}>
              金额 ${group?.bugetPerPerson} x {String(numOfNew)} 个人 +
              组织者小费
            </Text>
          )}
          <TouchableOpacity
            onPress={() => {
              // Call the function with the Venmo link you want to open
              openVenmoLink(venmoUrl);
            }}
            style={styles.venmoButton}>
            <Text style={styles.shareButtonText}>打开 Venmo</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.venmoContainerId}>
          <Text style={{fontSize: 18, marginBottom: 8}}>
            我确认我已经完成在线支付，我的Venmo账号ID是：
          </Text>
          <TextInput onChangeText={setVenmoId} style={styles.textInput} />
        </View>
        <View style={styles.venmoContainerId}>
          <Text style={{fontSize: 18, marginBottom: 8}}>
            我确认我已经完成在线支付，我的Zelle账号ID是
            (请填写Zelle上的名字或者用户名而不是手机号或者邮箱)：
          </Text>
          <TextInput onChangeText={setZelleId} style={styles.textInput} />
        </View>
        <TouchableOpacity
          disabled={buttonDisabled}
          onPress={async () => {
            oldPayments.set(
              currentUser?.id,
              venmoId
                ? 'Venmo: ' + venmoId
                : zelleId
                ? 'Zelle: ' + zelleId
                : '',
            );
            const newObj = Object.fromEntries(oldPayments);
            const groupRef = doc(getFirestore(), 'groups', group?.id);
            await updateDoc(groupRef, {
              payments: newObj,
            });
            navigationBehavior();
          }}
          style={[styles.shareButton, buttonDisabled && {opacity: 0.5}]}>
          <Text style={styles.shareButtonText}>
            {venmoId == '' && zelleId == '' ? '请填写付款账户Id' : '已完成'}
          </Text>
        </TouchableOpacity>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: 'white',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingHorizontal: 16,
  },
  option: {
    borderColor: '#ccc',
    borderRadius: 5,
    borderWidth: 1,
    marginBottom: 10,
    padding: 10,
  },
  optionText: {
    alignSelf: 'center',
    flexWrap: 'wrap',
    fontSize: 18,
    paddingLeft: 10,
    width: Dimensions.get('window').width - 58,
  },
  selectedOption: {
    backgroundColor: '#ccc',
  },
  separator: {
    alignSelf: 'center',
    backgroundColor: '#c0c0c0',
    height: 1,
    marginVertical: 20,
    width: length,
  },
  shareButton: {
    alignSelf: 'center',
    backgroundColor: '#66BB6A',
    borderRadius: 5,
    bottom: 20,
    marginBottom: 10,
    marginTop: 40,
    paddingVertical: 8,
    width: length,
  },
  shareButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  text: {
    flexDirection: 'row',
    fontSize: 18,
    paddingVertical: 8,
    width: length,
  },
  textInput: {
    alignSelf: 'stretch',
    borderColor: 'gray',
    borderRadius: 10,
    borderWidth: 1,
    color: '#222',
    fontSize: 14,
    lineHeight: 18,
    paddingHorizontal: 20,
    paddingVertical: 12,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  titleView: {
    alignItems: 'center',
    alignSelf: 'center',
    flexDirection: 'row',
  },
  venmoAmount: {
    flex: 1,
    fontSize: 18,
    marginRight: 8,
  },
  venmoButton: {
    alignSelf: 'center',
    backgroundColor: '#66BB6A',
    borderRadius: 5,
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  venmoContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    fontSize: 18,
    justifyContent: 'center',
    paddingVertical: 8,
    width: length,
  },
  venmoContainerId: {
    alignItems: 'stretch',
    flexDirection: 'column',
    fontSize: 18,
    justifyContent: 'flex-start',
    paddingVertical: 8,
    width: length,
  },
  venmoTitle: {
    fontSize: 18,
  },
});
