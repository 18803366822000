import {
  doc,
  getDoc,
  getFirestore,
  runTransaction,
  Timestamp,
  updateDoc,
  writeBatch,
} from 'firebase/firestore';
import {Clipboard, Dimensions, Platform, Share} from 'react-native';

import alert from '../components/container/alert';
import {USE_PRODUCTION_BUILD} from '../constants/config';
import {ENABLE_BANNER_AD, ENABLE_PAYMENT, ENABLE_TIP} from '../constants/dev';
import userConverter from '../firestoreConverters/userConverter';
import {DiscountCode, Group, Tour, User} from '../types';

export const defaultAvatar =
  'https://firebasestorage.googleapis.com/v0/b/yaytour-prod.appspot.com/o/publicAssets%2Fsimple_avatar_100_100.png?alt=media&token=19c5f33b-3679-4c69-9218-6269783c88f7';

export function produceStartToEndDate(
  timestamps: [Timestamp, Timestamp | null],
) {
  const startTimestamp: Date = timestamps[0].toDate();
  const endTimestamp: Date | null = timestamps[1]
    ? timestamps[1].toDate()
    : null;
  const startDateString = produceDateString(startTimestamp);

  if (
    endTimestamp === null ||
    startTimestamp.getTime() === endTimestamp.getTime()
  ) {
    return startDateString;
  } else {
    const endDateString = produceDateString(endTimestamp);
    return startDateString + ' - ' + endDateString;
  }
}

export function produceStartToEndDateShortVersion(
  timestamps: [Timestamp, Timestamp | null],
) {
  const startTimestamp: Date = timestamps[0].toDate();
  const endTimestamp: Date | null = timestamps[1]
    ? timestamps[1].toDate()
    : null;

  if (
    endTimestamp === null ||
    startTimestamp.getTime() === endTimestamp.getTime()
  ) {
    return startTimestamp.toLocaleDateString();
  } else {
    return (
      startTimestamp.toLocaleDateString() +
      ' - ' +
      endTimestamp.toLocaleDateString()
    );
  }
}

export function produceDurationOrTime(
  groupType: string,
  timestamps: [Timestamp, Timestamp | null],
) {
  const startTimestamp: Date = timestamps[0].toDate();
  const endTimestamp: Date | null = timestamps[1]
    ? timestamps[1].toDate()
    : null;
  if (
    groupType === 'around_la' ||
    groupType === 'around_ny' ||
    groupType === 'around_bos' ||
    groupType === 'around_bay_area'
  ) {
    return produceTimeString(timestamps[0]);
  }
  if (groupType === 'tour') {
    if (
      endTimestamp === null ||
      startTimestamp.getTime() === endTimestamp.getTime()
    ) {
      return '1天';
    } else {
      const duration = Math.round(
        Math.abs(endTimestamp.getTime() - startTimestamp.getTime()) /
          (1000 * 60 * 60 * 24),
      );
      return `${duration}天`;
    }
  }
  return 'undefined';
}

function produceDateString(date: Date) {
  const dd = date.getDate();
  const mm = date.getMonth() + 1;
  const yyyy = date.getFullYear();

  return (
    yyyy.toString() +
    (mm < 10 ? '.0' : '.') +
    mm.toString() +
    (dd < 10 ? '.0' : '.') +
    dd.toString()
  );
}

function produceTimeStringFromDateObj(date: Date) {
  const timeString = date.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });

  let timeZoneString;
  if (Platform.OS === 'android') {
    timeZoneString =
      '(' +
      date.toLocaleString('en-US', {timeZoneName: 'short'}).split(' ')[2] +
      ')';
  } else {
    timeZoneString =
      '(' +
      date.toLocaleString('en-US', {timeZoneName: 'short'}).split(' ')[3] +
      ')';
  }
  return `${timeString} ${timeZoneString}`;
}

export function produceDateTimeStringFromDateObj(date: Date) {
  if (date == null) {
    return '';
  }
  const dateString = produceDateString(date);
  const timeString = produceTimeStringFromDateObj(date);
  return dateString + ' ' + timeString;
}

export function produceDateTimeString(timestamp: Timestamp) {
  if (!(timestamp instanceof Timestamp)) {
    return '';
  }
  const dateString = produceChineseDateString(timestamp);
  const timeString = produceTimeString(timestamp);
  return dateString + ' ' + timeString;
}

export function produceChineseDateString(timestamp: Timestamp) {
  if (timestamp === null || typeof timestamp === 'undefined') {
    return '';
  }
  const date: Date = timestamp.toDate();
  const dd = date.getDate();
  const mm = date.getMonth() + 1;
  const yyyy = date.getFullYear();

  const dateString =
    yyyy.toString() + '年' + mm.toString() + '月' + dd.toString() + '日';
  return dateString;
}

export function produceTimeString(timestamp: Timestamp) {
  if (timestamp === null || typeof timestamp === 'undefined') {
    return '';
  }
  const date: Date = timestamp.toDate();
  // console.log('toLocaleDateString: ' + date.toLocaleDateString());
  // console.log('toDateString: ' + date.toDateString());
  // console.log('toLocaleTimeString: ' + date.toLocaleTimeString());
  // console.log('toTimeString: ' + date.toTimeString());
  // console.log('toLocaleString: ' + date.toLocaleString());
  const timeString = date.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });

  let timeZoneString;
  if (Platform.OS === 'android') {
    timeZoneString =
      '(' +
      date.toLocaleString('en-US', {timeZoneName: 'short'}).split(' ')[2] +
      ')';
  } else {
    timeZoneString =
      '(' +
      date.toLocaleString('en-US', {timeZoneName: 'short'}).split(' ')[3] +
      ')';
  }
  return `${timeString} ${timeZoneString}`;
}

function getWeekNumber(date: Date) {
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  const daysOffset =
    firstDayOfYear.getDay() === 0 ? 1 : 8 - firstDayOfYear.getDay();
  const firstMondayOfYear = new Date(date.getFullYear(), 0, daysOffset);
  const diffInDays = Math.round(
    (date.valueOf() - firstMondayOfYear.valueOf()) / (1000 * 3600 * 24),
  );
  const weekNumber = Math.ceil((diffInDays + daysOffset) / 7);
  return weekNumber;
}

export function produceChatTimeString(givenDate: Date | undefined) {
  const currentDate = new Date();
  if (givenDate == null) {
    return '';
  }

  // HH:MM for today
  if (
    currentDate.getFullYear() === givenDate.getFullYear() &&
    currentDate.getMonth() === givenDate.getMonth() &&
    currentDate.getDate() === givenDate.getDate()
  ) {
    const timeString = givenDate.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });
    return timeString;
  }

  // 星期日 for same week
  if (getWeekNumber(currentDate) === getWeekNumber(givenDate)) {
    let weekdayName;
    switch (givenDate.getDay()) {
      case 0:
        weekdayName = '星期日';
        break;
      case 1:
        weekdayName = '星期一';
        break;
      case 2:
        weekdayName = '星期二';
        break;
      case 3:
        weekdayName = '星期三';
        break;
      case 4:
        weekdayName = '星期四';
        break;
      case 5:
        weekdayName = '星期五';
        break;
      case 6:
        weekdayName = '星期六';
        break;
      default:
        weekdayName = '';
        break;
    }
    return weekdayName;
  }

  // YYYY-MM-DD for others
  return (
    givenDate.getFullYear() +
    (givenDate.getMonth() < 9 ? '-0' : '-') +
    (givenDate.getMonth() + 1) +
    (givenDate.getDate() < 10 ? '-0' : '-') +
    givenDate.getDate().toString()
  );
}

export function produceMeetUpLocation(group: Group) {
  if (!group.meetUpLocationRequired) {
    return group.meetUpLocation + '/' + '不限制出发地';
  }
  return group.meetUpLocation;
}

export function produceGroupType(groupType: string) {
  if (groupType === 'tour') {
    return '多日旅行';
  }
  if (groupType === 'around_ny') {
    return '纽约地区一日游';
  }
  if (groupType === 'around_bos') {
    return '波士顿地区一日游';
  }
  if (groupType === 'around_bay_area') {
    return '湾区一日游';
  }
  return 'illegal_type';
}

export function producePeople(group: Group) {
  const numNeeded = group.numMemberRequired - group.currentMembers.length;
  if (numNeeded === 0) {
    return '暂时满员';
  }

  if (group.genderFlexible == 'flexible') {
    return '还差' + numNeeded + '人/男女不限';
  } else if (group.genderFlexible == 'male_only') {
    return '还差' + numNeeded + '人/只限男生';
  } else if (group.genderFlexible == 'female_only') {
    return '还差' + numNeeded + '人/只限女生';
  }
  return 'error';
}

export function produceGenderFlexibility(group: Group) {
  if (group.numMemberRequired - group.currentMembers.length === 0) {
    return '暂时满员';
  }

  if (group.genderFlexible == 'flexible') {
    return '男女不限';
  } else if (group.genderFlexible == 'male_only') {
    return '只限男生';
  } else if (group.genderFlexible == 'female_only') {
    return '只限女生';
  }
  return 'error';
}

export function profileImagePreview(user: User | undefined) {
  if (
    user == undefined ||
    user == null ||
    typeof user.profileImageUrl != 'string' ||
    user.profileImageUrl == ''
  ) {
    return {
      uri: 'https://firebasestorage.googleapis.com/v0/b/rabbit-tour.appspot.com/o/simple-avatar.png?alt=media&token=6a1c3181-e1d9-4877-92ea-eaf450e8e29b',
    };
  }
  return {uri: user.profileImageUrl};
}

// The PixelRatio.getFontScale() method returns the scaling factor of the system font size,
// and multiplying it by the window width gives you the system font size in pixels.
// Not used, just use 2 columns for any screen
export function getGroupColumnByWindowSize() {
  const windowWidth = Dimensions.get('window').width;
  console.log('window width= ' + windowWidth);
  const numOfColumn = parseInt((windowWidth / 180).toString(), 10);
  console.log('num of column: ' + numOfColumn);
  return Math.max(numOfColumn, 2);
}

export function judgeClient() {
  if (Platform.OS === 'web') {
    const u = navigator.userAgent;
    const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //判断是否是 android终端
    const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //判断是否是 iOS终端

    if (isAndroid) {
      return 'web-android';
    } else if (isIOS) {
      return 'web-ios';
    } else {
      return 'web-pc';
    }
  } else {
    return Platform.OS;
  }
}

export async function shareLink(message: string, url: string) {
  try {
    let sharingMessage;
    const client = judgeClient();
    if (client === 'android' || client === 'web-ios' || client === 'ios') {
      sharingMessage = message + ' ' + url;
    } else if (client === 'web-android') {
      sharingMessage = message;
    } else {
      console.log('share on PC');
      const didCopy = window.prompt('复制链接分享活动', message + ' ' + url);

      if (didCopy) {
        Clipboard.setString(message + ' ' + url);
      } else {
        console.log('User cancelled copy link!');
      }
      return;
    }
    const result = await Share.share({
      message: sharingMessage,
      url: url,
    });
    if (result.action === Share.sharedAction) {
      console.log('Content shared successfully');
    } else if (result.action === Share.dismissedAction) {
      console.log('Sharing dismissed');
    }
  } catch (error: any) {
    console.error(error.message);
  }
}

export async function handleFollowAction(
  currentUserId: string, // Follower
  isFollowing: boolean, // isFollowing = True, will do unfollow action; isFollowing = False, will do follow action
  profileUserId: string, // Following
) {
  if (currentUserId === profileUserId) return; // Do not follow/unfollow self
  const db = getFirestore();
  const currentUserRef = doc(db, 'users', currentUserId).withConverter(
    userConverter,
  );
  const currentUserSnapshot = await getDoc(currentUserRef);
  const currentUser = currentUserSnapshot.data();
  const followings = currentUser?.followingList;

  const profileUserRef = doc(db, 'users', profileUserId).withConverter(
    userConverter,
  );
  const profileUserSnapshot = await getDoc(profileUserRef);
  const profileUser = profileUserSnapshot?.data();
  const followers = profileUser?.followerList;
  let updatedFollowerList: string[] = [];
  let updatedFollowingList: string[] = [];
  if (
    isFollowing &&
    followers != null &&
    followers != undefined &&
    followings != null &&
    followings != undefined
  ) {
    // Do unfollow action
    updatedFollowerList = followers.filter(function (person) {
      return person != currentUserId;
    });
    updatedFollowingList = followings.filter(function (person) {
      return person != profileUserId;
    });
  } else if (!isFollowing) {
    // Do follow action
    updatedFollowerList = followers?.slice() ?? []; // Create a copy
    updatedFollowerList.push(currentUserId); // Push the object
    updatedFollowingList = followings?.slice() ?? [];
    updatedFollowingList.push(profileUserId);
  }

  const batch = writeBatch(db);
  if (profileUserSnapshot != undefined) {
    updateDoc(profileUserSnapshot.ref, {
      followerList: updatedFollowerList,
    });
  }
  if (currentUserSnapshot != undefined) {
    updateDoc(currentUserSnapshot.ref, {
      followingList: updatedFollowingList,
    });
  }
  batch.commit();
}

export function isNumeric(str: any) {
  if (typeof str !== 'string') {
    return false;
  }
  return /^\d+$/.test(str);
}

export function isValidNumber(budget: any): boolean {
  if (budget == undefined || budget == '' || budget == 0) {
    return false;
  }
  const num = Number(budget);

  if (isNaN(num)) {
    return false;
  }

  //  positive and not zero
  return num > 0;
}

export function eligibleForTip(group: Group) {
  return (
    ENABLE_TIP &&
    group?.tips != undefined &&
    group?.tips != null &&
    group?.tips instanceof Array &&
    group?.tips.length > 0 &&
    group?.tips[0] != ''
  );
}

export function eligibleForPayment(group: Group) {
  return (
    isValidNumber(group?.bugetPerPerson) && group.createdByStripeEligibleUser
  );
}

// FIXME: Currently we stores a copy of user info in group creator, which is not good.
// we might consider storing a reference to user instead. But i don't know how to
// write the converter for it.
export function eligibleForPaymentPerUser(group: Group, groupCreator: User) {
  return (
    (groupCreator.venmoAccountId !== null ||
      groupCreator.zelleAccountId !== null) &&
    isValidNumber(group?.bugetPerPerson)
  );
}

export function eligibleForBannerAd(group: Group) {
  return (
    ENABLE_BANNER_AD &&
    group?.sponsors != undefined &&
    group?.sponsors != null &&
    group?.sponsors instanceof Array &&
    group?.sponsors.length >= 2 &&
    group?.sponsors[1] != ''
  );
}

export function eligibleForPurchase(tour: Tour) {
  return tour.purchaseUrl != null && tour.purchaseUrl != '';
}

export async function getMinAppVersion() {
  const db = getFirestore();
  const minAppVersionRef = doc(db, 'features', 'enable_min_app_version');
  const minAppVersionSnapshot = await getDoc(minAppVersionRef);
  return minAppVersionSnapshot?.data()?.min_app_version;
}

export async function addJoinedMember(
  groupId: string,
  userId: string,
  userName: string,
  joinedNumber: number,
  updatePaidMembers: boolean,
  hasTicketOptions: boolean,
  ticketOptions: any[],
  discountCode: string,
) {
  try {
    const db = getFirestore();
    await runTransaction(db, async (transaction) => {
      const groupRef = doc(db, 'groups', groupId);
      const groupSnap = await transaction.get(groupRef);
      var joinedMembers = groupSnap?.data()?.currentMembers ?? [];
      const paidMembers = groupSnap?.data()?.paidMembers ?? [];
      const paidTicketOptions = groupSnap?.data()?.paidTicketOptions ?? {};
      const newMember = {
        discountCode: discountCode,
        id: userId,
        userName: userName,
      };
      const newMembersArray = new Array(joinedNumber).fill(newMember);
      joinedMembers.push(...newMembersArray);
      paidMembers.push(...newMembersArray);
      if (hasTicketOptions) {
        paidTicketOptions[userId] = ticketOptions;
      }
      var payload = updatePaidMembers
        ? {
            currentMembers: joinedMembers,
            paidMembers: paidMembers,
            paidTicketOptions: paidTicketOptions,
          }
        : {currentMembers: joinedMembers};
      transaction.update(groupRef, payload);
    });
    console.log('Transaction successfully committed!');
  } catch (e) {
    alert('发送错误', ' 更新付款记录时发生错误: ' + e, [
      {onPress: () => {}, text: '确定'},
    ]);
  }
}

export async function getLatestMember(groupId: string) {
  const db = getFirestore();
  const groupRef = doc(db, 'groups', groupId);
  const groupSnap = await getDoc(groupRef);
  return [
    groupSnap?.data()?.currentMembers ?? [],
    groupSnap?.data()?.paidMembers ?? [],
  ];
}

export function isWeChat() {
  return (
    Platform.OS === 'web' && navigator.userAgent.indexOf('MicroMessenger') > -1
  );
}

export function isSystemCouponAvailable(
  code: DiscountCode,
  phoneNumber: string,
) {
  if (!code.isActive) {
    return false;
  }
  if (code?.expiresAt && code?.expiresAt < new Date()) {
    return false;
  }
  if (
    code.limitedUserNumbers &&
    !code.limitedUserNumbers.includes(phoneNumber)
  ) {
    return false;
  }
  if (
    code.isSingleUsePerUser &&
    code?.usedByUsers &&
    code.usedByUsers.includes(phoneNumber)
  ) {
    return false;
  }
  return true;
}

export function isDiscountCodeAvailable(
  code: DiscountCode,
  user: User,
  groupId: string,
  totalAmount: number,
  totalCount: number,
  phoneNumber: string,
) {
  if (!code.isActive) {
    return '折扣码已失效';
  }
  if (code?.expiresAt && code?.expiresAt < new Date()) {
    return '折扣码已过期';
  }
  if (
    (code.genderRequirment === 'male' && user.gender === 'F') ||
    (code.genderRequirment === 'female' && user.gender === 'M')
  ) {
    return '性别条件不符';
  }
  if (code.limitedGroupIds && !code.limitedGroupIds.includes(groupId)) {
    return '折扣码不适用于此活动';
  }
  if (
    code.limitedUserNumbers &&
    !code.limitedUserNumbers.includes(phoneNumber)
  ) {
    return '折扣码不适用于此账号';
  }
  if (code?.maxUsageCount && code.maxUsageCount <= 0) {
    return '折扣码用光了';
  }
  if (code?.minimumOrderAmount && code.minimumOrderAmount > totalAmount) {
    return '未到最小支付额度';
  }
  if (code?.minimumOrderCount && code.minimumOrderCount > totalCount) {
    return '未到最小支付人数';
  }
  if (
    code.isSingleUsePerUser &&
    code?.usedByUsers &&
    code.usedByUsers.includes(phoneNumber)
  ) {
    return '折扣码已使用';
  }
  return true;
}

export function getAmountAfterDiscount(
  code: DiscountCode,
  totalAmount: number,
) {
  if (code.discountType === 'fixed') {
    return totalAmount - code.discountAmount;
  } else if (code.discountType === 'percentage') {
    return (totalAmount * (100 - code.discountAmount)) / 100;
  } else {
    return totalAmount;
  }
}

export async function consumeDiscount(phoneNumber: string, code: string) {
  try {
    const db = getFirestore();
    await runTransaction(db, async (transaction) => {
      const discountRef = doc(db, 'discountCodes', code);
      const discountCode = (await transaction.get(discountRef)).data();
      if (discountCode == null || !discountCode.isSingleUsePerUser) {
        return;
      }

      var phoneNumberList = discountCode.usedByUsers ?? [];
      phoneNumberList.push(phoneNumber);
      var payload = {usedByUsers: phoneNumberList};
      transaction.update(discountRef, payload);
      console.log('consumeDiscount committed!');
    });
  } catch (e) {
    if (!USE_PRODUCTION_BUILD) {
      alert('error', 'consumeDiscount' + e);
    }
    console.log('consumeDiscount', e);
  }
}

export async function updateUserToDiscount(phoneNumber: string, code: string) {
  try {
    const db = getFirestore();
    await runTransaction(db, async (transaction) => {
      const discountRef = doc(db, 'discountCodes', code);
      const discountCode = (await transaction.get(discountRef)).data();
      if (discountCode == null) {
        return;
      }

      var limitedUserNumbers = discountCode.limitedUserNumbers ?? [];
      if (limitedUserNumbers.includes(phoneNumber)) {
        console.log('skip updateUserToDiscount as phoneNumber is existing');
        return;
      }
      limitedUserNumbers.push(phoneNumber);
      var payload = {limitedUserNumbers: limitedUserNumbers};
      transaction.update(discountRef, payload);
      console.log('updateUserToDiscount committed!');
    });
  } catch (e) {
    if (!USE_PRODUCTION_BUILD) {
      alert('error', 'updateUserToDiscount: ' + code + e);
    }
    console.log('updateUserToDiscount', e);
  }
}

export async function updateReferredUsersList(
  referredBy: string,
  currentUserId: string,
  currentUserNumber: string,
) {
  try {
    const db = getFirestore();
    const result = await runTransaction(db, async (transaction) => {
      const userRef = doc(db, 'users', referredBy);
      const user = (await transaction.get(userRef)).data();
      if (user == null) {
        return null;
      }

      var referredUsersList = user.referredUsersList ?? [];
      var referredUsersNumber = user.referredUsersNumber ?? [];
      if (referredUsersNumber.includes(currentUserNumber)) {
        console.log('skip updateReferredUsersList as user is already referred');
        return null;
      }
      referredUsersList.push(currentUserId);
      referredUsersNumber.push(currentUserNumber);
      var payload = {
        referredUsersList: referredUsersList,
        referredUsersNumber: referredUsersNumber,
      };
      transaction.update(userRef, payload);
      console.log('updateReferredUsersList committed!');
      return {
        phoneNumber: user.phoneNumber ?? '',
        referredCount: referredUsersList.length ?? 0,
      };
    });
    return result;
  } catch (e) {
    if (!USE_PRODUCTION_BUILD) {
      alert('error', 'updateReferredUsersList' + e);
    }
    console.log('updateReferredUsersList', e);
    return null;
  }
}
