import React from 'react';
import {StyleSheet, Text, TextInput, View} from 'react-native';
import {SelectList} from 'react-native-dropdown-select-list';

import {useFeaturesContext} from '../../context/FeaturesContext';

export default function PaymentSelectionView({
  customizedPayment,
  paymentInstr,
  setCustomizedPayment,
  setPaymentInstr,
  setVenmoAccountId,
  setZelleAccountId,
  venmoAccountId,
  zelleAccountId,
}: any) {
  const {features} = useFeaturesContext();
  const paymentOptions = [
    {key: 'venmo_or_zelle', value: 'Venmo或Zelle'},
    {key: 'onsite', value: '现场支付'},
    {key: 'free', value: '免费'},
    {key: 'customized', value: '自定义'},
  ];

  if (features?.enable_ticket_options && features?.stripe_payment_enabled) {
    paymentOptions.unshift({key: 'credit_card', value: '在线信用卡支付'});
  }

  return (
    <View>
      <Text style={styles.subTitle}>付款方式</Text>
      <SelectList
        data={paymentOptions}
        defaultOption={paymentOptions.find(
          (option) => option.key === paymentInstr,
        )}
        inputStyles={{
          color: paymentInstr === '' ? 'grey' : '#222',
        }}
        placeholder={'付款方式'}
        search={false}
        setSelected={(val: string) => setPaymentInstr(val)}
      />

      {paymentInstr === 'venmo_or_zelle' && (
        <View>
          <Text style={styles.subTitle}>Venmo ID</Text>
          <TextInput
            defaultValue={venmoAccountId}
            onChangeText={setVenmoAccountId}
            placeholder={'Venmo ID'}
            placeholderTextColor="#777"
            style={styles.textInput}
          />
          <Text style={styles.subTitle}>Zelle电话或邮箱</Text>
          <TextInput
            defaultValue={zelleAccountId}
            onChangeText={setZelleAccountId}
            placeholder={'Zelle电话或邮箱'}
            placeholderTextColor="#777"
            style={styles.textInput}
          />
        </View>
      )}
      {paymentInstr === 'customized' && (
        <View>
          <Text style={styles.subTitle}>自定义付款方式</Text>
          <TextInput
            defaultValue={customizedPayment}
            onChangeText={setCustomizedPayment}
            placeholder={'请输入您自定义的付款方式'}
            placeholderTextColor="#777"
            style={styles.textInput}
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  subTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: 18,
    marginBottom: 6,
    marginTop: 16,
  },
  textInput: {
    borderColor: 'gray',
    borderRadius: 10,
    borderWidth: 1,
    color: '#222',
    fontSize: 14,
    lineHeight: 18,
    paddingHorizontal: 20,
    paddingVertical: 12,
  },
});
