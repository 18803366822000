import {ReactNode} from 'react';
import {Keyboard, TouchableWithoutFeedback} from 'react-native';

export default function KeyboardDismissView({children}: {children: ReactNode}) {
  return (
    <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
      {children}
    </TouchableWithoutFeedback>
  );
}
