import {AntDesign, Ionicons, MaterialIcons} from '@expo/vector-icons';
import * as ImagePicker from 'expo-image-picker'; // requires Expo
import {doc, getDoc, Timestamp, updateDoc} from 'firebase/firestore';
import {getDownloadURL, getStorage, ref, uploadBytes} from 'firebase/storage';
import React, {useEffect, useState} from 'react';
import {useDocument} from 'react-firebase-hooks/firestore';
import {Image, StyleSheet, Text, TouchableOpacity, View} from 'react-native';

import AccountFollowSection from '../../components/account/AccountFollowSection';
import alert from '../../components/container/alert';
import Colors from '../../constants/Colors';
import {ENABLE_USER_ACTION} from '../../constants/dev';
import {useAuthContext} from '../../context/AuthContext';
import {useFeaturesContext} from '../../context/FeaturesContext';
import {getFirestore} from '../../firebase';
import userConverter from '../../firestoreConverters/userConverter';
import {BottomPicker} from './UserActions';

export default function AvatarSelector({
  navigation,
  userId,
}: {
  navigation: any;
  userId: any;
}) {
  const {features} = useFeaturesContext();

  const [rating, setRating] = useState(0);
  const [ratingAmount, setRatingAmount] = useState(0);
  const loggedInUserID = useAuthContext().currentUser?.id ?? '';
  let currentUserId = useAuthContext().currentUser?.id ?? '';
  let allowEdit = true;

  if (userId != null && userId != undefined) {
    currentUserId = userId;
    allowEdit = false;
  }
  const [userSnapshot] = useDocument(
    doc(getFirestore(), 'users', currentUserId).withConverter(userConverter),
  );

  const user = userSnapshot?.data();

  const userName = user?.userName;
  const gender = user?.gender;
  const profileImageUrl = user?.profileImageUrl;

  const userNameLine = userName ? userName : 'New User';
  const intro = user?.introduction;
  const userVerified = '已验证账号';

  const getUserRating = async () => {
    const ratingRef = doc(getFirestore(), 'ratings', currentUserId);
    const ratingSnap = await getDoc(ratingRef);
    if (ratingSnap.exists()) {
      setRating(ratingSnap.data().averageRating);
      setRatingAmount(ratingSnap.data().feedback.length);
    }
  };

  useEffect(() => {
    getUserRating();
  }, []);

  const handleImagePick = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      allowsEditing: true,
      aspect: [1, 1],
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: 0.1,
    });

    if (!result.canceled) {
      if (result.assets.length > 0 && userSnapshot != undefined) {
        try {
          const response = await fetch(result.assets[0].uri);
          const imageTimestamp = Timestamp.now();
          const newFileName = currentUserId + '_' + imageTimestamp;
          const blob = await response.blob();
          const refLocation = ref(
            getStorage(),
            'UserProfilePic/' + newFileName,
          );

          await uploadBytes(refLocation, blob);
          const downloadURL = (await getDownloadURL(refLocation)).toString();
          updateDoc(userSnapshot?.ref, {profileImageUrl: downloadURL});
          return downloadURL;
        } catch (error) {
          alert('发送错误', '上传用户头像时发生错误: ' + error, [
            {onPress: () => {}, text: '确定'},
          ]);
        }
      }
    }
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity
        disabled={!allowEdit}
        onPress={handleImagePick}
        style={styles.avatarImageBox}>
        <View style={styles.avatarContainer}>
          {profileImageUrl ? (
            <Image source={{uri: profileImageUrl}} style={styles.avatarImage} />
          ) : (
            <Image
              source={require('../../assets/images/simple-avatar.png')}
              style={styles.avatarImage}
            />
          )}
        </View>
        {user?.wechatVerified ? (
          <MaterialIcons
            color={Colors.mainAppColor}
            name="verified-user"
            size={20}
            style={styles.verifiedIcon}
          />
        ) : null}
      </TouchableOpacity>

      <View style={styles.avatarUserInfoBox}>
        <View style={styles.userNameRatingBox}>
          <Text style={styles.userName}>{userNameLine}</Text>
          {gender === 'M' ? (
            <Image
              source={require('../../assets/images/male.png')}
              style={styles.genderImage}
            />
          ) : gender === 'F' ? (
            <Image
              source={require('../../assets/images/female.png')}
              style={styles.genderImage}
            />
          ) : null}
          {features?.enable_rating_ui && ratingAmount > 0 && (
            <View style={styles.ratingContainer}>
              <Text style={styles.rating}>{rating.toFixed(1)}</Text>
              <Ionicons color="#FFD700" name="star" type="AntDesign" />
              <Text style={styles.rating}>{ratingAmount} 评价</Text>
            </View>
          )}
        </View>
        {user?.wechatVerified ? (
          <Text style={styles.userVerified}>{userVerified}</Text>
        ) : null}
        <View style={{paddingBottom: 12}}>
          <Text style={{fontSize: 12}}>
            {intro ? intro : '你还没有简介哦, 点击设置添加~'}
          </Text>
        </View>

        <AccountFollowSection navigation={navigation} profileUserId={userId} />
      </View>
      <View>
        {allowEdit ? (
          <TouchableOpacity
            disabled={currentUserId === ''}
            onPress={() => {
              navigation.navigate('AccountSettingsScreen', {
                navigation: navigation,
              });
            }}
            style={[styles.settingBox]}>
            <AntDesign color="black" name="setting" size={24} />
          </TouchableOpacity>
        ) : ENABLE_USER_ACTION && userId != loggedInUserID ? (
          <View style={styles.settingBox}>
            <BottomPicker
              currentLoggedInUserID={loggedInUserID}
              targetUserId={userId}></BottomPicker>
          </View>
        ) : null}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  avatarContainer: {
    borderRadius: 50,
    elevation: 5,
    height: 90,
    marginBottom: 2,
    overflow: 'hidden',
    shadowColor: 'black',
    shadowOffset: {height: 2, width: 2},
    shadowOpacity: 0.5,
    shadowRadius: 8,
    width: 90,
  },
  avatarImage: {
    height: '100%',
    width: '100%',
  },
  avatarImageBox: {
    alignItems: 'center',
  },
  avatarPlaceholder: {
    backgroundColor: 'lightgrey',
    height: '100%',
    width: '100%',
  },
  avatarUserInfoBox: {
    flex: 1,
    marginLeft: 12,
    paddingTop: 8,
  },
  button: {
    backgroundColor: 'green',
    borderRadius: 20,
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  buttonContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 5,
    marginTop: 5,
    width: '100%',
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
  },
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: 16,
    paddingVertical: 8,
    width: '100%',
  },
  genderImage: {
    height: 25,
    width: 25,
  },
  rating: {
    fontSize: 12,
    padding: 2,
  },
  ratingContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    marginLeft: 5,
  },
  settingBox: {
    flex: 1,
    paddingTop: 8,
  },
  userName: {
    fontSize: 18,
    fontWeight: 'bold',
    marginRight: 5,
  },
  userNameRatingBox: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    paddingVertical: 12,
  },
  userVerified: {
    fontSize: 12,
    marginBottom: 5,
  },
  verifiedIcon: {
    alignItems: 'center',
    bottom: 0,
    color: 'green',
    height: 35,
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
    width: 25,
  },
});
