import {FontAwesome} from '@expo/vector-icons';
import {doc, updateDoc} from 'firebase/firestore';
import React, {useState} from 'react';
import {useDocument} from 'react-firebase-hooks/firestore';
import {
  Button,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  View,
} from 'react-native';

import alert from '../../components/container/alert';
import {getFirestore} from '../../firebase';
import userConverter from '../../firestoreConverters/userConverter';

interface UserData {
  name: string | undefined;
  socialWechat: string | undefined | null;
  userIntro: string | undefined | null;
  userName: string | undefined;
}

export default function AccountEditPersonalSettings({
  userId,
}: {
  userId: string;
}) {
  const currentUserId = userId ?? '';
  const [updatedUserData, setUpdatedUserData] = useState<UserData>({
    name: undefined,
    socialWechat: undefined,
    userIntro: undefined,
    userName: undefined,
  });

  const Separator = () => {
    return <View style={styles.separator} />;
  };

  const [isEditingUserName, setIsEditingUserName] = useState(false);
  const [isEditingWechat, setIsEditingWechat] = useState(false);
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingIntro, setIsEditingIntro] = useState(false);

  const [userSnapshot] = useDocument(
    doc(getFirestore(), 'users', currentUserId).withConverter(userConverter),
  );
  const user = userSnapshot?.data();
  if (user == null) {
    return <View />;
  }

  const handleUserNameChange = (text: string) => {
    setUpdatedUserData((prevUserData) => ({
      ...prevUserData,
      userName: text,
    }));
    if (userSnapshot != undefined) {
      user.userName = text;
    }
  };

  const handleUserIntroChange = (text: string) => {
    setUpdatedUserData((prevUserData) => ({
      ...prevUserData,
      userIntro: text,
    }));
    if (userSnapshot != undefined) {
      user.introduction = text;
    }
  };

  const handleNameChange = (text: string) => {
    setUpdatedUserData((prevUserData) => ({
      ...prevUserData,
      name: text,
    }));
    if (userSnapshot != undefined) {
      user.name = text;
    }
  };

  const handleSaveUserName = () => {
    if (updatedUserData.userName != undefined && userSnapshot != undefined) {
      try {
        updateDoc(userSnapshot.ref, {userName: updatedUserData.userName});
      } catch (error) {
        alert('发送错误', '修改用户信息时发生错误: ' + error, [
          {onPress: () => {}, text: '确定'},
        ]);
      }
    }
    setIsEditingUserName(false);
    updatedUserData.userName = undefined;
  };

  const handleSaveIntro = () => {
    if (updatedUserData.userIntro != undefined && userSnapshot != undefined) {
      try {
        updateDoc(userSnapshot.ref, {introduction: updatedUserData.userIntro});
      } catch (error) {
        alert('发送错误', '修改用户信息时发生错误: ' + error, [
          {onPress: () => {}, text: '确定'},
        ]);
      }
    }
    setIsEditingIntro(false);
    updatedUserData.userIntro = undefined;
  };

  const handleSaveName = () => {
    if (updatedUserData.name != undefined && userSnapshot != undefined) {
      try {
        updateDoc(userSnapshot.ref, {name: updatedUserData.name});
      } catch (error) {
        alert('发送错误', '修改用户信息时发生错误: ' + error, [
          {onPress: () => {}, text: '确定'},
        ]);
      }
    }
    setIsEditingName(false);
    updatedUserData.name = undefined;
  };

  const handleEditUserName = () => {
    setIsEditingUserName(true);
    updatedUserData.userName = user.userName;
  };

  const handleEditUserIntro = () => {
    setIsEditingIntro(true);
    updatedUserData.userIntro = user.introduction;
  };

  const handleEditName = () => {
    setIsEditingName(true);
    updatedUserData.name = user.name;
  };

  const handleWechatChange = (text: string) => {
    setUpdatedUserData((prevUserData) => ({
      ...prevUserData,
      socialWechat: text,
    }));
    if (userSnapshot != undefined) {
      user.socialWechat = text;
    }
  };
  const handleEditWechat = () => {
    setIsEditingWechat(true);
    updatedUserData.socialWechat = user.socialWechat;
  };
  const handleSaveWechat = () => {
    if (
      updatedUserData.socialWechat != undefined &&
      userSnapshot != undefined
    ) {
      try {
        updateDoc(userSnapshot.ref, {
          socialWechat: updatedUserData.socialWechat,
        });
      } catch (error) {
        alert('发送错误', '修改用户信息时发生错误: ' + error, [
          {onPress: () => {}, text: '确定'},
        ]);
      }
    }
    updatedUserData.socialWechat = undefined;
    setIsEditingWechat(false);
  };

  return (
    <View>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={styles.scrollView}>
        {currentUserId != '' ? (
          <View>
            <View style={styles.fieldContainer}>
              <Text style={styles.label}>用户名:</Text>
              {isEditingUserName ? (
                <View>
                  <TextInput
                    onChangeText={handleUserNameChange}
                    style={styles.input}
                    value={updatedUserData.userName ?? user.userName}
                  />
                  <View style={styles.buttonContainer}>
                    <Button onPress={handleSaveUserName} title="保存" />
                  </View>
                </View>
              ) : (
                <View style={styles.textContainer}>
                  <Text style={styles.text}>{user.userName}</Text>
                  <FontAwesome
                    name="edit"
                    onPress={handleEditUserName}
                    size={20}
                  />
                </View>
              )}
            </View>
            <Separator />
          </View>
        ) : null}
        {currentUserId != '' ? (
          <View>
            <View style={styles.fieldContainer}>
              <Text style={styles.label}>姓名:</Text>
              {isEditingName ? (
                <View>
                  <TextInput
                    onChangeText={handleNameChange}
                    style={styles.input}
                    value={updatedUserData.name ?? user.name}
                  />
                  <View style={styles.buttonContainer}>
                    <Button onPress={handleSaveName} title="保存" />
                  </View>
                </View>
              ) : (
                <View style={styles.textContainer}>
                  <Text style={styles.text}>{user.name}</Text>
                  <FontAwesome name="edit" onPress={handleEditName} size={20} />
                </View>
              )}
            </View>
            <Separator />
          </View>
        ) : null}
        {currentUserId != '' ? (
          <View>
            <View style={styles.fieldContainer}>
              <Text style={styles.label}>微信Id: </Text>
              {isEditingWechat ? (
                <View>
                  <TextInput
                    onChangeText={handleWechatChange}
                    style={styles.input}
                    value={
                      updatedUserData.socialWechat ?? user.socialWechat ?? ''
                    }
                  />
                  <View style={styles.buttonContainer}>
                    <Button onPress={handleSaveWechat} title="保存" />
                  </View>
                </View>
              ) : (
                <View style={styles.textContainer}>
                  <Text style={styles.text}>{user.socialWechat}</Text>
                  <FontAwesome
                    name="edit"
                    onPress={handleEditWechat}
                    size={20}
                  />
                </View>
              )}
            </View>
            <View
              style={{
                backgroundColor: '#cccccc',
                height: 1,
                marginTop: 3,
                width: '100%',
              }}
            />
          </View>
        ) : null}
        {currentUserId != '' ? (
          <View>
            <View style={styles.fieldContainer}>
              <Text style={styles.label}>个人简介: </Text>
              {isEditingIntro ? (
                <View>
                  <TextInput
                    maxLength={100}
                    multiline={true}
                    onChangeText={handleUserIntroChange}
                    style={styles.input}
                    value={updatedUserData.userIntro ?? user.introduction ?? ''}
                  />
                  <View style={styles.buttonContainer}>
                    <Button onPress={handleSaveIntro} title="保存" />
                  </View>
                </View>
              ) : (
                <View style={styles.textContainer}>
                  <Text style={styles.text}>{user.introduction}</Text>
                  <FontAwesome
                    name="edit"
                    onPress={handleEditUserIntro}
                    size={20}
                  />
                </View>
              )}
            </View>
            <View
              style={{
                backgroundColor: '#cccccc',
                height: 1,
                marginTop: 3,
                width: '100%',
              }}
            />
          </View>
        ) : null}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  buttonContainer: {
    marginTop: 10,
  },
  container: {
    overflow: 'hidden',
    paddingBottom: 30,
    paddingLeft: 30,
    paddingRight: 30,
  },
  fieldContainer: {
    marginBottom: 8,
    marginTop: 8,
  },
  input: {
    borderColor: '#ccc',
    borderRadius: 5,
    borderWidth: 1,
    padding: 10,
  },
  label: {
    fontSize: 16,
    marginBottom: 5,
  },
  scrollView: {
    marginTop: 10,
  },
  separator: {
    backgroundColor: '#cccccc',
    height: 1,
    marginBottom: 3,
    marginTop: 3,
    width: '100%',
  },
  text: {
    flex: 1,
    fontSize: 16,
    marginBottom: 3,
  },
  textContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 2,
    marginTop: 2,
  },
});
