import {Feather, Ionicons} from '@expo/vector-icons';
import {
  collection,
  deleteDoc,
  deleteField,
  doc,
  getFirestore,
  query,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import {deleteObject, getStorage, ref} from 'firebase/storage';
import React from 'react';
import {useCollection, useDocument} from 'react-firebase-hooks/firestore';
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import AccountEditPersonalSettings from '../components/account/AccountEditPersonalSettings';
import alert from '../components/container/alert';
import Colors from '../constants/Colors';
import {useAuthContext} from '../context/AuthContext';
import {useFeaturesContext} from '../context/FeaturesContext';
import {getAuth} from '../firebase';
import groupConverter from '../firestoreConverters/groupConverter';
import userConverter from '../firestoreConverters/userConverter';
import userPostConverter from '../firestoreConverters/userPostConverter';
import {Group} from '../types';

export default function AccountSettingsScreen({navigation}: {navigation: any}) {
  const {currentUser} = useAuthContext();
  const currentUserId = currentUser?.id;
  if (currentUserId == null) {
    return <View />;
  }

  return (
    <AccountSettingsContent
      currentUserId={currentUserId}
      navigation={navigation}
    />
  );
}

function AccountSettingsContent({
  currentUserId,
  navigation,
}: {
  currentUserId: string;
  navigation: any;
}) {
  const {features} = useFeaturesContext();
  const {logout} = useAuthContext();
  const [groupsSnapshot] = useCollection(
    collection(getFirestore(), 'groups').withConverter(groupConverter),
  );

  const [userPosts] = useCollection(
    query(
      collection(
        getFirestore(),
        'user_posts',
        currentUserId,
        'posts',
      ).withConverter(userPostConverter),
    ),
  );
  const [userSnapshot] = useDocument(
    doc(getFirestore(), 'users', currentUserId).withConverter(userConverter),
  );

  const Separator = () => {
    return <View style={styles.separator} />;
  };

  const removeAccountAlert = () => {
    alert(
      '确认注销账户',
      '注销账户将删除账户的所有信息，确定要注销你的账户么？',
      [
        {
          onPress: () => console.log('Cancel Pressed'),
          style: 'cancel',
          text: '取消',
        },
        {
          onPress: () => {
            if (!userSnapshot) {
              return;
            }
            const allGroups: Group[] = [];
            if (groupsSnapshot) {
              groupsSnapshot.docs.forEach((groupdoc) => {
                const group = groupdoc.data();
                allGroups.push(group);
              });
            }
            const filteredGroup = allGroups.filter(
              (item) =>
                item.dateStart &&
                Timestamp.now().seconds - item.dateStart.seconds < 0 &&
                item.currentMembers.filter(
                  (member) =>
                    member?.id != null && member?.id === currentUserId,
                ).length > 0,
            );
            if (filteredGroup.length > 0) {
              alert(
                '',
                '你现在有未完成的组队，请通过 设置->用户协议 里的联系方式，联系我们来注销账户，谢谢你的配合！',
                [
                  {
                    onPress: () =>
                      console.log(
                        'User confirmed contact us to delete account',
                      ),
                    style: 'cancel',
                    text: '确认',
                  },
                ],
              );
              return;
            }

            userPosts?.docs.map((post) => {
              if (post.data().storageRef) {
                deleteObject(ref(getStorage(), post.data().storageRef));
              }
              deleteDoc(post.ref);
            });
            updateDoc(userSnapshot.ref, {
              created: deleteField(),
              phoneNumber: deleteField(),
              profileImageUrl: deleteField(),
              socialWechat: deleteField(),
              userName: 'User Deleted',
            });
            getAuth().currentUser?.delete();
            logout(() => {
              navigation.goBack();
            });
          },
          text: '确认',
        },
      ],
    );
  };

  return (
    <ScrollView contentContainerStyle={{flexGrow: 1}} style={styles.container}>
      {currentUserId != '' ? (
        <AccountEditPersonalSettings userId={currentUserId} />
      ) : null}
      {features?.enable_refer && (
        <View>
          <TouchableOpacity onPress={() => navigation.push('ReferAndDiscount')}>
            <View
              style={
                (styles.fieldContainer,
                {
                  alignItems: 'center',
                  backgroundColor: 'rgba(102, 187,106, 0.15)',
                  flexDirection: 'row',
                  paddingVertical: 15,
                })
              }>
              <Ionicons
                color={Colors.mainAppColor}
                name="gift-outline"
                size={24}
              />
              <Text
                style={[
                  styles.text,
                  {color: Colors.mainAppColor, marginLeft: 4},
                ]}>
                Give $5. Get $5.
              </Text>
              <Feather
                color="black"
                name="chevron-right"
                size={24}
                style={{alignItems: 'flex-end'}}
              />
            </View>
          </TouchableOpacity>
          <View
            style={{
              backgroundColor: '#cccccc',
              height: 1,
              marginBottom: 10,
              width: '100%',
            }}
          />
        </View>
      )}
      <TouchableOpacity onPress={() => navigation.push('UserTerms')}>
        <View
          style={
            (styles.fieldContainer,
            {
              flexDirection: 'row',
              marginBottom: 8,
              marginTop: features?.enable_refer ? 8 : 23,
            })
          }>
          <Text style={styles.text}>用户协议</Text>
          <Feather
            color="black"
            name="chevron-right"
            size={24}
            style={{alignItems: 'flex-end'}}
          />
        </View>
      </TouchableOpacity>
      <Separator />
      <TouchableOpacity onPress={() => navigation.push('ResponsibilityTerms')}>
        <View
          style={
            (styles.fieldContainer, {flexDirection: 'row', marginVertical: 8})
          }>
          <Text style={styles.text}>免责声明</Text>
          <Feather
            color="black"
            name="chevron-right"
            size={24}
            style={{alignItems: 'flex-end'}}
          />
        </View>
      </TouchableOpacity>
      <Separator />
      <TouchableOpacity onPress={() => navigation.push('PrivacyPolicy')}>
        <View
          style={
            (styles.fieldContainer, {flexDirection: 'row', marginVertical: 8})
          }>
          <Text style={styles.text}>隐私条款</Text>
          <Feather
            color="black"
            name="chevron-right"
            size={24}
            style={{alignItems: 'flex-end'}}
          />
        </View>
      </TouchableOpacity>
      <Separator />
      <TouchableOpacity onPress={removeAccountAlert}>
        <View
          style={
            (styles.fieldContainer, {flexDirection: 'row', marginVertical: 8})
          }>
          <Text style={styles.text}>注销账户</Text>
          <Feather
            color="black"
            name="chevron-right"
            size={24}
            style={{alignItems: 'flex-end'}}
          />
        </View>
      </TouchableOpacity>
      <Separator />
      <TouchableOpacity
        onPress={() => {
          logout(() => {
            navigation.goBack();
          });
        }}
        style={{
          alignItems: 'center',
          borderColor: '#999999',
          borderRadius: 8,
          borderWidth: 1,
          marginBottom: 16,
          marginTop: 8,
          padding: 8,
          width: '100%',
        }}>
        <View
          style={
            (styles.fieldContainer,
            {
              flexDirection: 'row',
              marginVertical: 8,
            })
          }>
          <Text style={[styles.text, {fontWeight: '700', textAlign: 'center'}]}>
            登出账户
          </Text>
        </View>
      </TouchableOpacity>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    paddingHorizontal: 16,
  },
  fieldContainer: {
    marginBottom: 8,
    marginTop: 8,
  },
  input: {
    borderColor: '#ccc',
    borderRadius: 5,
    borderWidth: 1,
    padding: 10,
  },
  label: {
    fontSize: 16,
    marginBottom: 5,
  },
  separator: {
    backgroundColor: '#cccccc',
    height: 1,
    marginBottom: 10,
    marginTop: 3,
    width: '100%',
  },
  text: {
    flex: 1,
    fontSize: 16,
  },
  textContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 2,
    marginTop: 2,
  },
});
