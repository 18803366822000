import * as Linking from 'expo-linking';
import {
  Image,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import {judgeClient} from '../../helpers/util';
import alert from './alert';

export default function WebDeeplinkHeaderView({path}: {path: string}) {
  const isWeChat =
    Platform.OS === 'web' && navigator.userAgent.indexOf('MicroMessenger') > -1;
  if (isWeChat) {
    return null;
  }
  const url = path === '' ? 'rabbit://' : 'rabbit://' + path;
  const downloadUrl =
    judgeClient() === 'web-ios'
      ? 'https://apps.apple.com/us/app/%E9%87%8E%E5%85%94%E7%BB%84%E9%98%9F/id6448244192'
      : 'https://play.google.com/store/apps/details?id=com.yaytour.androidapp';
  const onPressOpen = async () => {
    console.log('url is ' + url);
    try {
      const canOpen = await Linking.canOpenURL(url);
      if (canOpen) {
        Linking.openURL(url);
      } else {
        console.log('App is not installed');
        Linking.openURL(downloadUrl);
      }
    } catch (error) {
      console.log('canOpenURL: ' + error);
      Linking.openURL(downloadUrl);
    }
  };
  const onPressDownload = async () => {
    try {
      Linking.openURL(downloadUrl);
    } catch (error) {
      console.log('openDownloadUrl: ' + error);
      alert(
        '',
        '对不起, 出了一点小问题, 请前往App Store, 搜索"野兔组队"并下载我们的最新版官方App',
        [
          {
            onPress: () => {},
            text: '确定',
          },
        ],
      );
    }
  };
  return (
    <View style={styles.container}>
      <View style={styles.leftContainer}>
        <Image
          source={require('../../assets/images/yay_logo.png')}
          style={styles.iconImage}
        />
        <Text style={styles.title}>野兔组队</Text>
      </View>
      <View style={styles.rightContainer}>
        <TouchableOpacity
          onPress={onPressDownload}
          style={styles.buttonDownload}>
          <Text style={styles.buttonTextDownload}>下载App</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={onPressOpen} style={styles.button}>
          <Text style={styles.buttonText}>打开App</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: '#66BB6A',
    borderRadius: 8,
    marginHorizontal: 4,
    paddingHorizontal: 8,
    paddingVertical: 8,
  },
  buttonDownload: {
    borderColor: '#66BB6A',
    borderRadius: 8,
    borderWidth: 1,
    marginHorizontal: 4,
    paddingHorizontal: 8,
    paddingVertical: 8,
  },
  buttonText: {
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold',
  },
  buttonTextDownload: {
    color: '#66BB6A',
    fontSize: 16,
    fontWeight: 'bold',
  },
  container: {
    alignItems: 'center',
    backgroundColor: '#f2f2f2',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  iconImage: {
    borderRadius: 30 / 2,
    height: 30,
    position: 'relative',
    width: 30,
  },
  leftContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  rightContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    marginLeft: 8,
  },
});
