import {useNavigation} from '@react-navigation/native';
import {FirebaseRecaptchaVerifierModal} from 'expo-firebase-recaptcha';
import {FirebaseError} from 'firebase/app';
import {PhoneAuthProvider} from 'firebase/auth';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Image, Platform, StyleSheet, Text, View} from 'react-native';
import PhoneInput from 'react-native-phone-number-input';

import Checkbox from '../components/buttons/Checkbox';
import PrimaryButton from '../components/buttons/PrimaryButton';
import alert from '../components/container/alert';
import KeyboardDismissView from '../components/container/KeyboardDismissView';
import OpenInDefaultBrowserScreen from '../components/misc/OpenInDefaultBrowser';
import {FIREBASE_CONFIG} from '../constants/config';
import {DEBUG_ONLY, ENABLE_WECHAT_REDIRECT} from '../constants/dev';
import {getAuth} from '../firebase';

export default function LoginInputScreen({
  navigation,
  route,
}: {
  navigation: any;
  route: any;
}) {
  const {referCode} = route.params ?? '';
  const [value, setValue] = useState('');
  const [formattedValue, setFormattedValue] = useState('');
  const phoneInput = useRef<PhoneInput>(null);
  const recaptchaVerifier = useRef(null);
  const [isChecked, setIsChecked] = useState(true);

  const auth = getAuth();
  const [requestCodeDisabled, setRequestCodeDisabled] =
    useState<boolean>(false);

  const isWeChat =
    Platform.OS === 'web' && navigator.userAgent.indexOf('MicroMessenger') > -1;

  const onRequestCode = useCallback(async () => {
    setRequestCodeDisabled(true);
    try {
      const phoneProvider = new PhoneAuthProvider(auth);
      const phoneVerifyId = await phoneProvider.verifyPhoneNumber(
        formattedValue,
        // @ts-ignore null value will be caught in the catch statement
        recaptchaVerifier.current,
      );
      setRequestCodeDisabled(false);
      navigation.navigate('Verify', {
        formattedPhoneNumber: formattedValue,
        referCode: referCode ?? '',
        verificationId: phoneVerifyId,
      });
    } catch (error: unknown) {
      const title = '登陆失败';
      let description = '请稍后再试';
      if (error instanceof FirebaseError) {
        description = '错误代码: ' + error.code;
        if (error.code === 'auth/too-many-requests') {
          description += '; 尝试登陆太多次，请稍后再试';
        }
      }
      if (isWeChat) {
        description +=
          ' 如果您在微信内置网页登陆遇到问题, 请点击右上角，在默认浏览器打开';
      }
      alert(title, description, [{onPress: () => {}, text: '确定'}]);
      setRequestCodeDisabled(false);
      console.log('Error happens when verifying the phone number, ' + error);
    }
  }, [auth, formattedValue, isWeChat, navigation, referCode]);
  const disabled =
    requestCodeDisabled ||
    !phoneInput.current?.isValidNumber(value) ||
    !isChecked;

  let description = '请重新登陆';
  if (!phoneInput.current?.isValidNumber(value)) {
    description = '请输入有效的电话号码';
  } else if (!isChecked) {
    description = '请阅读并同意用户协议，隐私政策和接受服务短信';
  } else if (isWeChat) {
    description =
      '如果您在微信内置网页登陆遇到问题, 请点击右上角，在默认浏览器打开';
  }
  const alertUser = () => {
    alert(
      '',
      description,
      [{onPress: () => console.log('OK pressed'), text: '确定'}],
      {cancelable: true},
    );
  };
  // expo-firebase-recaptcha has issue with expo development build
  // see https://github.com/expo/expo/issues/11256
  // can also just disable `attemptInvisibleVerification` for Dev build
  const [isInit, setisInit] = useState(false);
  useEffect(() => {
    setTimeout(function () {
      setisInit(true);
    }, 1000);
    return () => {
      setisInit(false);
    };
  }, []);

  if (isWeChat && ENABLE_WECHAT_REDIRECT) {
    return <OpenInDefaultBrowserScreen />;
  } else {
    return (
      <KeyboardDismissView>
        <View style={styles.container}>
          <Image
            source={require('../assets/images/yay_logo.png')}
            style={styles.image}
          />
          <Text style={styles.headerText}>野兔组队</Text>
          <Text style={styles.subTitleText}>新人不迷路，单人不孤独</Text>
          {isInit && (
            <FirebaseRecaptchaVerifierModal
              androidHardwareAccelerationDisabled
              androidLayerType="software"
              attemptInvisibleVerification
              firebaseConfig={FIREBASE_CONFIG}
              ref={recaptchaVerifier}
            />
          )}
          <PhoneInput
            autoFocus={false}
            containerStyle={styles.phoneInputContainer}
            defaultCode="US"
            defaultValue={value}
            layout="first"
            onChangeFormattedText={(text) => {
              setFormattedValue(text);
            }}
            onChangeText={(text) => {
              setValue(text);
            }}
            placeholder="请输入手机号"
            ref={phoneInput}
            textContainerStyle={{
              borderRadius: 4,
            }}
            textInputProps={{returnKeyType: 'done'}}
            withShadow
          />
          <View style={{flexDirection: 'row'}}>
            <Text style={[styles.text, {color: '#333333'}]}>
              未注册的手机号验证后自动创建野兔组队账号
            </Text>
          </View>
          <PrimaryButton
            disabled={disabled}
            onDisablePress={alertUser}
            onPress={disabled ? alertUser : onRequestCode}
            text="获取短信验证码"
          />
          <View style={{flexDirection: 'row'}}>
            <View style={styles.checkbox}>
              <Checkbox onChange={setIsChecked} value={isChecked} />
            </View>
            <Text style={[styles.text, {color: '#616161'}]}>
              登陆代表您同意野兔组队
              <Text
                onPress={() => {
                  navigation.navigate('UserTerms');
                }}
                style={styles.linkText}>
                用户服务协议
              </Text>
              {', '}
              <Text
                onPress={() => {
                  navigation.navigate('PrivacyPolicy');
                }}
                style={styles.linkText}>
                隐私协议
              </Text>
              {', '}
              <Text
                onPress={() => {
                  navigation.navigate('ResponsibilityTerms');
                }}
                style={styles.linkText}>
                免责声明
              </Text>
              和接受服务短信
            </Text>
          </View>
          {DEBUG_ONLY ? (
            <View>
              <Text>Value : {value}</Text>
              <Text>Formatted Value : {formattedValue}</Text>
            </View>
          ) : null}
        </View>
      </KeyboardDismissView>
    );
  }
}

const styles = StyleSheet.create({
  checkbox: {
    justifyContent: 'flex-start',
    marginRight: 8,
    marginTop: -2,
  },
  container: {
    alignItems: 'center',
    flex: 1,
    padding: 16,
  },
  headerText: {
    fontSize: 32,
    fontWeight: '700',
    lineHeight: 36,
    marginBottom: 8,
    textAlign: 'center',
    width: 224,
  },
  image: {
    height: 200,
    width: 200,
  },
  linkText: {
    color: '#66BB6A',
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: 18,
    textDecorationLine: 'underline',
  },
  phoneInputContainer: {
    borderRadius: 4,
    marginBottom: 8,
    width: '100%',
  },
  separator: {
    color: '#eee',
    height: 1,
    marginVertical: 30,
    width: '80%',
  },
  subTitleText: {
    color: '#616161',
    fontSize: 16,
    fontWeight: '700',
    lineHeight: 23,
    marginBottom: 32,
    opacity: 0.7,
    textAlign: 'center',
  },
  text: {
    flex: 1,
    flexDirection: 'row',
    fontSize: 12,
    lineHeight: 18,
    marginBottom: 24,
    textAlign: 'left',
  },
});
