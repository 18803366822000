import {Feather, Ionicons} from '@expo/vector-icons';
import {useNavigation} from '@react-navigation/native';
import {doc, getDoc, updateDoc} from 'firebase/firestore';
import {getFunctions, httpsCallable} from 'firebase/functions';
import React, {useCallback, useEffect, useLayoutEffect, useState} from 'react';
import {
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from 'react-native';

import {
  RefundAndQuitGroupRequest,
  StartStripeRefundResult,
  StripeRefundDetailResult,
} from '../../functions/src';
import alert from '../components/container/alert';
import {YAYTOUR_WECHAT_SUPPORT} from '../constants/config';
import {useAuthContext} from '../context/AuthContext';
import {getFirestore} from '../firebase';
import {
  getFirstHeaderImageUrlFromGroup,
  headerImageEmpty,
} from '../helpers/imageUtil';
import FastImage from '../platforms/FastImage';
import LoadingScreen from './LoadingScreen';

const length = '96%';

export default function RefundModalScreen({route}: {route: any}) {
  const navigation: any = useNavigation();
  const {group} = route.params;
  const {currentUser} = useAuthContext();
  const {height} = useWindowDimensions();

  const [scrollEnabled, setScrollEnabled] = useState(false);
  const [refundDetail, setRefundDetail] = useState<StripeRefundDetailResult>({
    eligible_for_refund: false,
    number_of_people: 0,
    original_price_in_dollar: 0,
    // original fee & tax
    original_service_fee_in_dollar: 0,
    paid_ticket_options: [],
    // price before fee
    payment_intent_id: '',
    refund_amount_in_cents: 0,
    refund_service_fee_in_cents: 0,
    refund_window_in_hour: 0,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getRefundDetail = async () => {
      console.log('currentUser?.id: ', currentUser?.id);
      console.log('group_id: ', group.id);

      const requestData = {
        group_id: group.id,
        yaytour_user_id: currentUser?.id,
      };
      const functions = getFunctions();
      const startRefund = httpsCallable(functions, 'retrieverefunddetail');

      try {
        const result = await startRefund(requestData);
        const startRefundResult = result.data as StripeRefundDetailResult;
        setRefundDetail(startRefundResult);
        setLoading(false);
      } catch (error) {
        alert(
          '获取退款信息时发生错误',
          `请稍后重试或联系野兔小助手～ 微信号：${YAYTOUR_WECHAT_SUPPORT}`,
          [
            {
              onPress: () => {},
              text: '确定',
            },
          ],
          {cancelable: true},
        );
        navigation.goBack();
      }
    };

    getRefundDetail();
  }, [currentUser?.id, group.id, navigation]);

  const initializeRefund = useCallback(async () => {
    const requestData = {
      group_id: group.id,
      payment_intent_id: refundDetail.payment_intent_id,
      refund_amount_in_cents: refundDetail.refund_amount_in_cents,
      yaytour_user_id: currentUser?.id,
    } as RefundAndQuitGroupRequest;

    const functions = getFunctions();
    const stripeRefund = httpsCallable(
      functions,
      'proccessrefundandquitgroupv2',
    );

    try {
      setLoading(true);
      const httpResult = await stripeRefund(requestData);

      const stripeRefundResult = httpResult.data as StartStripeRefundResult;
      console.log(stripeRefundResult);
      switch (stripeRefundResult.refund_status) {
        case 'succeeded':
          alert('退款成功！', '请查看银行对账单～', [
            {
              onPress: () => {},
              text: '确定',
            },
          ]);

          console.log('Refund succeeded');
          break;
        case 'failed':
          throw new Error('Refund failed');
        case 'no_refund_needed':
          alert('退出组队成功', '', [
            {
              onPress: () => {},
              text: '确定',
            },
          ]);
          break;
        default:
          alert(
            '退款正在进行中',
            '请关注银行对账单，或联系野兔小助手查看进度～',
            [
              {
                onPress: () => {},
                text: '确定',
              },
            ],
          );
          console.log('Refund in proccess');
          break;
      }

      // Revert the ticket option quantity
      if (
        refundDetail.paid_ticket_options &&
        refundDetail.paid_ticket_options.length > 0
      ) {
        const groupRef = doc(getFirestore(), 'groups', group?.id);
        const groupSnap = await getDoc(groupRef);

        if (!groupSnap.exists()) {
          console.log('Data not exists');
          return;
        }

        const groupData = groupSnap.data();
        const updatedTicketOptions = [...groupData.ticketOptions];

        refundDetail.paid_ticket_options.forEach((refundOption) => {
          const refundOptionName = refundOption.optionName;
          const refundOptionQuantity = refundOption.quantity;

          // Find the matching ticket option by name
          const matchingTicketOption = updatedTicketOptions.find(
            (ticketOption) => ticketOption.optionName === refundOptionName,
          );

          if (matchingTicketOption) {
            // Update the quantity of the matching ticket option
            matchingTicketOption.optionQuantity = (
              parseInt(matchingTicketOption.optionQuantity, 10) +
              refundOptionQuantity
            ).toString();
          }
        });
        await updateDoc(groupRef, {
          ticketOptions: updatedTicketOptions,
        });
      }

      navigation.navigate('GroupHome');
    } catch (error) {
      alert(
        '退款时发送错误',
        `请稍后重试或联系野兔小助手～ 微信号：${YAYTOUR_WECHAT_SUPPORT}`,
        [
          {
            onPress: () => {},
            text: '确定',
          },
        ],
      );
      console.error('error occurs when starting Stripe refund: ', error);
    }

    navigation.goBack();
  }, [currentUser?.id, group.id, navigation, refundDetail]);

  const TitleView = () => {
    return (
      <View style={styles.titleView}>
        <Ionicons color="#66BB6A" name="checkmark-circle-outline" size={24} />
        <Text style={styles.title}>退出组队</Text>
      </View>
    );
  };

  const AlertMessageView = () => {
    return (
      <View style={styles.alertMessageContainer}>
        <Feather
          color="#faf064"
          name="alert-circle"
          size={24}
          style={{paddingRight: 10}}
        />
        {refundDetail?.eligible_for_refund && (
          <Text>
            <Text style={styles.body}>
              您已经完成付款！请注意，一旦您退出组队，您的位置将不再被保留。退款将在活动结束后的
            </Text>
            <Text style={styles.boldBody}>7-12</Text>
            <Text style={styles.body}>天内退回到您的支付账户上，但</Text>
            <Text style={styles.boldBody}>手续费用是不可退还的</Text>。
          </Text>
        )}

        {!refundDetail?.eligible_for_refund && (
          <Text>
            <Text style={styles.body}>您已经完成付款！请注意，您的活动</Text>
            <Text style={styles.boldBody}>将在</Text>
            <Text style={styles.boldBody}>
              {refundDetail.refund_window_in_hour}
            </Text>
            <Text style={styles.boldBody}>小时内</Text>
            <Text style={styles.body}>开始。如果您现在选择退出组队，将</Text>
            <Text style={styles.boldBody}>不会有退款</Text>。
          </Text>
        )}
      </View>
    );
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      headerBackVisible: false,
      headerTitle: () => <TitleView />,
    });
  });

  if (loading) {
    return <LoadingScreen />;
  } else {
    return (
      <ScrollView
        contentContainerStyle={{flexGrow: 1}} // https://stackoverflow.com/questions/41782600/scrollview-and-child-with-flex-1 saved my ass
        onContentSizeChange={(contentHeight) => {
          setScrollEnabled(contentHeight + 200 >= height);
        }}
        scrollEnabled={Platform.OS === 'web' || scrollEnabled}>
        <View style={styles.container}>
          <Text style={styles.text}>费用与退款明细如下：</Text>

          <AlertMessageView />

          <View style={styles.cardContainer}>
            {refundDetail.paid_ticket_options &&
            refundDetail.paid_ticket_options.length > 0 ? (
              <View>
                <Text
                  ellipsizeMode={'tail'}
                  numberOfLines={2}
                  style={[styles.groupName]}>
                  {group.title}
                </Text>
                {refundDetail.paid_ticket_options.map((ticketOption, index) => (
                  <View key={index} style={styles.productRow}>
                    {headerImageEmpty(group) ? (
                      <FastImage
                        source={require('../assets/images/icon.png')}
                        style={styles.productImage}
                      />
                    ) : (
                      <FastImage
                        source={{uri: getFirstHeaderImageUrlFromGroup(group)}}
                        style={styles.productImage}
                      />
                    )}

                    <View style={[styles.productDetails]}>
                      <Text
                        ellipsizeMode={'tail'}
                        numberOfLines={2}
                        style={styles.productTitle}>
                        {ticketOption.optionName}
                      </Text>
                      <Text style={styles.productSubtitle}>
                        人数 {ticketOption.quantity}
                      </Text>
                    </View>
                    <View>
                      <Text style={styles.optionPrice}>
                        ${ticketOption.optionPrice * ticketOption.quantity}
                      </Text>
                      <Text style={styles.optionUnitPrice}>
                        ${ticketOption.optionPrice}每人
                      </Text>
                    </View>
                  </View>
                ))}
              </View>
            ) : (
              <View style={styles.productRow}>
                {headerImageEmpty(group) ? (
                  <FastImage
                    source={require('../assets/images/icon.png')}
                    style={styles.productImage}
                  />
                ) : (
                  <FastImage
                    source={{uri: getFirstHeaderImageUrlFromGroup(group)}}
                    style={styles.productImage}
                  />
                )}

                <View style={styles.productDetails}>
                  <Text
                    ellipsizeMode={'tail'}
                    numberOfLines={2}
                    style={styles.productTitle}>
                    {group.title}
                  </Text>
                  <Text style={styles.productSubtitle}>
                    人数 {refundDetail.number_of_people}
                  </Text>
                </View>
                <Text style={styles.rightText}>${group.bugetPerPerson}</Text>
              </View>
            )}

            <View style={{marginLeft: 72, marginRight: 12, marginVertical: 8}}>
              <View style={styles.row}>
                <Text style={styles.leftText}>小计</Text>
                <Text style={styles.rightText}>
                  ${refundDetail.original_price_in_dollar}
                </Text>
              </View>
              <View style={styles.separator} />
              <View style={styles.row}>
                <Text style={styles.leftText}>原始费用</Text>
                <Text style={styles.rightText}>
                  ${refundDetail.original_service_fee_in_dollar}
                </Text>
              </View>
              <View style={styles.separator} />
              <View style={styles.row}>
                <Text style={styles.leftText}>原始总计</Text>
                <Text style={styles.rightText}>
                  $
                  {(
                    refundDetail.original_price_in_dollar +
                    refundDetail.original_service_fee_in_dollar
                  ).toFixed(2)}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.rightTextGray}>价格含税</Text>
              </View>

              <View style={styles.separator} />
              <View style={styles.row}>
                <Text style={styles.leftText}>退款手续费</Text>
                <Text style={styles.rightText}>
                  ${(refundDetail.refund_service_fee_in_cents / 100).toFixed(2)}
                </Text>
              </View>

              <View style={styles.separator} />
              <View style={styles.row}>
                <Text style={styles.leftText}>退款总金额</Text>
                <Text style={styles.rightText}>
                  $
                  {refundDetail.eligible_for_refund
                    ? (refundDetail.refund_amount_in_cents / 100).toFixed(2)
                    : 0}
                </Text>
              </View>
            </View>
          </View>

          <TouchableOpacity
            onPress={initializeRefund}
            style={[styles.payNowButton]}>
            <Text style={styles.payNowButtonText}>退出组队</Text>
          </TouchableOpacity>
        </View>
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  alertMessageContainer: {
    alignItems: 'center',
    backgroundColor: '#fffce4',
    borderRadius: 10,
    flexDirection: 'row',
    margin: 18,
    padding: 18,
  },
  body: {},
  boldBody: {
    fontWeight: 'bold',
  },
  cardContainer: {
    backgroundColor: 'white',
    borderRadius: 8,
    elevation: 2,
    margin: 16,
    padding: 16,
    shadowColor: '#000',
    shadowOffset: {
      height: 4,
      width: 1,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    width: '100%',
  },
  container: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'flex-start',
    paddingHorizontal: 16,
  },
  groupName: {
    fontSize: 18,
    marginLeft: 72,
    marginRight: 12,
    marginVertical: 8,
    paddingVertical: 2,
  },
  image: {
    height: 48,
    marginRight: 8,
    width: 48,
  },
  leftText: {
    flex: 1,
    fontSize: 14,
  },
  optionPrice: {
    fontSize: 18,
    paddingVertical: 2,
    textAlign: 'right',
  },
  optionUnitPrice: {
    color: 'lightgray',
    fontSize: 12,
    paddingVertical: 2,
    textAlign: 'right',
  },
  payLaterButton: {
    alignContent: 'center',
    backgroundColor: 'white',
    borderColor: '#66BB6A',
    borderRadius: 5,
    borderWidth: 1,
    flex: 1,
    justifyContent: 'center',
    margin: 8,
    padding: 8,
    width: '100%',
  },
  payLaterButtonText: {
    color: '#66BB6A',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  payNowButton: {
    alignContent: 'center',
    backgroundColor: '#66BB6A',
    borderColor: '#66BB6A',
    borderRadius: 5,
    borderWidth: 1,
    justifyContent: 'center',
    margin: 8,
    padding: 8,
    width: '100%',
  },
  payNowButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  productDetails: {
    flex: 3,
    flexDirection: 'column',
    marginHorizontal: 12,
  },
  productImage: {
    aspectRatio: 1,
    borderRadius: 5,
    width: 48,
  },
  productRow: {
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 8,
    marginHorizontal: 12,
  },
  productSubtitle: {
    fontSize: 12,
    fontWeight: 'bold',
    paddingVertical: 2,
  },
  productTitle: {
    fontSize: 18,
    paddingVertical: 2,
  },
  rightText: {
    flex: 1,
    fontSize: 14,
    textAlign: 'right',
  },
  rightTextGray: {
    color: 'lightgray',
    flex: 1,
    fontSize: 12,
    textAlign: 'right',
  },
  row: {
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 8,
  },
  separator: {
    backgroundColor: 'lightgray',
    height: 1,
    marginVertical: 8,
  },
  text: {
    flexDirection: 'row',
    fontSize: 18,
    paddingVertical: 8,
    width: length,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  titleView: {
    alignItems: 'center',
    alignSelf: 'center',
    flexDirection: 'row',
  },
});
