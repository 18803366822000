import {Ionicons} from '@expo/vector-icons';
import {useNavigation} from '@react-navigation/native';
import {useLayoutEffect, useState} from 'react';
import {Linking, StyleSheet, Text, TouchableOpacity, View} from 'react-native';

import {useAuthContext} from '../context/AuthContext';
import {eligibleForTip} from '../helpers/util';

const length = '96%';
const padding = '2%';

export default function JoinGroupTipScreen({route}: {route: any}) {
  const navigation: any = useNavigation();
  const {group, groupQrCodeUrl, standAlone} = route.params;
  const [buttonTitle, setButtonTitle] = useState('跳过');
  const {currentUser} = useAuthContext();

  const navigationBehavior = () => {
    if (standAlone) {
      navigation.pop();
    } else {
      navigation.navigate('JoinGroupSuccessfulScreen', {
        group: group,
        groupQrCodeUrl: groupQrCodeUrl,
      });
    }
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      headerBackVisible: false,
      title: '',
    });
  });

  const openVenmoLink = async (url: any) => {
    // Check if the Venmo app is installed on the device
    const isVenmoInstalled = await Linking.canOpenURL(url);

    if (isVenmoInstalled) {
      // If the Venmo app is installed, open the Venmo link
      await Linking.openURL(url);
    } else {
      // If the Venmo app is not installed, open the Venmo website in a browser
      await Linking.openURL('https://venmo.com/');
    }
    setButtonTitle('已完成');
  };

  if (!eligibleForTip(group)) {
    navigationBehavior();
  }

  const venmoAccountId = group.tips[0];
  const venmoDefaultAmount =
    group?.tips.length >= 2 && group.tips[1] ? group.tips[1] : '5';
  const groupPromotionText =
    group?.tips.length >= 3 && group.tips[2]
      ? group.tips[2]
      : '此次活动完全免费，不收取服务费，但是您可以选择为我们买一杯咖啡。小费金额可编辑。';

  const venmoNote = 'yaytour activity, wechat_id:' + currentUser?.socialWechat;
  const venmoUrl = encodeURI(
    'https://venmo.com/' +
      venmoAccountId +
      '?txn=pay&note=' +
      venmoNote +
      '&amount=' +
      venmoDefaultAmount,
  );
  return (
    <View style={styles.container}>
      <View style={styles.titleView}>
        <Ionicons color="#66BB6A" name="checkmark-circle-outline" size={24} />
        <Text style={styles.title}>欢迎您参与活动</Text>
      </View>
      <Text style={styles.text}>{groupPromotionText}</Text>
      <View style={styles.venmoContainer}>
        <Text style={styles.venmoAmount}>建议金额 ${venmoDefaultAmount}</Text>
        <TouchableOpacity
          onPress={() => {
            // Call the function with the Venmo link you want to open
            openVenmoLink(venmoUrl);
          }}
          style={styles.venmoButton}>
          <Text style={styles.shareButtonText}>打开 Venmo</Text>
        </TouchableOpacity>
      </View>
      <View style={{height: 30}} />
      <Text style={styles.text}>
        您也可以选择跳过，在活动结束之后再添加小费，感谢您的参与！
      </Text>
      <TouchableOpacity
        onPress={() => {
          navigationBehavior();
        }}
        style={styles.shareButton}>
        <Text style={styles.shareButtonText}>{buttonTitle}</Text>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'flex-start',
    backgroundColor: 'white',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingHorizontal: padding,
  },
  separator: {
    alignSelf: 'center',
    backgroundColor: '#c0c0c0',
    height: 1,
    marginVertical: 20,
    width: length,
  },
  shareButton: {
    alignSelf: 'center',
    backgroundColor: '#66BB6A',
    borderRadius: 5,
    bottom: 20,
    marginTop: 40,
    paddingVertical: 8,
    position: 'absolute',
    width: length,
  },
  shareButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  text: {
    fontSize: 18,
    paddingHorizontal: padding,
    paddingVertical: 8,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  titleView: {
    alignItems: 'center',
    alignSelf: 'center',
    flexDirection: 'row',
    paddingVertical: 16,
  },
  venmoAmount: {
    flex: 1,
    fontSize: 18,
  },
  venmoButton: {
    alignSelf: 'center',
    backgroundColor: '#66BB6A',
    borderRadius: 5,
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  venmoContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: padding,
    width: length,
  },
  venmoTitle: {
    fontSize: 18,
  },
});
