const tintColorLight = '#2f95dc';
const tintColorDark = '#fff';

export default {
  dark: {
    background: '#000',
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorDark,
    text: '#fff',
    tint: tintColorDark,
  },
  light: {
    background: '#fff',
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorLight,
    text: '#000',
    tint: tintColorLight,
  },
  mainAppColor: '#66BB6A',
};
