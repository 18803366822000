// Discount code for new registered users referred by others
// Needs to be created in database first, initial {discountAmount: 5, discountType: 'fixed', isSingleUsePerUser: true}
export const WELCOME = 'WELCOME5';
// Discount code for refer
// Needs to be created in database first, initial {discountAmount: 5, discountType: 'fixed', isSingleUsePerUser: true}
export const REFER_CODES = [
  'REFER_1',
  'REFER_2',
  'REFER_3',
  'REFER_4',
  'REFER_5',
  'REFER_6',
  'REFER_7',
  'REFER_8',
  'REFER_9',
  'REFER_10',
];

export const SYSTEM_COUPON = REFER_CODES.concat(WELCOME);

export const EXPIRATION_PERIOD_IN_DAYS = 60;
