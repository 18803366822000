import {Ionicons} from '@expo/vector-icons';
import {doc, getDoc, onSnapshot, setDoc, Timestamp} from 'firebase/firestore';
import React, {useEffect, useLayoutEffect, useState} from 'react';
import {
  FlatList,
  Image,
  Platform,
  Pressable,
  StyleSheet,
  Switch,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import {useAuthContext} from '../../context/AuthContext';
import {useFeaturesContext} from '../../context/FeaturesContext';
import {getFirestore} from '../../firebase';
import groupConverter from '../../firestoreConverters/groupConverter';
import userConverter from '../../firestoreConverters/userConverter';
import {produceDateTimeString} from '../../helpers/util';
import LoadingScreen from '../../screens/LoadingScreen';
import {emptyUser, Group, User} from '../../types';

export default function ChatDetails({
  navigation,
  route,
}: {
  navigation: any;
  route: any;
}) {
  const {features} = useFeaturesContext();
  const currentUser = useAuthContext().currentUser;
  const {groupId, isCreatorEnabled, isGroupAssistantEnabled} = route.params;
  const [loading, setLoading] = useState(true);
  const [members, setMembers] = useState<User[]>();
  const [cells, setCells] = useState<User[] | null[]>();
  const [group, setGroup] = useState<Group>();
  const [importantInfo, setImportantInfo] = useState('');
  const [importantInfoTime, setImportantInfoTime] = useState<Timestamp>();
  const [groupAssistantEnableStatus, setGroupAssistantEnableStatus] =
    useState<boolean>(isGroupAssistantEnabled);

  const toggleEnableGroupAssistantSwitch = () => {
    setGroupAssistantEnableStatus((previousState) => !previousState);
  };

  useEffect(() => {
    const fetchGroupData = async () => {
      try {
        const ref = doc(getFirestore(), 'groups', groupId).withConverter(
          groupConverter,
        );
        const groupSnap = await getDoc(ref);
        if (!groupSnap.exists()) {
          console.log('Data not exists');
          return;
        }
        const groupData = groupSnap.data();
        setGroup(groupData);

        const membersList = groupData.currentMembers;

        const uniqueMembersList = membersList.reduce(
          (acc: User[], curr: User) => {
            // Check if the current user's id already exists in the accumulator array
            if (!acc.some((user) => user.id === curr.id)) {
              // If it's not a duplicate, add the user object to the accumulator array
              acc.push(curr);
            }
            return acc;
          },
          [],
        );

        const result: User[] = [];

        // Create an array of promises for each user
        const promises = uniqueMembersList.map(async (m: User) => {
          try {
            const cu = (
              await getDoc(
                doc(getFirestore(), 'users', m.id).withConverter(userConverter),
              )
            ).data();
            if (cu != null) {
              return cu;
            }
          } catch (error) {
            console.error('Error fetching user:', error);
          }
          return null;
        });

        // Wait for all promises to resolve
        Promise.all(promises).then((users) => {
          // Filter out any null values and add non-null users to the result array
          result.push(...users.filter((user) => user != null));
          setMembers(result);

          const COLUMN_COUNT = 4;
          const numEmptyCells =
            (COLUMN_COUNT - (result.length % COLUMN_COUNT)) % COLUMN_COUNT;
          const dataWithEmptyCells = result.concat(
            Array(numEmptyCells).fill(emptyUser),
          );
          setCells(dataWithEmptyCells);
        });
      } catch (error) {
        console.error(
          'error occurs when fetching group data, no need to inform user.',
          error,
        );
      }
      setLoading(false);
    };
    fetchGroupData();
  }, [groupId]);

  useEffect(() => {
    const fetchGroupNotice = async () => {
      try {
        const ref = doc(getFirestore(), 'groups', groupId).withConverter(
          groupConverter,
        );
        const groupSnap = await getDoc(ref);
        if (!groupSnap.exists()) {
          console.log('Data not exists');
          return;
        }

        const groupData = groupSnap.data();
        setGroup(groupData);
      } catch (error) {
        console.error(
          'error occurs when fetching group data, no need to inform user.',
          error,
        );
      }
      // load important info
      const docRef = doc(getFirestore(), 'groupChatMessages', groupId);
      onSnapshot(docRef, (snapshot) => {
        const data = snapshot.data();
        if (data && data.importantInfo && data.importantInfo != '') {
          setImportantInfo(data.importantInfo);
        }
        if (data && data.importantInfoTime && data.importantInfoTime != '') {
          setImportantInfoTime(data.importantInfoTime);
        }
      });
    };
    fetchGroupNotice();
  }, [groupId]);

  useEffect(() => {
    const updateGroupAssistantConfiguration = async () => {
      try {
        const currentRef = doc(getFirestore(), 'groupChatMessages/' + groupId);
        const currentSnapshot = await getDoc(currentRef);

        const currentChat = currentSnapshot.data();

        const groupAssistantConfig =
          currentChat && currentChat.groupAssistantConfig
            ? new Map(Object.entries(currentChat.groupAssistantConfig))
            : new Map();
        groupAssistantConfig.set(currentUser?.id, groupAssistantEnableStatus);
        const newObj = Object.fromEntries(groupAssistantConfig);

        await setDoc(
          doc(getFirestore(), 'groupChatMessages/' + groupId),
          {
            groupAssistantConfig: newObj,
          },
          {merge: true},
        );
      } catch (error) {
        console.error(
          'error occurs when update group assistant config, no need to inform user.',
          error,
        );
      }
    };
    updateGroupAssistantConfiguration();
  }, [currentUser?.id, groupAssistantEnableStatus, groupId]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <Pressable
          onPress={() => {
            navigation.goBack();
          }}>
          <Ionicons
            color="black"
            name="arrow-back"
            size={24}
            style={{
              marginLeft: Platform.OS === 'web' ? 16 : 2,
              marginRight: 10,
              marginVertical: 5,
            }}
          />
        </Pressable>
      ),
      title: loading ? `群聊成员` : `群聊成员(${members?.length})`,
    });
  }, [groupId, loading, members?.length, navigation]);

  const ItemView = ({user}: {user: User}) => {
    return (
      <View style={{width: '25%'}}>
        <TouchableOpacity
          onPress={() => {
            navigation.navigate('AccountTab', {
              params: {fromTab: 'Chat', userId: user.id},
              screen: 'OtherAccountView',
            });
          }}
          style={styles.avatarImageBox}>
          <View style={styles.avatarAndTagContainer}>
            <View style={styles.avatarContainer}>
              {user.profileImageUrl ? (
                <Image
                  source={{uri: user.profileImageUrl}}
                  style={styles.avatarImage}
                />
              ) : (
                <Image
                  source={require('../../assets/images/simple-avatar.png')}
                  style={styles.avatarImage}
                />
              )}
            </View>

            {/*creator tag  */}
            {user.id === group?.creator?.id && (
              <View style={styles.creatorTag}>
                <Text style={styles.creatorTagText}>发起人</Text>
              </View>
            )}
          </View>

          <Text ellipsizeMode="tail" numberOfLines={1} style={styles.userName}>
            {user.userName}
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  const renderItem = ({item}) => {
    if (item.id === 'default') {
      return <View style={styles.emptyCell} />;
    }
    return <ItemView user={item} />;
  };

  if (loading) {
    return <LoadingScreen />;
  } else {
    return (
      <View style={{flex: 1, flexDirection: 'column'}}>
        <View style={styles.membersList}>
          <FlatList
            columnWrapperStyle={styles.columnWrapper}
            data={cells}
            numColumns={4}
            renderItem={renderItem}
            showsVerticalScrollIndicator={false}
          />
        </View>
        <View style={styles.divider} />
        <View style={styles.infoRow}>
          <Text style={styles.infoTitle}>群活动</Text>
          <Text selectable={true} style={styles.infoContent}>
            {group?.title}
          </Text>
        </View>
        <View style={styles.divider} />
        <Pressable
          disabled={!importantInfoTime}
          onPress={() => {
            navigation.push('GroupNoticeModal', {
              content: importantInfo,
              timeString: importantInfoTime
                ? produceDateTimeString(importantInfoTime)
                : '',
            });
          }}>
          <View style={styles.infoRow}>
            <Text style={styles.infoTitle}>群公告</Text>
            <Text selectable={true} style={styles.infoContent}>
              {importantInfoTime ? importantInfo : '未设置'}
            </Text>
          </View>
        </Pressable>

        {features?.enable_ai_group_chat && (
          <View>
            <View style={styles.divider} />

            <View style={styles.infoRow}>
              {group?.creator.id == currentUser?.id ? (
                <Text style={styles.infoTitle}>
                  开启AI助手兔兔(帮您回复参与者提问)
                </Text>
              ) : (
                <Text style={styles.infoTitle}>开启AI助手兔兔</Text>
              )}
              <Switch
                disabled={
                  group?.creator.id != currentUser?.id && !isCreatorEnabled
                }
                onValueChange={toggleEnableGroupAssistantSwitch}
                thumbColor="white"
                trackColor={{false: '#d3d3d3', true: '#66BB6A'}}
                value={groupAssistantEnableStatus}
              />
            </View>
            {!isCreatorEnabled && group?.creator.id != currentUser?.id && (
              <View style={styles.infoRow}>
                <Text style={styles.infoContent}>
                  此功能已被发起者关闭，您无法修改
                </Text>
              </View>
            )}
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  avatarAndTagContainer: {
    alignItems: 'center',
    position: 'relative',
  },
  avatarContainer: {
    borderRadius: 30,
    height: 60,
    marginBottom: 2,
    overflow: 'hidden',
    width: 60,
  },
  avatarImage: {
    height: '100%',
    width: '100%',
  },
  avatarImageBox: {
    alignItems: 'center',
    flexDirection: 'column',
    marginVertical: 4,
  },
  columnWrapper: {
    justifyContent: 'space-between',
    marginHorizontal: 2, // Temp fix to bring card shadow back
  },
  creatorTag: {
    backgroundColor: 'red',
    borderRadius: 4,
    display: 'flex',
    paddingLeft: 5,
    paddingRight: 4,
    paddingVertical: 2,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  creatorTagText: {
    alignItems: 'center',
    color: 'white',
    fontSize: 10,
    fontWeight: 'bold',
    justifyContent: 'center',
  },
  divider: {
    alignSelf: 'center',
    backgroundColor: 'lightgrey',
    flexDirection: 'column',
    height: 1,
    marginVertical: 16,
    width: '96%',
  },
  emptyCell: {
    flex: 1,
    height: 0, // Set height to 0 to make it occupy the space
  },
  infoContent: {
    color: 'grey',
    flexGrow: 1,
    flexShrink: 1,
    fontSize: 16,
  },
  infoRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 16,
  },
  infoTitle: {fontSize: 16, marginRight: 8},
  membersList: {
    justifyContent: 'center',
  },
  toggleRow: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginHorizontal: 16,
  },
  userName: {
    color: 'grey',
    fontSize: 12,
    justifyContent: 'center',
    textAlign: 'center',
    width: 80,
  },
});
