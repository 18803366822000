import {Ionicons} from '@expo/vector-icons';
import {doc} from 'firebase/firestore';
import {useState} from 'react';
import {useDocument} from 'react-firebase-hooks/firestore';
import {Image, ImageBackground, StyleSheet, Text, View} from 'react-native';

import Colors from '../../constants/Colors';
import Layout from '../../constants/Layout';
import {getFirestore} from '../../firebase';
import userConverter from '../../firestoreConverters/userConverter';
import {
  getFirstHeaderImageUrlFromGroup,
  headerImageEmpty,
} from '../../helpers/imageUtil';
import {
  produceChineseDateString,
  produceDurationOrTime,
  produceGenderFlexibility,
  profileImagePreview,
} from '../../helpers/util';
import FastImage from '../../platforms/FastImage';
import {Group} from '../../types';
import OverlappeddAvatarsRow from './OverlappedAvatarsRow';

export default function GroupCardViewVertical(group: {group: Group}) {
  const userId = group.group.creator.id;

  const [userSnapshot] = useDocument(
    doc(getFirestore(), 'users', userId).withConverter(userConverter),
  );

  const user = userSnapshot?.data();

  const AvatarsRow = () => {
    return (
      <View style={styles.avatarRowContainer}>
        <OverlappeddAvatarsRow
          avatarSize={20}
          group={group.group}
          maximumAvatars={5}
        />
        <Text numberOfLines={1} style={styles.description}>
          {group.group.currentMembers.length}人已组队
        </Text>
      </View>
    );
  };
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <View style={styles.card}>
      <View>
        {headerImageEmpty(group.group) ? (
          <ImageBackground
            source={require('../../assets/images/yay_logo.png')}
            style={styles.imagePreview}
          />
        ) : (
          <FastImage
            onLoad={() => setImageLoaded(true)}
            source={{uri: getFirstHeaderImageUrlFromGroup(group.group)}}
            style={styles.imagePreview}
          />
        )}
        {group.group.officialTag === 'selected' && (
          <View style={styles.tagStyle}>
            <Ionicons
              color="white"
              name="star"
              size={14}
              style={{marginRight: 2}}
            />
            <Text style={{color: 'white', fontSize: 14}}>精选</Text>
          </View>
        )}
      </View>

      <View style={styles.upperContainer}>
        <Text ellipsizeMode={'tail'} numberOfLines={2} style={styles.title}>
          {group.group.title}
        </Text>

        <View style={styles.label}>
          <Ionicons
            color="black"
            name="calendar-outline"
            size={16}
            style={{marginRight: 4}}
          />
          <Text numberOfLines={1} style={styles.description}>
            {produceChineseDateString(group.group.dateStart)}
          </Text>
        </View>

        <View style={styles.label}>
          <Ionicons
            color="black"
            name="time-outline"
            size={16}
            style={{marginRight: 4}}
          />
          <Text style={styles.description}>
            {produceDurationOrTime(group.group.groupType, [
              group.group.dateStart,
              group.group.dateEnd,
            ])}
          </Text>
        </View>

        <View
          style={[
            styles.label,
            {flexWrap: 'nowrap', justifyContent: 'space-between'},
          ]}>
          <View style={styles.progressBarContainer}>
            <View
              style={[
                styles.progressBar,
                {
                  width: `${
                    (100 * group.group.currentMembers.length) /
                    group.group.numMemberRequired
                  }%`,
                },
              ]}
            />
          </View>
          <Text numberOfLines={1} style={styles.lightGreyDescription}>
            {group.group.currentMembers.length}
            {'/'}
            {group.group.numMemberRequired}
            {'人'}
          </Text>
          <Text numberOfLines={1} style={styles.description}>
            {produceGenderFlexibility(group.group)}
          </Text>
        </View>
        <AvatarsRow />
      </View>

      <View style={styles.separator} />

      <View style={styles.middleContainer}>
        <Ionicons
          color={group.group.creator.gender === 'F' ? '#ba55d3' : '#4169e1'}
          name={group.group.creator.gender === 'F' ? 'female' : 'male'}
          size={20}
        />
        <Text numberOfLines={1} style={styles.description}>
          发起人：
        </Text>
        <Image
          source={profileImagePreview(user)}
          style={styles.avatarPreview}
        />
        <Text numberOfLines={1} style={[styles.description, {flex: 1}]}>
          {user?.userName}
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  avatarPreview: {
    borderRadius: 50,
    height: 20,
    marginRight: 4,
    width: 20,
  },
  avatarRowContainer: {
    alignContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    overflow: 'hidden',
  },
  card: {
    backgroundColor: '#fff',
    borderRadius: Layout.groupCardBorderRadius,
    elevation: 5,
    marginVertical: Layout.groupCardMarginVertical,
    shadowColor: '#000',
    shadowOffset: {
      height: 4,
      width: 1,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    width: Layout.unit * 22,
  },
  description: {
    flexWrap: 'nowrap',
    fontSize: 12,
    lineHeight: 18,
    overflow: 'hidden',
  },
  imagePreview: {
    borderRadius: 8,
    height: Layout.unit * 12,
    width: Layout.unit * 22,
  },
  label: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: 4,
  },
  lightGreyDescription: {
    alignContent: 'center',
    color: 'grey',
    flex: 1,
    flexWrap: 'nowrap',
    fontSize: 12,
    lineHeight: 18,
    textAlign: 'center',
  },
  middleContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    height: Math.max(25, 2.5 * Layout.fontScale * Layout.unit),
    justifyContent: 'flex-start',
    margin: Layout.unit,
    overflow: 'hidden',
  },
  progressBar: {
    backgroundColor: 'rgba(102, 187, 106, 1)',
    borderRadius: 10,
    height: 8,
  },
  progressBarContainer: {
    backgroundColor: 'rgba(102, 187, 106, 0.4)',
    borderRadius: 10,
    height: 8,
    overflow: 'hidden',
    width: '30%',
  },
  separator: {
    alignSelf: 'center',
    backgroundColor: '#d3d3d3',
    height: 1,
    width: '90%',
  },
  tagStyle: {
    alignItems: 'center',
    backgroundColor: Colors.mainAppColor,
    borderRadius: 2,
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 4,
    position: 'absolute',
    right: Layout.unit,
    top: Layout.unit,
  },
  title: {
    alignContent: 'center',
    fontSize: 16,
    fontWeight: 'bold',
    height: 18 * 2 * Layout.fontScale,
    lineHeight: 18,
    paddingHorizontal: 4,
  },
  upperContainer: {
    flexDirection: 'column',
    height: 16.5 * Layout.fontScale * Layout.unit,
    justifyContent: 'space-between',
    margin: Layout.unit,
  },
});
