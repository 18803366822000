import React from 'react';
import {Image, ImageBackground, StyleSheet, Text, View} from 'react-native';

import {profileImagePreview} from '../../helpers/util';
import {Group} from '../../types';

type OverlappeddAvatarsRowProps = {
  avatarSize: number;
  group: Group;
  maximumAvatars: number;
};

export default function OverlappeddAvatarsRow({
  avatarSize,
  group,
  maximumAvatars,
}: OverlappeddAvatarsRowProps) {
  const totalAvatars = Math.min(
    maximumAvatars,
    group?.currentMembers?.length || 0,
  );
  const avatarOverlap = avatarSize / 2;
  const renderArray = group.currentMembers?.slice(0, totalAvatars) || [];
  const hidenNumber = (group?.currentMembers?.length || 0) - totalAvatars + 1;

  const styles = StyleSheet.create({
    avatar: {
      borderColor: 'white',
      borderRadius: avatarSize / 2,
      borderWidth: 1.25,
      height: avatarSize,
      position: 'relative',
      width: avatarSize,
    },
    text: {
      color: 'white',
      fontSize: avatarSize / 2,
      textAlign: 'center',
    },
  });

  return (
    <View
      style={{
        flexDirection: 'row',
        marginHorizontal: 2,
        width: ((totalAvatars + 1) * avatarSize) / 2,
      }}>
      {renderArray.map((member, index) =>
        index == maximumAvatars - 1 ? (
          <ImageBackground
            key={index}
            style={[
              styles.avatar,
              {
                alignItems: 'center',
                backgroundColor: '#66BB6A',
                justifyContent: 'center',
                left: -(index * avatarOverlap),
                zIndex: index - totalAvatars,
              },
            ]}>
            <Text style={styles.text}>
              {hidenNumber < 100 ? hidenNumber : 99}+
            </Text>
          </ImageBackground>
        ) : (
          <Image
            key={index}
            source={profileImagePreview(member)}
            style={[
              styles.avatar,
              {
                left: -(index * avatarOverlap),
                zIndex: index - totalAvatars,
              },
            ]}
          />
        ),
      )}
    </View>
  );
}
