/**
 * Learn more about using TypeScript with React Navigation:
 * https://reactnavigation.org/docs/typescript/
 */

import {BottomTabScreenProps} from '@react-navigation/bottom-tabs';
import {
  CompositeScreenProps,
  NavigatorScreenParams,
} from '@react-navigation/native';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {Timestamp} from 'firebase/firestore';

declare global {
  namespace ReactNavigation {
    type RootParamList = RootStackParamList;
  }
}

export type RootStackParamList = {
  CampaignDetail: undefined;
  JoinGroupModal: undefined;
  LandingPage: undefined;
  LoginModal: undefined;
  Modal: undefined;
  NotFound: undefined;
  PrivacyPolicy: undefined;
  ResponsibilityTerms: undefined;
  Root: NavigatorScreenParams<RootTabParamList> | undefined;
  UserTerms: undefined;
};

export type RootStackScreenProps<Screen extends keyof RootStackParamList> =
  NativeStackScreenProps<RootStackParamList, Screen>;

export type RootTabParamList = {
  AccountTab: undefined;
  ChatTab: undefined;
  GroupTab: undefined;
  TourTab: undefined;
};

export type RootTabScreenProps<Screen extends keyof RootTabParamList> =
  CompositeScreenProps<
    BottomTabScreenProps<RootTabParamList, Screen>,
    NativeStackScreenProps<RootStackParamList>
  >;

export type UserGender = 'M' | 'F';

export type PaidMember = {
  discountCode: string;
  id: string;
  userName: string;
};

export type Group = {
  bugetPerPerson: number | null;
  checkin: {[key: string]: boolean} | null;
  compressedHeaderImage: string[];
  created: Timestamp;
  createdByStripeEligibleUser: boolean;
  creator: User;
  currentMembers: [User];
  date: [Timestamp, Timestamp | null]; // replaced by dateEnd and dateStart
  dateEnd: Timestamp | null;
  dateStart: Timestamp;
  destination: string | null;
  details: string | null;
  genderFlexible: 'male_only' | 'female_only' | 'flexible';
  groupQRCode: string;
  groupSubType: 'indoor' | 'outdoor';
  groupType:
    | 'tour'
    | 'around_la'
    | 'around_ny'
    | 'around_bos'
    | 'around_bay_area';
  headerImage: string[]; // stores original header image
  id: string;
  lastMessageRecord: {message: string; time: Timestamp} | null;
  meetUpLocation: string | null;
  meetUpLocationRequired: boolean;
  minGroupMembers: number;
  numMemberRequired: number;
  officialTag: 'selected' | null;
  paidMembers: PaidMember[];
  paidTicketOptions: {[key: string]: Array<any>};
  paymentInstruction: string | null;
  payments: Map<string, string> | null;
  privateGroup: boolean;
  rankingFactor: number;
  ratings: Map<string, number> | null;
  registrationDeadline: Timestamp | null;
  sponsors: string[] | null;
  status: 'pending' | 'close' | 'deleted';
  ticketOptions: {
    maxQtyPB: string;
    minQtyPB: string;
    optionName: string;
    optionPrice: string;
    optionQuantity: string;
  }[];
  tips: string[] | null;
  title: string;
  tour: Tour | null;
  userGeneratedTag: string[] | null;
};

export type User = {
  blockedUsersList: string[] | null;
  created: Timestamp;
  createdGroup: [Group] | null;
  createdPostsList: string[] | null;
  customizedPayment: string | null;
  defaultPayment: string | null;
  deleted: boolean;
  eduVerified: boolean;
  email: string | null;
  followerList: string[] | null;
  followingList: string[] | null;
  gender: UserGender;
  id: string;
  introduction: string | null;
  isSelectedMerchant: boolean;
  isSuperUser: boolean;
  joinedGroup: [Group] | null;
  lastRatingTime: Timestamp | null;
  likedPostsList: string[] | null;
  linkedinVerified: boolean;
  location: string | null;
  name: string;
  notificationToken: string[] | null;
  phoneNumber: string;
  profileImageUrl: string | null;
  referredUsersList: string[] | null;
  referredUsersNumber: string[] | null;
  socialIns: string | null;
  socialWechat: string | null;
  socialXhs: string | null;
  userName: string;
  venmoAccountId: string | null;
  wechatVerified: boolean;
  zelleAccountId: string | null;
};

export type Tour = {
  comments: [TourComment] | null;
  description: string;
  destination: string;
  groupQrCodeUrl: string | null;
  headerImage: string;
  id: string;
  likes: number;
  oldPrice: number | null;
  postedBySelectedMerchant: boolean;
  poster: string;
  price: number | null;
  purchaseUrl: string | null;
  title: string;
  uploadDate: Timestamp;
};

export type TourComment = {
  content: string;
  id: string;
  posterID: string;
};

export type UserPost = {
  description: string;
  id: string;
  imageUrl: string;
  storageRef: string;
  title: string;
  uploadDate: Timestamp;
};

export type GroupQRCode = {
  groupId: string;
  groupName: string;
  qr: string;
  wc: string;
};

export type PaymentRecord = {
  groupId: string;
  transactionId: string;
  userId: string;
};

export type DiscountCode = {
  code: string;
  discountAmount: number; // combined with discountType
  discountType: 'percentage' | 'fixed'; //e.g. 10% or 10$ off
  expiresAt?: Date;
  genderRequirment: 'male' | 'female' | 'both';
  isActive: boolean;
  isSingleUsePerUser: boolean;
  limitedGroupIds?: string[];
  limitedUserNumbers?: string[];
  maxUsageCount?: number;
  minimumOrderAmount?: number;
  minimumOrderCount?: number;
  usedByUsers?: string[]; // list of phone number
};

export const emptyUser: User = {
  blockedUsersList: [],
  created: Timestamp.now(),
  createdGroup: null,
  createdPostsList: null,
  customizedPayment: null,
  defaultPayment: null,
  deleted: false,
  eduVerified: false,
  email: null,
  followerList: null,
  followingList: null,
  gender: 'M',
  id: 'default',
  introduction: null,
  isSelectedMerchant: false,
  isSuperUser: false,
  joinedGroup: null,
  lastRatingTime: null,
  likedPostsList: [],
  linkedinVerified: false,
  location: null,
  name: 'fake user',
  notificationToken: null,
  phoneNumber: 'default',
  profileImageUrl: null,
  referredUsersList: [],
  referredUsersNumber: [],
  socialIns: null,
  socialWechat: null,
  socialXhs: null,
  userName: '',
  venmoAccountId: null,
  wechatVerified: false,
  zelleAccountId: null,
};

export const emptyGroup: Group = {
  bugetPerPerson: null,
  checkin: null,
  compressedHeaderImage: [],
  created: Timestamp.now(),
  createdByStripeEligibleUser: false,
  creator: emptyUser,
  currentMembers: [emptyUser],
  date: [Timestamp.now(), null],
  dateEnd: null,
  dateStart: Timestamp.now(),
  destination: null,
  details: null,
  genderFlexible: 'flexible',
  groupQRCode: 'empty',
  groupSubType: 'indoor',
  groupType: 'tour',
  headerImage: ['empty'],
  id: 'empty_group_id',
  lastMessageRecord: null,
  meetUpLocation: null,
  meetUpLocationRequired: false,
  minGroupMembers: 0,
  numMemberRequired: 0,
  officialTag: null,
  paidMembers: [],
  paidTicketOptions: {},
  paymentInstruction: null,
  payments: null,
  privateGroup: false,
  rankingFactor: 0,
  ratings: null,
  registrationDeadline: null,
  sponsors: null,
  status: 'pending',
  ticketOptions: [],
  tips: null,
  title: 'empty',
  tour: null,
  userGeneratedTag: null,
};

export const emptyTour: Tour = {
  comments: null,
  description: '',
  destination: '',
  groupQrCodeUrl: null,
  headerImage: '',
  id: '',
  likes: 0,
  oldPrice: null,
  postedBySelectedMerchant: false,
  poster: '',
  price: null,
  purchaseUrl: '',
  title: '',
  uploadDate: Timestamp.now(),
};

export type UserAction = {
  label: string;
  triggerAction: (value: string) => void;
  value: string;
};

export type BannerAdCampaign = {
  associatedDiscountCode: DiscountCode;
  associatedURL: string; // depending on type, this could be a url to group detail/tour detail/campaign detail
  buttonText: string;
  compaignImage: string;
  endDate: Timestamp;
  imageAssetURL: string;
  startDate: Timestamp;
  // we can rely on a cloud function to filter which users belongs to which group, and display the appropriate banner ad
  targetUserGroup: string[];
  title: string;
  type: string; // 'group' | 'tour' | 'campaign'
};

export type GroupTag = {
  // index of the tag, tag with smaller index will be positioned on the left
  index?: number;
  // if true, this tag will be shown in the filter bar, controlled by cloud storage
  shownInFilterBar?: boolean;
  // internal value, e.g. "outdoor" | "all"
  tagValue: string;
  // display value in Chinese, e.g. "户外"； "全部"
  title: string;
};
