import {
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions,
  Timestamp,
  WithFieldValue,
} from 'firebase/firestore';

import {User} from '../types';

export default {
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): User {
    const data = snapshot.data(options);
    return {
      blockedUsersList: data.blockedUsersList ?? [],
      created: data.created ?? Timestamp.now(),
      createdGroup: data.createdGroup ?? [],
      createdPostsList: data.createdPostsList ?? [],
      customizedPayment: data.customizedPayment ?? null,
      defaultPayment: data.defaultPayment ?? null,
      deleted: data.deleted ?? false,
      eduVerified: data.eduVerified ?? false,
      email: data.email ?? null,
      followerList: data.followerList ?? [],
      followingList: data.followingList ?? [],
      gender: data.gender,
      id: data.id,
      introduction: data.introduction ?? null,
      isSelectedMerchant: data.isSelectedMerchant ?? false,
      isSuperUser: data.isSuperUser ?? false,
      joinedGroup: data.joinedGroup ?? [],
      lastRatingTime: data.lastRatingTime ?? null,
      likedPostsList: data.likedPostsList ?? [],
      linkedinVerified: data.linkedinVerified ?? false,
      location: data.location ?? null,
      name: data.name ?? null,
      notificationToken: data.notificationToken ?? [],
      phoneNumber: data.phoneNumber ?? null,
      profileImageUrl: data.profileImageUrl ?? null,
      referredUsersList: data.referredUsersList ?? [],
      referredUsersNumber: data.referredUsersNumber ?? [],
      socialIns: data.socialIns ?? null,
      socialWechat: data.socialWechat ?? null,
      socialXhs: data.socialXhs ?? null,
      userName: data.userName, // mandatory
      venmoAccountId: data.venmoAccountId ?? null,
      wechatVerified: data.wechatVerified ?? false,
      zelleAccountId: data.zelleAccountId ?? null,
    };
  },
  toFirestore(user: WithFieldValue<User>): DocumentData {
    return {...user};
  },
};
