import 'expo-dev-client';

import {StripeProvider} from '@stripe/stripe-react-native';
import Constants from 'expo-constants';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import {useEffect, useRef, useState} from 'react';
import {AppState, LogBox, Platform} from 'react-native';
import {SafeAreaProvider} from 'react-native-safe-area-context';

import {STRIPE_PUBLISHABLE_KEY} from './app/constants/config';
import {AuthContextProvider} from './app/context/AuthContext';
import {ChatContextProvider} from './app/context/ChatContext';
import {FeaturesContextProvider} from './app/context/FeaturesContext';
import {initializeFirebase} from './app/firebase';
import useCachedResources from './app/hooks/useCachedResources';
import Navigation from './app/navigation';

initializeFirebase();
LogBox.ignoreLogs([
  'AsyncStorage has been extracted from react-native core and will be removed in a future release.',
]);
Notifications.setNotificationHandler({
  handleNotification: async () => {
    return {
      shouldPlaySound: false,
      shouldSetBadge: true,
      shouldShowAlert: false,
    };
  },
});

async function registerForPushNotificationsAsync() {
  let token;
  if (Device.isDevice) {
    const {status: existingStatus} = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
      const {status} = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== 'granted') {
      return;
    }
    if (Platform.OS === 'android') {
      token = (
        await Notifications.getExpoPushTokenAsync({
          projectId: Constants.expoConfig.extra.eas.projectId,
        })
      ).data;
    } else {
      token = (await Notifications.getExpoPushTokenAsync()).data;
    }
  }

  if (Platform.OS === 'android') {
    Notifications.setNotificationChannelAsync('default', {
      importance: Notifications.AndroidImportance.MAX,
      lightColor: '#FF231F7C',
      name: 'default',
      vibrationPattern: [0, 250, 250, 250],
    });
  }

  return token;
}

/**
 * use light mode only for MVP
 */
export default function App() {
  var pkg = require('./app.json');
  const version = pkg.expo.version;

  const isLoadingComplete = useCachedResources();

  const [expoPushToken, setExpoPushToken] = useState('');
  const [notification, setNotification] = useState(false);
  const notificationListener = useRef();
  const responseListener = useRef();
  useEffect(() => {
    if (Platform.OS == 'ios' || Platform.OS == 'android') {
      const handleAppStateChange = (newState) => {
        if (newState === 'active') {
          // Code to execute when the app is opened
          const clearBadge = async () => {
            await Notifications.setBadgeCountAsync(0);
          };
          clearBadge();
        }
      };

      AppState.addEventListener('change', handleAppStateChange);

      registerForPushNotificationsAsync().then((token) =>
        setExpoPushToken(token),
      );
      notificationListener.current =
        Notifications.addNotificationReceivedListener((notification) => {
          setNotification(notification);
        });

      responseListener.current =
        Notifications.addNotificationResponseReceivedListener((response) => {
          console.log(response);
        });
    }

    return () => {
      if (Platform.OS == 'ios' || Platform.OS == 'android') {
        Notifications.removeNotificationSubscription(
          notificationListener.current,
        );
        Notifications.removeNotificationSubscription(responseListener.current);
      }
    };
  }, []);

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <SafeAreaProvider>
        <AuthContextProvider token={expoPushToken}>
          <FeaturesContextProvider>
            <ChatContextProvider>
              {Platform.OS === 'web' ? (
                <Navigation colorScheme="light" version={version} />
              ) : (
                <StripeProvider
                  merchantIdentifier="merchant.com.yaytour.yaytourapp" // required for Apple Pay
                  publishableKey={STRIPE_PUBLISHABLE_KEY}
                  // urlScheme="your-url-scheme" // required for 3D Secure and bank redirects
                >
                  <Navigation colorScheme="light" version={version} />
                </StripeProvider>
              )}
            </ChatContextProvider>
          </FeaturesContextProvider>
        </AuthContextProvider>
      </SafeAreaProvider>
    );
  }
}
