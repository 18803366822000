import {doc, getDoc, updateDoc} from 'firebase/firestore';
import {useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {
  Image,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  useWindowDimensions,
  View,
} from 'react-native';
import DropDownPicker from 'react-native-dropdown-picker';
import {SafeAreaView} from 'react-native-safe-area-context';

import PrimaryButton from '../components/buttons/PrimaryButton';
import alert from '../components/container/alert';
import {OFFICIAL_ACCOUNT} from '../constants/config';
import {REFER_CODES, WELCOME} from '../constants/Discount';
import {useAuthContext} from '../context/AuthContext';
import {useFeaturesContext} from '../context/FeaturesContext';
import {getFirestore} from '../firebase';
import userConverter from '../firestoreConverters/userConverter';
import {
  defaultAvatar,
  handleFollowAction,
  updateReferredUsersList,
  updateUserToDiscount,
} from '../helpers/util';

const db = getFirestore();

type ProfileData = {
  location: string;
  referCode: string;
};

export default function LoginLocationScreen({
  navigation,
  route,
}: {
  navigation: any;
  route: any;
}) {
  const {width: screenWidth} = useWindowDimensions();
  const isScreenWide = screenWidth > 1024;

  const {currentUser, phoneNumber} = useAuthContext();
  const {features} = useFeaturesContext();
  const {
    username,
    gender,
    wechat,
    referCode: defaultReferCode,
  } = route.params || {};
  const {
    control,
    handleSubmit,
    formState: {errors, isSubmitting},
  } = useForm<ProfileData>({
    defaultValues: {
      location: 'NewYork',
      referCode: defaultReferCode ?? '',
    },
  });

  const onSubmit = async (data: ProfileData) => {
    console.log('data submit: ' + JSON.stringify(data));

    const userId = currentUser?.id;
    if (userId != null) {
      const userRef = doc(db, 'users', userId);
      try {
        await updateDoc(userRef, {
          location: data.location,
        });

        if (
          [gender, username, wechat].some(
            (field) => field == null || field === '',
          )
        ) {
          // New user: user has not set up the complete user info
          navigation.navigate('Profile', {
            location: data.location,
            mode: 'register',
            referCode: route.params.referCode,
          });
        } else {
          // Old user
          navigation.navigate('Root', {
            screen: 'AccountTab',
          });
        }
      } catch (error) {
        alert('发送错误', '更新城市时发生错误: ' + error, [
          {onPress: () => {}, text: '确定'},
        ]);
        console.error('error occurs when updating location: ', error);
      }
    } else {
      alert('发送错误', '用户没有被授权', [{onPress: () => {}, text: '确定'}]);
      console.error('error occurs when updating profile: user not logged in');
    }
  };

  const imageMap: {[key: string]: any} = {
    BayArea: require('../assets/images/locations/bay_area.png'),
    Boston: require('../assets/images/locations/boston.png'),
    NewYork: require('../assets/images/locations/new_york.png'),
    // Add more options if needed
  };

  const [open, setOpen] = useState(false);
  const [location, setLocation] = useState('NewYork');
  const [image, setImage] = useState(imageMap.NewYork);
  const [items, setItems] = useState([
    {label: 'New York', value: 'NewYork'},
    {label: 'Boston', value: 'Boston'},
    {label: 'Bay Area', value: 'BayArea'},
    // Add more options if needed
  ]);

  const handleLocationChange = (selectedLocation: string) => {
    setLocation(selectedLocation);
    setImage(imageMap[selectedLocation]);
  };

  return (
    <SafeAreaView style={{backgroundColor: '#DDEDDB', flexGrow: 1}}>
      <ScrollView>
        <View style={styles.container}>
          <View
            style={[
              styles.locationContainer,
              (Platform.OS !== 'web' || !isScreenWide) && {
                flexDirection: 'column',
              },
            ]}>
            <View style={styles.labelContainer}>
              <Text
                style={[
                  styles.label,
                  Platform.OS === 'web' &&
                    isScreenWide && {fontSize: 50, marginHorizontal: 20},
                ]}>
                YOUR DESTINATION IS:
              </Text>
            </View>

            <View style={styles.controllerContainer}>
              <Controller
                control={control}
                name="location"
                render={({field: {onChange}}) => (
                  <DropDownPicker
                    dropDownContainerStyle={[
                      styles.dropDownContainer,
                      Platform.OS === 'web' && {width: 300},
                    ]}
                    items={items}
                    onChangeValue={(selectedValue: any) => {
                      onChange(selectedValue);
                      handleLocationChange(selectedValue);
                    }}
                    open={open}
                    setItems={setItems}
                    setOpen={setOpen}
                    setValue={setLocation}
                    style={[
                      styles.input,
                      Platform.OS === 'web' && {width: 300},
                    ]}
                    value={location}
                  />
                )}
                rules={{required: true}}
              />
            </View>
          </View>

          <View style={styles.imageContainer}>
            <Image source={image} style={styles.image} />
          </View>

          <PrimaryButton
            disabled={Object.keys(errors).length > 0 || isSubmitting}
            onPress={() => {
              handleSubmit(onSubmit)();
            }}
            text={'Confirm'}
          />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flex: 1,
    padding: 20,
    paddingTop: 60,
    width: '100%',
  },
  controllerContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  dropDownContainer: {
    borderColor: '#E4E4E4',
    marginTop: 16,
    paddingHorizontal: 8,
  },
  image: {
    height: 450,
    resizeMode: 'cover',
    width: '100%',
  },
  imageContainer: {
    alignItems: 'center',
    borderRadius: 50,
    height: Platform.OS === 'web' ? 450 : 350,
    justifyContent: 'center',
    marginBottom: 50,
    marginTop: 30,
    maxWidth: 1200,
    overflow: 'hidden',
    width: '90%',
    zIndex: -10,
  },
  input: {
    alignItems: 'center',
    borderColor: '#9CADA0',
    borderWidth: 1,
    flex: 1,
    height: 50,
    marginVertical: 18,
    paddingHorizontal: 18,
    paddingVertical: 16,
    width: '100%',
    zIndex: 10,
  },
  label: {
    color: '#66BB6A',
    fontSize: 22,
    fontWeight: '600',
    textAlign: 'center',
    width: '100%',
  },
  labelContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  locationContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '80%',
  },
});
