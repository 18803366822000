import {Ionicons} from '@expo/vector-icons';
import {useNavigation} from '@react-navigation/native';
import {useLayoutEffect, useState} from 'react';
import {
  Image,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import {useFeaturesContext} from '../context/FeaturesContext';
import {profileImagePreview} from '../helpers/util';

export default function PeopleListDetailScreen({route}: {route: any}) {
  const navigation: any = useNavigation();
  const {features} = useFeaturesContext();

  const {
    user,
    toggleCheckIn,
    checkinStatus,
    handleDelete,
    allowUserRemoveParticipant,
    paidTicketOption,
    paymentId,
    discountCode,
  } = route.params;

  const [isUserCheckedIn, setIsUserCheckedIn] = useState(checkinStatus);

  const handleTapToOpenProfile = (tappedUserId: string) => {
    navigation.push('OtherAccountView', {
      userId: tappedUserId,
    });
  };

  const handleToggleCheckIn = async (userId: string) => {
    const confirmed = await toggleCheckIn(userId);

    if (confirmed) {
      setIsUserCheckedIn(!isUserCheckedIn);
    }
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      headerBackVisible: false,
      headerLeft: null,
      headerRight: () => (
        <Pressable
          onPress={() => {
            navigation.goBack();
          }}
          style={{marginRight: 10}}>
          <Ionicons color="black" name="close" size={24} />
        </Pressable>
      ),
    });
  }, [navigation]);

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      style={{backgroundColor: 'white'}}>
      {/* User Profile*/}
      <View style={styles.profileContainer}>
        <TouchableOpacity
          onPress={() => handleTapToOpenProfile(user?.userId)}
          style={styles.avatarContainer}>
          <Image
            source={profileImagePreview(user)}
            style={styles.avatarImage}
          />
        </TouchableOpacity>
        <Text style={styles.userText}>{user?.userName}</Text>
        <View
          style={{
            alignItems: 'center',
            flexDirection: 'row',
            paddingVertical: 8,
          }}>
          {user?.gender ? (
            user.gender === 'M' ? (
              <Image
                source={require('../assets/images/male.png')}
                style={styles.genderImage}
              />
            ) : (
              <Image
                source={require('../assets/images/female.png')}
                style={styles.genderImage}
              />
            )
          ) : (
            <View style={styles.genderImage} />
          )}
        </View>
      </View>

      {/* Payment Details */}
      <View style={styles.ticketDetailsContainer}>
        {paidTicketOption && (
          <View style={styles.ticketDetailsLine}>
            <Text style={styles.title}>购票情况</Text>
            {paidTicketOption?.map((option: any, index: number) => (
              <View style={{flexDirection: 'row'}}>
                <Text
                  key={index}
                  style={{
                    color: 'black',
                    fontSize: 13,
                    marginVertical: 4,
                  }}>
                  {`${option.optionName} x ${option.quantity}`}
                </Text>
                {index < paidTicketOption.length - 1 && (
                  <Text
                    style={{
                      color: 'black',
                      fontSize: 13,
                      marginVertical: 4,
                    }}>
                    {' + '}
                  </Text>
                )}
              </View>
            ))}
          </View>
        )}

        {discountCode && (
          <View style={styles.ticketDetailsLine}>
            <Text style={styles.title}>使用折扣码</Text>
            <Text style={styles.text}>{discountCode}</Text>
          </View>
        )}

        {paymentId && (
          <View style={styles.ticketDetailsLine}>
            <Text style={styles.title}>Payment Id</Text>
            <Text style={styles.text}>{paymentId}</Text>
          </View>
        )}
      </View>

      {/* Buttons */}
      <View style={styles.buttonsContainer}>
        {/* Checkin Button */}
        {features?.enable_check_in && (
          <TouchableOpacity
            onPress={() => {
              handleToggleCheckIn(user?.userId);
            }}
            style={isUserCheckedIn ? styles.checkoutBtn : styles.checkinBtn}>
            {isUserCheckedIn ? <Text>已签到</Text> : <Text>未签到</Text>}
          </TouchableOpacity>
        )}
        {/* Delete Member Button */}
        {features?.enable_delete_member && allowUserRemoveParticipant && (
          <TouchableOpacity
            onPress={() => handleDelete(user?.userId)}
            style={styles.deleteBtn}>
            <Text>请离</Text>
          </TouchableOpacity>
        )}
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  avatarContainer: {
    borderRadius: 31,
    elevation: 5,
    height: 62,
    marginBottom: 2,
    overflow: 'hidden',
    shadowColor: 'black',
    shadowOffset: {height: 2, width: 2},
    shadowOpacity: 0.5,
    shadowRadius: 8,
    width: 62,
  },
  avatarImage: {
    height: '100%',
    width: '100%',
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingVertical: 50,
  },
  checkinBtn: {
    alignItems: 'center',
    backgroundColor: 'lightgrey',
    borderRadius: 31,
    flexDirection: 'row',
    justifyContent: 'center',
    marginHorizontal: 25,
    paddingHorizontal: 15,
    paddingVertical: 5,
  },
  checkoutBtn: {
    alignItems: 'center',
    backgroundColor: 'lightgreen',
    borderRadius: 31,
    flexDirection: 'row',
    justifyContent: 'center',
    marginHorizontal: 25,
    paddingHorizontal: 15,
  },
  deleteBtn: {
    alignItems: 'center',
    backgroundColor: '#FFD317',
    borderRadius: 31,
    flexDirection: 'row',
    justifyContent: 'center',
    marginHorizontal: 25,
    paddingHorizontal: 15,
    paddingVertical: 3,
  },
  genderImage: {
    height: 24,
    width: 24,
  },
  profileContainer: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    marginTop: 10,
    paddingHorizontal: 20,
    paddingVertical: 5,
  },
  text: {
    fontSize: 13,
  },
  ticketDetailsContainer: {
    flexDirection: 'column',
    paddingHorizontal: 25,
  },
  ticketDetailsLine: {
    alignItems: 'center',
    flexDirection: 'row',
    marginVertical: 3,
  },
  title: {
    fontSize: 13,
    fontWeight: 'bold',
    marginRight: 15,
  },
  userText: {
    fontSize: 16,
    fontWeight: 'bold',
    marginLeft: 15,
    marginRight: 5,
  },
});
