/**
 * (c) Meta Platforms, Inc. and affiliates. Confidential and proprietary.
 *
 * @emails oncall+npe_project_move
 * @format
 */

import {collection, getDocs} from 'firebase/firestore';
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {getFirestore} from '../firebase';
import {useAuthContext} from './AuthContext';

type FeatureListType = {
  enable_ai_chat: boolean;
  enable_ai_chat_summary_memory: boolean;
  enable_ai_group_chat: boolean;
  enable_ai_group_chat_toggle: boolean;
  enable_banner_ad: boolean;
  enable_check_in: boolean;
  enable_delete_member: boolean;
  enable_discount: boolean;
  enable_group_tags: boolean;
  enable_location_page: boolean;
  enable_min_group_members: boolean;
  enable_payment_dropdown: boolean;
  enable_rating_pop_up: boolean;
  enable_rating_ui: boolean;
  enable_refer: boolean;
  enable_refund: boolean;
  enable_registration_deadline: boolean;
  enable_ticket_options: boolean;
  enable_tour_compact_view: boolean;
  enable_venmo_and_zelle_for_all: boolean;
  selected_merchants: boolean;
  stripe_payment_enabled: boolean;
  test_feature_gate: boolean;
  user_payment_eligible: boolean;
  web_deeplink_feature: boolean;
};

export type FeaturesContextType = {
  features: FeatureListType | null;
};

const FeaturesContext = createContext<FeaturesContextType | null>(null);

export function useFeaturesContext() {
  const featuresContext = useContext(FeaturesContext);
  if (featuresContext == null) {
    throw Error(
      'Null FeaturesContext. Make sure to use FeaturesContextProvider',
    );
  }
  return featuresContext;
}

const db = getFirestore();
export function FeaturesContextProvider({children}: {children: ReactNode}) {
  const {currentUser} = useAuthContext();
  const [features, setFeatures] = useState<FeatureListType | null>(null);

  useEffect(() => {
    const fetchFeatures = async () => {
      const snapshot = await getDocs(collection(db, 'features'));
      const tempFeatures: {[name: string]: boolean} = {};
      snapshot.forEach((doc) => {
        const enabled: boolean = doc.get('enabled');
        const internalTestReady: boolean = doc.get('internal_test_ready');
        const allowlist: string[] = doc.get('allowList') ?? [];
        tempFeatures[doc.id] =
          enabled ||
          (currentUser != null &&
            ((internalTestReady && currentUser.isSuperUser) ||
              allowlist.includes(currentUser?.id)));
      });
      setFeatures(tempFeatures as FeatureListType);
    };
    fetchFeatures();
  }, [currentUser]);

  const value = useMemo(() => ({features}), [features]);

  return (
    <FeaturesContext.Provider value={value}>
      {children}
    </FeaturesContext.Provider>
  );
}
