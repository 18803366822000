import {MaterialCommunityIcons} from '@expo/vector-icons';
import {BottomTabBarHeightContext} from '@react-navigation/bottom-tabs';
import {
  collection,
  doc,
  setDoc,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import React, {useState} from 'react';
import {FlatList, Modal, Text, TouchableOpacity, View} from 'react-native';

import alert from '../../components/container/alert';
import {useAuthContext} from '../../context/AuthContext';
import {getFirestore} from '../../firebase';
import {UserAction} from '../../types';

export const BottomPicker = (params: {
  currentLoggedInUserID: string;
  targetUserId: string;
}) => {
  const {currentUser} = useAuthContext();
  const [visible, setVisible] = useState(false);
  const [reportReasonVisible, setReportReasonVisible] = useState(false);

  const userRef = doc(getFirestore(), 'users', params.currentLoggedInUserID);
  console.log(params);

  const toggleModal = () => {
    setVisible(!visible);
    setReportReasonVisible(false);
  };

  const toggleReportReasonModal = () => {
    setVisible(false);
    setReportReasonVisible(true);
  };

  const reportUser = () => {
    toggleReportReasonModal();
  };

  const blockUser = async () => {
    if (currentUser === null) {
      return;
    }

    if (currentUser.blockedUsersList) {
      currentUser.blockedUsersList.push(params.targetUserId);
    } else {
      currentUser.blockedUsersList = [params.targetUserId];
    }

    await updateDoc(userRef, {
      blockedUsersList: currentUser.blockedUsersList,
    });

    toggleModal();
  };

  const blockUserConfirm = () => {
    alert(
      '',
      '你确定要拉黑这名用户么？',
      [
        {
          onPress: () => {
            blockUser();
          },
          text: '确定',
        },
        {
          onPress: () => {},
          style: 'cancel',
          text: '取消',
        },
      ],
      {cancelable: true},
    );
    setVisible(false);
  };

  const cancelAction = () => {
    setVisible(false);
    setReportReasonVisible(false);
  };

  const removeAccountAlert = () => {
    alert('', '我们将尽快核实你所举报的内容，并第一时间处理，谢谢你的理解', [
      {
        onPress: () => {
          cancelAction();
        },
        text: '确认',
      },
    ]);
  };

  const reportUserWithReason = async (reason: string) => {
    const reportUserRef = doc(collection(getFirestore(), 'reportUserRecord'));
    await setDoc(reportUserRef, {
      reportDatetime: Timestamp.now(),
      reportedUserId: params.targetUserId,
      reportingUserId: params.currentLoggedInUserID,
      reportreason: reason,
      reviewed: false,
    });
    setVisible(false);
    setReportReasonVisible(false);
    removeAccountAlert();
  };

  const actions: UserAction[] = [
    {label: '举报', triggerAction: reportUser, value: '举报'},
    {label: '拉黑', triggerAction: blockUserConfirm, value: '拉黑'},
    {label: '取消', triggerAction: cancelAction, value: '取消'},
  ];

  const reportOption: UserAction[] = [
    {label: '违法违规', triggerAction: reportUserWithReason, value: '违法违规'},
    {label: '诈骗信息', triggerAction: reportUserWithReason, value: '诈骗信息'},
    {label: '色情内容', triggerAction: reportUserWithReason, value: '色情内容'},
    {label: '人身攻击', triggerAction: reportUserWithReason, value: '人身攻击'},
    {label: '政治敏感', triggerAction: reportUserWithReason, value: '政治敏感'},
    {label: '其他', triggerAction: cancelAction, value: '其他'},
    {label: '取消', triggerAction: cancelAction, value: '取消'},
  ];

  const renderItem = ({item}: {item: UserAction}) => {
    return (
      <TouchableOpacity
        onPress={() => {
          item.triggerAction(item.value);
        }}
        style={{
          alignItems: 'center',
          marginBottom: 20,
          paddingVertical: 8,
        }}>
        <Text style={{fontSize: 16}}>{item.label}</Text>
      </TouchableOpacity>
    );
  };

  return (
    <BottomTabBarHeightContext.Consumer>
      {(tabBarHeight) => (
        <>
          <TouchableOpacity onPress={toggleModal}>
            <MaterialCommunityIcons
              color="black"
              name="dots-horizontal"
              size={24}
            />
          </TouchableOpacity>

          <Modal animationType="slide" transparent visible={visible}>
            <View style={{flex: 1, paddingBottom: tabBarHeight}}>
              <TouchableOpacity onPress={toggleModal} style={{flex: 1}} />
              <View style={{backgroundColor: 'white'}}>
                <FlatList
                  data={actions}
                  keyExtractor={(item) => item.value.toString()}
                  renderItem={renderItem}
                />
              </View>
            </View>
          </Modal>

          <Modal
            animationType="slide"
            transparent
            visible={reportReasonVisible}>
            <View style={{flex: 1, paddingBottom: tabBarHeight}}>
              <TouchableOpacity onPress={toggleModal} style={{flex: 1}} />
              <View>
                <Text
                  style={{
                    backgroundColor: 'white',
                    color: 'grey',
                    paddingVertical: 12,
                    textAlign: 'center',
                  }}>
                  请选择举报原因
                </Text>
              </View>
              <View>
                <View style={{backgroundColor: 'white'}}>
                  <FlatList
                    data={reportOption}
                    keyExtractor={(item) => item.value.toString()}
                    renderItem={renderItem}
                  />
                </View>
              </View>
            </View>
          </Modal>
        </>
      )}
    </BottomTabBarHeightContext.Consumer>
  );
};
