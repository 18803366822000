import {Ionicons} from '@expo/vector-icons';
import {useNavigation} from '@react-navigation/native';
import {
  doc,
  getDoc,
  runTransaction,
  setDoc,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import {useEffect, useLayoutEffect, useState} from 'react';
import {
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';

import alert from '../components/container/alert';
import StarsRatingContainer from '../components/container/StarsRatingContainer';
import {USE_PRODUCTION_BUILD} from '../constants/config';
import {useAuthContext} from '../context/AuthContext';
import {getFirestore} from '../firebase';
import {Group} from '../types';

const length = '96%';
const padding = '2%';
const starSize = 36;

export default function RatingPopUpScreen({route}: {route: any}) {
  const navigation: any = useNavigation();
  const {currentUser} = useAuthContext();
  const {groups} = route.params;

  type feedbackState = {
    [creatorId: string]: {
      rating: number;
      review: string;
      groupId: string;
      groupTitle: string;
    };
  };
  const [feedback, setFeedback] = useState<feedbackState>({});

  const handleRating = (
    groupId: string,
    rating: number,
    creatorId: string,
    groupTitle: string,
  ) => {
    const groupFeedback = feedback[creatorId] || {};
    groupFeedback.rating = rating;
    groupFeedback.review = groupFeedback.review || '';
    groupFeedback.groupId = groupId;
    groupFeedback.groupTitle = groupTitle;

    const updatedFeedback = {
      ...feedback,
      [creatorId]: groupFeedback,
    };
    setFeedback(updatedFeedback);
  };

  const handleReview = (
    groupId: string,
    text: string,
    creatorId: string,
    groupTitle: string,
  ) => {
    const groupFeedback = feedback[creatorId] || {};
    groupFeedback.rating = groupFeedback.rating || 0;
    groupFeedback.review = text;
    groupFeedback.groupId = groupId;
    groupFeedback.groupTitle = groupTitle;
    const updatedFeedback = {
      ...feedback,
      [creatorId]: groupFeedback,
    };
    setFeedback(updatedFeedback);
  };

  const handleSubmit = async () => {
    const db = getFirestore();
    for (const [creatorId, feedbackData] of Object.entries(feedback)) {
      try {
        const ratingRef = doc(db, 'ratings', creatorId);
        const ratingSnap = await getDoc(ratingRef);

        const newData = {
          groupId: feedbackData.groupId,
          userId: currentUser?.id,
          userName: currentUser?.userName,
          userProfileImageUrl: currentUser?.profileImageUrl,
          rating: feedbackData.rating ?? 0,
          review: feedbackData.review ?? '',
          created: Timestamp.now(),
        };

        if (ratingSnap.exists()) {
          const currentData = ratingSnap.data();
          currentData.feedback.push(newData);

          // Calculate the average rating
          const nonZeroRatings = currentData.feedback.filter(
            (entry: any) => entry.rating !== 0,
          );

          const totalRatingSum = nonZeroRatings.reduce(
            (sum: number, entry: any) => sum + entry.rating,
            0,
          );

          const newAverageRating =
            nonZeroRatings.length > 0
              ? totalRatingSum / nonZeroRatings.length
              : 0;

          await updateDoc(ratingRef, {
            averageRating: newAverageRating,
            feedback: currentData.feedback,
          });
        } else {
          // Group ratings dont exist yet
          await setDoc(ratingRef, {
            userId: creatorId,
            averageRating: feedbackData.rating || 0,
            feedback: [newData],
          });
        }

        // await runTransaction(db, async (transaction) => {
        //   const groupRef = doc(db, 'groups', groupId);
        //   const groupSnap = await transaction.get(groupRef);
        //   const oldRatings = groupSnap?.data()?.ratings
        //     ? new Map(Object.entries(groupSnap?.data()?.ratings))
        //     : new Map();
        //   oldRatings.set(currentUser?.id, rating);
        //   var payload = {ratings: Object.fromEntries(oldRatings)};
        //   transaction.update(groupRef, payload);
        // });
      } catch (error) {
        console.log('update rating ', creatorId, error);
        if (!USE_PRODUCTION_BUILD) {
          alert('评分出错', creatorId + ': ' + error);
        }
      }
    }

    navigation.navigate('GroupHome');
    alert(
      '提交反馈成功',
      '您可以随时重新提交修改您的反馈',
      [{onPress: () => {}, text: '确定'}],
      {cancelable: false},
    );
  };

  const updateLastRatingTime = async () => {
    try {
      await updateDoc(doc(getFirestore(), 'users/' + currentUser?.id), {
        lastRatingTime: Timestamp.now(),
      });
    } catch (error) {
      if (!USE_PRODUCTION_BUILD) {
        alert('', 'lastRatingTime: ' + error);
      }
      console.log('error update lastRatingTime ', error);
    }
  };

  useEffect(() => {
    updateLastRatingTime(); // Call the async function when the component mounts
  }, []); // Empty dependency array means it only runs once on mount

  useLayoutEffect(() => {
    navigation.setOptions({
      headerBackVisible: false,
      headerRight: () => (
        <Pressable
          onPress={() => {
            navigation.goBack();
          }}>
          <Ionicons color="black" name="close" size={24} />
        </Pressable>
      ),
      title: '提交反馈',
    });
  }, [navigation]);

  return (
    <View style={styles.container}>
      <ScrollView
        style={{paddingHorizontal: padding}}
        showsVerticalScrollIndicator={false}>
        <View style={styles.feedbackContainer}>
          {groups.map((group: Group) => (
            <View key={group.id}>
              <View style={styles.titleView}>
                <Text style={styles.title}>给活动{group.title}评分</Text>
              </View>
              <StarsRatingContainer
                groupId={group.id}
                handleRating={(rating) =>
                  handleRating(group.id, rating, group.creator.id, group.title)
                }
                rating={feedback[group.creator.id]?.rating ?? 0} // Use the saved rating for this group
                starSize={starSize}
              />
              {/* Review text field */}
              <TextInput
                multiline={true}
                numberOfLines={4}
                onChangeText={(text) =>
                  handleReview(group.id, text, group.creator.id, group.title)
                }
                placeholder="您可以提供任何评价~"
                placeholderTextColor="gray"
                style={styles.reviewInput}
                value={feedback[group.creator.id]?.review ?? ''}
              />
              <View style={styles.separator} />
            </View>
          ))}
        </View>
      </ScrollView>

      <TouchableOpacity onPress={handleSubmit} style={styles.shareButton}>
        <Text style={styles.shareButtonText}>提交</Text>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: 'white',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: 20,
    width: '100%',
  },
  feedbackContainer: {
    width: 300,
  },
  reviewInput: {
    borderColor: 'lightgray',
    borderRadius: 20,
    borderWidth: 0.5,
    height: 150,
    marginVertical: 10,
    padding: 15,
    width: '100%',
    textAlignVertical: 'top',
  },
  separator: {
    alignSelf: 'center',
    backgroundColor: '#c0c0c0',
    height: 1,
    marginVertical: 20,
    width: length,
  },
  shareButton: {
    alignSelf: 'center',
    backgroundColor: '#66BB6A',
    borderRadius: 5,
    bottom: 30,
    marginTop: 40,
    paddingVertical: 8,
    width: length,
  },
  shareButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  text: {
    fontSize: 18,
    paddingHorizontal: padding,
    paddingVertical: 8,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  titleView: {
    alignItems: 'center',
    alignSelf: 'center',
    flexDirection: 'row',
  },
});
