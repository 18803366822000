import 'firebase/database';

import React from 'react';
import {StyleSheet, Text, TouchableOpacity, View} from 'react-native';

import Colors from '../../constants/Colors';

type TabProps = {
  onPress: () => void;
  selected: boolean;
  title: string;
};

const Tab: React.FC<TabProps> = ({title, selected, onPress}) => (
  <TouchableOpacity onPress={onPress} style={styles.tab}>
    <Text style={[styles.tabText, selected && styles.selectedTabText]}>
      {title}
    </Text>
    {selected && <View style={styles.tabIndicator} />}
  </TouchableOpacity>
);

export default function TabBar({
  tabs,
  selectedTab,
  onTabPress,
}: {
  onTabPress: any;
  selectedTab: number;
  tabs: {title: string}[];
}) {
  return (
    <View style={styles.tabBar}>
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          onPress={() => onTabPress(index)}
          selected={index === selectedTab}
          title={tab.title}
        />
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  selectedTabText: {
    color: 'black',
  },
  tab: {
    alignItems: 'center',
    borderBottomWidth: 2,
    borderColor: 'transparent',
    flex: 1,
    paddingVertical: 10,
  },
  tabBar: {
    backgroundColor: 'white',
    flexDirection: 'row',
  },
  tabContent: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  tabIndicator: {
    backgroundColor: Colors.mainAppColor,
    bottom: 0,
    height: 2,
    position: 'absolute',
    width: '100%',
  },
  tabText: {
    color: 'grey',
    fontSize: 14,
    fontWeight: 'bold',
  },
});
