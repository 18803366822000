import {AntDesign, Feather} from '@expo/vector-icons';
import {doc, getDoc} from 'firebase/firestore';
import React, {useEffect, useState} from 'react';
import {Dimensions, Image, StyleSheet, Text, View} from 'react-native';

import {useAuthContext} from '../../context/AuthContext';
import {getFirestore} from '../../firebase';
import userConverter from '../../firestoreConverters/userConverter';
import FastImage from '../../platforms/FastImage';
import {emptyUser, Tour} from '../../types';
import Spacer from '../misc/Spacer';

type DiscoverCardProps = {
  defaultWidth?: number;
  enableCardBackground?: boolean;
  enableCommentHeader?: boolean;
  tour: Tour;
};

const {width: WINDOW_WIDTH} = Dimensions.get('window');

export default function DiscoverCardView({
  defaultWidth = WINDOW_WIDTH - 20,
  tour,
  enableCardBackground = true, // by default, we need a shadow background
  enableCommentHeader = true,
}: DiscoverCardProps) {
  const {currentUser} = useAuthContext();
  const [postLiked, setPostLiked] = useState(false);
  const [poster, setPoster] = useState(emptyUser);
  const [imageHeight, setImageHeight] = useState(200);

  // Fetch poster's information, including name and profile image
  useEffect(() => {
    const fetchPoster = async (posterID: string) => {
      const posterRef = doc(getFirestore(), 'users', posterID).withConverter(
        userConverter,
      );
      const posterSnapshot = await getDoc(posterRef);
      const fetchedPoster = posterSnapshot.data() || emptyUser;
      setPoster(fetchedPoster);
    };

    fetchPoster(tour.poster);
  }, [tour.poster]);

  // Set whether the post is liked by the current user
  useEffect(() => {
    if (
      currentUser !== null &&
      currentUser.likedPostsList &&
      currentUser.likedPostsList.includes(tour.id)
    ) {
      setPostLiked(true);
    } else {
      setPostLiked(false);
    }
  }, [currentUser, tour.id]);

  Image.getSize(tour.headerImage, (width, height) => {
    const originalRatio = width / height;
    const adjustedHeight = defaultWidth / originalRatio;

    setImageHeight(adjustedHeight);
  });

  return (
    <View
      style={[
        enableCardBackground ? styles.card : {},
        {
          width: defaultWidth,
        },
      ]}>
      <View style={styles.container}>
        <FastImage
          source={{uri: tour.headerImage}}
          style={[
            styles.headerImage,
            {
              height: imageHeight,
              width: defaultWidth,
            },
          ]}
        />
        <View style={styles.cardText}>
          <Text style={styles.sectionTitle}>{tour.title}</Text>
          {enableCommentHeader ? (
            <View style={styles.row}>
              {postLiked ? (
                <AntDesign
                  color="red"
                  name="heart"
                  size={20}
                  style={styles.icon}
                />
              ) : (
                <Feather
                  color="black"
                  name="heart"
                  size={20}
                  style={styles.icon}
                />
              )}
              <Text>{tour.likes}</Text>

              {/*<Spacer horizontal={true} size={'2%'} />*/}

              {/*<Feather*/}
              {/*  color="black"*/}
              {/*  name="message-square"*/}
              {/*  size={20}*/}
              {/*  style={styles.icon}*/}
              {/*/>*/}
              {/*<Text>{tour.comments?.length || 0}</Text>*/}

              <View style={{flexGrow: 1}} />

              <View style={styles.avatarContainer}>
                {poster.profileImageUrl ? (
                  <FastImage
                    source={{uri: poster?.profileImageUrl}}
                    style={styles.avatarImage}
                  />
                ) : (
                  <FastImage
                    source={require('../../assets/images/simple-avatar.png')}
                    style={styles.avatarImage}
                  />
                )}
              </View>

              <Text style={styles.description}>{poster?.userName}</Text>
            </View>
          ) : (
            <View />
          )}

          <Spacer size={6} />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  avatarContainer: {
    borderRadius: 50,
    height: 25,
    margin: 4,
    overflow: 'hidden',
    width: 25,
  },
  avatarImage: {
    height: '100%',
    width: '100%',
  },
  avatarRowContainer: {
    alignContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    marginHorizontal: 5,
    marginVertical: 3,
  },
  card: {
    backgroundColor: '#fff',
    borderRadius: 5,
    elevation: 5,
    marginHorizontal: 8,
    shadowColor: '#000',
    shadowOffset: {
      height: 4,
      width: 1,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
  cardText: {},
  container: {},
  description: {
    fontSize: 14,
    fontWeight: 'normal',
    paddingLeft: 4,
    paddingRight: 10,
  },
  headerImage: {
    borderRadius: 5,
    marginBottom: 8,
  },
  icon: {
    marginHorizontal: 8,
    marginVertical: 8,
  },
  row: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  sectionTitle: {
    fontSize: 17,
    fontWeight: 'bold',
    padding: 8,
  },
});
