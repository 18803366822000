import {useFocusEffect} from '@react-navigation/native';
import * as Notifications from 'expo-notifications';
import {getAnalytics, logEvent, setUserId} from 'firebase/analytics';
import {collection, doc, getDoc} from 'firebase/firestore';
import React, {useState} from 'react';
import {useCollection} from 'react-firebase-hooks/firestore';
import {
  FlatList,
  Platform,
  Pressable,
  SafeAreaView,
  StatusBar,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import ChatEntryBox from '../components/chat/ChatEntryBox';
import WebDeeplinkHeaderView from '../components/container/WebDeeplinkHeaderView';
import FocusAwareStatusBar from '../components/misc/FocusAwareStatusBar';
import {OFFICIAL_ACCOUNT} from '../constants/config';
import {useAuthContext} from '../context/AuthContext';
import {useFeaturesContext} from '../context/FeaturesContext';
import {getFirestore} from '../firebase';
import groupConverter from '../firestoreConverters/groupConverter';
import {judgeClient, produceChatTimeString} from '../helpers/util';
import FastImage from '../platforms/FastImage';
import {Group, User} from '../types';
import LoadingScreen from './LoadingScreen';

export default function ChatHomeScreen({navigation}: {navigation: any}) {
  const {currentUser} = useAuthContext();
  if (Platform.OS === 'web') {
    const analytics = getAnalytics();
    setUserId(analytics, currentUser == undefined ? null : currentUser.id);
    logEvent(analytics, 'user_view_chathome');
  }
  const {features} = useFeaturesContext();
  const [lastAiMessage, setLastAiMessage] = useState<any | null>(null);

  const [groupsSnapshot, isLoading] = useCollection(
    collection(getFirestore(), 'groups').withConverter(groupConverter),
  );
  // TODO: set a page size to load eligible groups
  const eligibleGroups: Group[] = [];
  if (groupsSnapshot) {
    groupsSnapshot.docs.forEach((groupDoc) => {
      const group = groupDoc.data();
      if (
        group.status !== 'deleted' &&
        (currentUser?.id == OFFICIAL_ACCOUNT ||
          group.paidMembers.filter((user: User) => user.id === currentUser?.id)
            .length > 0 ||
          group.currentMembers.filter(
            (user: User) => user.id === currentUser?.id,
          ).length > 0)
      ) {
        eligibleGroups.push(group);
      }
    });
  }

  // sort in descending order
  const sortedGroups = eligibleGroups.sort((group1, group2) => {
    const time1 = group1?.lastMessageRecord?.time ?? 0;
    const time2 = group2?.lastMessageRecord?.time ?? 0;

    if (time1 > time2) {
      return -1;
    } else if (time1 < time2) {
      return 1;
    } else {
      return 0;
    }
  });

  useFocusEffect(
    React.useCallback(() => {
      const fetchData = async () => {
        if (currentUser) {
          const aiChatId = 'yaytour_' + currentUser?.id;
          // Create a reference to AI chat messages collection
          const currentRef = doc(getFirestore(), 'aiChatMessages/' + aiChatId);
          const currentSnapshot = await getDoc(currentRef);

          if (currentSnapshot.exists()) {
            const currentChat = currentSnapshot.data();
            const messages = currentChat?.messages;

            if (messages?.length > 0) {
              setLastAiMessage(messages[messages.length - 1]);
            }
          } else {
            console.log('Document does not exist for aiChatId:', aiChatId);
          }
        }
      };
      fetchData();
    }, [currentUser]),
  );

  if (isLoading) {
    return (
      <SafeAreaView style={styles.safeAreaStyle}>
        <LoadingScreen />
      </SafeAreaView>
    );
  }

  return (
    <SafeAreaView style={styles.safeAreaStyle}>
      <View style={styles.container}>
        {features?.web_deeplink_feature &&
          (judgeClient() === 'web-android' || judgeClient() === 'web-ios') && (
            <WebDeeplinkHeaderView path="chats" />
          )}
        <FocusAwareStatusBar style="dark" />
        <View style={styles.sectionTitle}>
          <Text style={styles.sectionTitleText}>消息列表</Text>
        </View>
        {features?.enable_ai_chat && currentUser !== null && (
          <View>
            <TouchableOpacity
              onPress={() => {
                navigation.navigate('AiChatScreen');
              }}
              style={styles.chatEntry}>
              <View style={styles.chatEntryBoxRox}>
                <View style={{padding: 10}}>
                  <FastImage
                    source={require('../assets/images/AI_avatar_thumbnail.png')}
                    style={styles.chatImage}
                  />
                </View>
                <View style={styles.chatDetails}>
                  <Text
                    ellipsizeMode={'tail'}
                    numberOfLines={1}
                    style={styles.chatGroupName}>
                    AI活动推荐助手
                  </Text>
                  {lastAiMessage ? (
                    <Text
                      ellipsizeMode={'tail'}
                      numberOfLines={1}
                      style={styles.chatLastMessage}>
                      {lastAiMessage.text}
                    </Text>
                  ) : (
                    <Text
                      ellipsizeMode={'tail'}
                      numberOfLines={1}
                      style={styles.chatLastMessage}>
                      来做第一个开启群聊的人吧
                    </Text>
                  )}
                </View>
                <Text style={styles.lastMessageTime}>
                  {produceChatTimeString(lastAiMessage?.createdAt.toDate())}
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        )}
        <View style={{flex: 1, flexDirection: 'column'}}>
          <FlatList
            contentContainerStyle={styles.content}
            data={sortedGroups}
            renderItem={({item}) => (
              <Pressable
                key={item.id}
                onPress={() =>
                  navigation.navigate('ChatScreen', {
                    groupId: item.id,
                    groupTitle: item.title,
                  })
                }
                style={styles.chatEntry}>
                <ChatEntryBox group={item} />
              </Pressable>
            )}
            showsVerticalScrollIndicator={false}
          />
        </View>

        {sortedGroups.length == 0 && (
          <View style={{flex: 1}}>
            <Text style={styles.placeHolderText}>
              加入组队后，才可以看到群消息
            </Text>
          </View>
        )}
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  aiChatEntry: {
    borderBottomWidth: 1,
    paddingHorizontal: 8,
    paddingVertical: 4,
  },
  aiChatEntryText: {
    color: 'green',
    fontSize: 20,
    paddingLeft: 20,
    textAlign: 'left',
  },
  chatDetails: {
    flex: 3,
    flexDirection: 'column',
    marginHorizontal: 12,
  },
  chatEntry: {
    borderBottomColor: 'whitesmoke',
    borderBottomWidth: 1,
    paddingHorizontal: 8,
    paddingVertical: 4,
  },
  chatEntryBoxRox: {
    alignItems: 'center',
    flexDirection: 'row',
    marginHorizontal: 12,
  },
  chatGroupName: {
    fontSize: 18,
    paddingVertical: 2,
  },
  chatImage: {
    aspectRatio: 1 / 1,
    borderRadius: 5,
    width: 48,
  },
  chatLastMessage: {
    color: 'grey',
    fontSize: 12,
    paddingVertical: 2,
  },
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  content: {
    alignItems: 'stretch',
  },
  lastMessageTime: {
    color: 'grey',
    flex: 1,
    fontSize: 12,
    textAlign: 'right',
  },
  placeHolderText: {
    color: 'grey',
    fontSize: 20,
    textAlign: 'center',
  },
  safeAreaStyle: {
    backgroundColor: 'white',
    flex: 1,
    paddingTop: Platform.OS === 'android' ? StatusBar.currentHeight : 0,
  },
  sectionTitle: {
    alignItems: 'center',
    borderBottomColor: '#66BB6A',
    borderBottomWidth: 2,
    justifyContent: 'center',
    paddingVertical: 10,
  },
  sectionTitleText: {
    fontSize: 14,
    fontWeight: 'bold',
  },
});
