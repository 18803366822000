import {getAnalytics, logEvent, setUserId} from 'firebase/analytics';
import {
  Platform,
  SafeAreaView,
  StatusBar,
  StyleSheet,
  View,
} from 'react-native';

import {AccountAskForLogin} from '../components/account/AccountAskLogIn';
import AccountProfile from '../components/account/AccountProfile';
import WebDeeplinkHeaderView from '../components/container/WebDeeplinkHeaderView';
import FocusAwareStatusBar from '../components/misc/FocusAwareStatusBar';
import {useAuthContext} from '../context/AuthContext';
import {useFeaturesContext} from '../context/FeaturesContext';
import {judgeClient} from '../helpers/util';

export default function AccountHomeScreen({navigation}: {navigation: any}) {
  const currentUserId = useAuthContext().currentUser?.id;
  const {features} = useFeaturesContext();
  if (currentUserId == null) {
    return (
      <SafeAreaView style={styles.safeAreaStyle}>
        <View style={{flex: 1}}>
          {features?.web_deeplink_feature &&
            (judgeClient() === 'web-android' ||
              judgeClient() === 'web-ios') && (
              <WebDeeplinkHeaderView path="account" />
            )}
          <FocusAwareStatusBar />
          <AccountAskForLogin navigation={navigation} />
        </View>
      </SafeAreaView>
    );
  }
  if (Platform.OS === 'web') {
    const analytics = getAnalytics();
    setUserId(analytics, currentUserId);
    logEvent(analytics, 'user_view_account');
  }
  return (
    <SafeAreaView style={styles.safeAreaStyle}>
      {features?.web_deeplink_feature &&
        (judgeClient() === 'web-android' || judgeClient() === 'web-ios') && (
          <WebDeeplinkHeaderView path="account" />
        )}
      <FocusAwareStatusBar />
      <AccountProfile navigation={navigation} userId={null} />
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  safeAreaStyle: {
    backgroundColor: 'white',
    flex: 1,
    paddingTop: Platform.OS === 'android' ? StatusBar.currentHeight : 0,
  },
});
