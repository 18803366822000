import 'firebase/database';

import {Ionicons} from '@expo/vector-icons';
import React, {useState} from 'react';
import {Image, Linking, Pressable, StyleSheet, Text, View} from 'react-native';

import {Group} from '../../types';

const bannerAdHeight = 100;

export default function GroupDatailBannerAd({group}: {group: Group}) {
  const [pageState, setPageState] = useState(false); // false: default; true: closed
  const sponsorLink =
    group?.sponsors && group?.sponsors.length > 1 ? group?.sponsors[1] : '';

  if (sponsorLink === '' || pageState) {
    return null;
  }
  const sponsorImageUrl =
    group?.sponsors && group?.sponsors.length > 2 ? group?.sponsors[2] : '';
  const sponsorTitle =
    group?.sponsors && group?.sponsors.length > 3 ? group?.sponsors[3] : '';
  const sponsorDescription =
    group?.sponsors && group?.sponsors.length > 4 ? group?.sponsors[4] : '';

  return (
    <View>
      <Pressable
        onPress={() => {
          Linking.openURL(sponsorLink);
        }}
        style={styles.container}>
        {sponsorImageUrl && (
          <Image source={{uri: sponsorImageUrl}} style={[styles.image]} />
        )}
        <View style={styles.middleText}>
          {sponsorTitle && (
            <Text ellipsizeMode={'tail'} numberOfLines={2} style={styles.title}>
              {sponsorTitle}
            </Text>
          )}
          {sponsorDescription && (
            <Text ellipsizeMode={'tail'} numberOfLines={2}>
              {sponsorDescription}
            </Text>
          )}
        </View>
        <Pressable
          onPress={() => {
            setPageState(true);
          }}
          style={{margin: 8}}>
          <Ionicons color="black" name="close" size={24} />
        </Pressable>
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#e4e4e470',
    borderRadius: 12,
    flexDirection: 'row',
    height: bannerAdHeight,
    marginVertical: 10,
    width: '100%',
  },
  image: {
    borderRadius: 12,
    height: bannerAdHeight,
    width: bannerAdHeight,
  },
  middleText: {
    flexGrow: 10,
    flexShrink: 10,
    margin: 8,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 2,
  },
});
