import {Ionicons} from '@expo/vector-icons';
import {useNavigation} from '@react-navigation/native';
import {useLayoutEffect} from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import {SHARE_LINK_BASE_URL} from '../constants/config';
import {shareLink} from '../helpers/util';

const length = '96%';
const padding = '2%';

export default function CreateGroupSuccessfulScreen({route}: {route: any}) {
  const navigation: any = useNavigation();
  const {numLeft, title, groupId} = route.params;

  useLayoutEffect(() => {
    navigation.setOptions({
      headerBackVisible: false,
      headerLeft: () => <></>,
      headerRight: () => (
        <Pressable
          onPress={() => {
            navigation.getParent()?.goBack();
            navigation.navigate('AccountTab', {screen: 'AccountHome'}); //In web it will stay at group page if this line removed
          }}>
          <Ionicons color="black" name="close" size={24} />
        </Pressable>
      ),
      title: '',
    });
  }, [navigation]);

  return (
    <View style={{backgroundColor: 'white', height: '100%'}}>
      <View style={styles.container}>
        <View style={styles.titleView}>
          <Ionicons color="#66BB6A" name="paper-plane-outline" size={24} />
          <Text style={styles.title}>已成功创建组队！</Text>
        </View>
        <Text style={styles.text}>
          还差{numLeft}
          人，赶快分享你的组队来帮助你的组队早日成团！
        </Text>

        <TouchableOpacity
          onPress={() => {
            console.log('share ');
            shareLink(
              `我已成功创建活动${title}，还差${numLeft}人， 快来加入我的组队吧！`,
              SHARE_LINK_BASE_URL + `/group/${groupId}`,
            );
          }}
          style={styles.shareButton}>
          <Text style={styles.shareButtonText}>分享</Text>
        </TouchableOpacity>

        <View style={styles.divider} />

        <Text style={styles.text}>
          现在您可以通过野兔APP发送群聊消息了！这意味着您可以实时收到消息并与小伙伴进行交流。
        </Text>

        <TouchableOpacity
          onPress={() => {
            navigation.navigate('ChatTab', {
              params: {
                groupId: groupId,
                groupTitle: title,
              },
              screen: 'ChatScreen',
            });
          }}
          style={styles.shareButton}>
          <Text style={styles.shareButtonText}>查看群聊</Text>
        </TouchableOpacity>
      </View>

      <View style={{height: 30}} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'flex-start',
    backgroundColor: 'white',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'flex-start',
    paddingHorizontal: 16,
  },
  divider: {
    alignSelf: 'center',
    backgroundColor: 'lightgrey',
    flexDirection: 'column',
    height: 1,
    marginVertical: 30,
    width: length,
  },
  qrCode: {
    alignSelf: 'center',
    height: 160,
    width: 160,
  },
  separator: {
    alignSelf: 'center',
    backgroundColor: '#c0c0c0',
    height: 1,
    marginVertical: 20,
    width: length,
  },
  shareButton: {
    alignSelf: 'center',
    backgroundColor: '#66BB6A',
    borderRadius: 5,
    bottom: 20,
    marginTop: 40,
    paddingVertical: 8,
    width: length,
  },
  shareButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  text: {
    fontSize: 18,
    paddingHorizontal: padding,
    paddingVertical: 8,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    paddingLeft: 2,
  },
  titleView: {
    alignItems: 'center',
    alignSelf: 'center',
    flexDirection: 'row',
    paddingVertical: 16,
  },
});
