import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {useEffect, useState} from 'react';
import {View} from 'react-native';

import {STRIPE_PUBLISHABLE_KEY} from '../../constants/config';
import CheckoutForm from './WebCheckoutForm';

export default function WebStripeContainer({
  clientSecret,
  addUsersToPaidMembers,
}: {
  addUsersToPaidMembers: any;
  clientSecret: string;
}) {
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    setStripePromise(loadStripe(STRIPE_PUBLISHABLE_KEY));
  }, []);
  console.log('CS: ' + clientSecret);
  return (
    <View>
      {clientSecret && stripePromise && (
        <Elements
          key={clientSecret}
          options={{
            clientSecret: clientSecret,
          }}
          stripe={stripePromise}>
          <CheckoutForm addUsersToPaidMembers={addUsersToPaidMembers} />
        </Elements>
      )}
    </View>
  );
}
