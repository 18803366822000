import React from 'react';
import {Text, View} from 'react-native';

type WebDatePickerFieldProps = {
  datePickerType: 'date' | 'time' | 'datetime-local';
  onChange: any;
  shouldHighlight: boolean;
  defaultValue: Date | undefined;
};

export default function WebDatePickerField({
  datePickerType,
  onChange,
  shouldHighlight,
  defaultValue,
}: WebDatePickerFieldProps) {
  function formatDefaultValue(date: Date | undefined): string {
    if (date === undefined) return '';
    const year = date?.getFullYear();
    const month = String(date?.getMonth() + 1).padStart(2, '0');
    const day = String(date?.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    if (datePickerType === 'date') {
      return `${year}-${month}-${day}`;
    }
    if (datePickerType === 'time') {
      return `${hours}:${minutes}`;
    }
    if (datePickerType === 'datetime-local') {
      return `${year}-${month}-${day}T${hours}:${minutes}`;
    }
    return '';
  }

  return (
    <View>
      {React.createElement('input', {
        defaultValue: formatDefaultValue(defaultValue),
        onInput: onChange,
        style: {
          borderColor: shouldHighlight ? 'red' : 'gray',
          borderRadius: 10,
          borderWidth: 1,
          display: 'block',
          fontSize: 14,
          height: 42,
          width: '100%',
        },
        type: datePickerType,
      })}
    </View>
  );
}
