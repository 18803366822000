import React, {useState} from 'react';
import {ScrollView, StyleSheet, useWindowDimensions, View} from 'react-native';

import Features from '../components/landing/Features';
import Footer from '../components/landing/Footer';
import Hero from '../components/landing/Hero';
import Navbar from '../components/landing/Navbar';

export default function LandingPageScreen({navigation}: any) {
  const {width: screenWidth} = useWindowDimensions();
  const isScreenWide = screenWidth > 1024;

  const [language, setLanguage] = useState('chinese');

  const toggleLanguage = () => {
    setLanguage((prevLanguage) =>
      prevLanguage === 'english' ? 'chinese' : 'english',
    );
  };
  return (
    <View style={styles.landingPageContainer}>
      {/* Navbar */}
      <Navbar
        isScreenWide={isScreenWide}
        language={language}
        navigation={navigation}
        toggleLanguage={toggleLanguage}
      />
      <ScrollView>
        {/* Hero Section */}
        <Hero
          isScreenWide={isScreenWide}
          language={language}
          navigation={navigation}
        />
        {/* Feature Section */}
        <Features isScreenWide={isScreenWide} language={language} />
        {/* Footer */}
        <Footer
          isScreenWide={isScreenWide}
          language={language}
          navigation={navigation}
        />
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  landingPageContainer: {
    backgroundColor: 'white',
    flex: 1,
  },
});
