import 'firebase/database';

import {collection, Timestamp} from 'firebase/firestore';
import React from 'react';
import {useCollection} from 'react-firebase-hooks/firestore';
import {FlatList, Pressable, StyleSheet, Text, View} from 'react-native';

import Colors from '../../constants/Colors';
import Layout from '../../constants/Layout';
import {useAuthContext} from '../../context/AuthContext';
import {getFirestore} from '../../firebase';
import groupConverter from '../../firestoreConverters/groupConverter';
import isUserProfileCompeleted from '../../helpers/isUserProfileCompeleted';
import LoadingScreen from '../../screens/LoadingScreen';
import {emptyUser, Group} from '../../types';
import GroupCardViewVertical from '../group/GroupCardViewVertical';

export default function AccountListTripView({
  isPast,
  userId,
  navigation,
}: {
  isPast: boolean;
  navigation: any;
  userId: any;
}) {
  const {currentUser} = useAuthContext();
  const loggedInUser = currentUser ?? emptyUser;
  let currentUserId = currentUser?.id ?? '';

  let viewSelf = true;
  if (userId != null && userId != undefined && userId != currentUserId) {
    currentUserId = userId;
    viewSelf = false;
  }
  const [groupsSnapshot, isLoading] = useCollection(
    collection(getFirestore(), 'groups').withConverter(groupConverter),
  );
  const allGroups: Group[] = [];
  if (groupsSnapshot) {
    groupsSnapshot.docs.forEach((doc) => {
      const group = doc.data();
      if (
        group.status !== 'deleted' &&
        (group.privateGroup != true || viewSelf)
      ) {
        allGroups.push(group);
      }
    });
  }

  const dateFilteredGroup = allGroups.filter(
    (item) =>
      (item.dateEnd &&
        item.dateEnd != null &&
        isPast &&
        Timestamp.now().seconds - item.dateEnd.seconds > 0) ||
      (item.dateEnd &&
        item.dateEnd != null &&
        !isPast &&
        Timestamp.now().seconds - item.dateEnd.seconds < 0),
  );

  const sortedGroups = dateFilteredGroup.slice().sort((a, b) => {
    const startDateOfA = a.dateStart;
    const startDateOfB = b.dateStart;
    const multiplier = isPast ? -1 : 1;
    return multiplier * (startDateOfA.toMillis() - startDateOfB.toMillis());
  });

  const shownGroups =
    sortedGroups.filter(
      (item) =>
        item.creator.id === currentUserId ||
        item.currentMembers.filter(
          (member) => member?.id != null && member?.id === currentUserId,
        ).length > 0,
    ) ?? [];

  if (loggedInUser?.blockedUsersList?.includes(currentUserId)) {
    return null;
  }

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <View style={styles.groupCardList}>
      <FlatList
        ListFooterComponent={
          viewSelf && !isPast ? (
            <View>
              {shownGroups.length == 0 && (
                <View style={styles.emptyText}>
                  <Text>
                    您当前未加入任何活动，去组队界面查看或者发起您自己的活动吧！
                  </Text>
                </View>
              )}
              <Pressable
                onPress={() => {
                  if (
                    currentUser != null &&
                    !isUserProfileCompeleted(currentUser)
                  ) {
                    navigation.push('LoginModal', {
                      params: {
                        gender: currentUser.gender || '',
                        image: currentUser.profileImageUrl || '',
                        mode: 'complete',
                        name: currentUser.name || '',
                        referCode: '',
                        username: currentUser.userName || '',
                        wechat: currentUser.socialWechat || '',
                      },
                      screen: 'Profile',
                    });
                  } else {
                    navigation.push('CreateGroupScreen');
                  }
                }}
                style={styles.createGroupButton}>
                <Text style={styles.createGroupButtonText}>发起组队</Text>
              </Pressable>
            </View>
          ) : null
        }
        columnWrapperStyle={styles.columnWrapper}
        contentContainerStyle={styles.content}
        data={shownGroups}
        numColumns={2}
        renderItem={({item}) => (
          <Pressable
            key={item.id}
            onPress={() =>
              navigation.push('GroupDetails', {
                groupId: item.id,
                isPast: isPast,
                title: '我的组队',
              })
            }>
            <GroupCardViewVertical group={item} />
          </Pressable>
        )}
        showsVerticalScrollIndicator={false}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  columnWrapper: {
    justifyContent: 'space-between',
    paddingHorizontal: 0,
  },
  content: {
    paddingBottom: 20,
  },
  createGroupButton: {
    alignSelf: 'center',
    backgroundColor: Colors.mainAppColor,
    borderRadius: 8,
    marginVertical: 0,
    paddingVertical: 8,
    width: '100%',
  },
  createGroupButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  emptyText: {
    color: 'black',
    fontSize: 16,
    fontWeight: 'bold',
    paddingVertical: 20,
    textAlign: 'center',
  },
  groupCardList: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    paddingHorizontal: Layout.unit * 2,
  },
});
