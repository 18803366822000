import {collection, doc, setDoc, Timestamp} from 'firebase/firestore';
import {getDownloadURL, getStorage, ref, uploadBytes} from 'firebase/storage';

import {getFirestore} from '../firebase';
import {Group} from '../types';

export async function uploadTourObj(
  uri: string,
  title: string,
  description: string,
  userId: string,
  folder: string,
  isSelectedMerchant: boolean,
  price: string,
  oldPrice: string,
  purchaseUrl: string,
) {
  const response = await fetch(uri);
  const imageTimestamp = Timestamp.now();
  const newFileName = userId + '_' + imageTimestamp;
  const blob = await response.blob();
  const refLocation = ref(getStorage(), folder + newFileName);

  await uploadBytes(refLocation, blob);
  const downloadURL = await getDownloadURL(refLocation);

  const documentRef = doc(collection(getFirestore(), 'tours'));
  const tourID = documentRef.id;

  await setDoc(documentRef, {
    description: description,
    headerImage: downloadURL,
    id: tourID,
    likes: 0,
    oldPrice: oldPrice ? oldPrice : null,
    postedBySelectedMerchant: isSelectedMerchant,
    poster: userId,
    price: price ? price : null,
    purchaseUrl: purchaseUrl,
    title: title,
    uploadDate: imageTimestamp,
  });

  return tourID;
}

export function getFirstHeaderImageUrl(headerImage: string | string[]) {
  if (headerImage == null) {
    return '';
  }
  if (typeof headerImage === 'string') {
    return headerImage;
  }
  return headerImage[0];
}

export function isHeaderImageEmpty(headerImage: string | string[]) {
  if (headerImage == null) {
    return true;
  }
  if (typeof headerImage === 'string') {
    return headerImage === '';
  }
  return headerImage.length === 0;
}

// since we're using Firebase Image Extension, every pic uploaded to store will have two versions: original and compressed
// in group details screen, we'll (try to) use the original version, while in other places we'll (try to) use the compressed version
export function headerImageEmpty(group: Group) {
  return (
    group.headerImage.length === 0 && group.compressedHeaderImage.length === 0
  );
}

export function getFirstHeaderImageUrlFromGroup(
  group: Group,
  preferOriginal: boolean = false,
) {
  // well, original version is guaranteed to exist. code is structured this way to avoid breaking changes...
  if (preferOriginal && group.headerImage.length > 0) {
    return getFirstHeaderImageUrl(group.headerImage);
  } else if (group.compressedHeaderImage.length > 0) {
    return getFirstHeaderImageUrl(group.compressedHeaderImage);
  } else if (
    group.compressedHeaderImage.length === 0 &&
    group.headerImage.length > 0
  ) {
    return getFirstHeaderImageUrl(group.headerImage);
  } else {
    return '';
  }
}
