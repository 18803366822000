import {Entypo, Ionicons} from '@expo/vector-icons';
import {useNavigation} from '@react-navigation/native';
import {Overlay} from '@rneui/themed';
import * as Linking from 'expo-linking';
import {getAnalytics, logEvent, setUserId} from 'firebase/analytics';
import {collection, doc, getDoc, updateDoc} from 'firebase/firestore';
import {getFunctions, httpsCallable} from 'firebase/functions';
import React, {useEffect, useLayoutEffect, useState} from 'react';
import {useCollection} from 'react-firebase-hooks/firestore';
import {
  Dimensions,
  Image,
  ImageBackground,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import {CreatePaymentIntentResult} from '../../functions/src';
import alert from '../components/container/alert';
import WebDeeplinkHeaderView from '../components/container/WebDeeplinkHeaderView';
import GroupDatailBannerAd from '../components/group/GroupDetailBannerAd';
import ImageViewer from '../components/group/ImageViewer';
import OverlappeddAvatarsRow from '../components/group/OverlappedAvatarsRow';
import SimilarGroupSection from '../components/group/SimilarGroupSection';
import OpenInDefaultBrowserScreen from '../components/misc/OpenInDefaultBrowser';
import Colors from '../constants/Colors';
import {
  OFFICIAL_ACCOUNT,
  SHARE_LINK_BASE_URL,
  YAYTOUR_WECHAT_SUPPORT,
} from '../constants/config';
import {
  ENABLE_FEEDBACK,
  ENABLE_MULTIPLE_IMAGE,
  ENABLE_PAYMENT,
  ENABLE_WECHAT_REDIRECT,
} from '../constants/dev';
import Layout from '../constants/Layout';
import {useAuthContext} from '../context/AuthContext';
import {useFeaturesContext} from '../context/FeaturesContext';
import {getFirestore} from '../firebase';
import groupConverter from '../firestoreConverters/groupConverter';
import groupTagConverter from '../firestoreConverters/groupTagConverter';
import userConverter from '../firestoreConverters/userConverter';
import {
  getFirstHeaderImageUrlFromGroup,
  headerImageEmpty,
} from '../helpers/imageUtil';
import isUserProfileCompeleted from '../helpers/isUserProfileCompeleted';
import {
  eligibleForBannerAd,
  eligibleForPayment,
  eligibleForPaymentPerUser,
  eligibleForTip,
  getLatestMember,
  judgeClient,
  produceChineseDateString,
  produceDateTimeString,
  produceMeetUpLocation,
  produceTimeString,
  profileImagePreview,
  shareLink,
} from '../helpers/util';
import FastImage from '../platforms/FastImage';
import {emptyGroup, emptyUser, GroupTag, User} from '../types';
import LoadingScreen from './LoadingScreen';

const {width: WINDOW_WIDTH} = Dimensions.get('window');
const CONTAINER_PADDING_HORIZONTAL = 16;
const CONTENT_WIDTH = WINDOW_WIDTH - CONTAINER_PADDING_HORIZONTAL * 2;

export default function GroupDetailsScreen({route}: {route: any}) {
  const navigation: any = useNavigation();
  const {currentUser} = useAuthContext();
  const {features} = useFeaturesContext();
  const {groupId, title, isPast, fromChat} = route.params;
  const [loading, setLoading] = useState(true);
  const [group, setGroup] = useState(emptyGroup);
  const [groupCreator, setGroupCreator] = useState<User>(emptyUser);
  const [imageHeight, setImageHeight] = useState(200);
  const [showWechatRedirect, setShowWechatRedirect] = useState(false);
  /* To determine page UI:
    0: unlogged
    1: joined quit
    2: group closed
    3: new to join
    4: joined feedback
    5: owner
    6: joined payment
    7: joined tip
    8: joined finish payment
    9: registration closed */
  const [pageState, setPageState] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);

  if (Platform.OS === 'web') {
    const analytics = getAnalytics();
    setUserId(analytics, currentUser == undefined ? null : currentUser.id);
    logEvent(analytics, 'user_view_grouphome');
  }

  const isWeChat =
    Platform.OS === 'web' && navigator.userAgent.indexOf('MicroMessenger') > -1;

  // FIXME: Currently we stores a copy of user info in group creator, which is not good.
  // we might consider storing a reference to user instead. But i don't know how to
  // write the converter for it.
  useEffect(() => {
    const fetchData = async () => {
      const ref = doc(getFirestore(), 'groups', groupId).withConverter(
        groupConverter,
      );
      const groupSnap = await getDoc(ref);
      if (!groupSnap.exists()) {
        console.log('Data not exists');
        return;
      }

      const groupData = groupSnap.data();
      setGroup(groupData);

      if (!headerImageEmpty(groupData)) {
        Image.getSize(
          getFirstHeaderImageUrlFromGroup(groupData),
          (width, height) => {
            if (width != 0 && height != 0) {
              setImageHeight((CONTENT_WIDTH * height) / width);
            }
          },
        );
      }

      // Retrieve group creator info
      const creatorRef = doc(
        getFirestore(),
        'users',
        groupData?.creator.id,
      ).withConverter(userConverter);
      const creatorSnap = await getDoc(creatorRef);
      if (!creatorSnap.exists()) {
        console.log('Data not exists');
        return;
      }
      setGroupCreator(creatorSnap.data());
      setLoading(false);
    };
    fetchData();
  }, [currentUser?.id, groupId]);

  const isUserLoggedIn = currentUser != null;
  const numberOfJoinedMember = group?.currentMembers.filter(
    (member: any) => member?.id != null && member?.id === currentUser?.id,
  ).length;
  const isUserAlreadyJoined = numberOfJoinedMember > 0;

  const hasTicketOptions =
    features?.enable_ticket_options && group.ticketOptions.length > 0;
  let minTicketPrice = '';
  let maxTicketPrice = '';

  if (group.ticketOptions.length > 0) {
    minTicketPrice = String(
      Math.min(
        ...group.ticketOptions.map((option) => parseFloat(option.optionPrice)),
      ),
    );
    maxTicketPrice = String(
      Math.max(
        ...group.ticketOptions.map((option) => parseFloat(option.optionPrice)),
      ),
    );
  }

  useLayoutEffect(() => {
    // Check the tab of curent screen
    const routesNames = navigation.getState().routeNames;
    let fromTab = 'Group';
    if (routesNames?.includes('AccountHome')) {
      fromTab = 'Account';
    }
    console.log('stack in GroupDetails: ', fromTab);

    const handleBackButton = () => {
      if (fromChat) {
        navigation.navigate('ChatTab', {
          params: {
            groupId: groupId,
            groupTitle: group.title,
          },
          screen: 'ChatScreen',
        });
      }

      if (navigation.canGoBack()) {
        navigation.pop();
      } else {
        navigation.navigate('GroupHome');
      }
    };
    if (isWeChat && ENABLE_WECHAT_REDIRECT) {
    } else if (
      features?.web_deeplink_feature &&
      (judgeClient() === 'web-android' || judgeClient() === 'web-ios')
    ) {
      const renderWebNavigationHeader = () => (
        <View>
          <WebDeeplinkHeaderView path={'group/' + groupId} />
          <View style={styles.rowContainer}>
            <Pressable onPress={handleBackButton}>
              <Ionicons
                color="black"
                name="arrow-back"
                size={24}
                style={{
                  marginLeft: Platform.OS === 'web' ? 16 : 2,
                  marginRight: 10,
                  marginVertical: 5,
                }}
              />
            </Pressable>
            <Text style={styles.navigationTitle}>
              {title ? title : '加入组队'}
            </Text>
            {(currentUser?.id == OFFICIAL_ACCOUNT ||
              (isUserLoggedIn && isUserAlreadyJoined)) && (
              <Pressable
                onPress={() => {
                  navigation.navigate('ChatTab', {
                    params: {
                      fromTab: fromTab,
                      groupId: groupId,
                      groupTitle: group.title,
                    },
                    screen: 'ChatScreen',
                  });
                }}
                style={{marginRight: 20}}>
                <Ionicons
                  color="black"
                  name="chatbubbles-outline"
                  size={24}
                  style={{
                    marginRight: Platform.OS === 'web' ? 16 : 2,
                  }}
                />
              </Pressable>
            )}
            <Pressable
              onPress={() => {
                shareLink(
                  `我发现了活动${group.title}， 快来加入吧！`,
                  SHARE_LINK_BASE_URL + `/group/${groupId}`,
                );
              }}>
              <Ionicons
                color="black"
                name="paper-plane-outline"
                size={24}
                style={{
                  marginRight: Platform.OS === 'web' ? 16 : 2,
                }}
              />
            </Pressable>
          </View>
        </View>
      );
      navigation.setOptions({
        header: renderWebNavigationHeader,
      });
    } else {
      navigation.setOptions({
        headerLeft: () => (
          <Pressable onPress={handleBackButton}>
            <Ionicons
              color="black"
              name="arrow-back"
              size={24}
              style={{
                marginLeft: Platform.OS === 'web' ? 16 : 2,
                marginRight: 10,
                marginVertical: 5,
              }}
            />
          </Pressable>
        ),
        headerRight: () => (
          <View style={{flexDirection: 'row'}}>
            {(currentUser?.id == OFFICIAL_ACCOUNT ||
              (isUserLoggedIn && isUserAlreadyJoined)) && (
              <Pressable
                onPress={() => {
                  navigation.navigate('ChatTab', {
                    params: {
                      fromTab: fromTab,
                      groupId: groupId,
                      groupTitle: group.title,
                    },
                    screen: 'ChatScreen',
                  });
                }}
                style={{marginRight: 20}}>
                <Ionicons
                  color="black"
                  name="chatbubbles-outline"
                  size={24}
                  style={{
                    marginRight: Platform.OS === 'web' ? 16 : 2,
                  }}
                />
              </Pressable>
            )}
            <Pressable
              onPress={() => {
                shareLink(
                  `我发现了活动${group.title}， 快来加入吧！`,
                  SHARE_LINK_BASE_URL + `/group/${groupId}`,
                );
              }}>
              <Ionicons
                color="black"
                name="paper-plane-outline"
                size={24}
                style={{
                  marginRight: Platform.OS === 'web' ? 16 : 2,
                }}
              />
            </Pressable>
          </View>
        ),
        title: title ? title : '加入组队',
      });
    }
  }, [
    currentUser?.id,
    features?.web_deeplink_feature,
    fromChat,
    group.title,
    groupId,
    isUserAlreadyJoined,
    isUserLoggedIn,
    isWeChat,
    navigation,
    title,
  ]);

  const isGroupClosed =
    group?.currentMembers.length >= group?.numMemberRequired;
  const isUserCreator = currentUser?.id === group?.creator.id;
  const isUserOfficialAccount = currentUser?.id === OFFICIAL_ACCOUNT;

  const sponsorName =
    group?.sponsors != undefined && group?.sponsors.length >= 1
      ? group?.sponsors[0]
      : '';

  const now = new Date();
  const start = group.dateStart;
  const end = group.dateEnd;
  const registrationDeadline = group.registrationDeadline;
  const isRegistrationClosed =
    features?.enable_registration_deadline && registrationDeadline != null
      ? registrationDeadline.toDate() < now
      : start.toDate() < now;

  const [joinedNumber, setJoinedNumber] = useState(1);

  function disableJoinedButton() {
    return (
      isUserLoggedIn &&
      !isUserAlreadyJoined &&
      (isGroupClosed || isRegistrationClosed)
    );
  }

  function disablePlusButton() {
    const maximum = group?.numMemberRequired - group?.currentMembers.length;
    return (
      !isUserLoggedIn ||
      isGroupClosed ||
      isRegistrationClosed ||
      joinedNumber === maximum
    );
  }

  function disableMinusButton() {
    return (
      !isUserLoggedIn ||
      isGroupClosed ||
      isRegistrationClosed ||
      joinedNumber === 1
    );
  }

  const handleMinusPress = () => {
    setJoinedNumber(joinedNumber - 1);
  };
  const handlePlusPress = () => {
    setJoinedNumber(joinedNumber + 1);
  };

  const onPressDownload = async () => {
    try {
      const downloadUrl =
        judgeClient() === 'web-ios'
          ? 'https://apps.apple.com/us/app/%E9%87%8E%E5%85%94%E7%BB%84%E9%98%9F/id6448244192'
          : 'https://play.google.com/store/apps/details?id=com.yaytour.androidapp';
      Linking.openURL(downloadUrl);
    } catch (error) {
      console.log('openDownloadUrl: ' + error);
      alert(
        '',
        '对不起, 出了一点小问题, 请前往App Store, 搜索"野兔组队"并下载我们的最新版官方App',
        [
          {
            onPress: () => {},
            text: '确定',
          },
        ],
      );
    }
  };

  const [groupTagsSnapshot] = useCollection(
    collection(getFirestore(), 'groupTags').withConverter(groupTagConverter),
  );
  let groupTags: GroupTag[] = [];
  if (groupTagsSnapshot) {
    groupTagsSnapshot?.docs.forEach((tags) => {
      const tag = tags.data() as GroupTag;
      groupTags.push(tag);
    });
  }

  const handleJoined = async () => {
    setIsProcessing(true);
    try {
      if (isWeChat && ENABLE_WECHAT_REDIRECT) {
        setShowWechatRedirect(true);
        return;
      }
      if (!isUserLoggedIn) {
        console.log('navigate to member log in');
        navigation.push('LoginModal');
      } else if (!isUserProfileCompeleted(currentUser)) {
        navigation.push('LoginModal', {
          params: {
            gender: currentUser.gender || '',
            image: currentUser.profileImageUrl || '',
            location: currentUser.location || '',
            mode: 'complete',
            name: currentUser.name || '',
            referCode: '',
            username: currentUser.userName || '',
            wechat: currentUser.socialWechat || '',
          },
          screen: 'Profile',
        });
      } else if (isUserAlreadyJoined) {
        if (ENABLE_FEEDBACK && start.toDate() < now) {
          navigation.push('GroupFeedbackScreen', {
            group: group,
          });
          return;
        }

        // user has paid
        if (pageState == 8 && features?.enable_refund) {
          navigation.push('JoinGroupModal', {
            params: {
              group: group,
            },
            screen: 'RefundModalScreen',
          });
        } else {
          alert(
            pageState == 8 ? '您已付款！如退出，还需联系组织者退款哦！' : '',
            '确定要退出组队吗？',
            [
              {
                onPress: async () => {
                  var [latestMember, latestPaid] = await getLatestMember(
                    group?.id,
                  );
                  const newMembersArray = latestMember.filter(
                    (user: User) => user.id !== currentUser.id,
                  );
                  const newPaidMembersArray = latestPaid.filter(
                    (user: User) => user.id !== currentUser.id,
                  );
                  try {
                    const docRef = doc(getFirestore(), 'groups', group?.id);
                    await updateDoc(docRef, {
                      currentMembers: newMembersArray,
                      paidMembers: newPaidMembersArray,
                    });
                    navigation.pop();
                  } catch (error) {
                    alert('发送错误', ' 退出组队时发生错误: ' + error, [
                      {
                        onPress: () => {},
                        text: '确定',
                      },
                    ]);
                    console.error('error occurs when leaving group: ', error);
                  }
                },
                text: '退出',
              },
              {
                onPress: () => {},
                text: '保留',
              },
            ],
            {cancelable: true},
          );
        }
      } else if (
        (group.genderFlexible === 'female_only' &&
          currentUser.gender === 'M') ||
        (group.genderFlexible === 'male_only' && currentUser.gender === 'F')
      ) {
        alert(
          '',
          '对不起>_<...根据性别要求，您不能参与此项活动!',
          [
            {
              onPress: () => {},
              text: '确定',
            },
          ],
          {cancelable: true},
        );
      } else {
        const ref = doc(getFirestore(), 'groups', groupId).withConverter(
          groupConverter,
        );
        const latestGroupSnap = await getDoc(ref);
        const latestMemberCount = latestGroupSnap.data()?.currentMembers.length;
        if (
          !latestGroupSnap.exists() ||
          (latestMemberCount ?? 0) + joinedNumber >
            (latestGroupSnap.data()?.numMemberRequired ?? 0)
        ) {
          alert(
            '',
            '对不起>_<...活动信息或者已报名人数已经发生了变化, 请重新填写报名人数.',
            [
              {
                onPress: () => {},
                text: '确定',
              },
            ],
            {cancelable: true},
          );

          setGroup(latestGroupSnap.data() ?? emptyGroup);
        } else {
          // ==============PAYMENT 1: Stripe credit card================
          if (
            group.createdByStripeEligibleUser &&
            group.bugetPerPerson !== 0 &&
            // If the payment feature is enabled, trigger Stripe under certain conditions:
            //   - If payment method is specified as credit card, or
            //   - If no payment method is specified (default to Stripe)
            // If the payment feature is disabled, only the first two conditions are required to trigger Stripe.
            (features?.enable_payment_dropdown
              ? group.paymentInstruction === 'credit_card' ||
                !group.paymentInstruction
              : true)
          ) {
            if (hasTicketOptions) {
              await navigation.navigate('JoinGroupModal', {
                params: {
                  currentUser: currentUser,
                  group: group,
                },
                screen: 'TicketOptionModalScreen',
              });
            } else {
              await handleStripePayment();
            }
          } else if (
            // ==============PAYMENT 2: Venmo or Zelle================
            features?.enable_payment_dropdown &&
            features?.enable_venmo_and_zelle_for_all &&
            group.bugetPerPerson !== 0 &&
            group.paymentInstruction === 'venmo_or_zelle'
          ) {
            await handleVenmoOrZelleJoinGroup();
          } else {
            // ==========PAYMENT 3: Onsite/ Customized/ Free / Not specified - Direct join==============
            await handleNormalJoinGroup();
          }
        }
      }
    } finally {
      setIsProcessing(false);
    }
  };

  const handleStripePayment = async () => {
    // FIXME: group.bugetPerPerson should be `number | undefined` instead of  string
    // here im assuming group.bugetPerPerson is always valid, thus parsedPrice is always non-zero
    const parsedPrice = Number(group.bugetPerPerson ?? '');

    const requestData = {
      email: currentUser?.email,
      group_id: groupId,
      group_unit_price: parsedPrice,
      number_of_people:
        pageState == 6
          ? group.currentMembers.filter(
              (user: User) => user.id === currentUser?.id,
            ).length
          : joinedNumber,
      phone_umber: currentUser?.phoneNumber,
      user_name: currentUser?.userName,
      yaytour_user_id: currentUser?.id,
    };
    const functions = getFunctions();
    const paymentIntent = httpsCallable(functions, 'createpaymentintent');
    try {
      const result = await paymentIntent(requestData);
      const paymentIntentResult = result.data as CreatePaymentIntentResult;

      navigation.navigate('JoinGroupModal', {
        params: {
          clientSecret: paymentIntentResult.client_secret,
          customerEphemeralKey: paymentIntentResult.customer_ephemeral_key,
          group: group,
          numberOfPeople: requestData.number_of_people,
          paymentIntentId: paymentIntentResult.payment_intent_id,
          priceWithoutFee: paymentIntentResult.price_before_fee,
          serviceFee: paymentIntentResult.tax_and_fees,
          stripeCustomerID: paymentIntentResult.customer,
        },
        screen: 'PaymentModalScreen',
      });
    } catch (error) {
      alert(
        '付款时发生错误',
        `请稍后重试或联系野兔小助手～ 微信号：${YAYTOUR_WECHAT_SUPPORT}`,
        [
          {
            onPress: () => {},
            text: '确定',
          },
        ],
      );
      console.error('error occurs when getting stripe payment data: ', error);
    }
  };

  const handleNormalJoinGroup = async () => {
    const joinedMembers = group?.currentMembers;
    // TODO(chenfada) add more info when currentUser has more
    const newMember = {
      id: currentUser?.id,
      userName: currentUser?.userName,
    };
    const newMembersArray = new Array(joinedNumber).fill(newMember);
    joinedMembers.push(...newMembersArray);
    try {
      const docRef = doc(getFirestore(), 'groups', group?.id);
      await updateDoc(docRef, {currentMembers: joinedMembers});

      navigation.goBack();
      navigation.navigate('AccountTab', {screen: 'AccountHome'});
      if (eligibleForTip(group)) {
        await navigation.navigate('JoinGroupModal', {
          params: {
            group: group,
            standAlone: false,
          },
          screen: 'JoinGroupTipScreen',
        });
      } else {
        await navigation.navigate('JoinGroupModal', {
          params: {
            group: group,
          },
          screen: 'JoinGroupSuccessfulScreen',
        });
      }
    } catch (error) {
      alert('发送错误', ' 加入组队时发生错误: ' + error, [
        {
          onPress: () => {},
          text: '确定',
        },
      ]);
      console.error('error occurs when joining group: ', error);
    }
  };

  const handleVenmoOrZelleJoinGroup = async () => {
    if (hasTicketOptions) {
      await navigation.navigate('JoinGroupModal', {
        params: {
          currentUser: currentUser,
          group: group,
        },
        screen: 'TicketOptionModalScreen',
      });
    } else {
      const joinedMembers = group?.currentMembers;
      const newMember = {
        id: currentUser?.id,
        userName: currentUser?.userName,
      };
      const newMembersArray = new Array(joinedNumber).fill(newMember);
      joinedMembers.push(...newMembersArray);
      try {
        const docRef = doc(getFirestore(), 'groups', group?.id);
        await updateDoc(docRef, {currentMembers: joinedMembers});
        await navigation.navigate('JoinGroupModal', {
          params: {
            group: group,
            numOfNew: joinedNumber,
            standAlone: false,
          },
          screen: 'JoinGroupPaymentScreen',
        });
      } catch (error) {
        alert('发送错误', ' 加入组队时发生错误: ' + error, [
          {
            onPress: () => {},
            text: '确定',
          },
        ]);
        console.error('error occurs when joining group: ', error);
      }
    }
  };

  const handleDeleteGroup = async () => {
    if (eligibleForPayment(group)) {
      alert(
        '',
        '您已开通支付功能，暂不能删除组队哦～',
        [
          {
            onPress: () => {},
            text: '朕知道了',
          },
        ],
        {cancelable: true},
      );
    } else {
      alert(
        '',
        '确定要删除组队？',
        [
          {
            onPress: async () => {
              try {
                const docRef = doc(getFirestore(), 'groups', group?.id);
                await updateDoc(docRef, {status: 'deleted'});
                navigation.pop();
              } catch (error) {
                alert('发送错误', ' 删除组队时发生错误: ' + error, [
                  {
                    onPress: () => {},
                    text: '确定',
                  },
                ]);
                console.error('error occurs when deleting group: ', error);
              }
            },
            text: '删除',
          },
          {
            onPress: () => {},
            text: '保留',
          },
        ],
        {cancelable: true},
      );
    }
  };

  const handleTapToOpenList = async () => {
    const peopleList = group.currentMembers.map((person) => {
      return person.id;
    });

    navigation.navigate('PeopleListScreen', {
      allowDuplicate: true,
      creatorID: group?.creator.id,
      groupId: groupId,
      isUserCreator: isUserCreator,
      isUserOfficialAccount: isUserOfficialAccount,
      paymentMap: group?.payments
        ? new Map(Object.entries(group?.payments))
        : new Map(),
      peopleList: peopleList,
      showPayment: ENABLE_PAYMENT && group?.creator.id === currentUser?.id,
      stripePaymentMap:
        group.createdByStripeEligibleUser &&
        (group?.creator.id === currentUser?.id ||
          currentUser?.id == OFFICIAL_ACCOUNT)
          ? new Set(group.paidMembers?.map((user: User) => user.id))
          : new Set(),
    });
  };

  function getJoinedButtonText() {
    switch (pageState) {
      case 0:
        return '请登录';
      case 1:
        return '退出组队';
      case 2:
        return '已满员';
      case 3:
        return '加入组队';
      case 4:
        return '提交反馈';
      case 5:
        return '编辑组队';
      case 6:
      case 7:
      case 8:
        return '退出组队';
      case 9:
        return '报名结束';
    }
  }

  const handleTipOrPayment = () => {
    if (pageState == 6) {
      if (
        group.createdByStripeEligibleUser &&
        group.paymentInstruction !== 'venmo_or_zelle'
      ) {
        handleStripePayment();
      } else {
        navigation.navigate('JoinGroupPaymentScreen', {
          group: group,
          numOfNew: joinedNumber,
          standAlone: true,
        });
      }
    } else if (pageState == 7) {
      navigation.navigate('JoinGroupTipScreen', {
        group: group,
        standAlone: true,
      });
    }
  };

  function setPageStateForRendering() {
    if (!isUserLoggedIn) {
      setPageState(0); // 0:unlogged
      return;
    } else {
      if (isUserCreator) {
        setPageState(5); // 5:owner
        return;
      } else if (isUserAlreadyJoined) {
        if (ENABLE_FEEDBACK && start.toDate() < now) {
          setPageState(4); // 4:joined feedback
          return;
        }
        if (group.createdByStripeEligibleUser && group.bugetPerPerson) {
          if (
            group.paidMembers.filter((user: User) => user.id === currentUser.id)
              .length > 0
          ) {
            setPageState(8); // 8:joined finish payment
            return;
          }
          // if (
          //   group.currentMembers.filter(
          //     (user: User) => user.id === currentUser.id,
          //   ).length > 0
          // ) {
          //   setPageState(6); // 6:joined payment
          //   return;
          // }
        }
        if (
          eligibleForPaymentPerUser(group, groupCreator) &&
          features?.enable_venmo_and_zelle_for_all
        ) {
          const paymentMap = group?.payments
            ? new Map(Object.entries(group?.payments))
            : new Map();
          if (
            typeof currentUser?.id == 'string' &&
            paymentMap.has(currentUser?.id) &&
            paymentMap.get(currentUser?.id) != ''
          ) {
            setPageState(8); // 8:joined finish payment
            return;
          }
          setPageState(6); // 6:joined payment
          return;
        }
        if (eligibleForTip(group)) {
          setPageState(7); // 7:joined tip
          return;
        }
        setPageState(1); // 1:joined quit
        return;
      } else if (isGroupClosed) {
        setPageState(2); // 2:group closed
        return;
      } else if (isRegistrationClosed) {
        setPageState(9); // 9: registration closed
        return;
      } else {
        setPageState(3); // 3:new to join
        return;
      }
    }
  }

  useEffect(() => {
    setPageStateForRendering();
  });

  const isPaymentOrTipState = pageState === 6 || pageState === 7;

  const toggleOverlay = () => {
    setShowWechatRedirect(!showWechatRedirect);
  };
  const PaymentInstruction = () => {
    // Payment Info is now pulled from user level for eligible user
    const renderPaymentText = (paymentInstruction: any, user: User) => {
      if (!features?.enable_payment_dropdown) {
        return paymentInstruction || '暂无';
      }

      switch (paymentInstruction) {
        case 'onsite':
          return '现场支付';
        case 'free':
          return '免费';
        case 'venmo_or_zelle':
          if (paymentInstruction === 'venmo_or_zelle' && user) {
            const venmoText = user.venmoAccountId
              ? 'Venmo: ' + user.venmoAccountId
              : '';
            const zelleText = user.zelleAccountId
              ? 'Zelle: ' + user.zelleAccountId
              : '';

            return (
              venmoText + (venmoText && zelleText ? '; ' : '') + zelleText ||
              '暂无'
            );
          }
        case 'customized':
          return user.customizedPayment;
        case 'credit_card':
          return '在线信用卡支付';
        default:
          return '暂无';
      }
    };
    return group.paymentInstruction ? (
      <View style={styles.textContentContainer}>
        <Text style={styles.textContentLabel}>{'付款方式'}</Text>
        <Text selectable={true} style={styles.textContent}>
          {renderPaymentText(group.paymentInstruction, group.creator)}
        </Text>
      </View>
    ) : null;
  };

  if (loading) {
    return <LoadingScreen />;
  } else {
    return (
      <View style={styles.container} testID={`group_details_screen_container`}>
        <ScrollView>
          {showWechatRedirect && (
            <Overlay
              fullScreen={true}
              isVisible={showWechatRedirect}
              onBackdropPress={toggleOverlay}>
              <OpenInDefaultBrowserScreen />
            </Overlay>
          )}
          <View
            style={[
              styles.scrollViewContainer,
              isPaymentOrTipState && {paddingBottom: 100},
            ]}>
            <View>
              {headerImageEmpty(group) ? (
                <ImageBackground
                  source={require('../assets/images/yay_logo.png')}
                  style={[
                    styles.defaultBackgroundImage,
                    {justifyContent: 'center'},
                  ]}>
                  <Text style={styles.imageText}>{group.title}</Text>
                </ImageBackground>
              ) : ENABLE_MULTIPLE_IMAGE ? (
                // TODO(chenfada) migrate to FastImage
                <ImageViewer
                  imageUrls={group.headerImage}
                  viewHeight={imageHeight}
                />
              ) : (
                <FastImage
                  onLoad={(event) => {
                    setImageHeight(
                      ((WINDOW_WIDTH - CONTAINER_PADDING_HORIZONTAL * 2) *
                        event.nativeEvent.height) /
                        event.nativeEvent.width,
                    );
                  }}
                  source={{uri: getFirstHeaderImageUrlFromGroup(group, true)}}
                  style={[
                    styles.backgroundImage,
                    {
                      height: imageHeight,
                      width: '100%',
                    },
                  ]}
                />
              )}
              {group.officialTag === 'selected' && (
                <View
                  style={[
                    styles.tagStyle,
                    {
                      position: 'absolute',
                      right: Layout.unit,
                      top: Layout.unit,
                    },
                  ]}>
                  <Ionicons
                    color="white"
                    name="star"
                    size={14}
                    style={{marginRight: 2}}
                  />
                  <Text style={{color: 'white', fontSize: 14}}>精选</Text>
                </View>
              )}
            </View>

            <Text selectable={true} style={styles.text}>
              {group.title}
            </Text>
            <View style={styles.sponsorCreatorLine}>
              {sponsorName != '' && (
                <View style={styles.sponsorContainer}>
                  <Entypo
                    color={Colors.mainAppColor}
                    name="smashing"
                    size={16}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      color: '#a9a9a9',
                      fontSize: 14,
                    }}>
                    赞助方：
                  </Text>
                  <Text
                    numberOfLines={1}
                    style={{
                      flexShrink: 1,
                      fontSize: 14,
                    }}>
                    {sponsorName}
                  </Text>
                </View>
              )}
              <View style={{flex: 1}}></View>
              <TouchableOpacity
                disabled={!isUserLoggedIn}
                onPress={() => {
                  navigation.push('GroupCreatorView', {
                    userId: group?.creator.id,
                  });
                }}
                style={styles.creatorContainer}>
                <Ionicons
                  color={group.creator.gender === 'F' ? '#ba55d3' : '#4169e1'}
                  name={group.creator.gender === 'F' ? 'female' : 'male'}
                  size={16}
                />
                <Text
                  style={{
                    color: '#a9a9a9',
                    fontSize: 14,
                    marginHorizontal: 2,
                  }}>
                  发起人：
                </Text>
                <FastImage
                  source={profileImagePreview(group.creator)}
                  style={styles.avatarPreview}
                />
                <Text
                  style={{
                    fontSize: 14,
                    marginLeft: 4,
                  }}>
                  {group.creator.userName}
                </Text>
              </TouchableOpacity>
            </View>

            {eligibleForBannerAd(group) && (
              <GroupDatailBannerAd group={group} />
            )}

            <View style={styles.groupInfoSection}>
              <Text selectable={true} style={styles.text}>
                活动信息
              </Text>
              {group.userGeneratedTag && group.userGeneratedTag.length > 0 && (
                <View style={styles.textContentContainer}>
                  <Text
                    selectable={true}
                    style={[styles.textContentLabel, {marginRight: 36}]}>
                    分类
                  </Text>

                  <View style={styles.row}>
                    {group.userGeneratedTag?.map((value, key) => (
                      <View key={key} style={{paddingHorizontal: 5}}>
                        <View key={key} style={styles.tagStyle}>
                          <Text
                            key={key}
                            style={{color: 'white', fontSize: 12}}>
                            {
                              groupTags.filter(
                                (tag) => tag.tagValue === value,
                              )[0].title
                            }
                          </Text>
                        </View>
                      </View>
                    ))}
                  </View>
                </View>
              )}

              <View style={styles.textContentContainer}>
                <Text selectable={true} style={styles.textContentLabel}>
                  {group.groupType === 'tour'
                    ? '出发日期'
                    : '日期\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}
                </Text>
                <Text selectable={true} style={styles.textContent}>
                  {produceChineseDateString(start)}
                </Text>
              </View>

              {(group.groupType === 'around_ny' ||
                group.groupType === 'around_la' ||
                group.groupType === 'around_bos' ||
                group.groupType === 'around_bay_area') && (
                <View style={styles.textContentContainer}>
                  <Text selectable={true} style={styles.textContentLabel}>
                    集合时间
                  </Text>
                  <Text selectable={true} style={styles.textContent}>
                    {produceTimeString(start)}
                  </Text>
                </View>
              )}

              {(group.groupType === 'around_ny' ||
                group.groupType === 'around_la' ||
                group.groupType === 'around_bos' ||
                group.groupType === 'around_bay_area') &&
                end !== null && (
                  <View style={styles.textContentContainer}>
                    <Text selectable={true} style={styles.textContentLabel}>
                      结束时间
                    </Text>
                    <Text selectable={true} style={styles.textContent}>
                      {produceTimeString(end)}
                    </Text>
                  </View>
                )}

              {!(end === null || typeof end === 'undefined') &&
                group.groupType === 'tour' && (
                  <View style={styles.textContentContainer}>
                    <Text selectable={true} style={styles.textContentLabel}>
                      回程日期
                    </Text>
                    <Text selectable={true} style={styles.textContent}>
                      {produceChineseDateString(end)}
                    </Text>
                  </View>
                )}

              <View style={styles.textContentContainer}>
                <Text selectable={true} style={styles.textContentLabel}>
                  {group.groupType === 'tour'
                    ? '出发地\u00A0\u00A0\u00A0'
                    : '集合地点'}
                </Text>
                <Text selectable={true} style={styles.textContent}>
                  {produceMeetUpLocation(group)}
                </Text>
              </View>

              {group.groupType === 'tour' && (
                <View style={styles.textContentContainer}>
                  <Text selectable={true} style={styles.textContentLabel}>
                    {'目的地\u00A0\u00A0\u00A0'}{' '}
                  </Text>
                  <Text selectable={true} style={styles.textContent}>
                    {group.destination}
                  </Text>
                </View>
              )}

              {group.registrationDeadline != null && (
                <View style={styles.textContentContainer}>
                  <Text selectable={true} style={styles.textContentLabel}>
                    报名结束时间
                  </Text>
                  <Text selectable={true} style={styles.textContent}>
                    {produceDateTimeString(group.registrationDeadline)}
                  </Text>
                </View>
              )}

              {group.bugetPerPerson !== 0 && (
                <View style={styles.textContentContainer}>
                  <Text selectable={true} style={styles.textContentLabel}>
                    {'人均费用'}
                  </Text>
                  <Text selectable={true} style={styles.textContent}>
                    {group.bugetPerPerson}
                  </Text>
                </View>
              )}

              <PaymentInstruction />

              <View style={styles.textContentContainer}>
                <Text selectable={true} style={styles.textContentLabel}>
                  活动细节
                </Text>
                <Text selectable={true} style={styles.textContent}>
                  {group.details}
                </Text>
              </View>
            </View>

            {hasTicketOptions && (
              <View style={styles.groupInfoSection}>
                <Text selectable={true} style={styles.text}>
                  活动套餐
                </Text>
                {group.ticketOptions.map((option, index) => (
                  <View key={index} style={styles.optionContainer}>
                    <View style={styles.optionRow}>
                      <Text selectable={true} style={styles.textContentLabel}>
                        套餐 {index + 1}
                      </Text>
                      <Text selectable={true} style={styles.textContent}>
                        {option.optionName}
                      </Text>
                    </View>
                    <View style={styles.optionRow}>
                      <Text selectable={true} style={styles.textContentLabel}>
                        价格
                      </Text>
                      <Text selectable={true} style={styles.textContent}>
                        ${option.optionPrice}
                      </Text>
                    </View>
                  </View>
                ))}
              </View>
            )}

            <View style={styles.groupInfoSection}>
              <Text selectable={true} style={styles.text}>
                人数信息
              </Text>

              <View style={styles.textContentContainer}>
                <Text selectable={true} style={styles.textContentLabel}>
                  组队总人数
                </Text>
                <Text selectable={true} style={styles.textContent}>
                  {group.numMemberRequired}
                </Text>
              </View>

              {features?.enable_min_group_members &&
                group.minGroupMembers !== 0 &&
                !isNaN(group.minGroupMembers) && (
                  <View style={styles.textContentContainer}>
                    <Text selectable={true} style={styles.textContentLabel}>
                      组队最少人数
                    </Text>
                    <Text selectable={true} style={styles.textContent}>
                      {group.minGroupMembers}
                    </Text>
                  </View>
                )}

              <View style={styles.textContentContainer}>
                <Text selectable={true} style={styles.textContentLabel}>
                  性别要求
                </Text>
                <Text selectable={true} style={styles.textContent}>
                  {group.genderFlexible === 'flexible'
                    ? '男女不限'
                    : group.genderFlexible === 'male_only'
                    ? '仅限男生'
                    : '仅限女生'}
                </Text>
              </View>

              <View
                style={[styles.textContentContainer, {alignItems: 'center'}]}>
                <TouchableOpacity
                  onPress={() => handleTapToOpenList()}
                  style={[{alignItems: 'center', flexDirection: 'row'}]}>
                  <OverlappeddAvatarsRow
                    avatarSize={30}
                    group={group}
                    maximumAvatars={10}
                  />
                  <Text
                    selectable={true}
                    style={[styles.textContent, {marginLeft: 4}]}>
                    {group.currentMembers.length}人已组队
                    {group.paidMembers.length > 0 &&
                      (isUserCreator || isUserOfficialAccount) &&
                      `, ${group.paidMembers.length} 人已付款`}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
            {!navigation.canGoBack() && (
              <SimilarGroupSection originalGroup={group} />
            )}
          </View>
        </ScrollView>

        {!isUserCreator && !isUserOfficialAccount ? (
          <View style={styles.joinGroupButtonRow}>
            {isUserAlreadyJoined && (
              <View
                style={{
                  alignItems: 'flex-start',
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  borderRadius: 5,
                  flex: 1,
                  justifyContent: 'center',
                  marginHorizontal: 8,
                }}>
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: 'bold',
                  }}>
                  您已经为{numberOfJoinedMember}人报名
                  {pageState === 8 ? '并付款' : ''}
                </Text>
              </View>
            )}

            {!isUserAlreadyJoined &&
              (hasTicketOptions ? (
                <View
                  style={{
                    flex: 1,
                    justifyContent: 'center',
                  }}>
                  {group.ticketOptions.length > 1 ? (
                    <Text style={styles.ticketOptionRange}>
                      ${minTicketPrice} - ${maxTicketPrice}
                    </Text>
                  ) : (
                    <Text style={styles.ticketOptionRange}>
                      ${minTicketPrice}
                    </Text>
                  )}
                </View>
              ) : (
                <View
                  style={{
                    flex: 1,
                  }}>
                  <View style={styles.joinGroupNumber}>
                    <TouchableOpacity
                      disabled={disableMinusButton()}
                      onPress={handleMinusPress}
                      style={styles.button}>
                      <Text
                        style={[
                          {fontSize: 24},
                          disableMinusButton() && {color: 'lightgrey'},
                        ]}>
                        -
                      </Text>
                    </TouchableOpacity>
                    <Text style={styles.number}>{joinedNumber}</Text>
                    <TouchableOpacity
                      disabled={disablePlusButton()}
                      onPress={handlePlusPress}
                      style={styles.button}>
                      <Text
                        style={[
                          {fontSize: 24},
                          disablePlusButton() && {color: 'lightgrey'},
                        ]}>
                        +
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              ))}

            <View
              style={{
                flex: 1,
              }}>
              {isPaymentOrTipState && (
                <TouchableOpacity
                  onPress={handleTipOrPayment}
                  style={[styles.tipOrPaymentButton]}>
                  <Text style={styles.tipOrPaymentButtonText}>
                    {pageState == 6 ? '在线支付' : '添加小费'}
                  </Text>
                </TouchableOpacity>
              )}
              <TouchableOpacity
                disabled={disableJoinedButton() || isProcessing}
                onPress={handleJoined}
                style={[
                  styles.joinGroupButton,
                  disableJoinedButton() && {opacity: 0.5},
                ]}>
                <Text style={styles.joinGroupButtonText}>
                  {getJoinedButtonText()}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        ) : (
          <View style={styles.joinGroupButtonRow}>
            <TouchableOpacity
              onPress={() => {
                navigation.push('CreateGroupScreen', {
                  groupId: groupId,
                  isPast: isPast,
                });
              }}
              style={[styles.joinGroupButton]}>
              <Text style={styles.joinGroupButtonText}>
                {isPast ? '复制组队' : '编辑组队'}
              </Text>
            </TouchableOpacity>

            <TouchableOpacity
              onPress={handleDeleteGroup}
              style={[styles.deleteGroupButton]}>
              <Text style={styles.tipOrPaymentButtonText}>删除组队</Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  avatarPreview: {
    borderRadius: 50,
    height: 20,
    width: 20,
  },
  backgroundImage: {
    borderRadius: 5,
  },
  button: {
    paddingHorizontal: 16,
    paddingVertical: 4,
  },
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  creatorContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginHorizontal: 4,
  },
  defaultBackgroundImage: {
    aspectRatio: 16 / 9,
    borderRadius: 5,
    flex: 1,
    resizeMode: 'cover',
    width: '100%',
  },
  deleteGroupButton: {
    alignContent: 'center',
    backgroundColor: 'white',
    borderColor: '#66BB6A',
    borderRadius: 5,
    borderWidth: 1,
    flex: 1,
    justifyContent: 'center',
    marginHorizontal: 8,
    padding: 8,
  },
  groupInfoSection: {
    backgroundColor: '#e4e4e470',
    borderRadius: 12,
    marginVertical: 10,
    paddingHorizontal: 10,
    paddingVertical: 4,
  },
  imageText: {
    alignItems: 'center',
    backgroundColor: 'rgba(255,255,255,0.6)',
    fontSize: 24,
    fontWeight: 'bold',
    marginVertical: 8,
    paddingVertical: 16,
    textAlign: 'center',
  },
  joinGroupButton: {
    alignContent: 'center',
    backgroundColor: '#66BB6A',
    borderColor: '#66BB6A',
    borderRadius: 5,
    borderWidth: 1,
    flex: 1,
    justifyContent: 'center',
    marginHorizontal: 8,
    padding: 8,
  },
  joinGroupButtonRow: {
    backgroundColor: 'white',
    bottom: 0,
    flexDirection: 'row',
    paddingHorizontal: 10,
    paddingVertical: 10,
    position: 'absolute',
    width: Layout.window.width,
  },
  joinGroupButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  joinGroupNumber: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderColor: 'black',
    borderRadius: 5,
    borderWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 8,
  },
  navigationTitle: {
    flex: 1,
    fontSize: 18,
    fontWeight: 'bold',
    paddingLeft: 8,
    textAlign: 'left',
  },
  number: {
    flex: 1,
    fontSize: 16,
    paddingHorizontal: 20,
    textAlign: 'center',
  },
  optionContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  optionRow: {
    flex: 2,
    flexDirection: 'row',
    marginVertical: 4,
  },
  row: {
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  rowContainer: {
    alignItems: 'center',
    backgroundColor: 'white',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 8,
  },
  scrollViewContainer: {
    flexDirection: 'column',
    paddingBottom: 68,
    paddingHorizontal: CONTAINER_PADDING_HORIZONTAL,
  },
  sponsorContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    flexShrink: 1,
    justifyContent: 'flex-start',
    marginHorizontal: 4,
  },
  sponsorCreatorLine: {
    flexDirection: 'row',
    width: CONTENT_WIDTH,
  },
  tagStyle: {
    alignItems: 'center',
    backgroundColor: Colors.mainAppColor,
    borderRadius: 2,
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 4,
  },
  text: {
    fontSize: 18,
    fontWeight: 'bold',
    marginVertical: 8,
  },
  textContent: {
    flex: 1,
    fontSize: 16,
  },
  textContentContainer: {
    alignContent: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'wrap',
    fontSize: 16,
    marginHorizontal: 2,
    marginVertical: 4,
  },
  textContentLabel: {
    fontSize: 16,
    fontWeight: 'bold',
    marginRight: 8,
  },
  ticketOptionRange: {
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  tipOrPaymentButton: {
    alignContent: 'center',
    backgroundColor: 'white',
    borderColor: '#66BB6A',
    borderRadius: 5,
    borderWidth: 1,
    flex: 1,
    justifyContent: 'center',
    marginBottom: 4,
    marginHorizontal: 8,
    padding: 8,
  },
  tipOrPaymentButtonText: {
    color: '#66BB6A',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
});
