import {
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions,
  Timestamp,
  WithFieldValue,
} from 'firebase/firestore';

import {Group} from '../types';

export default {
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): Group {
    const data = snapshot.data(options);
    return {
      bugetPerPerson: data.bugetPerPerson ?? null,
      checkin: data.checkin ?? null,
      compressedHeaderImage: data.compressedHeaderImage ?? [],
      created: data.created ?? Timestamp.now(),
      createdByStripeEligibleUser: data.createdByStripeEligibleUser ?? false,
      creator: data.creator, // mandatory
      currentMembers: data.currentMembers ?? [],
      date: data.date ?? [Timestamp.now(), null],
      dateEnd:
        data.dateEnd ??
        (data.date && data.date.length > 1 ? data.date[1] : null),
      dateStart: data.dateStart ?? data.date[0],
      destination: data.destination ?? null,
      details: data.details ?? null,
      genderFlexible: data.genderFlexible ?? 'flexible',
      groupQRCode: data.groupQRCode ?? '',
      groupSubType: data.groupSubType ?? 'indoor',
      groupType: data.groupType ?? 'around_ny',
      headerImage: data.headerImage ?? [],
      id: data.id, // mandatory
      lastMessageRecord: data.lastMessageRecord ?? null,
      meetUpLocation: data.meetUpLocation ?? null,
      meetUpLocationRequired: data.meetUpLocationRequired ?? false,
      minGroupMembers: data.minGroupMembers ?? 0,
      numMemberRequired: data.numMemberRequired ?? 0,
      officialTag: data.officialTag ?? null,
      paidMembers: data.paidMembers ?? [],
      paidTicketOptions: data.paidTicketOptions ?? {},
      paymentInstruction: data.paymentInstruction ?? null,
      payments: data.payments ?? null,
      privateGroup: data.privateGroup ?? false,
      rankingFactor: data.rankingFactor ?? 0,
      ratings: data.ratings ?? null,
      registrationDeadline: data.registrationDeadline ?? null,
      sponsors: data.sponsors ?? null,
      status: data.status ?? 'pending',
      ticketOptions: data.ticketOptions ?? [],
      tips: data.tips ?? null,
      title: data.title ?? '',
      tour: data.tour ?? null,
      userGeneratedTag: data.userGeneratedTag ?? [],
    };
  },
  toFirestore(group: WithFieldValue<Group>): DocumentData {
    return {...group};
  },
};
