import {
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from 'firebase/firestore';

import {GroupTag} from '../types';

export default {
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): GroupTag {
    const data = snapshot.data(options);
    return {
      index: data.index ?? 999, // TODO: there might be a better way to do this
      shownInFilterBar: data.shownInFilterBar ?? false,
      tagValue: data.tagValue,
      title: data.title,
    };
  },
  toFirestore(groupTag: WithFieldValue<GroupTag>): DocumentData {
    return {...groupTag};
  },
};
