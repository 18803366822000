import {Ionicons} from '@expo/vector-icons';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {Platform, Pressable} from 'react-native';

import DiscoverDetailsScreen from '../components/discover/DiscoverDetailsScreen';
import DiscoverHomeScreen from '../components/discover/DiscoverHomeScreen';
import {JoinWechatModal} from '../components/discover/WechatQRCodeView';
import CreateGroupScreen from '../screens/CreateGroupScreen';
import GroupCreatorView from '../screens/GroupCreatorView';
import PeopleListScreen from '../screens/PeopleListScreen';

const Stack = createNativeStackNavigator<{
  CreateGroupScreen: {
    // When creating a group from tour, we'll pass in some default values
    // from tour to help user get started
    tourDefaultDescription: string;
    tourDefaultImageURL: string;
    tourDefaultTitle: string;
  };
  JoinWechatModal: {
    QRCodeTitle: string;
    QRCodeURL: string;
    shareText: string;
    subTitle: string;
    title: string;
  };
  OtherAccountView: {userId: any};
  PeopleListScreen: {
    allowDuplicate: boolean;
    paymentMap: any;
    peopleList: string[];
    showPayment: boolean;
  };
  TourDetails: {tourID: string};
  TourHome: undefined;
}>();

export default function TourTabStackNavigator() {
  return (
    <Stack.Navigator>
      <Stack.Group
        screenOptions={({navigation}) => ({
          contentStyle: {
            backgroundColor: 'white',
            paddingTop: Platform.OS === 'web' ? 8 : 0,
          },
          headerLeft: () => (
            <Pressable
              onPress={() => {
                navigation.goBack();
              }}
              style={({pressed}) => ({
                opacity: pressed ? 0.5 : 1,
              })}>
              <Ionicons
                color="black"
                name="arrow-back"
                size={24}
                style={{
                  marginLeft: Platform.OS === 'web' ? 16 : 0,
                }}
              />
            </Pressable>
          ),
          headerShadowVisible: false,
        })}>
        <Stack.Screen
          component={DiscoverHomeScreen}
          name="TourHome"
          options={{headerShown: false, title: '发现'}}
        />
        <Stack.Screen
          component={DiscoverDetailsScreen}
          name="TourDetails"
          options={{title: '详情'}}
        />
        <Stack.Screen
          component={PeopleListScreen}
          name="PeopleListScreen"
          options={{title: '人员信息'}}
        />
        <Stack.Screen
          component={GroupCreatorView}
          name="OtherAccountView"
          options={{title: ''}}
        />
        <Stack.Screen
          component={CreateGroupScreen}
          name="CreateGroupScreen"
          options={{title: '发起组队'}}
        />
      </Stack.Group>
      <Stack.Group screenOptions={{presentation: 'modal'}}>
        <Stack.Screen
          component={JoinWechatModal}
          name="JoinWechatModal"
          options={{headerShown: false}}
        />
      </Stack.Group>
    </Stack.Navigator>
  );
}
