import {Ionicons} from '@expo/vector-icons';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {Platform, Pressable} from 'react-native';

import CreateGroupSuccessfulScreen from '../screens/CreateGroupSuccessfulScreen';
import JoinGroupPaymentScreen from '../screens/JoinGroupPaymentScreen';
import JoinGroupSuccessfulScreen from '../screens/JoinGroupSuccessfulScreen';
import JoinGroupTipScreen from '../screens/JoinGroupTipScreen';
import JoinGroupWechatScreen from '../screens/JoinGroupWechatScreen';
import PaymentModalScreen from '../screens/PaymentModalScreen';
import RefundModalScreen from '../screens/RefundModalScreen';
import TicketOptionModalScreen from '../screens/TicketOptionModalScreen';
import {Group} from '../types';

const Stack = createNativeStackNavigator<{
  CreateGroupSuccessfulScreen: {
    groupId: string;
    numLeft: number;
    qrCodeId: string;
    title: string;
  };
  JoinGroupPaymentScreen: {
    group: Group;
    groupQrCodeUrl: string;
    numOfNew: any;
    standAlone: boolean;
  };
  JoinGroupSuccessfulScreen: {
    group: Group;
    groupQrCodeUrl: string;
  };
  JoinGroupTipScreen: {
    group: Group;
    groupQrCodeUrl: string;
    standAlone: boolean;
  };
  JoinGroupWechatScreen: {
    group: Group;
    groupQrCodeUrl: string;
  };
  TicketOptionModalScreen: {
    group: Group;
  };
  PaymentModalScreen: {
    clientSecret: string;
    customerEphemeralKey: string;
    group: Group;
    groupQrCodeUrl: string;
    numberOfPeople: number;
    priceWithoutFee: number;
    serviceFee: number;
    stripeCustomerID: string;
  };
  RefundModalScreen: {
    group: Group;
  };
}>();

export default function JoinGroupModalStackNavigator() {
  return (
    <Stack.Navigator
      screenOptions={({navigation}) => ({
        contentStyle: {
          backgroundColor: 'white',
          paddingTop: Platform.OS === 'web' ? 16 : 0,
        },
        headerLeft: () => {
          return (
            <Pressable
              onPress={() => {
                navigation.goBack();
              }}
              style={({pressed}) => ({
                opacity: pressed ? 0.5 : 1,
              })}>
              <Ionicons
                color="black"
                name="arrow-back"
                size={24}
                style={{
                  marginLeft: Platform.OS === 'web' ? 16 : 0,
                }}
              />
            </Pressable>
          );
        },
        headerShadowVisible: false,
      })}>
      <Stack.Group>
        <Stack.Screen
          component={JoinGroupWechatScreen}
          name="JoinGroupWechatScreen"
        />
        <Stack.Screen
          component={JoinGroupTipScreen}
          name="JoinGroupTipScreen"
        />
        <Stack.Screen
          component={JoinGroupPaymentScreen}
          name="JoinGroupPaymentScreen"
        />
        <Stack.Screen
          component={JoinGroupSuccessfulScreen}
          name="JoinGroupSuccessfulScreen"
        />
        <Stack.Screen component={RefundModalScreen} name="RefundModalScreen" />
        <Stack.Screen
          component={TicketOptionModalScreen}
          name="TicketOptionModalScreen"
        />
        <Stack.Screen
          component={PaymentModalScreen}
          name="PaymentModalScreen"
        />
        <Stack.Screen
          component={CreateGroupSuccessfulScreen}
          name="CreateGroupSuccessfulScreen"
        />
      </Stack.Group>
    </Stack.Navigator>
  );
}
