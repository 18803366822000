import {
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from 'firebase/firestore';

import {Tour} from '../types';

export default {
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): Tour {
    const data = snapshot.data(options);
    return {
      comments: data.comments,
      description: data.description
        .split('\\n')
        .join('\n')
        .split('\\t')
        .join('\t'),
      destination: data.destination,
      groupQrCodeUrl: data.groupQrCodeUrl ?? null,
      headerImage: data.headerImage,
      id: snapshot.id,
      likes: data.likes,
      oldPrice: data.oldPrice ?? null,
      postedBySelectedMerchant: data.postedBySelectedMerchant ?? false,
      poster: data.poster,
      price: data.price ?? null,
      purchaseUrl: data.purchaseUrl ?? null,
      title: data.title,
      uploadDate: data.uploadDate,
    };
  },
  toFirestore(group: WithFieldValue<Tour>): DocumentData {
    return {...group};
  },
};
