import {
  collection,
  doc,
  getDocs,
  getFirestore,
  onSnapshot,
  Timestamp,
} from 'firebase/firestore';
import React, {
  createContext,
  Dispatch,
  useContext,
  useEffect,
  useState,
} from 'react';

import {OFFICIAL_ACCOUNT} from '../constants/config';
import groupConverter from '../firestoreConverters/groupConverter';
import {Group, User} from '../types';
import {useAuthContext} from './AuthContext';

type ChatContextType = {
  chatIdOnScreen: string;
  setChatIdOnScreen: Dispatch<React.SetStateAction<string>>;
  setSkipLoadingChatContext: Dispatch<React.SetStateAction<boolean>>;
  unread: boolean;
};

const ChatContext = createContext<ChatContextType | null>(null);

export function useChatContext() {
  const chatContext = useContext(ChatContext);
  if (chatContext == null) {
    throw Error('Null ChatContext. Make sure to use ChatContextProvider');
  }
  return chatContext;
}

export function ChatContextProvider({children}: {children: React.ReactNode}) {
  const [unread, setUnread] = useState(false);
  const [chatIdOnScreen, setChatIdOnScreen] = useState('');
  const [skipLoadingChatContext, setSkipLoadingChatContext] = useState(true);
  const {currentUser} = useAuthContext();

  useEffect(() => {
    const fetchChatDate = async () => {
      if (skipLoadingChatContext || currentUser == null) {
        return;
      }
      try {
        const groupsSnapshot = await getDocs(
          collection(getFirestore(), 'groups').withConverter(groupConverter),
        );

        const eligibleGroups: Group[] = [];
        if (groupsSnapshot) {
          groupsSnapshot.docs.forEach((docData) => {
            const group = docData.data();
            if (
              group.status !== 'deleted' &&
              (currentUser?.id == OFFICIAL_ACCOUNT ||
                group.paidMembers.filter(
                  (user: User) => user.id === currentUser?.id,
                ).length > 0 ||
                group.currentMembers.filter(
                  (user: User) => user.id === currentUser?.id,
                ).length > 0)
            ) {
              eligibleGroups.push(group);
            }
          });
        }

        for (let index = 0; index < eligibleGroups.length; index++) {
          const group = eligibleGroups[index];
          const docRef = doc(getFirestore(), 'groupChatMessages', group.id);
          onSnapshot(docRef, (snapshot) => {
            const data = snapshot.data();
            if (
              data &&
              data.history &&
              currentUser?.id &&
              data.history[currentUser?.id]
            ) {
              const timestamp: Timestamp = data.history[currentUser?.id];
              if (
                group.lastMessageRecord?.time &&
                group.lastMessageRecord?.time > timestamp &&
                group.id !== chatIdOnScreen
              ) {
                setUnread(true);
                return;
              }
            }
          });
        }
        setUnread(false);
      } catch (error) {
        console.log('error in fetching unread', error);
      }
    };
    fetchChatDate();
  }, [currentUser, chatIdOnScreen, skipLoadingChatContext]);

  const value: ChatContextType = {
    chatIdOnScreen,
    setChatIdOnScreen,
    setSkipLoadingChatContext,
    unread,
  };

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
}
