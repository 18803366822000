import {Ionicons} from '@expo/vector-icons';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {Platform, Pressable} from 'react-native';

import ReferAndDiscount from '../components/account/ReferAndDiscount';
import DiscoverDetailsScreen from '../components/discover/DiscoverDetailsScreen';
import AccountHomeScreen from '../screens/AccountHomeScreen';
import AccountSettingsScreen from '../screens/AccountSettingsScreen';
import CreateGroupScreen from '../screens/CreateGroupScreen';
import CreatePostScreen from '../screens/CreatePostScreen';
import GroupCreatorView from '../screens/GroupCreatorView';
import GroupDetailsScreen from '../screens/GroupDetailsScreen';
import GroupFeedbackScreen from '../screens/GroupFeedbackScreen';
import JoinGroupPaymentScreen from '../screens/JoinGroupPaymentScreen';
import JoinGroupTipScreen from '../screens/JoinGroupTipScreen';
import PeopleListDetailScreen from '../screens/PeopleListDetailScreen';
import PeopleListScreen from '../screens/PeopleListScreen';
import {Group, User} from '../types';

const Stack = createNativeStackNavigator<{
  AccountHome: undefined;
  AccountSettingsScreen: undefined;
  CreateGroupScreen: undefined;
  CreatePostScreen: {imageUrl: any};
  GroupCreatorView: {fromTab: string; userId: any};
  GroupDetails: {group: Group};
  GroupFeedbackScreen: {
    group: Group;
  };
  JoinGroupPaymentScreen: {
    group: Group;
    groupQrCodeUrl: string;
    numOfNew: any;
    standAlone: boolean;
  };
  JoinGroupTipScreen: {
    group: Group;
    groupQrCodeUrl: string;
    standAlone: boolean;
  };
  OtherAccountView: {userId: any};
  PeopleListDetailScreen: {
    allowUserRemoveParticipant: boolean;
    checkinStatus: boolean;
    discountCode: string;
    handleDelete: () => void;
    paidTicketOption: any[];
    paymentId: string;
    toggleCheckIn: (userId: string) => boolean;
    user: User;
  };
  PeopleListScreen: {
    allowDuplicate: boolean;
    paymentMap: any;
    peopleList: string[];
    showPayment: boolean;
  };
  ReferAndDiscount: undefined;
  TourDetails: {tourID: string};
}>();

export default function AccountTabStackNavigator() {
  return (
    <Stack.Navigator>
      <Stack.Group
        screenOptions={({navigation}) => ({
          contentStyle: {
            backgroundColor: 'white',
            paddingTop: 8,
          },
          headerLeft: () => {
            return (
              <Pressable
                onPress={() => {
                  navigation.goBack();
                }}
                style={({pressed}) => ({
                  opacity: pressed ? 0.5 : 1,
                })}>
                <Ionicons
                  color="black"
                  name="arrow-back"
                  size={24}
                  style={{
                    marginLeft: Platform.OS === 'web' ? 16 : 0,
                  }}
                />
              </Pressable>
            );
          },
          headerShadowVisible: false,
        })}>
        <Stack.Screen
          component={AccountHomeScreen}
          name="AccountHome"
          options={{headerShown: false, title: '账户'}}
        />
        <Stack.Screen
          component={AccountSettingsScreen}
          name="AccountSettingsScreen"
          options={{title: '设置账户'}}
        />
        <Stack.Screen
          component={PeopleListScreen}
          name="PeopleListScreen"
          options={{title: '人员信息'}}
        />
        <Stack.Screen
          component={GroupDetailsScreen}
          name="GroupDetails"
          options={{title: '组队'}}
        />
        <Stack.Screen
          component={GroupCreatorView}
          name="GroupCreatorView"
          options={{title: '发起人'}}
        />
        <Stack.Screen
          component={GroupCreatorView}
          name="OtherAccountView"
          options={{title: ''}}
        />
        <Stack.Screen
          component={CreatePostScreen}
          name="CreatePostScreen"
          options={{title: '分享笔记'}}
        />
        <Stack.Screen
          component={DiscoverDetailsScreen}
          name="TourDetails"
          options={{title: '详情'}}
        />
        <Stack.Screen
          component={CreateGroupScreen}
          name="CreateGroupScreen"
          options={{title: '发起组队'}}
        />
      </Stack.Group>
      <Stack.Group screenOptions={{presentation: 'modal'}}>
        <Stack.Screen
          component={JoinGroupTipScreen}
          name="JoinGroupTipScreen"
        />
        <Stack.Screen
          component={JoinGroupPaymentScreen}
          name="JoinGroupPaymentScreen"
        />
        <Stack.Screen
          component={GroupFeedbackScreen}
          name="GroupFeedbackScreen"
        />
        <Stack.Screen
          component={PeopleListDetailScreen}
          name="PeopleListDetailScreen"
          options={{title: ''}}
        />
        <Stack.Screen component={ReferAndDiscount} name="ReferAndDiscount" />
      </Stack.Group>
    </Stack.Navigator>
  );
}
