import {Ionicons} from '@expo/vector-icons';
import {useNavigation} from '@react-navigation/native';
import {
  addDoc,
  collection,
  doc,
  getFirestore,
  updateDoc,
} from 'firebase/firestore';
import {useLayoutEffect} from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import {SHARE_LINK_BASE_URL} from '../constants/config';
import {useAuthContext} from '../context/AuthContext';
import {useFeaturesContext} from '../context/FeaturesContext';
import {shareLink} from '../helpers/util';

const length = '96%';
const padding = '2%';

export default function JoinGroupSuccessfulScreen({route}: {route: any}) {
  const navigation: any = useNavigation();
  const {group, paidMemberNumber} = route.params;
  const groupId = group?.id;
  const title = group?.title;
  const numLeft =
    group?.numMemberRequired -
    group?.currentMembers.length -
    (paidMemberNumber || 0);
  const {features} = useFeaturesContext();
  const {currentUser} = useAuthContext();

  useLayoutEffect(() => {
    const messageData = {
      _id: currentUser?.id,
      createdAt: new Date(),
      system: true,
      text: currentUser?.userName + '加入了活动',
      user: {_id: currentUser?.id, name: currentUser?.userName},
    };
    const messagesCollectionRef = collection(
      getFirestore(),
      'groupChatMessages',
      groupId,
      'Messages',
    );
    try {
      addDoc(messagesCollectionRef, messageData);
      updateDoc(doc(getFirestore(), 'groups/' + groupId), {
        lastMessageRecord: {
          message: messageData.text,
          time: messageData.createdAt,
        },
      });
    } catch (error) {
      console.log('add system message failed', error);
    }
  }, [currentUser?.id, currentUser?.userName, groupId]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerBackVisible: false,
      headerRight: () => (
        <Pressable
          onPress={() => {
            navigation.getParent()?.goBack();
            navigation.navigate('AccountTab', {screen: 'AccountHome'}); //In web it will stay at group page if this line removed
          }}>
          <Ionicons color="black" name="close" size={24} />
        </Pressable>
      ),
      headerTitle: () => <TitleView />,
    });
  }, [navigation]);

  const TitleView = () => {
    return (
      <View style={styles.titleView}>
        <Ionicons color="#66BB6A" name="checkmark-circle-outline" size={24} />
        <Text style={styles.title}>已成功加入组队!</Text>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <Text style={styles.text}>
        恭喜您已成功加入组队！您可以在您的账户里查看最新的组队情况！
      </Text>
      <Text style={styles.text}>
        还差{numLeft}
        人，赶快分享你的组队来帮助你的组队早日成团！
      </Text>
      <TouchableOpacity
        onPress={() => {
          shareLink(
            `我已成功加入活动${title}，还差${numLeft}人， 快来加入我的组队吧！`,
            SHARE_LINK_BASE_URL + `/group/${groupId}`,
          );
        }}
        style={styles.shareButton}>
        <Text style={styles.shareButtonText}>分享</Text>
      </TouchableOpacity>

      <View style={styles.divider} />
      <Text style={styles.text}>
        现在您可以通过野兔APP发送群聊消息了！这意味着您可以实时收到消息并与小伙伴进行交流。
      </Text>
      <TouchableOpacity
        onPress={() => {
          navigation.navigate('ChatTab', {
            params: {
              groupId: groupId,
              groupTitle: group.title,
            },
            screen: 'ChatScreen',
          });
        }}
        style={styles.shareButton}>
        <Text style={styles.shareButtonText}>查看群聊</Text>
      </TouchableOpacity>

      <View style={{height: 30}} />
    </View>
  );
}

const styles = StyleSheet.create({
  buttonGroup: {
    alignSelf: 'center',
    bottom: 40,
    flexDirection: 'column',
    marginBottom: 10,
    marginTop: 40,
    paddingVertical: 8,
    position: 'absolute',
    width: length,
  },
  container: {
    alignItems: 'flex-start',
    backgroundColor: 'white',
    flex: 1,
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'flex-start',
    paddingHorizontal: 16,
  },
  divider: {
    alignSelf: 'center',
    backgroundColor: 'lightgrey',
    flexDirection: 'column',
    height: 1,
    marginVertical: 30,
    width: length,
  },
  qrCode: {
    alignSelf: 'center',
    height: 160,
    marginVertical: 16,
    width: 160,
  },
  separator: {
    alignSelf: 'center',
    backgroundColor: '#c0c0c0',
    height: 1,
    marginVertical: 20,
    width: length,
  },
  shareButton: {
    backgroundColor: '#66BB6A',
    borderRadius: 5,
    marginVertical: 10,
    paddingVertical: 8,
    width: '100%',
  },
  shareButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  text: {
    fontSize: 18,
    paddingHorizontal: padding,
    paddingVertical: 8,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    paddingLeft: 2,
  },
  titleView: {
    alignItems: 'center',
    alignSelf: 'center',
    flexDirection: 'row',
    // paddingVertical: 16,
  },
});
