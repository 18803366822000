import {Feather, Ionicons} from '@expo/vector-icons';
import {Image, StyleSheet, Text, TouchableOpacity, View} from 'react-native';

import {shareLink} from '../../helpers/util';

const QRCodeView = ({title, QRCodeURL}: {QRCodeURL: string; title: string}) => {
  const GetQRCode = () => {
    if (QRCodeURL) {
      console.log('Got a valid QRCode link: ' + QRCodeURL);
      return <Image source={{uri: QRCodeURL}} style={styles.qrCode} />;
    } else {
      return <View style={[styles.qrCode, {backgroundColor: 'green'}]} />;
    }
  };

  return (
    <View style={styles.codeContainer}>
      <GetQRCode />
      <Text> {title}</Text>
    </View>
  );
};

export function JoinWechatModal({route}: {route: any}) {
  const {QRCodeTitle, QRCodeURL, subTitle, title, shareText} = route.params;

  return (
    <View style={styles.modalContainer}>
      <View
        style={[
          styles.modalTitleRow,
          {
            paddingTop: 50,
          },
        ]}>
        <Feather color="#66BB6A" name="check-circle" size={24} />
        <Text style={styles.title}>{title}</Text>
      </View>

      <Text style={styles.subTitle}>{subTitle}</Text>

      <QRCodeView QRCodeURL={QRCodeURL} title={QRCodeTitle} />

      <View style={styles.divider} />

      <View
        style={[
          styles.modalTitleRow,
          {
            paddingTop: 0,
          },
        ]}>
        <Ionicons color="#66BB6A" name="paper-plane-outline" size={24} />
        <Text style={styles.title}>分享你的旅途</Text>
      </View>

      <Text style={styles.subTitle}>赶快分享来找到伙伴</Text>
      <TouchableOpacity
        onPress={() => {
          shareLink(shareText, 'https://yaytour.com');
        }}
        style={styles.shareButton}>
        <Text style={styles.shareButtonText}>分享</Text>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  codeContainer: {
    alignItems: 'center',
    paddingTop: 20,
  },
  divider: {
    alignSelf: 'center',
    borderBottomColor: 'rgba(0, 0, 0, 0.2)',
    borderBottomWidth: 1,
    marginVertical: 20,
    width: '100%',
  },
  modalContainer: {
    paddingHorizontal: 10,
  },
  modalTitleRow: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  qrCode: {
    alignSelf: 'center',
    height: 200,
    marginVertical: 16,
    width: 200,
  },
  shareButton: {
    alignSelf: 'center',
    backgroundColor: '#66BB6A',
    borderRadius: 5,
    marginVertical: 10,
    paddingVertical: 8,
    width: '95%',
  },
  shareButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  subTitle: {
    fontSize: 16,
    paddingHorizontal: 15,
    paddingTop: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginLeft: 5,
  },
});
