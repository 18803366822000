import {
  Feather,
  FontAwesome,
  Ionicons,
  SimpleLineIcons,
} from '@expo/vector-icons';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import * as React from 'react';
import {Pressable, View} from 'react-native';

import Colors from '../constants/Colors';
import {useChatContext} from '../context/ChatContext';
import {RootTabParamList, RootTabScreenProps} from '../types';
import AccountTabStackNavigator from './AccountTabStackNavigator';
import ChatTabStackNavigator from './ChatTabStackNavigator';
import GroupTabStackNavigator from './GroupTabStackNavigator';
import TourTabStackNavigator from './TourTabStackNavigator';

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const BottomTab = createBottomTabNavigator<RootTabParamList>();

export function BottomTabNavigator() {
  const {unread} = useChatContext();
  return (
    <BottomTab.Navigator
      initialRouteName="GroupTab"
      screenOptions={{
        headerShown: false,
        tabBarActiveTintColor: '#66BB6A',
      }}>
      <BottomTab.Screen
        component={GroupTabStackNavigator}
        name="GroupTab"
        options={{
          tabBarIcon: ({color}) => (
            <SimpleLineIcons color={color} name="fire" size={24} />
          ),
          title: '组队',
        }}
      />
      <BottomTab.Screen
        component={TourTabStackNavigator}
        name="TourTab"
        options={({navigation}: RootTabScreenProps<'TourTab'>) => ({
          headerRight: () => (
            <Pressable
              onPress={() => navigation.navigate('Modal')}
              style={({pressed}) => ({
                opacity: pressed ? 0.5 : 1,
              })}>
              <FontAwesome
                color={Colors.light.text}
                name="info-circle"
                size={25}
                style={{marginRight: 15}}
              />
            </Pressable>
          ),
          tabBarIcon: ({color}) => (
            <Feather color={color} name="map-pin" size={24} />
          ),
          title: '发现',
        })}
      />
      <BottomTab.Screen
        component={ChatTabStackNavigator}
        name="ChatTab"
        options={{
          tabBarIcon: ({color}) =>
            unread ? (
              <View>
                <Ionicons color={color} name="chatbox-outline" size={24} />
                <View
                  style={{
                    backgroundColor: 'red',
                    borderRadius: 4,
                    height: 8,
                    position: 'absolute',
                    right: -1,
                    top: -1,
                    width: 8,
                  }}
                />
              </View>
            ) : (
              <Ionicons color={color} name="chatbox-outline" size={24} />
            ),
          title: '群聊',
        }}
      />
      <BottomTab.Screen
        component={AccountTabStackNavigator}
        name="AccountTab"
        options={{
          tabBarIcon: ({color}) => (
            <Ionicons color={color} name="person-outline" size={24} />
          ),
          title: '账号',
        }}
      />
    </BottomTab.Navigator>
  );
}
