import {useNavigation} from '@react-navigation/native';
import {useEffect, useState} from 'react';
import {
  ActivityIndicator,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

export default function LoadingScreen() {
  const navigation: any = useNavigation();

  const [isShowingButton, setIsShowingButton] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsShowingButton(true);
    }, 30000);
  }, []);

  return (
    <View style={styles.container}>
      <ActivityIndicator size="large" />

      {isShowingButton && (
        <View style={styles.buttonContainer}>
          <Text style={{padding: 25}}>暂时无法连接服务器，请稍后再试。</Text>

          <TouchableOpacity
            onPress={() => navigation.replace('Root')}
            style={styles.button}>
            <Text>{'回到主页'}</Text>
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  button: {
    alignSelf: 'center',
    borderRadius: 8,
    borderWidth: 1,
    justifyContent: 'center',
    padding: 8,
    width: '50%',
  },
  buttonContainer: {
    opacity: 0.7,
  },
  container: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  linkContainer: {
    marginTop: 15,
    opacity: 0.7,
    paddingVertical: 15,
  },
});
