import {StyleSheet, Text, View} from 'react-native';
import Hyperlink from 'react-native-hyperlink';

const length = '96%';
const padding = '2%';

export default function GroupNoriceModal({route}: {route: any}) {
  const {content, timeString} = route.params;

  return (
    <View style={styles.container}>
      <Text style={styles.header}>发布于 {timeString}</Text>
      <Text style={styles.header}>只有组织者和管理员才可修改群公告</Text>
      <View style={styles.separator} />
      <Hyperlink
        linkDefault={true}
        linkStyle={{color: '#2980b9', fontSize: 18}}>
        <Text selectable={true} style={styles.text}>
          {content}
        </Text>
      </Hyperlink>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'flex-start',
    backgroundColor: 'white',
    flex: 1,
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'flex-start',
    paddingHorizontal: 16,
    paddingVertical: 16,
  },
  header: {
    color: 'grey',
    fontSize: 16,
    paddingVertical: 4,
  },
  separator: {
    alignSelf: 'center',
    backgroundColor: '#c0c0c0',
    height: 1,
    marginVertical: 20,
    width: length,
  },
  text: {
    fontSize: 18,
    paddingHorizontal: padding,
    paddingVertical: 8,
  },
});
