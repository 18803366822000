import {PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {useState} from 'react';
import {StyleSheet, Text, TouchableOpacity, View} from 'react-native';

import alert from '../container/alert';

export default function CheckoutForm({addUsersToPaidMembers}) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (e) => {
    setIsProcessing(true);
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const {error} = await stripe.confirmPayment({
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}`,
      },
      elements,
      redirect: 'if_required',
    });
    if (error) {
      alert(
        '支付遇到问题, 请稍后支付',
        `Error code: ${error.code}\nError message: ${error.message}`,
        [{onPress: () => {}, text: '确定'}],
      );
      setIsProcessing(false);
    } else {
      await addUsersToPaidMembers();
      setIsProcessing(false);
    }
  };

  return (
    <View>
      <View style={{alignContent: 'center'}}>
        <PaymentElement />
      </View>

      <TouchableOpacity
        disabled={isProcessing}
        onPress={handleSubmit}
        style={[styles.payNowButton]}>
        <Text style={styles.payNowButtonText}>立即支付</Text>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  payNowButton: {
    alignContent: 'center',
    backgroundColor: '#66BB6A',
    borderColor: '#66BB6A',
    borderRadius: 5,
    borderWidth: 1,
    flex: 1,
    justifyContent: 'center',
    marginVertical: 16,
    padding: 8,
    width: '100%',
  },
  payNowButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
});
