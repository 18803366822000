import {useNavigation} from '@react-navigation/native';
import {doc, updateDoc} from 'firebase/firestore';
import React, {useLayoutEffect, useState} from 'react';
import {
  Image,
  Pressable,
  StyleSheet,
  Text,
  TextInput,
  View,
} from 'react-native';

import alert from '../components/container/alert';
import {OFFICIAL_ACCOUNT} from '../constants/config';
import {useAuthContext} from '../context/AuthContext';
import {getFirestore} from '../firebase';
import {uploadTourObj} from '../helpers/imageUtil';
import LoadingScreen from './LoadingScreen';

export default function CreatePostScreen({route}: {route: any}) {
  const {imageUrl} = route.params;
  const navigation: any = useNavigation();
  const currentUser = useAuthContext().currentUser;
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState('');
  const [oldPrice, setOldPrice] = useState('');
  const [purchaseUrl, setPurchaseUrl] = useState('');
  const [description, setDescription] = useState('');
  const [imageWidth, setImageWidth] = useState(80);

  const defaultHeight = 200; // Fixed height, width is adjusted based on image ratio

  useLayoutEffect(() => {
    const savePost = async () => {
      if (!currentUser) {
        return;
      }

      if (title === '' || description === '' || imageUrl === '') {
        alert(
          '',
          '填入标题，内容和照片之后才能发布笔记哦～',
          [{onPress: () => console.log('OK pressed'), text: '确定'}],
          {cancelable: true},
        );
        return;
      } else {
        setIsLoading(true);
        console.log('Current user: ' + currentUser.isSelectedMerchant);
        const tourID = await uploadTourObj(
          imageUrl,
          title,
          description,
          currentUser.id,
          'TourImages/',
          currentUser.isSelectedMerchant,
          price,
          oldPrice,
          purchaseUrl,
        );

        if (currentUser.createdPostsList) {
          currentUser.createdPostsList.push(tourID);
        } else {
          currentUser.createdPostsList = [tourID];
        }

        console.log('Created posts list: ' + currentUser.createdPostsList);
        // Update user created post list
        const userRef = doc(getFirestore(), 'users', currentUser.id);
        updateDoc(userRef, {
          createdPostsList: currentUser.createdPostsList,
        });

        setIsLoading(false);

        setDescription('');
        setTitle('');
        navigation.goBack();
      }
    };

    navigation.setOptions({
      headerRight: () => (
        <Pressable onPress={savePost}>
          <View style={styles.uploadButton}>
            <Text style={{color: 'white', fontSize: 14, fontWeight: 'bold'}}>
              发布
            </Text>
          </View>
        </Pressable>
      ),
    });
  }, [
    currentUser,
    description,
    imageUrl,
    navigation,
    oldPrice,
    price,
    purchaseUrl,
    title,
  ]);

  Image.getSize(imageUrl, (width, height) => {
    const originalRatio = width / height;
    const adjustedWidth = defaultHeight * originalRatio;

    setImageWidth(adjustedWidth);
  });

  if (isLoading) {
    return (
      <View style={{alignItems: 'center', flex: 1}}>
        <LoadingScreen />
      </View>
    );
  } else {
    return (
      <View style={styles.container}>
        <TextInput
          editable
          maxLength={48}
          onChangeText={(text) => setTitle(text)}
          placeholder="写标题会有更多赞哦～"
          style={[styles.descriptionBox, {fontSize: 18, fontWeight: 'bold'}]}
          value={title}
        />

        <View
          style={{
            borderBottomColor: 'black',
            borderBottomWidth: 1,
            opacity: 0.15,
          }}
        />

        <TextInput
          editable
          multiline
          numberOfLines={15}
          onChangeText={(text) => setDescription(text)}
          placeholder="分享此刻的想法"
          style={[styles.descriptionBox, {fontSize: 16, fontWeight: 'bold'}]}
          value={description}
        />

        {currentUser?.id == OFFICIAL_ACCOUNT && (
          <View>
            <View style={{flexDirection: 'row'}}>
              <Text style={styles.descriptionBox}>原价</Text>
            </View>
            <TextInput
              defaultValue={oldPrice}
              keyboardType="numeric"
              onChangeText={setOldPrice}
              placeholder={'原价'}
              placeholderTextColor="#777"
            />
            <View style={{flexDirection: 'row'}}>
              <Text style={styles.descriptionBox}>折扣价</Text>
            </View>
            <TextInput
              defaultValue={price}
              keyboardType="numeric"
              onChangeText={setPrice}
              placeholder={'原价'}
              placeholderTextColor="#777"
            />
            <View style={{flexDirection: 'row'}}>
              <Text style={styles.descriptionBox}>购买链接</Text>
            </View>
            <TextInput
              defaultValue={purchaseUrl}
              onChangeText={setPurchaseUrl}
              placeholder={'购买链接'}
              placeholderTextColor="#777"
            />
          </View>
        )}

        <View>
          <Image
            source={{uri: imageUrl}}
            style={[
              styles.image,
              {
                height: defaultHeight,
                width: imageWidth,
              },
            ]}
          />
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    paddingHorizontal: 30,
    paddingVertical: 20,
    width: '100%',
  },
  descriptionBox: {
    paddingVertical: 10,
  },
  image: {
    alignSelf: 'center',
    borderRadius: 5,
    height: 230,
    margin: 20,
    padding: 10,
    width: 260,
  },
  loadingInvisible: {
    opacity: 0,
  },
  loadingVisible: {
    opacity: 1,
  },
  uploadButton: {
    alignSelf: 'center',
    backgroundColor: '#66BB6A',
    borderRadius: 8,
    paddingHorizontal: 10,
    paddingVertical: 6,
  },
});
