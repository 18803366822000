import {Platform, View} from 'react-native';
import WebView from 'react-native-webview';

export default function UserTermsScreen({}: {navigation: any}) {
  return Platform.OS === 'web' ? (
    <View style={{flex: 1, height: '100%'}}>
      <iframe
        src="https://yaytour.com/termofservice/"
        style={{height: '100%'}}></iframe>
    </View>
  ) : (
    <WebView
      source={{uri: 'https://yaytour.com/termofservice/'}}
      style={{flex: 1}}
    />
  );
}
