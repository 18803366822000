import type {User as FirebaseUser} from 'firebase/auth';
import {doc, getDoc, onSnapshot, updateDoc} from 'firebase/firestore';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {USE_PRODUCTION_BUILD} from '../constants/config';
import {getAuth, getFirestore} from '../firebase';
import userConverter from '../firestoreConverters/userConverter';
import type {User} from '../types';

export type AuthContextData = {
  currentUser: User | null;
  loginError: string | null;
  logout: (onSuccess: () => void) => void;
  phoneNumber: string | null;
};

export const AuthContext = createContext<AuthContextData>({
  currentUser: null,
  loginError: null,
  logout: () => {},
  phoneNumber: null,
});

type ProviderProps = {
  children: ReactNode;
  token: string;
};

const auth = getAuth();
const db = getFirestore();

export function AuthContextProvider({children, token}: ProviderProps) {
  const [loginError, setLoginError] = useState<string | null>(null);
  const [currentAuthUser, setCurrentAuthUser] = useState<FirebaseUser | null>(
    null,
  );
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  const logout = (onSuccess: () => void) => {
    auth
      .signOut()
      .then(() => {
        setCurrentUser(null);
        setCurrentAuthUser(null);
        setPhoneNumber(null);
        onSuccess();
      })
      .catch((error) => setLoginError(error));
  };

  useEffect(() => {
    auth.onAuthStateChanged((user: FirebaseUser | null) => {
      setCurrentAuthUser(user);
      setPhoneNumber(user?.phoneNumber ?? null);
    });
  }, []);

  useEffect(() => {
    if (currentAuthUser == null) {
      setCurrentUser(null);
      return () => {};
    }
    const {uid} = currentAuthUser;
    const unsubscribe = onSnapshot(
      doc(db, 'users', uid).withConverter(userConverter),
      async (document) => {
        if (currentAuthUser === null) {
          return;
        }
        const user = document?.data();
        if (user == null) {
          setCurrentUser(null);
          return;
        }

        // force load phone number if User object doesn't have it
        if (
          currentAuthUser &&
          currentAuthUser.phoneNumber &&
          currentAuthUser.phoneNumber != user.phoneNumber
        ) {
          user.phoneNumber = currentAuthUser.phoneNumber;
          const userRef = doc(db, 'users', uid);
          try {
            await updateDoc(userRef, {
              phoneNumber: currentAuthUser.phoneNumber,
            });
          } catch (error) {
            if (!USE_PRODUCTION_BUILD) {
              alert('cannot update phone number' + error);
            }
            console.log('cannot update phone number', error);
          }
        }
        setCurrentUser(user);
      },
    );
    console.log('push token: ' + token);
    const updateTokenFunc = async () => {
      var cu = (
        await getDoc(doc(db, 'users', uid).withConverter(userConverter))
      ).data();
      if (token && cu) {
        cu.notificationToken = [];
        cu.notificationToken.push(token);
        await updateDoc(doc(db, 'users', uid), {
          notificationToken: cu.notificationToken,
        });
      }
    };
    updateTokenFunc();
    return unsubscribe;
  }, [currentAuthUser]);

  const value: AuthContextData = useMemo(
    () => ({
      currentUser,
      loginError,
      logout,
      phoneNumber,
    }),
    [currentUser, loginError, phoneNumber],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuthContext(): AuthContextData {
  return useContext(AuthContext);

  // Debug purpose when not logged in.
  // return {
  //   currentUser: {
  //     id: '0K1IOHkudbcMTn0ELttC9XZS6Uw2',
  //     userName: 'SEEKER',
  //     gender: 'F',
  //     profileImageUrl:
  //       'https://firebasestorage.googleapis.com/v0/b/yaytour-prod.appspot.com/o/UserProfilePic%2F0K1IOHkudbcMTn0ELttC9XZS6Uw2_063818250542.912000000?alt=media&token=afd8e561-232a-4e8b-9d1f-9a532713301f',
  //   },
  // };
  // return {
  //   currentUser: {
  //     id: '4hiNRx0IgZgEydHCRaaCBYDaYWf2',
  //     userName: '拉疯喜剧',
  //     gender: 'F',
  //     profileImageUrl:
  //       'https://firebasestorage.googleapis.com/v0/b/yaytour-prod.appspot.com/o/UserProfilePic%2FWechatIMG34.png?alt=media&token=57e12f11-e24f-49e1-93ab-e2054a45f45f',
  //   },
  // };
  // return {
  //   currentUser: {
  //     gender: 'F',
  //     id: '8pppdbIBmdPQWwZ9fXFQzfUKuoO2',
  //     name: 'Ss',
  //     profileImageUrl:
  //       'https://firebasestorage.googleapis.com/v0/b/yaytour-prod.appspot.com/o/UserProfilePic%2Fsimple-avatar.png?alt=media&token=0c2c7b33-3897-4792-9239-74d04bb6c4a1',
  //     socialWechat: '525318946',
  //     userName: '爱哭的狗子猪',
  //   },
  // };
  // return {
  //   currentUser: {
  //     gender: 'M',
  //     id: 'mgINNLIv1YRfKCkytAG7m0YBxcY2',
  //     name: 'George.',
  //     profileImageUrl:
  //       'https://firebasestorage.googleapis.com/v0/b/yaytour-prod.appspot.com/o/UserProfilePic%2FmgINNLIv1YRfKCkytAG7m0YBxcY2_063818900560.215000000?alt=media&token=7318c93c-c696-4e07-85b3-e046f789a95d',
  //     socialWechat: 'Georgehe0000',
  //     userName: 'Let’s Play Hoops',
  //   },
  // };
  //   return {
  //   currentUser: {
  //     gender: 'F',
  //     id: 's1LLerttDBcbmlXd59idiWQL2t13',
  //     name: 'Yisha',
  //     profileImageUrl:
  //       'https://firebasestorage.googleapis.com/v0/b/yaytour-prod.appspot.com/o/UserProfilePic%2Fsimple-avatar.png?alt=media&token=0c2c7b33-3897-4792-9239-74d04bb6c4a1',
  //     socialWechat: 'S_squareD',
  //     userName: 'Yisha',
  //   },
  // };
}
