import {Ionicons} from '@expo/vector-icons';
import {useNavigation} from '@react-navigation/native';
import {useLayoutEffect, useState} from 'react';
import {
  Image,
  Linking,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

const length = '96%';
const padding = '2%';

export default function JoinGroupWechatScreen({route}: {route: any}) {
  const navigation: any = useNavigation();
  const {group, groupQrCodeUrl} = route.params;
  const [wechatGroupJoined, setWechatGroupJoined] = useState(false);

  const navigationBehavior = () => {
    if (!wechatGroupJoined) {
      Linking.openURL('weixin://scanqrcode');
      setWechatGroupJoined(true);
    }
    if (wechatGroupJoined) {
      navigation.navigate('JoinGroupSuccessfulScreen', {
        group: group,
        groupQrCodeUrl: groupQrCodeUrl,
      });
    }
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      headerBackVisible: false,
      headerTitle: () => <TitleView />,
    });
  });

  const TitleView = () => {
    return (
      <View style={styles.titleView}>
        <Ionicons color="#66BB6A" name="checkmark-circle-outline" size={24} />
        <Text style={styles.title}>最后一步！</Text>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <Text style={styles.text}>
        保存下面二维码，并点击打开微信，加入群聊，其他小伙伴都在里面哦！
      </Text>
      <View style={styles.qrCode}>
        <Image
          source={{uri: groupQrCodeUrl}}
          style={{height: '100%', width: '100%'}}
        />
      </View>
      <View style={{height: 30}} />
      <TouchableOpacity
        onPress={async () => {
          navigationBehavior();
        }}
        style={styles.shareButton}>
        <Text style={styles.shareButtonText}>
          {wechatGroupJoined ? '我已进入群聊' : '打开微信加入组队群聊'}
        </Text>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'flex-start',
    backgroundColor: 'white',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingHorizontal: padding,
  },
  qrCode: {
    alignSelf: 'center',
    height: 160,
    marginVertical: 16,
    width: 160,
  },
  separator: {
    alignSelf: 'center',
    backgroundColor: '#c0c0c0',
    height: 1,
    marginVertical: 20,
    width: length,
  },
  shareButton: {
    alignSelf: 'center',
    backgroundColor: '#66BB6A',
    borderRadius: 5,
    bottom: 20,
    marginBottom: 10,
    marginTop: 40,
    paddingVertical: 8,
    position: 'absolute',
    width: length,
  },
  shareButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  text: {
    fontSize: 18,
    paddingHorizontal: padding,
    paddingVertical: 8,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  titleView: {
    alignItems: 'center',
    alignSelf: 'center',
    flexDirection: 'row',
  },
});
