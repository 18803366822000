import {Ionicons} from '@expo/vector-icons';
import {useLayoutEffect} from 'react';
import {Platform, Pressable} from 'react-native';

import AccountProfile from '../components/account/AccountProfile';

export default function GroupCreatorView({
  navigation,
  route,
}: {
  navigation: any;
  route: any;
}) {
  const {userId, fromTab} = route.params;

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <Pressable
          onPress={() => {
            if (fromTab === 'Chat') {
              navigation.navigate('AccountTab', {screen: 'AccountHome'});
              navigation.navigate('ChatTab');
            } else {
              navigation.goBack();
            }
          }}
          style={({pressed}) => ({
            opacity: pressed ? 0.5 : 1,
          })}>
          <Ionicons
            color="black"
            name="arrow-back"
            size={24}
            style={{
              marginLeft: Platform.OS === 'web' ? 16 : 2,
              marginRight: 10,
              marginVertical: 5,
            }}
          />
        </Pressable>
      ),
    });
  }, [fromTab, navigation]);

  return <AccountProfile navigation={navigation} userId={userId} />;
}
