import {useNavigation} from '@react-navigation/native';
import {collection, Timestamp} from 'firebase/firestore';
import {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {useCollection} from 'react-firebase-hooks/firestore';
import {
  Dimensions,
  Image,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from 'react-native';

import Layout from '../../constants/Layout';
import {getFirestore} from '../../firebase';
import bannerAdCampaignConverter from '../../firestoreConverters/bannerAdCampaignConverter';
import FastImage from '../../platforms/FastImage';
import {BannerAdCampaign} from '../../types';

const {width: WINDOW_WIDTH} = Dimensions.get('window');
const TOTAL_WIDTH = WINDOW_WIDTH - Layout.unit * 2 - 24;
const AUTO_SCROLL_INTERVAL = 3500;
const BANNER_SECTION_HEIGHT = 120;

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    if (Platform.OS === 'web') {
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }

    // return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const Slide = (props: {ad: BannerAdCampaign}) => {
  const navigation: any = useNavigation();
  const [windowWidth] = useWindowSize();
  const [isWeb] = useState(Platform.OS === 'web');
  const [webImageHeight, setWebImageHeight] = useState(0);

  useEffect(() => {
    Image.getSize(props.ad.imageAssetURL, (w, h) => {
      const originalRatio = w / h;
      const adjustedHeight = (windowWidth - 25) / originalRatio;
      setWebImageHeight(adjustedHeight);
    });
  }, [props.ad.imageAssetURL, windowWidth]);

  return (
    <View
      style={[
        styles.slide,
        {height: isWeb ? webImageHeight : BANNER_SECTION_HEIGHT},
      ]}>
      <Pressable
        onPressOut={() => {
          // if (isWeb) {
          //   return;
          // }
          switch (props.ad.type) {
            case 'group':
              navigation.push('GroupDetails', {
                groupId: props.ad.associatedURL,
              });
              break;
            case 'campaign':
              navigation.navigate('CampaignDetail', {
                content: props.ad,
              });
              break;
            case 'tour': // TODO: redirect to tour details screen
            default:
              break;
          }
        }}
        unstable_pressDelay={100}>
        <FastImage
          source={{uri: props.ad.imageAssetURL}}
          style={[
            styles.imagePreview,
            {
              height: isWeb ? webImageHeight : BANNER_SECTION_HEIGHT,
              width: TOTAL_WIDTH,
            },
          ]}
        />
      </Pressable>
    </View>
  );
};

export default function CarouselBannerView() {
  const [index, setIndex] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [width, setWidth] = useState(0);
  const [bullets, setBullets] = useState<JSX.Element[]>([]);
  const [itemWidth, setItemWidth] = useState(0);
  const [isWeb] = useState(Platform.OS === 'web');
  const scrollRef = useRef<ScrollView>(null);

  const [bannerAdsSnapshot, isLoading] = useCollection(
    collection(getFirestore(), 'bannerCampaign').withConverter(
      bannerAdCampaignConverter,
    ),
  );
  const displayedBannerAdCampaign: BannerAdCampaign[] = [];
  if (bannerAdsSnapshot) {
    bannerAdsSnapshot.docs.forEach((doc) => {
      const ad = doc.data() as BannerAdCampaign;
      if (
        Timestamp.now().seconds - ad.startDate.seconds > 0 &&
        Timestamp.now().seconds - ad.endDate.seconds < 0
      ) {
        displayedBannerAdCampaign.push(ad);
      }
    });
  }

  const init = (w: number) => {
    // initialise width
    setWidth(w);
    // initialise total intervals
    setPageCount(Math.ceil(displayedBannerAdCampaign.length));
    setItemWidth(w / pageCount);
  };

  const getInterval = (offset: number): number => {
    for (let i = 1; i <= pageCount; i++) {
      if (offset + 1 < (width / pageCount) * i) {
        return i;
      }
      if (i == pageCount) {
        return i;
      }
    }

    return 1;
  };

  useEffect(() => {
    const newBullets = [];
    for (let i = 1; i <= pageCount; i++) {
      newBullets.push(
        <Text
          key={i}
          style={{
            ...styles.bullet,
            opacity: index === i ? 0.5 : 0.1,
          }}>
          &bull;
        </Text>,
      );
    }
    setBullets(newBullets);
  }, [index, pageCount]);

  useEffect(() => {
    const i = setInterval(() => {
      let newIndex = index + 1;
      if (newIndex > pageCount) {
        newIndex = 1;
      }

      setIndex(newIndex);
      scrollRef.current?.scrollTo({
        animated: true,
        x: itemWidth * (newIndex - 1),
      });
    }, AUTO_SCROLL_INTERVAL);
    return () => clearInterval(i);
  }, [index, itemWidth, pageCount, width]);

  useEffect(() => {
    setIndex(index);
    scrollRef.current?.scrollTo({
      animated: true,
      x: itemWidth * (index - 1),
    });
  }, [index, itemWidth]);

  if (isLoading || displayedBannerAdCampaign.length === 0) {
    return <View />;
  }

  return (
    <View style={styles.container}>
      <ScrollView
        contentContainerStyle={{
          ...styles.scrollView,
          width: `${100 * pageCount}%`,
        }}
        decelerationRate="fast"
        horizontal={true}
        onContentSizeChange={(w) => init(w)}
        onMomentumScrollEnd={(data) => {
          setIndex(getInterval(data.nativeEvent.contentOffset.x));
        }}
        onScroll={(data) => {
          setWidth(data.nativeEvent.contentSize.width);
        }}
        ref={scrollRef}
        scrollEnabled={!isWeb}
        scrollEventThrottle={200}
        showsHorizontalScrollIndicator={false}
        snapToInterval={itemWidth}>
        {displayedBannerAdCampaign.map(
          (item: BannerAdCampaign, idx: number) => {
            return <Slide ad={item} key={idx} />;
          },
        )}
      </ScrollView>
      <View style={styles.bullets}>{bullets}</View>
    </View>
  );
}

const styles = StyleSheet.create({
  bullet: {
    fontSize: 24,
    paddingHorizontal: 5,
  },
  bullets: {
    alignSelf: 'center',
    bottom: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: 10,
    position: 'absolute',
    zIndex: 10,
  },
  container: {
    width: '100%',
  },
  imagePreview: {
    borderRadius: 8,
  },
  scrollView: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    zIndex: -1,
  },
  slide: {
    alignContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexBasis: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    maxWidth: '100%',
    // paddingBottom: 8,
    // paddingHorizontal: 10,
    // paddingTop: 8,
  },
  slideText: {
    fontSize: 20,
    textAlign: 'left',
    width: '100%',
  },
  stat: {
    alignContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexBasis: '33%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    maxWidth: '33%',
    paddingBottom: 10,
    paddingHorizontal: 20,
    paddingTop: 30,
  },
  statHold: {
    marginBottom: 8,
    width: '100%',
  },
  statLabel: {
    fontSize: 11,
    fontWeight: '600',
    paddingTop: 5,
    textAlign: 'left',
    width: '100%',
  },
  statText: {
    fontSize: 20,
    textAlign: 'left',
    width: '100%',
  },
});
