import {Ionicons} from '@expo/vector-icons';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import React, {useEffect, useState} from 'react';
import {Platform, Pressable} from 'react-native';

import {ENABLE_WECHAT_REDIRECT} from '../constants/dev';
import {getAuth} from '../firebase';
import LoginCompleteScreen from '../screens/LoginCompleteScreen';
import LoginInputScreen from '../screens/LoginInputScreen';
import LoginLocationScreen from '../screens/LoginLocationScreen';
import LoginProfileScreen from '../screens/LoginProfileScreen';
import LoginVerifyScreen from '../screens/LoginVerifyScreen';

const Stack = createNativeStackNavigator<{
  Complete: undefined;
  Input: {referCode: string};
  Location: {
    gender: string | undefined | null;
    image: string | null;
    location: string;
    name: string | undefined | null;
    referCode: string;
    username: string | undefined | null;
    wechat: string | undefined | null;
  };
  OpenInDefaultBrowser: undefined;
  Profile: {
    gender: string | undefined | null;
    image: string | null;
    mode: 'register' | 'complete' | undefined;
    name: string | undefined | null;
    referCode: string;
    username: string | undefined | null;
    wechat: string | undefined | null;
  };
  Verify: {
    formattedPhoneNumber: string;
    referCode: string;
    verificationId: string;
  };
}>();

export default function LoginModalStackNavigator() {
  const [isWechat, setIsWechat] = useState(false);

  useEffect(() => {
    const isWechatBrowser = () => {
      if (
        navigator.userAgent &&
        typeof navigator.userAgent !== 'undefined' &&
        navigator.userAgent.match(/MicroMessenger/i)
      ) {
        setIsWechat(true);
      }
    };

    isWechatBrowser();
  }, []);

  return (
    <Stack.Navigator
      screenOptions={({navigation}) => ({
        contentStyle: {
          backgroundColor: 'white',
          paddingTop: Platform.OS === 'web' ? 16 : 0,
        },
        headerRight: () => (
          <Pressable
            onPress={() => {
              getAuth().signOut(); // TODO: Error handling?
              navigation.goBack();
            }}
            style={({pressed}) => ({
              opacity: pressed ? 0.5 : 1,
            })}>
            <Ionicons
              color="black"
              name="close"
              size={24}
              style={{
                marginRight: Platform.OS === 'web' ? 16 : 0,
              }}
            />
          </Pressable>
        ),
        headerShadowVisible: false,
      })}>
      <Stack.Group>
        <Stack.Screen
          component={LoginInputScreen}
          name="Input"
          options={{
            headerLeft: () => null,
            headerShown: !(isWechat && ENABLE_WECHAT_REDIRECT),
            title: '登陆',
          }}
        />
        <Stack.Screen
          component={LoginVerifyScreen}
          name="Verify"
          options={{
            title: '验证手机号',
          }}
        />
        <Stack.Screen
          component={LoginProfileScreen}
          name="Profile"
          options={{title: '继续注册'}}
        />
        <Stack.Screen
          component={LoginLocationScreen}
          name="Location"
          options={() => ({
            contentStyle: {
              paddingTop: 0,
            },
            headerRight: () => null,
            headerTransparent: true,
            title: '',
          })}
        />
        <Stack.Screen component={LoginCompleteScreen} name="Complete" />
      </Stack.Group>
    </Stack.Navigator>
  );
  // }
}
