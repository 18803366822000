import {Ionicons} from '@expo/vector-icons';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {Platform, Pressable} from 'react-native';

import CreateGroupScreen from '../screens/CreateGroupScreen';
import GroupCreatorView from '../screens/GroupCreatorView';
import GroupDetailsScreen from '../screens/GroupDetailsScreen';
import GroupHomeScreen from '../screens/GroupHomeScreen';
import JoinGroupPaymentScreen from '../screens/JoinGroupPaymentScreen';
import JoinGroupTipScreen from '../screens/JoinGroupTipScreen';
import PeopleListDetailScreen from '../screens/PeopleListDetailScreen';
import PeopleListScreen from '../screens/PeopleListScreen';
import RatingPopUpScreen from '../screens/RatingPopUpScreen';
import {Group, User} from '../types';

const Stack = createNativeStackNavigator<{
  CreateGroupScreen: undefined;
  GroupCreatorView: {userId: any};
  GroupDetails: {groupId: string};

  GroupHome: undefined;
  JoinGroupPaymentScreen: {
    group: Group;
    groupQrCodeUrl: string;
    numOfNew: any;
    standAlone: boolean;
  };
  JoinGroupTipScreen: {
    group: Group;
    groupQrCodeUrl: string;
    standAlone: boolean;
  };
  OtherAccountView: {userId: any};
  PeopleListDetailScreen: {
    allowUserRemoveParticipant: boolean;
    checkinStatus: boolean;
    discountCode: string;
    handleDelete: () => void;
    paidTicketOption: any[];
    paymentId: string;
    toggleCheckIn: (userId: string) => boolean;
    user: User;
  };
  PeopleListScreen: {
    allowDuplicate: boolean;
    creatorID: string;
    groupId: string;
    isUserCreator: boolean;
    isUserOfficialAccount: boolean;
    paymentMap: any;
    peopleList: string[];
    showPayment: boolean;
    stripePaymentMap: Set<string>;
  };
  RatingPopUpScreen: {groups: Group[]};
}>();

export default function GroupTabStackNavigator() {
  return (
    <Stack.Navigator>
      <Stack.Group
        screenOptions={({navigation}) => ({
          contentStyle: {
            backgroundColor: 'white',
            paddingTop: 8,
          },
          headerLeft: () => (
            <Pressable
              onPress={() => {
                navigation.goBack();
              }}
              style={({pressed}) => ({
                opacity: pressed ? 0.5 : 1,
              })}>
              <Ionicons
                color="black"
                name="arrow-back"
                size={24}
                style={{
                  marginLeft: Platform.OS === 'web' ? 16 : 0,
                }}
              />
            </Pressable>
          ),
          headerShadowVisible: false,
        })}>
        <Stack.Screen
          component={GroupHomeScreen}
          name="GroupHome"
          options={{headerShown: false, title: '野兔组队'}}
        />
        <Stack.Screen
          component={GroupDetailsScreen}
          name="GroupDetails"
          options={{title: '加入组队'}}
        />
        <Stack.Screen
          component={CreateGroupScreen}
          name="CreateGroupScreen"
          options={{title: '发起组队'}}
        />
        <Stack.Screen
          component={GroupCreatorView}
          name="GroupCreatorView"
          options={{title: '发起人'}}
        />
        <Stack.Screen
          component={GroupCreatorView}
          name="OtherAccountView"
          options={{title: ''}}
        />
        <Stack.Screen
          component={PeopleListScreen}
          name="PeopleListScreen"
          options={{title: '人员信息'}}
        />
        <Stack.Screen
          component={JoinGroupTipScreen}
          name="JoinGroupTipScreen"
        />
        <Stack.Screen
          component={JoinGroupPaymentScreen}
          name="JoinGroupPaymentScreen"
        />
      </Stack.Group>
      <Stack.Group screenOptions={{presentation: 'modal'}}>
        <Stack.Screen
          component={PeopleListDetailScreen}
          name="PeopleListDetailScreen"
          options={{title: ''}}
        />
        <Stack.Screen component={RatingPopUpScreen} name="RatingPopUpScreen" />
      </Stack.Group>
    </Stack.Navigator>
  );
}
