import {Ionicons} from '@expo/vector-icons';
import {useNavigation} from '@react-navigation/native';
import {doc, getDoc} from 'firebase/firestore';
import React, {useEffect, useLayoutEffect, useState} from 'react';
import {
  Dimensions,
  Image,
  Linking,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import Colors from '../../constants/Colors';
import {ENABLE_WECHAT_REDIRECT} from '../../constants/dev';
import {useAuthContext} from '../../context/AuthContext';
import {getFirestore} from '../../firebase';
import tourConverter from '../../firestoreConverters/tourConverter';
import FastImage from '../../platforms/FastImage';
import LoadingScreen from '../../screens/LoadingScreen';
import {emptyTour, UserGender} from '../../types';
import OpenInDefaultBrowserScreen from '../misc/OpenInDefaultBrowser';
import DiscoverCommentSection from './DiscoverCommentSection';

const {width: WINDOW_WIDTH} = Dimensions.get('window');

export interface TeamRowProps {
  creatorName: string;
  creatorProfileImageUrl: string;
  gender: UserGender;
  remainingDays: number;
  remainingPeers: number;
}

export default function DiscoverDetailsScreen({route}: {route: any}) {
  const navigation: any = useNavigation();
  const {tourID} = route.params;
  const {currentUser} = useAuthContext();
  const [isLoading, setIsLoading] = useState(true);
  const [tour, setTour] = useState(emptyTour);
  const [imageHeight, setImageHeight] = useState(200);
  const isUserLoggedIn = currentUser != null;
  // const {features} = useFeaturesContext();

  // const [qrCodeSnapshot] = useDocument(
  //   doc(getFirestore(), 'groupQrCode', 'qUjXCB6taPxZ7EhPWz4B').withConverter(
  //     groupQrCodeConverter,
  //   ),
  // );
  // const qrCode = qrCodeSnapshot?.data();

  const isWeChat =
    Platform.OS === 'web' && navigator.userAgent.indexOf('MicroMessenger') > -1;

  const handleCreateGroup = async () => {
    if (!isUserLoggedIn) {
      console.log('navigate to member log in');
      navigation.push('LoginModal');
    } else {
      let description: string;
      if (tour.purchaseUrl !== null && tour.purchaseUrl !== '') {
        description = '购买链接：' + tour.purchaseUrl + '\n' + tour.description;
      } else {
        description = tour.description;
      }

      navigation.navigate('CreateGroupScreen', {
        tourDefaultDescription: description,
        tourDefaultImageURL: tour.headerImage,
        tourDefaultTitle: tour.title,
      });
    }
  };

  const handleJoinWechatGroup = () => {
    navigation.push('JoinWechatModal', {
      QRCodeTitle: '群活动二维码',
      QRCodeURL: tour.groupQrCodeUrl,
      shareText: '一起联系小助手加入野兔组队群聊吧',
      subTitle: '超多小伙伴已经加入啦～在微信中扫描群聊二维码即可加入！',
      title: '加入野兔组队的群聊！',
    });
  };

  const handlePurchasePress = () => {
    Linking.canOpenURL(tour.purchaseUrl ?? '').then((supported) => {
      if (!supported || tour.purchaseUrl == null || tour.purchaseUrl === '') {
        return;
      } else {
        return Linking.openURL(tour.purchaseUrl);
      }
    });
  };

  function PurchaseButton() {
    return (
      <TouchableOpacity
        onPress={handlePurchasePress}
        style={styles.purchaseButton}>
        <Text style={styles.purchaseButtonText}>直接购买</Text>
      </TouchableOpacity>
    );
  }

  function CreateGroupButton() {
    return (
      <TouchableOpacity
        onPress={handleCreateGroup}
        style={styles.createGroupButton}>
        <Text style={styles.createGroupButtonText}>发起组队</Text>
      </TouchableOpacity>
    );
  }

  function JoinWechatGroupButton() {
    return (
      <TouchableOpacity
        onPress={handleJoinWechatGroup}
        style={styles.purchaseButton}>
        <Text style={styles.purchaseButtonText}>加入群聊</Text>
      </TouchableOpacity>
    );
  }

  // function JoinGroupRow() {
  //   return (
  //     <View style={styles.rowContainerWithShadow}>
  //       <View style={{alignItems: 'center', flexDirection: 'row'}}>
  //         <Image
  //           source={require('../../assets/images/yay_logo.png')}
  //           style={styles.groupChatImage}
  //         />
  //
  //         <View>
  //           <Text style={{fontWeight: 'bold'}}>活动结伴群</Text>
  //           <Text style={{opacity: 0.8}}>快来加入吧～</Text>
  //         </View>
  //       </View>
  //
  //       <View style={{flex: 1}} />
  //       <View
  //         style={{
  //           height: 35,
  //         }}>
  //         <JoinWechatGroupButton />
  //       </View>
  //     </View>
  //   );
  // }

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <Pressable
          onPress={() => {
            if (navigation.getState().routes.length > 1) {
              navigation.pop();
            } else {
              navigation.navigate('TourHome');
            }
          }}>
          <Ionicons
            color="black"
            name="arrow-back"
            size={24}
            style={{
              marginLeft: Platform.OS === 'web' ? 16 : 2,
              marginRight: 10,
              marginVertical: 5,
            }}
          />
        </Pressable>
      ),
    });
  }, [navigation]);

  useEffect(() => {
    const fetchData = async () => {
      const ref = doc(getFirestore(), 'tours', tourID).withConverter(
        tourConverter,
      );

      console.log('fetching tour id: ' + tourID);

      const tourSnapshot = await getDoc(ref);
      if (tourSnapshot.exists()) {
        const tourData = tourSnapshot.data();
        console.log('load data: ' + tourData.title);

        // Well, calculate image height here
        Image.getSize(tourData.headerImage, (width, height) => {
          const originalRatio = width / height;
          const adjustedHeight = (WINDOW_WIDTH - 20) / originalRatio;

          setImageHeight(adjustedHeight);
        });

        setTour(tourData);

        setIsLoading(false);
      } else {
        console.log('Data not exists');
      }
    };
    fetchData();
  }, [tour.headerImage, tourID]);

  // Two necessary conditions: 1. loading finished. 2. no data
  const loadingFailed = !isLoading && tour.id.length === 0;

  function ButtonRow() {
    if (tour.purchaseUrl != null && tour.purchaseUrl != '') {
      return (
        <View style={{display: 'flex', flexDirection: 'row'}}>
          <PurchaseButton />
          <CreateGroupButton />
        </View>
      );
    } else if (tour.groupQrCodeUrl != null && tour.groupQrCodeUrl != '') {
      return (
        <View style={{display: 'flex', flexDirection: 'row'}}>
          <JoinWechatGroupButton />
          <CreateGroupButton />
        </View>
      );
    } else {
      return (
        <View style={{display: 'flex', flexDirection: 'row'}}>
          <CreateGroupButton />
        </View>
      );
    }
  }

  if (isLoading || loadingFailed) {
    return <LoadingScreen />;
  } else if (isWeChat && ENABLE_WECHAT_REDIRECT) {
    return <OpenInDefaultBrowserScreen />;
  } else {
    return (
      // <KeyboardAvoidingView
      //   behavior={Platform.OS === 'ios' ? 'position' : undefined}
      //   keyboardVerticalOffset={20}
      //   style={styles.keyboardAvoid}>
      <View style={styles.container}>
        <ScrollView>
          <FastImage
            source={{uri: tour.headerImage}}
            style={[
              styles.imagePreview,
              {
                height: imageHeight,
                width: WINDOW_WIDTH - 20,
              },
            ]}
          />
          <Text selectable={true} style={styles.title}>
            {tour.title}
          </Text>

          <View style={{flex: 1}}>
            <View style={styles.tourDetailTextContainer}>
              <Text selectable={true} style={styles.tourDetailText}>
                {tour.description}
              </Text>
            </View>
          </View>

          <DiscoverCommentSection tour={tour} />

          <View style={{paddingVertical: 15}} />
        </ScrollView>
        <View style={styles.buttonContainer}>
          {tour.postedBySelectedMerchant && <ButtonRow />}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  backButton: {
    opacity: 0.8,
  },
  button: {
    paddingHorizontal: 16,
    paddingVertical: 4,
  },
  buttonContainer: {
    paddingBottom: 10,
    width: '100%',
  },
  buttonRow: {
    flexDirection: 'row',
    height: 45,
    marginVertical: 8,
  },
  container: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    padding: 8,
  },
  createGroupButton: {
    alignSelf: 'center',
    backgroundColor: Colors.mainAppColor,
    borderRadius: 8,
    flex: 1,
    marginVertical: 0,
    paddingVertical: 8,
    width: '100%',
  },
  createGroupButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  currentTeamContainer: {
    paddingVertical: 8,
  },
  groupChatImage: {
    borderRadius: 65 / 2,
    height: 65,
    position: 'relative',
    width: 65,
  },
  imagePreview: {
    borderRadius: 5,
  },
  joinGroupNumber: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderColor: 'black',
    borderRadius: 5,
    borderWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginHorizontal: 8,
  },
  joinWechatGroupButton: {
    backgroundColor: '#66BB6A',
    borderColor: '#66BB6A',
    borderRadius: 5,
    borderWidth: 1,
    flex: 1,
    height: '100%',
    justifyContent: 'center',
    marginHorizontal: 6,
    paddingHorizontal: 11,
  },
  joinWechatGroupButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  keyboardAvoid: {
    flex: 1,
    justifyContent: 'flex-start',
  },
  number: {
    flex: 1,
    fontSize: 16,
    paddingHorizontal: 20,
    textAlign: 'center',
  },
  purchaseButton: {
    alignSelf: 'center',
    borderColor: Colors.mainAppColor,
    borderRadius: 8,
    borderWidth: 1,
    flex: 1,
    justifyContent: 'center',
    marginHorizontal: 6,
    paddingVertical: 7,
    width: '100%',
  },
  purchaseButtonText: {
    color: '#66BB6A',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  rowContainerWithShadow: {
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: 8,
    elevation: 5,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginVertical: 10,
    paddingHorizontal: 20,
    shadowColor: '#000',
    shadowOffset: {
      height: 4,
      width: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 3.84,
  },
  separator: {
    height: 1,
    marginVertical: 30,
    width: '80%',
  },
  teamAvatarImage: {
    borderRadius: 22.5 / 2,
    height: 22.5,
    resizeMode: 'center',
    width: 22.5,
  },
  teamCreatorName: {
    padding: 10,
    width: '20%',
  },
  teamPeerText: {
    marginHorizontal: 15,
  },
  teamRow: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  teamUpButton: {
    borderRadius: 8,
    overflow: 'hidden',
  },
  teamUpText: {
    color: 'white',
    fontSize: 14,
    fontWeight: 'bold',
    paddingHorizontal: 8,
    paddingVertical: 5,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginVertical: 8,
    textAlign: 'left',
  },
  tourDetailText: {},
  tourDetailTextContainer: {
    backgroundColor: 'rgb(249,249,249)',
    borderRadius: 8,
    padding: 10,
    width: WINDOW_WIDTH - 20,
  },
});
