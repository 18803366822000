import {Dimensions, PixelRatio} from 'react-native';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;

export default {
  fontScale: PixelRatio.getFontScale(),
  groupCardBorderRadius: 8,
  groupCardMarginVertical: 10,
  isSmallDevice: width < 375,
  unit: width / 50,
  window: {
    height,
    width,
  },
};
