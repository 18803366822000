import {Feather, Ionicons, MaterialCommunityIcons} from '@expo/vector-icons';
import {doc, getDoc, onSnapshot, setDoc, updateDoc} from 'firebase/firestore';
import React, {useCallback, useEffect, useLayoutEffect, useState} from 'react';
import {
  Clipboard,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import {
  Bubble,
  GiftedChat,
  IMessage,
  Send,
  User,
} from 'react-native-gifted-chat';

import {
  defaultBotUser,
  testBotUser,
} from '../../../functions/src/models/AiChatModels';
import Colors from '../../constants/Colors';
import {
  AI_ACCOUNT,
  AI_CHAT_ENDPOINT,
  USE_PRODUCTION_BUILD,
} from '../../constants/config';
import {useAuthContext} from '../../context/AuthContext';
import {useFeaturesContext} from '../../context/FeaturesContext';
import {getFirestore} from '../../firebase';
import {defaultAvatar} from '../../helpers/util';
import LoadingScreen from '../../screens/LoadingScreen';

export default function AiChat({navigation}: {navigation: any; route: any}) {
  const {features} = useFeaturesContext();
  const [messages, setMessages] = useState<IMessage[]>([]);
  const currentUser = useAuthContext().currentUser;
  const [loading, setLoading] = useState(true);
  const aiChatId = 'yaytour_' + currentUser?.id;
  const [generatingAIResponse, setGeneratingAIResponse] = useState(false);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerBackVisible: false,
      headerLeft: () => (
        <Pressable
          onPress={() => {
            navigation.navigate('ChatTab', {screen: 'ChatHome'});
            navigation.navigate('ChatHome');
          }}>
          <Ionicons
            color="black"
            name="arrow-back"
            size={24}
            style={{
              marginLeft: Platform.OS === 'web' ? 16 : 2,
              marginRight: 10,
              marginVertical: 5,
            }}
          />
        </Pressable>
      ),
      headerRight: () => (
        <Pressable onPress={() => {}}>
          <MaterialCommunityIcons
            color="black"
            name="dots-horizontal"
            size={24}
            style={{
              marginLeft: 10,
              marginRight: Platform.OS === 'web' ? 16 : 2,
              marginVertical: 5,
            }}
          />
        </Pressable>
      ),
      headerTitle: () => (
        <TouchableOpacity onPress={() => {}}>
          <Text style={{fontSize: 18, fontWeight: 'bold'}}>
            {'AI 活动推荐助手'}
          </Text>
        </TouchableOpacity>
      ),
    });
  }, [navigation]);

  useEffect(() => {
    return () => {
      const fetchData = async () => {
        if (aiChatId === 'AI_undefined') {
          return;
        }

        const currentRef = doc(getFirestore(), 'aiChatMessages/' + aiChatId);
        const currentSnapshot = await getDoc(currentRef);

        const currentChat = currentSnapshot.data();

        const historyMap =
          currentChat && currentChat.history
            ? new Map(Object.entries(currentChat.history))
            : new Map();

        const exitDate = new Date();
        console.log('Page exit time:', exitDate);
        historyMap.set(currentUser?.id, exitDate);
        const newObj = Object.fromEntries(historyMap);

        await setDoc(
          doc(getFirestore(), 'aiChatMessages/' + aiChatId),
          {
            history: newObj,
          },
          {merge: true},
        );
      };
      fetchData();
    };
  }, []); // Empty array and return() function ensures the effect runs only once on unmount

  useLayoutEffect(() => {
    const aiChatDocRef = doc(getFirestore(), 'aiChatMessages', aiChatId);
    const unsubscribe = onSnapshot(aiChatDocRef, (snapshot) => {
      if (snapshot.data()?.messages === undefined) {
        setLoading(false);
        return;
      }

      setMessages(
        snapshot
          .data()
          ?.messages.sort((a: any, b: any) => {
            return b.createdAt.toDate() - a.createdAt.toDate();
          })
          .map((d) => ({
            _id: d._id,
            createdAt: d.createdAt.toDate(),
            // system: d.messageType === "ai",
            text: d.text,
            user:
              d.messageType === 'ai'
                ? USE_PRODUCTION_BUILD
                  ? defaultBotUser
                  : testBotUser
                : {
                    _id: currentUser?.id ?? '',
                    avatar: currentUser?.profileImageUrl ?? defaultAvatar,
                    name: currentUser?.name,
                  },
          })),
      );
      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const onSend = useCallback(
    async (msgs = []) => {
      setMessages((previousMessages) =>
        GiftedChat.append(previousMessages, msgs),
      );
      setGeneratingAIResponse(true);

      const {text, user} = msgs[0];

      try {
        let aiChatEndpoint = `${AI_CHAT_ENDPOINT}?user_id=${aiChatId}&message=${text}`;
        console.log('liangzhen debug ' + aiChatEndpoint);
        if (features?.enable_ai_chat_summary_memory) {
          aiChatEndpoint = aiChatEndpoint.concat('&use_chat_summary=1');
        }

        const response = await fetch(aiChatEndpoint);
        console.log(await response.text());
      } catch (e) {
        console.log('well, trying failed');
        console.error(e);
      }

      setGeneratingAIResponse(false);
    },
    [aiChatId],
  );

  const onLongPress = (context: any, currentMessage: any) => {
    if (currentMessage.text) {
      const options = ['复制文字', '取消', '删除'];
      const cancelButtonIndex = 1;
      context.actionSheet().showActionSheetWithOptions(
        {
          cancelButtonIndex,
          options,
        },
        async (buttonIndex: number) => {
          switch (buttonIndex) {
            case 0:
              Clipboard.setString(currentMessage.text);
              break;
            case 2:
              var updatedMessages = [];
              var convertedMessages = [];
              for (var i = 0; i < messages.length; i++) {
                var m = messages[i];
                if (m._id !== currentMessage._id) {
                  const convertedMessage = {
                    _id: m._id,
                    createdAt: m.createdAt,
                    messageType: m.user._id == currentUser?.id ? 'human' : 'ai',
                    text: m.text,
                  };
                  updatedMessages.push(m);
                  convertedMessages.push(convertedMessage);
                }
              }
              setMessages(updatedMessages);
              const currentRef = doc(
                getFirestore(),
                'aiChatMessages/' + aiChatId,
              );
              await updateDoc(currentRef, {messages: convertedMessages});
              break;
          }
        },
      );
    }
  };

  const renderUsername = (user: User) => {
    return (
      <View style={{flexDirection: 'row'}}>
        <Text style={styles.userNameText}>~{user.name}</Text>
      </View>
    );
  };

  if (loading) {
    return <LoadingScreen />;
  } else {
    return (
      <View style={{flex: 1}}>
        <View style={{flex: 1}}>
          <GiftedChat
            isTyping={generatingAIResponse}
            messages={messages}
            onLongPress={onLongPress}
            onPressAvatar={(user) => {
              navigation.navigate('AccountTab', {
                params: {fromTab: 'Chat', userId: AI_ACCOUNT},
                screen: 'OtherAccountView',
              });
            }}
            onSend={(msg) => onSend(msg)}
            placeholder={'和AI助手聊天吧'}
            renderBubble={(props) => {
              return (
                <Bubble
                  {...props}
                  wrapperStyle={{
                    left: {
                      maxWidth: '95%',
                    },
                    right: {
                      backgroundColor: Colors.mainAppColor,
                    },
                  }}
                />
              );
            }}
            renderSend={(props) => {
              return (
                <Send {...props}>
                  <View
                    style={{
                      paddingHorizontal: 12,
                      paddingVertical: 5,
                    }}>
                    <Feather
                      color={Colors.mainAppColor}
                      name="send"
                      size={24}
                    />
                  </View>
                </Send>
              );
            }}
            renderUsername={renderUsername}
            renderUsernameOnMessage={true}
            showAvatarForEveryMessage={true}
            user={{
              _id: currentUser?.id ?? '',
              avatar: currentUser?.profileImageUrl ?? defaultAvatar,
              name: currentUser?.userName,
            }}
          />
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  headerContainer: {
    backgroundColor: 'yellow',
    paddingHorizontal: 10,
    paddingVertical: 8,
  },
  headerText: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  userNameInfo: {
    color: 'grey',
    fontSize: 12,
    paddingHorizontal: 4,
  },
  userNameText: {
    color: 'grey',
    fontSize: 12,
    fontStyle: 'italic',
    paddingLeft: 10,
    paddingRight: 4,
  },
});
