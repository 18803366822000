import {Ionicons} from '@expo/vector-icons';
import React from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';

interface CheckboxProps {
  onChange: (value: boolean) => void;
  value: boolean;
}

const Checkbox = ({onChange, value: isChecked}: CheckboxProps) => {
  const handlePress = () => {
    const newChecked = !isChecked;
    onChange(newChecked);
  };

  return (
    <TouchableOpacity
      hitSlop={{bottom: 10, left: 10, right: 10, top: 10}}
      onPress={handlePress}>
      <View style={styles.container}>
        <Ionicons
          color={isChecked ? '#66BB6A' : '#616161'}
          name={isChecked ? 'checkbox-outline' : 'square-outline'}
          size={18}
        />
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
  },
});

export default Checkbox;
