export const SYSTEM_PROMPT_MESSAGE =
  "你现在是野兔组队的AI聊天助手，野兔组队是一个北美华人组队工具，会玩爱玩的年轻人都在这里，野兔组队上有许多有关运动/户外/旅游/城市活动/文化艺术/桌游唱k的活动。用户会向你询问一些活动、旅行相关的话题，你需要按照用户的需求来回答。你的语气不需要很正式，回答不需要很繁琐。你需要像朋友一样和用户聊天。You're now an AI chat assistant for Yaytour. Yaytour is a social platform where people can meet new friends through various activities like sports/travel/arts and culture/board games and karaoke. Users will ask you questions about these activities and travel, and you will need to answer according to the user's needs. Your answer should not be too formal or complicated: just chat with users like you're their friends.";

export const AI_CHAT_ENDPOINT = process.env.CHAT_API_ENDPOINT;

export type GiftedChatUser = {
  _id: string;
  avatar: string;
  name: string;
};

export const defaultBotUser: GiftedChatUser = {
  _id: '5i3mwbfQIVa0Dnv5FcG4kvUJkDz1',
  avatar:
    'https://firebasestorage.googleapis.com/v0/b/yaytour-prod.appspot.com/o/AI%20images%2FAI_avatar_thumbnail.png?alt=media&token=4564b09c-e4f1-4679-ab1c-a3e334ebda5b',
  name: 'AI 社交闺蜜兔兔',
};

export const testBotUser: GiftedChatUser = {
  _id: '5i3mwbfQIVa0Dnv5FcG4kvUJkDz1',
  avatar:
    'https://firebasestorage.googleapis.com/v0/b/rabbit-tour.appspot.com/o/AI%20images%2FAI_avatar_thumbnail.png?alt=media&token=9d837ef7-9fb5-4c25-8dc6-22da7a461f80',
  name: 'AI 社交闺蜜兔兔',
};

export type AiChatRequest = {
  ai_chat_id: string;
  created_at: Date;
  new_message: string;
  session_id: string;
  user: GiftedChatUser;
};

export type AiChatResponse = {
  // response: OpenAI.Chat.Completions.ChatCompletion.Choice[];
  response: string;
};
