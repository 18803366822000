import {Image, StyleSheet, Text, View} from 'react-native';

import PrimaryButton from '../components/buttons/PrimaryButton';

export default function LoginCompleteScreen({navigation}: {navigation: any}) {
  return (
    <View style={styles.container}>
      <Image
        source={require('../assets/images/yay_logo.png')}
        style={styles.image}
      />
      <Text style={styles.text}>您已经成功加入野兔组队</Text>
      <PrimaryButton
        disabled={false}
        onPress={() => {
          navigation.navigate('Root', {
            screen: 'AccountTab',
          });
        }}
        text="完成"
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: 'white',
    flex: 1,
    padding: 16,
    paddingTop: 80,
  },
  image: {
    height: 200,
    width: 200,
  },
  text: {
    fontSize: 32,
    fontWeight: '700',
    lineHeight: 36,
    marginBottom: 32,
    textAlign: 'center',
    width: 224,
  },
});
