import {doc, updateDoc, writeBatch} from 'firebase/firestore';
import React, {useState} from 'react';
import {useDocument} from 'react-firebase-hooks/firestore';
import {StyleSheet, Text, TouchableOpacity, View} from 'react-native';

import alert from '../../components/container/alert';
import Colors from '../../constants/Colors';
import {useAuthContext} from '../../context/AuthContext';
import {getFirestore} from '../../firebase';
import userConverter from '../../firestoreConverters/userConverter';

export default function AccountFollowButton({
  initialFollowState,
  profileUserId,
}: {
  initialFollowState: any;
  profileUserId: any;
}) {
  const currentUserId = useAuthContext().currentUser?.id ?? '';
  const [currentUserSnapshot] = useDocument(
    doc(getFirestore(), 'users', currentUserId).withConverter(userConverter),
  );
  const currentUser = currentUserSnapshot?.data();
  const followings = currentUser?.followingList;

  const [profileUserSnapshot] = useDocument(
    doc(getFirestore(), 'users', profileUserId).withConverter(userConverter),
  );
  const profileUser = profileUserSnapshot?.data();
  const followers = profileUser?.followerList;

  const [isFollowing, setIsFollowing] = useState(initialFollowState);

  const handleFollowPress = () => {
    let updatedFollowerList: string[] = [];
    let updatedFollowingList: string[] = [];
    if (
      isFollowing &&
      followers != null &&
      followers != undefined &&
      followings != null &&
      followings != undefined
    ) {
      // Do unfollow action
      updatedFollowerList = followers.filter(function (person) {
        return person != currentUserId;
      });
      updatedFollowingList = followings.filter(function (person) {
        return person != profileUserId;
      });
    } else if (!isFollowing) {
      // Do follow action
      updatedFollowerList = followers?.slice() ?? []; // Create a copy
      updatedFollowerList.push(currentUserId); // Push the object
      updatedFollowingList = followings?.slice() ?? [];
      updatedFollowingList.push(profileUserId);
    }

    const db = getFirestore();
    const batch = writeBatch(db);
    if (profileUserSnapshot != undefined) {
      try {
        updateDoc(profileUserSnapshot.ref, {
          followerList: updatedFollowerList,
        });
      } catch (error) {
        alert('发送错误', '关注用户时发生错误: ' + error, [
          {onPress: () => {}, text: '确定'},
        ]);
      }
    }
    if (currentUserSnapshot != undefined) {
      try {
        updateDoc(currentUserSnapshot.ref, {
          followingList: updatedFollowingList,
        });
      } catch (error) {
        alert('发送错误', '关注用户时发生错误: ' + error, [
          {onPress: () => {}, text: '确定'},
        ]);
      }
    }
    batch.commit();
    setIsFollowing(!isFollowing);
  };

  return (
    <View>
      <TouchableOpacity
        onPress={handleFollowPress}
        style={[
          styles.button,
          {backgroundColor: isFollowing ? 'transparent' : Colors.mainAppColor},
        ]}>
        <Text
          style={[
            styles.text,
            {color: isFollowing ? Colors.mainAppColor : 'white'},
          ]}>
          {isFollowing ? '取消关注' : '关注'}
        </Text>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  button: {
    alignSelf: 'stretch',
    borderColor: Colors.mainAppColor,
    borderRadius: 8,
    borderWidth: 1,
    paddingHorizontal: 16,
    paddingVertical: 6,
  },
  text: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
});
