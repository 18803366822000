import {
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from 'firebase/firestore';

import {DiscountCode} from '../types';

export default {
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): DiscountCode {
    const data = snapshot.data(options);
    return {
      code: data.code,
      discountAmount: data.discountAmount,
      discountType: data.discountType,
      expiresAt: data.expiresAt,
      genderRequirment: data.genderRequirment ?? 'both',
      isActive: data.isActive ?? true,
      isSingleUsePerUser: data.isSingleUsePerUser ?? false,
      limitedGroupIds: data.limitedGroupIds,
      limitedUserNumbers: data.limitedUserNumbers,
      maxUsageCount: data.maxUsageCount,
      minimumOrderAmount: data.minimumOrderAmount,
      minimumOrderCount: data.minimumOrderCount,
      usedByUsers: data.usedByUsers,
    };
  },
  toFirestore(discountCode: WithFieldValue<DiscountCode>): DocumentData {
    return {...discountCode};
  },
};
