import {useStripe} from '@stripe/stripe-react-native';
import React, {useCallback, useEffect, useState} from 'react';
import {StyleSheet, Text, TouchableOpacity} from 'react-native';

import alert from '../container/alert';

export default function MobileStripeButton({
  clientSecret,
  customerEphemeralKey,
  stripeCustomerID,
  addUsersToPaidMembers,
}: {
  addUsersToPaidMembers: any;
  clientSecret: string;
  customerEphemeralKey: string;
  stripeCustomerID: string;
}) {
  const {initPaymentSheet, presentPaymentSheet} = useStripe();
  const [loading, setLoading] = useState(false);

  const initializePaymentSheet = useCallback(async () => {
    const {error} = await initPaymentSheet({
      // don't allow deplayed payment methods
      allowsDelayedPaymentMethods: false,

      applePay: {
        merchantCountryCode: 'US',
      },

      // customerEphemeralKeySecret is used for saving payment method
      customerEphemeralKeySecret: customerEphemeralKey,

      customerId: stripeCustomerID,

      defaultBillingDetails: {
        name: 'Fada Chen',
      },
      merchantDisplayName: 'Yaytour, Inc.',
      paymentIntentClientSecret: clientSecret,
    });
    if (!error) {
      setLoading(true);
    }
  }, [clientSecret, customerEphemeralKey, initPaymentSheet, stripeCustomerID]);

  const openPaymentSheet = async () => {
    const {error} = await presentPaymentSheet();
    if (error) {
      alert(
        '支付遇到问题, 请稍后支付',
        `Error code: ${error.code}\nError message: ${error.message}`,
      );
    } else {
      addUsersToPaidMembers();
    }
  };

  useEffect(() => {
    initializePaymentSheet();
  }, [initializePaymentSheet]);

  return (
    <TouchableOpacity
      disabled={!loading}
      onPress={openPaymentSheet}
      style={[styles.payNowButton]}>
      <Text style={styles.payNowButtonText}>立即支付</Text>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  payNowButton: {
    alignContent: 'center',
    backgroundColor: '#66BB6A',
    borderColor: '#66BB6A',
    borderRadius: 5,
    borderWidth: 1,
    flex: 1,
    justifyContent: 'center',
    margin: 8,
    padding: 8,
    width: '100%',
  },
  payNowButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
});
