import React from 'react';
import {Image, View} from 'react-native';

export default function OpenInDefaultBrowserScreen() {
  return (
    <View
      style={{
        backgroundColor: 'white',
        flexGrow: 1,
      }}>
      <Image
        source={require('../../assets/images/open_in_default_browser.png')}
        style={{
          flex: 1,
          resizeMode: 'contain',
        }}
      />
      <View style={{flexGrow: 1}} />
    </View>
  );
}
