/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import {LinkingOptions} from '@react-navigation/native';
import * as Linking from 'expo-linking';

import {RootStackParamList} from '../types';

const linking: LinkingOptions<RootStackParamList> = {
  config: {
    screens: {
      CampaignDetail: 'campaign',
      LandingPage: 'landing',
      LoginModal: {
        screens: {
          Input: 'input/:referCode?',
        },
      },
      Modal: 'modal',
      NotFound: '*',
      PrivacyPolicy: 'privacypolicy',
      ResponsibilityTerms: 'responsibility',
      Root: {
        screens: {
          AccountTab: {
            screens: {
              AccountHome: 'account',
            },
          },
          ChatTab: {
            screens: {
              ChatHome: 'chats',
              ChatScreen: 'chat/:groupId',
            },
          },
          GroupTab: {
            screens: {
              // TODO(chenfada) Add GroupCreatorView when auth is fixed.
              GroupDetails: 'group/:groupId',
              GroupHome: 'groups',
            },
          },
          TourTab: {
            screens: {
              TourDetails: 'tour',
              TourHome: 'tours',
            },
          },
        },
      },
      UserTerms: 'userterms',
    },
  },
  prefixes: [Linking.createURL('/'), 'rabbit://'],
};

export default linking;
