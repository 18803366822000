import React from 'react';
import {View} from 'react-native';

type SpacerProps = {
  horizontal: boolean;
  size: number | string;
};

const Spacer = ({size, horizontal}: SpacerProps) => {
  const defaultValue = 'auto';

  const height = !horizontal ? size : defaultValue;
  const width = horizontal ? size : defaultValue;

  return (
    <View
      style={{
        height: height,
        width: width,
      }}
    />
  );
};

Spacer.defaultProps = {
  horizontal: false,
};

export default Spacer;
