import {Ionicons} from '@expo/vector-icons';
import {useNavigation} from '@react-navigation/native';
import React, {useEffect, useState} from 'react';
import {
  Dimensions,
  Image,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import Layout from '../constants/Layout';
import FastImage from '../platforms/FastImage';

const screenHeight = Dimensions.get('window').height;
const screenWidth = Dimensions.get('window').width;

const CampaignDetailScreen = ({
  navigation,
  route,
}: {
  navigation: any;
  route: any;
}) => {
  const {content} = route.params;
  const [imageRatio, setImageRatio] = useState(1);

  const handleOpenLink = async (url: string) => {
    try {
      const screen = url.includes('accountTab') && 'AccountHome';
      navigation.navigate(url, {screen: screen});
    } catch {
      alert('oh no！网址有误');
    }
  };

  useEffect(() => {
    if (content.compaignImage) {
      Image.getSize(content.compaignImage, (width, height) => {
        const ratio = height / width;
        setImageRatio(ratio);
      });
    }
  }, [content.compaignImage]);

  return (
    <View style={styles.container}>
      <ScrollView>
        <FastImage
          resizeMode="stretch"
          source={{uri: content.compaignImage}}
          style={{
            height: screenWidth * imageRatio,
            width: screenWidth,
          }}
        />
      </ScrollView>
      <TouchableOpacity
        onPress={() => handleOpenLink(content.associatedURL)}
        style={styles.button}>
        <Text style={styles.buttonText}>{content.buttonText}</Text>
      </TouchableOpacity>
    </View>
  );
};

export default CampaignDetailScreen;

const styles = StyleSheet.create({
  button: {
    alignSelf: 'center',
    backgroundColor: '#66BB6A',
    borderRadius: 8,
    bottom: 25,
    paddingVertical: 10,
    position: 'absolute',
    width: screenWidth - Layout.unit * 2,
  },
  buttonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  closeButton: {
    alignItems: 'center',
    height: 40,
    justifyContent: 'center',
    position: 'absolute',
    right: 20,
    top: 20,
    width: 40,
  },
  container: {
    alignItems: 'center',
    backgroundColor: 'white',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
});
