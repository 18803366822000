import {Ionicons} from '@expo/vector-icons';
import {StyleSheet, TouchableOpacity, View} from 'react-native';

interface StarsRatingContainerProps {
  groupId?: string;
  handleRating: (value: number, groupId?: string) => void;
  rating: number;
  starSize: number;
}

const StarsRatingContainer = ({
  groupId,
  handleRating,
  rating,
  starSize,
}: StarsRatingContainerProps) => {
  const handler = (param: number) => {
    if (groupId) {
      handleRating(param, groupId);
    } else {
      handleRating(param);
    }
  };
  return (
    <View style={styles.starContainer}>
      <TouchableOpacity onPress={() => handler(1)}>
        <Ionicons
          color="#FFD700"
          name={rating >= 1 ? 'star' : 'star-outline'}
          size={starSize}
          type="AntDesign"
        />
      </TouchableOpacity>
      <TouchableOpacity onPress={() => handler(2)}>
        <Ionicons
          color="#FFD700"
          name={rating >= 2 ? 'star' : 'star-outline'}
          size={starSize}
          type="AntDesign"
        />
      </TouchableOpacity>
      <TouchableOpacity onPress={() => handler(3)}>
        <Ionicons
          color="#FFD700"
          name={rating >= 3 ? 'star' : 'star-outline'}
          size={starSize}
          type="AntDesign"
        />
      </TouchableOpacity>
      <TouchableOpacity onPress={() => handler(4)}>
        <Ionicons
          color="#FFD700"
          name={rating >= 4 ? 'star' : 'star-outline'}
          size={starSize}
          type="AntDesign"
        />
      </TouchableOpacity>
      <TouchableOpacity onPress={() => handler(5)}>
        <Ionicons
          color="#FFD700"
          name={rating >= 5 ? 'star' : 'star-outline'}
          size={starSize}
          type="AntDesign"
        />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  starContainer: {
    alignItems: 'center',
    alignSelf: 'center',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginVertical: 10,
  },
});

export default StarsRatingContainer;
