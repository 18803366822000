import {doc} from 'firebase/firestore';
import React from 'react';
import {useDocument} from 'react-firebase-hooks/firestore';
import {StyleSheet, Text, TouchableOpacity, View} from 'react-native';

import AccountFollowButton from '../../components/account/AccountFollowButton';
import {useAuthContext} from '../../context/AuthContext';
import {getFirestore} from '../../firebase';
import userConverter from '../../firestoreConverters/userConverter';

export default function AccountFollowSection({
  navigation,
  profileUserId,
}: {
  navigation: any;
  profileUserId: any;
}) {
  const currentUserId = useAuthContext().currentUser?.id ?? '';

  if (profileUserId == null || profileUserId == undefined) {
    profileUserId = currentUserId;
  }

  const [userSnapshot] = useDocument(
    doc(getFirestore(), 'users', profileUserId).withConverter(userConverter),
  );

  const user = userSnapshot?.data();

  const followings = user?.followingList;
  const followers = user?.followerList;

  if (user == undefined) {
    return null;
  }

  const initialFollowState = followers?.some(
    (follower) => follower == currentUserId,
  );

  const handleTapToOpenList = (peopleList: string[] | null | undefined) => {
    if (
      peopleList == null ||
      peopleList == undefined ||
      peopleList.length == 0
    ) {
      return;
    }
    navigation.push('PeopleListScreen', {
      allowDuplicate: false,
      paymentMap: null,
      peopleList: peopleList,
      showPayment: false,
    });
  };

  return (
    <View style={{marginRight: 16, marginVertical: 5}}>
      <View style={{flexDirection: 'row', marginBottom: 10}}>
        <View style={{alignItems: 'center', flex: 1}}>
          <TouchableOpacity onPress={() => handleTapToOpenList(followings)}>
            <Text style={(styles.text, {textAlign: 'center'})}>
              {followings ? followings.length : 0}
            </Text>
            <Text style={styles.text}>关注</Text>
          </TouchableOpacity>
        </View>
        <View style={{alignItems: 'center', flex: 1}}>
          <TouchableOpacity onPress={() => handleTapToOpenList(followers)}>
            <Text style={(styles.text, {textAlign: 'center'})}>
              {followers ? followers.length : 0}
            </Text>
            <Text style={styles.text}>粉丝</Text>
          </TouchableOpacity>
        </View>
      </View>
      {profileUserId != currentUserId ? (
        <AccountFollowButton
          initialFollowState={initialFollowState}
          profileUserId={profileUserId}
        />
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  text: {
    fontSize: 14,
    fontWeight: 'normal',
    textAlign: 'center',
  },
});
