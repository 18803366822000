import {Ionicons} from '@expo/vector-icons';
import {useNavigation} from '@react-navigation/native';
import {doc, getDoc, setDoc, Timestamp, updateDoc} from 'firebase/firestore';
import {useEffect, useLayoutEffect, useState} from 'react';
import {
  Linking,
  Pressable,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';

import alert from '../components/container/alert';
import StarsRatingContainer from '../components/container/StarsRatingContainer';
import {USE_PRODUCTION_BUILD} from '../constants/config';
import {useAuthContext} from '../context/AuthContext';
import {getFirestore} from '../firebase';
import {eligibleForTip} from '../helpers/util';

const length = '96%';
const padding = '2%';
const starSize = 36;

export default function GroupFeedbackScreen({route}: {route: any}) {
  const navigation: any = useNavigation();
  const {group} = route.params;
  const {currentUser} = useAuthContext();

  const db = getFirestore();

  type feedbackState = {
    rating: number;
    review: string;
  };
  const [feedback, setFeedback] = useState<feedbackState>({
    rating: 0,
    review: '',
  });

  const handleRating = (value: number) => {
    setFeedback({...feedback, rating: value});
  };

  const handleReview = (text: string) => {
    setFeedback({...feedback, review: text});
  };

  const handleSubmit = async () => {
    try {
      const ratingRef = doc(db, 'ratings', group?.creator?.id);
      const ratingSnap = await getDoc(ratingRef);

      const newData = {
        groupId: group?.id,
        userId: currentUser?.id,
        userName: currentUser?.userName,
        userProfileImageUrl: currentUser?.profileImageUrl,
        rating: feedback.rating,
        review: feedback.review,
        created: Timestamp.now(),
      };

      // check if the creator id exists in rating collection
      if (ratingSnap.exists()) {
        const currentData = ratingSnap.data();

        const feedbackIndex = currentData.feedback.findIndex(
          (entry: any) =>
            entry.groupId === group?.id && entry.userId === currentUser?.id,
        );

        // check if the feedback already exists under the creator id
        if (feedbackIndex !== -1) {
          currentData.feedback[feedbackIndex] = newData;
        } else {
          currentData.feedback.push(newData);
        }

        // Calculate the average rating
        const nonZeroRatings = currentData.feedback.filter(
          (entry: any) => entry.rating !== 0,
        );

        const totalRatingSum = nonZeroRatings.reduce(
          (sum: number, entry: any) => sum + entry.rating,
          0,
        );

        const newAverageRating =
          nonZeroRatings.length > 0
            ? totalRatingSum / nonZeroRatings.length
            : 0;

        await updateDoc(ratingRef, {
          averageRating: newAverageRating,
          feedback: currentData.feedback,
        });
      } else {
        // Group ratings dont exist yet
        await setDoc(ratingRef, {
          userId: group?.creator?.id,
          averageRating: feedback.rating || 0,
          feedback: [newData],
        });
      }
    } catch (error) {
      console.log('update rating ', error);
      if (!USE_PRODUCTION_BUILD) {
        alert('评分出错: ' + error);
      }
    }
    navigation.navigate('AccountTab', {screen: 'AccountHome'});
    navigation.navigate('GroupHome');
    alert(
      '提交反馈成功',
      '您可以随时重新提交修改您的反馈',
      [{onPress: () => {}, text: '确定'}],
      {cancelable: false},
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ratingRef = doc(db, 'ratings', group?.creator?.id);
        const ratingSnap = await getDoc(ratingRef);

        if (!ratingSnap.exists()) return;

        const currentData = ratingSnap.data();

        const existingFeedback = currentData.feedback.find(
          (entry: any) =>
            entry.groupId === group?.id && entry.userId === currentUser?.id,
        );
        if (existingFeedback) {
          setFeedback({
            rating: existingFeedback.rating,
            review: existingFeedback.review,
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerBackVisible: false,
      headerRight: () => (
        <Pressable
          onPress={() => {
            navigation.goBack();
          }}>
          <Ionicons color="black" name="close" size={24} />
        </Pressable>
      ),
      title: '提交反馈',
    });
  }, [navigation]);

  const openVenmoLink = async (url: any) => {
    // Check if the Venmo app is installed on the device
    const isVenmoInstalled = await Linking.canOpenURL(url);

    if (isVenmoInstalled) {
      // If the Venmo app is installed, open the Venmo link
      await Linking.openURL(url);
    } else {
      // If the Venmo app is not installed, open the Venmo website in a browser
      await Linking.openURL('https://venmo.com/');
    }
  };

  const venmoAccountId = eligibleForTip(group) ? group.tips[0] : '';
  const venmoDefaultAmount =
    eligibleForTip(group) && group?.tips.length >= 2 && group.tips[1]
      ? group.tips[1]
      : '5';
  const groupPromotionText =
    eligibleForTip(group) && group?.tips.length >= 3 && group.tips[2]
      ? group.tips[2]
      : '此次活动完全免费，不收取服务费，但是您可以选择为我们买一杯咖啡。小费金额可编辑。';

  const venmoNote = 'yaytour event, wechatid:' + currentUser?.socialWechat;
  const venmoUrl = encodeURI(
    'https://venmo.com/' +
      venmoAccountId +
      '?txn=pay&note=' +
      venmoNote +
      '&amount=' +
      venmoDefaultAmount,
  );
  return (
    <View style={styles.container}>
      {eligibleForTip(group) && (
        <View style={{width: '100%'}}>
          <View style={styles.titleView}>
            <Text style={styles.title}>给组织者添加小费</Text>
          </View>
          <Text style={styles.text}>{groupPromotionText}</Text>
          <View style={styles.venmoContainer}>
            <Text style={styles.venmoAmount}>
              建议金额 ${venmoDefaultAmount}
            </Text>
            <TouchableOpacity
              onPress={() => {
                // Call the function with the Venmo link you want to open
                openVenmoLink(venmoUrl);
              }}
              style={styles.venmoButton}>
              <Text style={styles.shareButtonText}>打开 Venmo</Text>
            </TouchableOpacity>
          </View>
          <View style={{height: 30}} />
        </View>
      )}

      <View style={styles.titleView}>
        <Text style={styles.title}>给活动评分</Text>
      </View>
      <StarsRatingContainer
        handleRating={handleRating}
        rating={feedback.rating}
        starSize={starSize}
      />
      <TextInput
        multiline={true}
        numberOfLines={4}
        onChangeText={handleReview}
        placeholder="您可以提供任何评价~"
        placeholderTextColor="gray"
        style={styles.reviewInput}
        value={feedback.review}
      />
      <View style={{flexGrow: 1, height: 30}} />
      <TouchableOpacity onPress={handleSubmit} style={styles.shareButton}>
        <Text style={styles.shareButtonText}>提交</Text>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: 'white',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    paddingHorizontal: padding,
    paddingTop: 20,
  },
  reviewInput: {
    borderColor: 'lightgray',
    borderRadius: 20,
    borderWidth: 0.5,
    height: 150,
    marginVertical: 10,
    padding: 15,
    width: 300,
    textAlignVertical: 'top',
  },
  separator: {
    alignSelf: 'center',
    backgroundColor: '#c0c0c0',
    height: 1,
    marginVertical: 20,
    width: length,
  },
  shareButton: {
    alignSelf: 'center',
    backgroundColor: '#66BB6A',
    borderRadius: 5,
    bottom: 30,
    marginTop: 40,
    paddingVertical: 8,
    width: length,
  },
  shareButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  text: {
    fontSize: 18,
    paddingHorizontal: padding,
    paddingVertical: 8,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  titleView: {
    alignItems: 'center',
    alignSelf: 'center',
    flexDirection: 'row',
  },
  venmoAmount: {
    flex: 1,
    fontSize: 18,
  },
  venmoButton: {
    alignSelf: 'center',
    backgroundColor: '#66BB6A',
    borderRadius: 5,
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  venmoContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: padding,
    width: length,
  },
  venmoTitle: {
    fontSize: 18,
  },
});
