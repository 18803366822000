import {Entypo} from '@expo/vector-icons';
import {useNavigation} from '@react-navigation/native';
import {doc} from 'firebase/firestore';
import React from 'react';
import {useDocument} from 'react-firebase-hooks/firestore';
import {Image, StyleSheet, Text, TouchableOpacity, View} from 'react-native';

import Colors from '../../constants/Colors';
import {useFeaturesContext} from '../../context/FeaturesContext';
import {getFirestore} from '../../firebase';
import userConverter from '../../firestoreConverters/userConverter';
import {profileImagePreview} from '../../helpers/util';

export default function PeopleListCell({
  checkinStatus,
  creatorID,
  discountCode,
  handleDelete,
  isUserCreator,
  allowUserRemoveParticipant,
  isUserOfficialAccount,
  recordPaid,
  toggleCheckIn,
  unique,
  userId,
  paymentId,
  paidTicketOption,
}: {
  allowUserRemoveParticipant: boolean;
  checkinStatus: boolean;
  creatorID: string;
  discountCode: string;
  handleDelete: Function;
  isUserCreator: boolean;
  isUserOfficialAccount: boolean;
  paidTicketOption: any[];
  paymentId: string;
  recordPaid: boolean;
  toggleCheckIn: Function;
  unique: boolean;
  userId: any;
}) {
  const {features} = useFeaturesContext();

  const [userSnapshot] = useDocument(
    doc(getFirestore(), 'users', userId).withConverter(userConverter),
  );
  const user = userSnapshot?.data();
  const navigation: any = useNavigation();

  if (user == null || user == undefined) {
    return null;
  }

  const userName = user?.userName;
  const userText = unique ? userName : userName + ' 代报名';
  const gender = user?.gender;

  const handleTapToOpenProfile = (tappedUserId: string) => {
    navigation.push('OtherAccountView', {
      userId: tappedUserId,
    });
  };

  return (
    <TouchableOpacity
      // Disable click when 1. the group creator wants to click in themselves, 2.or the person is not unique
      disabled={isUserCreator ? !unique || creatorID === userId : false}
      onPress={() => {
        if (isUserCreator) {
          if (unique && creatorID != userId) {
            navigation.push('PeopleListDetailScreen', {
              allowUserRemoveParticipant: allowUserRemoveParticipant,
              checkinStatus: checkinStatus,
              discountCode: discountCode,
              handleDelete: handleDelete,
              paidTicketOption: paidTicketOption,
              paymentId: paymentId,
              toggleCheckIn: toggleCheckIn,
              user: user,
            });
          }
        } else {
          // If user is not group creator, navigate to user profile
          handleTapToOpenProfile(userId);
        }
      }}>
      <View style={styles.container}>
        <TouchableOpacity
          onPress={() => handleTapToOpenProfile(userId)}
          style={styles.avatarContainer}>
          <Image
            source={profileImagePreview(user)}
            style={styles.avatarImage}
          />
        </TouchableOpacity>
        <View style={styles.leftContainer}>
          <Text style={styles.userText}>{userText}</Text>
          <View
            style={{
              flexDirection: 'row',
              paddingVertical: 8,
            }}>
            {unique ? (
              gender == 'M' ? (
                <Image
                  source={require('../../assets/images/male.png')}
                  style={styles.genderImage}
                />
              ) : (
                <Image
                  source={require('../../assets/images/female.png')}
                  style={styles.genderImage}
                />
              )
            ) : (
              <View style={styles.genderImage} />
            )}
            {unique && paidTicketOption && (
              <View
                style={{
                  flex: 1,
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  marginHorizontal: 5,
                  paddingHorizontal: 2,
                }}>
                {paidTicketOption.map((option, index) => (
                  <View
                    key={index}
                    style={{
                      alignItems: 'center',
                      backgroundColor: Colors.mainAppColor,
                      borderRadius: 15,
                      flexDirection: 'row',
                      justifyContent: 'center',
                      marginRight: 2,
                      marginVertical: 2,
                      paddingHorizontal: 10,
                      paddingVertical: 1,
                    }}>
                    <Text
                      style={{
                        color: 'white',
                        fontSize: 13,
                        textAlignVertical: 'center',
                      }}>
                      {`${option.optionName} x ${option.quantity}`}
                    </Text>
                  </View>
                ))}
              </View>
            )}
          </View>
        </View>
        {recordPaid && unique && (
          <Image
            source={require('../../assets/images/dollar.png')}
            style={styles.genderImage}
          />
        )}

        {(isUserCreator || isUserOfficialAccount) && userId !== creatorID && (
          <View style={styles.buttonsContainer}>
            {/* checkin feature */}
            {features?.enable_check_in && unique && (
              <TouchableOpacity
                onPress={() => toggleCheckIn(userId)}
                style={checkinStatus ? styles.checkoutBtn : styles.checkinBtn}>
                {checkinStatus ? <Text>已签到</Text> : <Text>未签到</Text>}
              </TouchableOpacity>
            )}
            {/* delete members */}
            {features?.enable_delete_member &&
              allowUserRemoveParticipant &&
              unique && (
                <TouchableOpacity
                  onPress={() => handleDelete(userId)}
                  style={styles.deleteBtn}>
                  <Entypo color="#FFD317" name="circle-with-cross" size={24} />
                </TouchableOpacity>
              )}
          </View>
        )}
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  avatarContainer: {
    borderRadius: 31,
    elevation: 5,
    height: 62,
    marginBottom: 2,
    overflow: 'hidden',
    shadowColor: 'black',
    shadowOffset: {height: 2, width: 2},
    shadowOpacity: 0.5,
    shadowRadius: 8,
    width: 62,
  },
  avatarImage: {
    height: '100%',
    width: '100%',
  },
  avatarPlaceholder: {
    backgroundColor: 'lightgrey',
    height: '100%',
    width: '100%',
  },
  buttonsContainer: {
    alignContent: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  checkinBtn: {
    alignItems: 'center',
    backgroundColor: 'lightgrey',
    borderRadius: 31,
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: 15,
    paddingVertical: 3,
  },
  checkoutBtn: {
    alignItems: 'center',
    backgroundColor: 'lightgreen',
    borderRadius: 31,
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: 15,
    paddingVertical: 3,
  },
  container: {
    alignItems: 'center',
    backgroundColor: 'white',
    flex: 1,
    flexDirection: 'row',
    padding: 20,
  },
  deleteBtn: {
    justifyContent: 'center',
    marginHorizontal: 5,
  },
  genderImage: {
    height: 24,
    width: 24,
  },
  leftContainer: {
    flexGrow: 10,
    flexShrink: 10,
    paddingHorizontal: 10,
  },
  userText: {
    fontSize: 16,
    fontWeight: 'bold',
  },
});
