import {Ionicons} from '@expo/vector-icons';
import {useNavigation} from '@react-navigation/native';
import {
  collection,
  doc,
  getDoc,
  getFirestore,
  setDoc,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import {getFunctions, httpsCallable} from 'firebase/functions';
import React, {useLayoutEffect, useState} from 'react';
import {
  Image,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from 'react-native';

import {CreatePaymentIntentResult} from '../../functions/src';
import alert from '../components/container/alert';
import MobileStripeButton from '../components/payment/MobileStripeButton';
import WebStripeContainer from '../components/payment/WebStripeContainer';
import Colors from '../constants/Colors';
import {useAuthContext} from '../context/AuthContext';
import {useFeaturesContext} from '../context/FeaturesContext';
import discountCodeConverter from '../firestoreConverters/discountCodeConverter';
import {
  getFirstHeaderImageUrlFromGroup,
  headerImageEmpty,
} from '../helpers/imageUtil';
import {
  addJoinedMember,
  consumeDiscount,
  getAmountAfterDiscount,
  isDiscountCodeAvailable,
} from '../helpers/util';
import FastImage from '../platforms/FastImage';

const length = '96%';

export default function PaymentModalScreen({route}: {route: any}) {
  const navigation: any = useNavigation();
  const {
    group,
    numberOfPeople,
    priceWithoutFee,
    serviceFee,
    clientSecret,
    paymentIntentId,
    groupQrCodeUrl,
    stripeCustomerID,
    customerEphemeralKey,
    hasTicketOptions,
    quantities,
  } = route.params;
  const {currentUser, phoneNumber} = useAuthContext();
  const [scrollEnabled, setScrollEnabled] = useState(false);
  const [skipPayment, setSkipPayment] = useState(false);
  const {height} = useWindowDimensions();
  const {features} = useFeaturesContext();
  const [discountCode, setDiscountCode] = useState('');
  const [priceAfterDiscount, setPriceAfterDiscount] = useState<number>();
  const [serviceFeeAfterDiscount, setServiceFeeAfterDiscount] =
    useState<number>();
  const [clientSecretAfterDiscount, setClientSecretAfterDiscount] =
    useState<string>();
  const [paymentIntentIdAfterDiscount, setPaymentIntentIdAfterDiscount] =
    useState<string>();

  const TitleView = () => {
    return (
      <View style={styles.titleView}>
        <Ionicons color="#66BB6A" name="checkmark-circle-outline" size={24} />
        <Text style={styles.title}>支付活动</Text>
      </View>
    );
  };

  const applyDiscountCode = async () => {
    try {
      const codeObj = (
        await getDoc(
          doc(getFirestore(), 'discountCodes', discountCode).withConverter(
            discountCodeConverter,
          ),
        )
      ).data();
      if (!codeObj || !currentUser || !phoneNumber) {
        alert('折扣码出错', '', [
          {
            onPress: () => {},
            text: '确定',
          },
        ]);
        return;
      }

      const discountCodeResult = isDiscountCodeAvailable(
        codeObj,
        currentUser,
        group?.id,
        priceWithoutFee,
        numberOfPeople,
        phoneNumber,
      );
      if (discountCodeResult != true) {
        alert(discountCodeResult, '', [
          {
            onPress: () => {},
            text: '确定',
          },
        ]);
        return;
      }

      const totalAmountAfterDiscount =
        Math.ceil(getAmountAfterDiscount(codeObj, priceWithoutFee) * 100) / 100;
      if (totalAmountAfterDiscount < 0.5) {
        // skip stripe payment for amount < 0.5
        setSkipPayment(true);
        setServiceFeeAfterDiscount(0);
        setPriceAfterDiscount(0);
      } else {
        setSkipPayment(false);
        const requestDataAfterDiscount = {
          email: currentUser?.email,
          group_id: group.id,
          group_unit_price:
            Math.ceil((totalAmountAfterDiscount / numberOfPeople) * 100) / 100,
          number_of_people: numberOfPeople,
          phone_umber: currentUser?.phoneNumber,
          price_after_discount: totalAmountAfterDiscount,
          user_name: currentUser?.userName,
          yaytour_user_id: currentUser?.id,
        };

        const functions = getFunctions();
        const paymentIntent = httpsCallable(functions, 'createpaymentintent');
        const result = await paymentIntent(requestDataAfterDiscount);
        const paymentIntentResult = result.data as CreatePaymentIntentResult;
        setClientSecretAfterDiscount(paymentIntentResult.client_secret);
        setServiceFeeAfterDiscount(paymentIntentResult.tax_and_fees);
        setPaymentIntentIdAfterDiscount(paymentIntentResult.payment_intent_id);
        setPriceAfterDiscount(totalAmountAfterDiscount);
      }
    } catch (error) {
      alert('发送错误', ' 使用折扣码时发生错误: ' + error, [
        {
          onPress: () => {},
          text: '确定',
        },
      ]);
      console.error('error occurs when using discount code: ', error);
    }
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      headerBackVisible: false,
      headerTitle: () => <TitleView />,
    });
  });

  // Copied from GroupDetailsScreen#handleNormalJoinGroup
  const addUsersToJoinedMembers = async () => {
    try {
      await addJoinedMember(
        group.id,
        currentUser?.id ?? '',
        currentUser?.userName ?? '',
        numberOfPeople,
        false,
      );
      if (phoneNumber && discountCode != '') {
        await consumeDiscount(phoneNumber, discountCode);
      }
      // Make GroupTab show GroupHome screen
      navigation.navigate('GroupTab', {screen: 'GroupHome'});
      // Navigate to AccountHome screen in AccountTab
      navigation.navigate('AccountTab', {screen: 'AccountHome'});
      navigation.navigate('JoinGroupModal', {
        params: {
          group: group,
          groupQrCodeUrl: groupQrCodeUrl,
        },
        screen: 'JoinGroupSuccessfulScreen',
      });
    } catch (error) {
      alert('发送错误', ' 加入组队时发生错误: ' + error, [
        {
          onPress: () => {},
          text: '确定',
        },
      ]);
      console.error('error occurs when joining group: ', error);
    }
  };

  const addUsersToPaidMembers = async () => {
    try {
      // For updating payment record
      const paidTicketOptions: any = [];
      // For updating group ticket options with new ticket counts
      const updatedTicketOptions = group.ticketOptions || [];

      if (hasTicketOptions) {
        group.ticketOptions.forEach((option: any, index: number) => {
          if (quantities[index] > 0) {
            paidTicketOptions.push({
              optionName: option.optionName,
              optionPrice: option.optionPrice,
              quantity: quantities[index],
            });

            updatedTicketOptions[index].optionQuantity = (
              parseInt(updatedTicketOptions[index].optionQuantity) -
              quantities[index]
            ).toString();
          }
        });
      }

      // Update paid members in group details
      await addJoinedMember(
        group.id,
        currentUser?.id ?? '',
        currentUser?.userName ?? '',
        numberOfPeople,
        true,
        hasTicketOptions,
        paidTicketOptions ?? [],
        discountCode,
      );

      // Update group details
      const groupDocRef = doc(getFirestore(), 'groups', group.id);
      await updateDoc(groupDocRef, {
        ticketOptions: updatedTicketOptions,
      });

      // Update payment records
      const documentRef = doc(collection(getFirestore(), 'paymentRecords'));
      console.log('documentRef： ' + documentRef.path);
      await setDoc(documentRef, {
        clientSecret: clientSecretAfterDiscount ?? clientSecret,
        groupId: group.id,
        numberOfPeople: numberOfPeople,
        originalPriceWithoutFee: priceWithoutFee,
        originalServiceFee: serviceFee,
        paidTicketOptions: paidTicketOptions ?? [],
        paymentDateTime: Timestamp.now(),
        paymentIntentId: paymentIntentIdAfterDiscount ?? paymentIntentId,
        priceWithoutFee: priceAfterDiscount ?? priceWithoutFee,
        serviceFee: serviceFeeAfterDiscount ?? serviceFee,
        userId: currentUser?.id,
      });
      if (phoneNumber && discountCode != '') {
        await consumeDiscount(phoneNumber, discountCode);
      }
      // Make GroupTab show GroupHome screen
      navigation.navigate('GroupTab', {screen: 'GroupHome'});
      // Navigate to AccountHome screen in AccountTab
      navigation.navigate('AccountTab', {screen: 'AccountHome'});
      await navigation.navigate('JoinGroupModal', {
        params: {
          group: group,
          groupQrCodeUrl: groupQrCodeUrl,
          paidMemberNumber: numberOfPeople,
        },
        screen: 'JoinGroupSuccessfulScreen',
      });
    } catch (error) {
      alert('发送错误', ' 更新付款记录时发生错误: ' + error, [
        {
          onPress: () => {},
          text: '确定',
        },
      ]);
      console.error('error occurs when adding payment record: ', error);
    }
  };

  const total = Math.ceil((priceWithoutFee + serviceFee) * 100) / 100;
  return (
    <KeyboardAvoidingView behavior={'position'} style={{flex: 1}}>
      <ScrollView
        contentContainerStyle={{flexGrow: 1}}
        onContentSizeChange={(contentHeight) => {
          setScrollEnabled(contentHeight + 200 >= height);
        }}
        scrollEnabled={Platform.OS === 'web' || scrollEnabled}>
        <View style={styles.container}>
          <Text style={styles.text}>您选择的本次活动费用如下：</Text>
          <View style={styles.notification}>
            <Ionicons color="#66BB6A" name="alert-circle-outline" size={24} />
            <Text style={styles.notificationText}>
              付款后，在活动开始前的72小时内，您可以申请全额退款。请注意，手续费用不会退还。
            </Text>
          </View>

          <View style={styles.cardContainer}>
            {hasTicketOptions ? (
              group.ticketOptions.map((option: any, index: number) => {
                if (quantities[index] > 0) {
                  return (
                    <View style={styles.productRow}>
                      {headerImageEmpty(group) ? (
                        <FastImage
                          source={require('../assets/images/icon.png')}
                          style={styles.productImage}
                        />
                      ) : (
                        <FastImage
                          source={{uri: getFirstHeaderImageUrlFromGroup(group)}}
                          style={styles.productImage}
                        />
                      )}

                      <View style={styles.productDetails}>
                        <Text
                          ellipsizeMode={'tail'}
                          numberOfLines={2}
                          style={styles.productTitle}>
                          {option.optionName}
                        </Text>
                        <Text style={styles.productSubtitle}>
                          数量 {quantities[index]}
                        </Text>
                      </View>
                      <View style={styles.productPrices}>
                        <Text style={styles.productTotalPrice}>
                          ${(option.optionPrice * quantities[index]).toFixed(2)}
                        </Text>
                        <Text style={styles.productUnitPrice}>
                          ${option.optionPrice} 每人
                        </Text>
                      </View>
                    </View>
                  );
                }
              })
            ) : (
              <View style={styles.productRow}>
                {headerImageEmpty(group) ? (
                  <FastImage
                    source={require('../assets/images/icon.png')}
                    style={styles.productImage}
                  />
                ) : (
                  <FastImage
                    source={{uri: getFirstHeaderImageUrlFromGroup(group)}}
                    style={styles.productImage}
                  />
                )}

                <View style={styles.productDetails}>
                  <Text
                    ellipsizeMode={'tail'}
                    numberOfLines={2}
                    style={styles.productTitle}>
                    {group.title}
                  </Text>
                  <Text style={styles.productSubtitle}>
                    人数 {numberOfPeople}
                  </Text>
                </View>
                <Text style={styles.rightText}>${group.bugetPerPerson}</Text>
              </View>
            )}

            <View style={{marginLeft: 72, marginRight: 12, marginVertical: 8}}>
              <View style={styles.row}>
                <Text style={styles.leftText}>小计</Text>
                <Text
                  style={[
                    styles.rightText,
                    priceAfterDiscount != null
                      ? {
                          textDecorationLine: 'line-through',
                        }
                      : {},
                  ]}>
                  ${priceWithoutFee.toFixed(2)}
                </Text>
              </View>
              {priceAfterDiscount != null ? (
                <Text style={styles.discountText}>
                  ${priceAfterDiscount.toFixed(2)}
                </Text>
              ) : null}
              <View style={styles.separator} />
              <View style={styles.row}>
                <Text style={styles.leftText}>Stripe手续费</Text>
                <Text style={styles.rightText}>
                  ${serviceFeeAfterDiscount ?? serviceFee.toFixed(2)}
                </Text>
              </View>
              <View style={styles.separator} />
              <View style={styles.row}>
                <Text style={styles.leftText}>总共</Text>
                <Text style={styles.rightText}>
                  $
                  {priceAfterDiscount != null && serviceFeeAfterDiscount != null
                    ? Math.ceil(
                        (priceAfterDiscount + serviceFeeAfterDiscount) * 100,
                      ) / 100
                    : total.toFixed(2)}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.rightTextGray}>价格含税</Text>
              </View>
            </View>
          </View>

          {features?.enable_discount && (
            <View style={styles.discountContainer}>
              <TextInput
                onChangeText={setDiscountCode}
                style={[
                  styles.payLaterButton,
                  {flex: 2, marginRight: 8},
                ]}></TextInput>
              <TouchableOpacity
                disabled={discountCode === ''}
                onPress={applyDiscountCode}
                style={[
                  styles.payLaterButton,
                  {flex: 1, marginLeft: 8},
                  discountCode === '' && {opacity: 0.3},
                ]}>
                <Text style={styles.payLaterButtonText}>使用折扣码</Text>
              </TouchableOpacity>
            </View>
          )}

          {(Platform.OS === 'android' || Platform.OS === 'ios') &&
            (skipPayment ? (
              <TouchableOpacity
                onPress={addUsersToPaidMembers}
                style={[styles.payNowButton]}>
                <Text style={styles.payNowButtonText}>立即支付</Text>
              </TouchableOpacity>
            ) : (
              <MobileStripeButton
                addUsersToPaidMembers={addUsersToPaidMembers}
                clientSecret={clientSecretAfterDiscount ?? clientSecret}
                customerEphemeralKey={customerEphemeralKey}
                stripeCustomerID={stripeCustomerID}
              />
            ))}
          {Platform.OS === 'web' &&
            clientSecret &&
            (skipPayment ? (
              <TouchableOpacity
                onPress={addUsersToPaidMembers}
                style={[styles.payNowButton]}>
                <Text style={styles.payNowButtonText}>立即支付</Text>
              </TouchableOpacity>
            ) : (
              <WebStripeContainer
                addUsersToPaidMembers={addUsersToPaidMembers}
                clientSecret={clientSecretAfterDiscount ?? clientSecret}
              />
            ))}

          <Image
            source={require('../assets/images/powered_by_stripe_outline.png')}
            // style={{alignSelf: 'center', flex: 1}}
            style={{height: 50, width: 150}}
          />
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  cardContainer: {
    backgroundColor: 'white',
    borderRadius: 8,
    elevation: 2,
    margin: 16,
    padding: 16,
    shadowColor: '#000',
    shadowOffset: {
      height: 4,
      width: 1,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    width: '100%',
  },
  container: {
    alignItems: 'center',
    backgroundColor: 'white',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingHorizontal: 16,
    width: '100%',
  },
  discountContainer: {
    backgroundColor: 'white',
    flexDirection: 'row',
    marginVertical: 16,
    width: '100%',
  },
  discountText: {
    color: Colors.mainAppColor,
    fontSize: 14,
    textAlign: 'right',
  },
  image: {
    height: 48,
    marginRight: 8,
    width: 48,
  },
  leftText: {
    flex: 1,
    fontSize: 14,
  },
  notification: {
    alignItems: 'center',
    backgroundColor: '#F5F5F5',
    borderRadius: 12,
    flexDirection: 'row',
    paddingHorizontal: 15,
    paddingVertical: 8,
    width: '100%',
  },
  notificationText: {
    flex: 1,
    marginLeft: 10,
  },
  payLaterButton: {
    alignContent: 'center',
    backgroundColor: 'white',
    borderColor: Colors.mainAppColor,
    borderRadius: 5,
    borderWidth: 1,
    flex: 1,
    justifyContent: 'center',
    margin: 8,
    padding: 8,
    width: '100%',
  },
  payLaterButtonText: {
    color: Colors.mainAppColor,
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  payNowButton: {
    alignContent: 'center',
    backgroundColor: Colors.mainAppColor,
    borderColor: Colors.mainAppColor,
    borderRadius: 5,
    borderWidth: 1,
    flex: 1,
    justifyContent: 'center',
    margin: 8,
    padding: 8,
    width: '100%',
  },
  payNowButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  productDetails: {
    flex: 3,
    flexDirection: 'column',
    marginHorizontal: 12,
  },
  productImage: {
    aspectRatio: 1 / 1,
    borderRadius: 5,
    width: 48,
  },
  productPrices: {
    alignItems: 'flex-end',
    flex: 3,
    flexDirection: 'column',
  },
  productRow: {
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 8,
    marginHorizontal: 12,
  },
  productSubtitle: {
    fontSize: 12,
    paddingVertical: 2,
  },
  productTitle: {
    fontSize: 18,
    paddingVertical: 2,
  },
  productTotalPrice: {
    fontSize: 18,
    paddingVertical: 2,
  },
  productUnitPrice: {
    color: '#BEBEBE',
    fontSize: 12,
    paddingVertical: 2,
  },
  rightText: {
    flex: 1,
    fontSize: 14,
    textAlign: 'right',
  },
  rightTextGray: {
    color: 'lightgray',
    flex: 1,
    fontSize: 12,
    textAlign: 'right',
  },
  row: {
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 8,
  },
  separator: {
    backgroundColor: 'lightgray',
    height: 1,
    marginVertical: 8,
  },
  text: {
    flexDirection: 'row',
    fontSize: 18,
    paddingVertical: 8,
    width: '100%',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  titleView: {
    alignItems: 'center',
    alignSelf: 'center',
    flexDirection: 'row',
  },
});
