import {Feather, Ionicons, MaterialCommunityIcons} from '@expo/vector-icons';
import {useFocusEffect} from '@react-navigation/native';
import {
  addDoc,
  collection,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import React, {useCallback, useEffect, useLayoutEffect, useState} from 'react';
import {
  Clipboard,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import {Bubble, GiftedChat, Send, User} from 'react-native-gifted-chat';
import uuid from 'react-native-uuid';

import Colors from '../../constants/Colors';
import {AI_GROUP_CHAT_ENDPOINT, OFFICIAL_ACCOUNT} from '../../constants/config';
import {useAuthContext} from '../../context/AuthContext';
import {useChatContext} from '../../context/ChatContext';
import {useFeaturesContext} from '../../context/FeaturesContext';
import {getFirestore} from '../../firebase';
import groupConverter from '../../firestoreConverters/groupConverter';
import {produceDateTimeString} from '../../helpers/util';
import LoadingScreen from '../../screens/LoadingScreen';
import {Group} from '../../types';
import alert from '../container/alert';

export default function Chat({
  navigation,
  route,
}: {
  navigation: any;
  route: any;
}) {
  const {features} = useFeaturesContext();
  const {groupId, groupTitle, fromTab} = route.params;
  const [messages, setMessages] = useState([]);
  const currentUser = useAuthContext().currentUser;
  const {setChatIdOnScreen} = useChatContext();
  const [group, setGroup] = useState<Group>();
  const [importantInfo, setImportantInfo] = useState(
    '活动组织者和管理员可以通过长按群消息来修改此公告',
  );
  const [importantInfoTime, setImportantInfoTime] = useState<Timestamp>();
  const [loading, setLoading] = useState(true);
  const [enableGroupAssistant, setEnableGroupAssistant] = useState(true);
  const [isCreatorEnableGroupAssistant, setIsCreatorEnableGroupAssistant] =
    useState(true);
  setChatIdOnScreen(groupId);

  useEffect(() => {
    const fetchGroupData = async () => {
      setImportantInfo('活动组织者和管理员可以通过长按群消息来修改此公告');
      setImportantInfoTime(undefined);
      try {
        const ref = doc(getFirestore(), 'groups', groupId).withConverter(
          groupConverter,
        );
        const groupSnap = await getDoc(ref);
        if (!groupSnap.exists()) {
          console.log('Data not exists');
          return;
        }

        const groupData = groupSnap.data();
        setGroup(groupData);
      } catch (error) {
        console.error(
          'error occurs when fetching group data, no need to inform user.',
          error,
        );
      }
      // load important info
      const docRef = doc(getFirestore(), 'groupChatMessages', groupId);
      onSnapshot(docRef, (snapshot) => {
        const data = snapshot.data();
        if (data && data.importantInfo && data.importantInfo != '') {
          setImportantInfo(data.importantInfo);
        }
        if (data && data.importantInfoTime && data.importantInfoTime != '') {
          setImportantInfoTime(data.importantInfoTime);
        }
      });
    };
    fetchGroupData();
  }, [groupId]);

  useFocusEffect(
    React.useCallback(() => {
      const fetchGroupAssistantConfiguration = async () => {
        if (features?.enable_ai_group_chat) {
          try {
            const currentRef = doc(
              getFirestore(),
              'groupChatMessages/' + groupId,
            );
            const currentSnapshot = await getDoc(currentRef);

            const currentChat = currentSnapshot.data();

            const groupAssistantConfig =
              currentChat && currentChat.groupAssistantConfig
                ? new Map(Object.entries(currentChat.groupAssistantConfig))
                : new Map();
            const currentUserConfig =
              groupAssistantConfig.get(currentUser?.id) ?? true;
            const creatorConfig =
              groupAssistantConfig.get(group?.creator.id) ?? true;
            setEnableGroupAssistant(currentUserConfig && creatorConfig);
            setIsCreatorEnableGroupAssistant(creatorConfig);
          } catch (error) {
            console.error(
              'error occurs when fetching group assistant config, no need to inform user.',
              error,
            );
          }
        }
      };
      fetchGroupAssistantConfiguration();
    }, [
      currentUser?.id,
      features?.enable_ai_group_chat,
      group?.creator.id,
      groupId,
    ]),
  );

  useLayoutEffect(() => {
    navigation.setOptions({
      headerBackVisible: false,
      headerLeft: () => (
        <Pressable
          onPress={() => {
            if (fromTab === 'Group') {
              navigation.navigate('ChatTab', {screen: 'ChatHome'});
              navigation.navigate('GroupTab');
            } else if (fromTab === 'Account') {
              navigation.navigate('ChatTab', {screen: 'ChatHome'});
              navigation.navigate('AccountTab');
            } else {
              navigation.navigate('ChatTab', {screen: 'ChatHome'});
            }
          }}>
          <Ionicons
            color="black"
            name="arrow-back"
            size={24}
            style={{
              marginLeft: Platform.OS === 'web' ? 16 : 2,
              marginRight: 10,
              marginVertical: 5,
            }}
          />
        </Pressable>
      ),
      headerRight: () => (
        <Pressable
          onPress={() => {
            navigation.navigate('ChatDetails', {
              groupId: groupId,
              isCreatorEnabled: isCreatorEnableGroupAssistant,
              isGroupAssistantEnabled: enableGroupAssistant,
            });
          }}>
          <MaterialCommunityIcons
            color="black"
            name="dots-horizontal"
            size={24}
            style={{
              marginLeft: 10,
              marginRight: Platform.OS === 'web' ? 16 : 2,
              marginVertical: 5,
            }}
          />
        </Pressable>
      ),
      headerTitle: () => (
        <TouchableOpacity
          onPress={() =>
            navigation.navigate('GroupTab', {
              params: {fromChat: true, groupId: groupId},
              screen: 'GroupDetails',
            })
          }>
          <Text style={{fontSize: 18, fontWeight: 'bold'}}>{groupTitle}</Text>
        </TouchableOpacity>
      ),
    });
  }, [
    enableGroupAssistant,
    fromTab,
    groupId,
    groupTitle,
    isCreatorEnableGroupAssistant,
    navigation,
  ]);

  useEffect(() => {
    return () => {
      const fetchData = async () => {
        const currentRef = doc(getFirestore(), 'groupChatMessages/' + groupId);
        const currentSnapshot = await getDoc(currentRef);

        const currentChat = currentSnapshot.data();

        const historyMap =
          currentChat && currentChat.history
            ? new Map(Object.entries(currentChat.history))
            : new Map();

        const exitDate = new Date();
        console.log('Page exit time:', exitDate);
        historyMap.set(currentUser?.id, exitDate);
        const newObj = Object.fromEntries(historyMap);

        await setDoc(
          doc(getFirestore(), 'groupChatMessages/' + groupId),
          {
            history: newObj,
          },
          {merge: true},
        );
        setChatIdOnScreen('');
      };
      fetchData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty array and return() function ensures the effect runs only once on unmount

  useLayoutEffect(() => {
    const q = query(
      collection(getFirestore(), 'groupChatMessages/' + groupId + '/Messages'),
      orderBy('createdAt', 'desc'),
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      setMessages(
        snapshot.docs.map((doc) => ({
          _id: doc.data()._id,
          createdAt: doc.data().createdAt.toDate(),
          system: doc.data().system ?? false,
          text: doc.data().text,
          user: doc.data().user,
        })),
      );

      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, [groupId, group]);

  const onSend = useCallback(
    async (messages = []) => {
      setMessages((previousMessages) =>
        GiftedChat.append(previousMessages, messages),
      );
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const {_id, createdAt, text, user} = messages[0];
      await addDoc(
        collection(
          getFirestore(),
          'groupChatMessages/' + groupId + '/Messages',
        ),
        {_id, createdAt, text, user},
      );
      try {
        updateDoc(doc(getFirestore(), 'groups/' + groupId), {
          lastMessageRecord: {message: text, time: createdAt},
        });
      } catch (error) {
        console.log('error update msg ', error);
      }
      if (features?.enable_ai_group_chat && enableGroupAssistant) {
        if (user._id != OFFICIAL_ACCOUNT && user._id != group?.creator.id) {
          // trigger ai response, wrap group info and history message to ai chat
          try {
            let aiChatEndpoint = `${AI_GROUP_CHAT_ENDPOINT}?group_id=${groupId}`;
            const response = await fetch(aiChatEndpoint);
            const content = await response.text();

            const aiRepliedMessage = {
              _id: uuid.v4(),
              createdAt: new Date(),
              system: false,
              text: content,
              user: {
                _id: OFFICIAL_ACCOUNT,
                avatar:
                  'https://firebasestorage.googleapis.com/v0/b/yaytour-prod.appspot.com/o/GroupImages%2F5i3mwbfQIVa0Dnv5FcG4kvUJkDz1_063818070035.891000000?alt=media&token=fd6b2f17-3e08-4cd3-8eef-a8d04b81f3dc',
                name: 'AI小助手兔兔',
              },
            };
            await addDoc(
              collection(
                getFirestore(),
                'groupChatMessages/' + groupId + '/Messages',
              ),
              aiRepliedMessage,
            );
            try {
              updateDoc(doc(getFirestore(), 'groups/' + groupId), {
                lastMessageRecord: {
                  message: aiRepliedMessage.text,
                  time: aiRepliedMessage.createdAt,
                },
              });
            } catch (error) {
              console.log('error update msg ', error);
            }
          } catch (e) {
            console.log('well, trying failed');
            console.error(e);
          }
        }
      }
    },
    [
      enableGroupAssistant,
      features?.enable_ai_group_chat,
      group?.creator.id,
      groupId,
    ],
  );

  // only creator or offical account can set important info
  const canSetImportantInfo = () => {
    return (
      currentUser?.id === group?.creator?.id ||
      currentUser?.id === OFFICIAL_ACCOUNT
    );
  };

  const onLongPress = (context: any, currentMessage: any) => {
    if (currentMessage.text) {
      const options = canSetImportantInfo()
        ? ['复制文字', '设为群公告', '取消']
        : ['复制文字', '取消'];
      const cancelButtonIndex = options.length - 1;
      context.actionSheet().showActionSheetWithOptions(
        {
          cancelButtonIndex,
          options,
        },
        async (buttonIndex: number) => {
          switch (buttonIndex) {
            case 0:
              Clipboard.setString(currentMessage.text);
              break;
            case 1:
              try {
                if (canSetImportantInfo()) {
                  // 设为群公告
                  console.log('text: ', currentMessage.text);
                  console.log('createAt: ', currentMessage.createdAt);
                  setImportantInfo(currentMessage.text);
                  setImportantInfoTime(currentMessage.createdAt);
                  await setDoc(
                    doc(getFirestore(), 'groupChatMessages/' + groupId),
                    {
                      importantInfo: currentMessage.text,
                      importantInfoTime: currentMessage.createdAt,
                    },
                    {merge: true},
                  );
                }
              } catch (error) {
                console.log(error);
                alert('发送错误', '设置群公告时发生错误: ' + error, [
                  {onPress: () => {}, text: '确定'},
                ]);
              }
              break;
          }
        },
      );
    }
  };

  const renderHeader = () => {
    return (
      <Pressable
        onPress={() => {
          navigation.push('GroupNoticeModal', {
            content: importantInfo,
            timeString: importantInfoTime
              ? produceDateTimeString(importantInfoTime)
              : '',
          });
        }}>
        <View style={styles.headerContainer}>
          <Text
            ellipsizeMode="tail"
            numberOfLines={2}
            style={styles.headerText}>
            <Ionicons color="red" name="volume-high" size={16} />
            群公告:{importantInfo}
          </Text>
        </View>
      </Pressable>
    );
  };

  const renderUsername = (user: User) => {
    // console.log('render user name: ', user._id, user.name);
    // console.log('creator: ', group?.creator.id);
    const userInfoState = getUserInfoState(user);
    return (
      <View style={{flexDirection: 'row'}}>
        <Text style={styles.userNameText}>~{user.name}</Text>
        {userInfoState === 0 && <Text style={styles.userNameInfo}></Text>}
        {/* {userInfoState === 1 && (
          <Text style={styles.userNameInfo}>(管理员)</Text>
        )} */}
        {userInfoState === 2 && (
          <Text style={styles.userNameInfo}>(发起人)</Text>
        )}
        {/* {userInfoState === 3 && (
          <Text style={styles.userNameInfo}>(已退出)</Text>
        )} */}
      </View>
    );
  };

  const getUserInfoState = (user: User) => {
    // console.log('render user name: ', user._id, user.name);
    // console.log('creator: ', group?.creator.id);
    if (group == null) {
      return 0;
    }
    if (user._id === OFFICIAL_ACCOUNT) {
      return 1; //管理员
    }
    if (user._id === group?.creator?.id) {
      return 2; //组织者
    }
    if (
      group?.currentMembers.filter(
        (member: any) => member?.id != null && member?.id === user._id,
      ).length === 0 &&
      group?.paidMembers.filter(
        (member: any) => member?.id != null && member?.id === user._id,
      ).length === 0
    ) {
      return 3; //已退出
    }
    return 0;
  };

  if (loading) {
    return <LoadingScreen />;
  } else {
    return (
      <View style={{flex: 1}}>
        {importantInfo !== '' && renderHeader()}
        <View style={{flex: 1}}>
          <GiftedChat
            messages={messages}
            onLongPress={onLongPress}
            onPressAvatar={(user) => {
              navigation.navigate('AccountTab', {
                params: {fromTab: 'Chat', userId: user._id},
                screen: 'OtherAccountView',
              });
            }}
            onSend={(messages) => onSend(messages)}
            placeholder={'发送群消息'}
            renderBubble={(props) => {
              return (
                <Bubble
                  {...props}
                  wrapperStyle={{
                    // left: {
                    //   backgroundColor: '#E6F5F3',
                    // },
                    right: {
                      backgroundColor: Colors.mainAppColor,
                    },
                  }}
                />
              );
            }}
            renderSend={(props) => {
              return (
                <Send {...props}>
                  <View
                    style={{
                      paddingHorizontal: 12,
                      paddingVertical: 5,
                    }}>
                    <Feather
                      color={Colors.mainAppColor}
                      name="send"
                      size={24}
                    />
                  </View>
                </Send>
              );
            }}
            renderUsername={renderUsername}
            renderUsernameOnMessage={true}
            showAvatarForEveryMessage={true}
            user={{
              _id: currentUser?.id ?? '',
              avatar:
                currentUser?.profileImageUrl ??
                'https://firebasestorage.googleapis.com/v0/b/yaytour-prod.appspot.com/o/publicAssets%2Fsimple-avatar.png?alt=media&token=388a8b89-5ecf-4538-9cdc-3facd5aefa60',
              name: currentUser?.userName,
            }}
          />
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  headerContainer: {
    backgroundColor: 'yellow',
    paddingHorizontal: 10,
    paddingVertical: 8,
  },
  headerText: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  userNameInfo: {
    color: 'grey',
    fontSize: 12,
    paddingHorizontal: 4,
  },
  userNameText: {
    color: 'grey',
    fontSize: 12,
    fontStyle: 'italic',
    paddingLeft: 10,
    paddingRight: 4,
  },
});
