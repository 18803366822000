import {StyleSheet, Text, TouchableOpacity} from 'react-native';

export default function PrimaryButton({
  disabled,
  onPress,
  onDisablePress,
  text,
}: {
  disabled?: boolean;
  onDisablePress?: () => void;
  onPress: () => void;
  text: string;
}) {
  return (
    <TouchableOpacity
      disabled={onDisablePress ? false : disabled}
      onPress={disabled ? onDisablePress : onPress}
      style={[styles.button, disabled && {opacity: 0.5}]}>
      <Text style={styles.buttonText}>{text}</Text>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    backgroundColor: '#66BB6A',
    borderRadius: 8,
    height: 44,
    justifyContent: 'center',
    marginBottom: 24,
    width: '100%',
  },
  buttonText: {
    color: 'white',
    fontSize: 16,
    fontWeight: '700',
    lineHeight: 18,
  },
});
