import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  Timestamp,
} from 'firebase/firestore';
import React, {useEffect, useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';

import Layout from '../../constants/Layout';
import {useAuthContext} from '../../context/AuthContext';
import {getFirestore} from '../../firebase';
import {
  getFirstHeaderImageUrlFromGroup,
  headerImageEmpty,
} from '../../helpers/imageUtil';
import {produceChatTimeString} from '../../helpers/util';
import FastImage from '../../platforms/FastImage';
import {Group} from '../../types';

type ChatEntryBoxProps = {
  group: Group;
};

type MessageProps = {
  _id: string;
  createdAt: Timestamp;
  text: string;
  user: {_id: string; avatar: string; name: string};
};

export default function ChatEntryBox({group}: ChatEntryBoxProps) {
  const {currentUser} = useAuthContext();
  const [messages, setMessages] = useState<MessageProps[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [unread, setUnread] = useState(0);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        setIsLoading(true);
        const messagesQuery = query(
          collection(
            getFirestore(),
            'groupChatMessages/' + group.id + '/Messages',
          ),
          orderBy('createdAt', 'desc'),
        );
        const querySnapshot = await getDocs(messagesQuery);
        const messagesData = querySnapshot.docs.map((docSnap) => ({
          _id: docSnap.data()._id,
          createdAt: docSnap.data().createdAt.toDate(),
          text: docSnap.data().text,
          user: docSnap.data().user,
        }));
        setMessages(messagesData);
        setIsLoading(false);
      } catch (error) {
        console.error('Error getting messages: ', error);
        setIsLoading(false);
      }
    };

    fetchMessages();
  }, [group.id, group.lastMessageRecord]);

  useEffect(() => {
    const docRef = doc(getFirestore(), 'groupChatMessages', group.id);

    const unsubscribe = onSnapshot(docRef, (snapshot) => {
      const data = snapshot.data();

      if (
        data &&
        data.history &&
        currentUser?.id &&
        data.history[currentUser?.id]
      ) {
        const timestamp: Timestamp = data.history[currentUser?.id];
        setUnread(
          messages.filter((message: MessageProps) => {
            // FIXME: createdAt is a Date object not a TImestamp, not sure why
            return message.createdAt > timestamp.toDate();
          }).length,
        );
      } else {
        setUnread(0);
      }
    });

    return () => unsubscribe(); // Unsubscribe from the listener when component unmounts or dependencies change
  }, [currentUser?.id, group.id, messages]);

  return (
    <View style={styles.chatEntryBoxRox}>
      <View style={{padding: 10}}>
        {headerImageEmpty(group) ? (
          <FastImage
            source={require('../../assets/images/icon.png')}
            style={styles.chatImage}
          />
        ) : (
          <FastImage
            source={{uri: getFirstHeaderImageUrlFromGroup(group)}}
            style={styles.chatImage}
          />
        )}
        {unread > 0 && (
          <View style={styles.badgeContainer}>
            <View style={styles.badge}>
              <Text style={styles.badgeText}>
                {unread > 99 ? '...' : unread}
              </Text>
            </View>
          </View>
        )}
      </View>

      <View style={styles.chatDetails}>
        <Text
          ellipsizeMode={'tail'}
          numberOfLines={1}
          style={styles.chatGroupName}>
          {group.title}
        </Text>
        {messages.length === 0 ? (
          <Text
            ellipsizeMode={'tail'}
            numberOfLines={1}
            style={styles.chatLastMessage}>
            来做第一个开启群聊的人吧
          </Text>
        ) : (
          <Text
            ellipsizeMode={'tail'}
            numberOfLines={1}
            style={styles.chatLastMessage}>
            {group.lastMessageRecord?.message}
          </Text>
        )}
      </View>
      {group.lastMessageRecord?.time && (
        <Text numberOfLines={1} style={styles.lastMessageTime}>
          {produceChatTimeString(group.lastMessageRecord?.time?.toDate())}
        </Text>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  badge: {
    alignItems: 'center',
    backgroundColor: 'red',
    borderRadius: 10 * Layout.fontScale,
    height: 20 * Layout.fontScale,
    justifyContent: 'center',
    width: 20 * Layout.fontScale,
  },
  badgeContainer: {
    position: 'absolute',
    right: 1,
    top: 1,
  },
  badgeText: {
    color: 'white',
    fontSize: 12,
    fontWeight: 'bold',
  },
  chatDetails: {
    flex: 2,
    flexDirection: 'column',
    marginHorizontal: 12,
  },
  chatEntryBoxRox: {
    alignItems: 'center',
    flexDirection: 'row',
    marginHorizontal: 12,
  },
  chatGroupName: {
    fontSize: 18,
    paddingVertical: 2,
  },
  chatImage: {
    aspectRatio: 1 / 1,
    borderRadius: 5,
    width: 48,
  },
  chatLastMessage: {
    color: 'grey',
    fontSize: 12,
    paddingVertical: 2,
  },
  lastMessageTime: {
    color: 'grey',
    flex: 1,
    fontSize: 12,
    textAlign: 'right',
  },
});
