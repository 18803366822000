import {AntDesign} from '@expo/vector-icons';
import React, {useState} from 'react';
import {
  Image,
  Linking,
  Modal,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

export default function Navbar({isScreenWide, navigation, language}: any) {
  const [wechatCodeVisible, setWechatCodeVisible] = useState(false);

  const toggleModal = () => {
    setWechatCodeVisible(!wechatCodeVisible);
  };
  return (
    <View
      style={[styles.footerContainer, isScreenWide && {flexDirection: 'row'}]}>
      <View>
        <Text style={styles.text}>@YayTour 2023. All rights reserved.</Text>
      </View>
      <View style={styles.footerRight}>
        <View
          style={[
            styles.footerNav,
            isScreenWide && {flexDirection: 'row', marginTop: 0},
          ]}>
          <TouchableOpacity onPress={() => navigation.push('UserTerms')}>
            <Text style={styles.text}>
              {language === 'english' ? 'User Terms' : '用户服务协议'}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => navigation.push('PrivacyPolicy')}>
            <Text style={styles.text}>
              {language === 'english' ? 'Privacy' : '隐私协议'}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => navigation.push('ResponsibilityTerms')}>
            <Text style={styles.text}>
              {language === 'english' ? 'Responsibility Terms' : '免责声明'}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => navigation.push('ResponsibilityTerms')}>
            <Text style={styles.text}>
              {language === 'english' ? 'Responsibility Terms' : '免责声明'}
            </Text>
          </TouchableOpacity>
        </View>
        <View style={[styles.social, isScreenWide && {marginTop: 20}]}>
          <TouchableOpacity onPress={toggleModal} style={styles.iconContainer}>
            <Image
              source={require('../../assets/images/social/wechat.png')}
              style={{height: 35, width: 35}}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              Linking.openURL(
                'https://www.xiaohongshu.com/user/profile/5c6ac0020000000012018053?xhsshare=CopyLink&appuid=5a755de44eacab0bbee36774&apptime=1700453110',
              );
            }}
            style={styles.iconContainer}>
            <Image
              source={require('../../assets/images/social/red.png')}
              style={{height: 35, width: 35}}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              Linking.openURL(
                'https://www.youtube.com/channel/UCjFnsnLn_cF1sNYZxBi5Z9g',
              );
            }}
            style={styles.iconContainer}>
            <Image
              source={require('../../assets/images/social/youtube.png')}
              style={{height: 35, width: 35}}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              Linking.openURL('https://www.instagram.com/yaytour/');
            }}
            style={styles.iconContainer}>
            <Image
              source={require('../../assets/images/social/instagram.webp')}
              style={{height: 35, width: 35}}
            />
          </TouchableOpacity>
          {/* Wechat Modal */}
          <Modal transparent visible={wechatCodeVisible}>
            <View style={styles.centeredView}>
              <View style={styles.modalView}>
                <Image
                  resizeMode="contain"
                  source={require('../../assets/images/social/wechat_qrcode.jpg')}
                  style={{height: '100%', marginBottom: 20, width: '100%'}}
                />
                <TouchableOpacity
                  onPress={toggleModal}
                  style={styles.closeButton}>
                  <AntDesign color="black" name="close" size={24} />
                </TouchableOpacity>
              </View>
            </View>
          </Modal>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  centeredView: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: 20,
    top: 20,
  },
  footerContainer: {
    alignItems: 'center',
    backgroundColor: '#66BB6A',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingHorizontal: 30,
    paddingVertical: 50,
  },
  footerNav: {
    flexDirection: 'column',
    marginTop: 30,
  },
  footerRight: {
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  iconContainer: {
    marginHorizontal: 10,
  },
  modalView: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 10,
    height: '100%',
    maxHeight: 550,
    maxWidth: 400,
    padding: 20,
    width: '100%',
  },

  social: {
    flexDirection: 'row',
    marginTop: 30,
  },
  text: {
    color: 'white',
    fontWeight: '600',
    paddingHorizontal: 20,
    paddingVertical: 10,
    textAlign: 'center',
  },
});
