/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import {Ionicons} from '@expo/vector-icons';
import {
  DarkTheme,
  DefaultTheme,
  NavigationContainer,
} from '@react-navigation/native';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import * as Notifications from 'expo-notifications';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {ColorSchemeName, Linking, Platform, Pressable} from 'react-native';

import alert from '../components/container/alert';
import {getMinAppVersion} from '../helpers/util';
import CampaignDetailScreen from '../screens/CampaignDetailScreen';
import LandingPageScreen from '../screens/LandingPageScreen';
import ModalScreen from '../screens/ModalScreen';
import NotFoundScreen from '../screens/NotFoundScreen';
import PrivacyPolicyScreen from '../screens/PrivacyPolicyScreen';
import ResponsibilityTermsScreen from '../screens/ResponsibilityTermsScreen';
import UserTermsScreen from '../screens/UserTermsScreen';
import {RootStackParamList} from '../types';
import {BottomTabNavigator} from './BottomTabNavigator';
import JoinGroupModalStackNavigator from './JoinGroupModalStackNavigator';
import LinkingConfiguration from './LinkingConfiguration';
import LoginModalStackNavigator from './LoginModalStackNavigator';

export default function Navigation({
  colorScheme,
  version,
}: {
  colorScheme: ColorSchemeName;
  version: string;
}) {
  const [minAppVersion, setMinAppVersion] = useState('');

  useEffect(() => {
    const fetchMinApp = async () => {
      const minApp = await getMinAppVersion();
      setMinAppVersion(minApp);
    };
    fetchMinApp();
  }, []);

  var loadApp =
    Platform.OS == 'web' ||
    minAppVersion == undefined ||
    minAppVersion == '' ||
    version >= minAppVersion;

  if (!loadApp) {
    alert(
      '当前App版本过低',
      '抱歉, 您当前的 App 版本过低, 请前往 App Store 更新后使用!',
      [
        {
          onPress: () => {
            const downloadUrl =
              Platform.OS == 'ios'
                ? 'https://apps.apple.com/us/app/%E9%87%8E%E5%85%94%E7%BB%84%E9%98%9F/id6448244192'
                : 'https://play.google.com/store/apps/details?id=com.yaytour.androidapp';
            Linking.openURL(downloadUrl);
          },
          text: '确定',
        },
      ],
    );
  }
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}>
      {loadApp && <RootNavigator />}
    </NavigationContainer>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
  return (
    <Stack.Navigator
      initialRouteName="Root"
      screenOptions={{
        headerShown: false,
        headerStyle: {backgroundColor: 'white'},
      }}>
      <Stack.Screen component={BottomTabNavigator} name="Root" />
      <Stack.Screen
        component={LandingPageScreen}
        name="LandingPage"
        options={{title: 'Yaytour'}}
      />
      <Stack.Screen
        component={NotFoundScreen}
        name="NotFound"
        options={{title: 'Oops!'}}
      />
      <Stack.Group screenOptions={{presentation: 'modal'}}>
        <Stack.Screen
          component={CampaignDetailScreen}
          name="CampaignDetail"
          options={({navigation}) => getHeaderOptions(navigation, '')}
        />
        <Stack.Screen component={ModalScreen} name="Modal" />
        <Stack.Screen component={LoginModalStackNavigator} name="LoginModal" />
        <Stack.Screen
          component={JoinGroupModalStackNavigator}
          name="JoinGroupModal"
        />
        <Stack.Screen
          component={UserTermsScreen}
          name="UserTerms"
          options={({navigation}) =>
            getHeaderOptions(navigation, '用户服务协议')
          }
        />
        <Stack.Screen
          component={ResponsibilityTermsScreen}
          name="ResponsibilityTerms"
          options={({navigation}) => getHeaderOptions(navigation, '免责声明')}
        />
        <Stack.Screen
          component={PrivacyPolicyScreen}
          name="PrivacyPolicy"
          options={({navigation}) => getHeaderOptions(navigation, '隐私协议')}
        />
      </Stack.Group>
    </Stack.Navigator>
  );
}

const getHeaderOptions = (navigation: any, title: string) => ({
  headerRight: () => (
    <Pressable
      onPress={() => {
        navigation.goBack();
      }}
      style={({pressed}) => ({
        marginRight: Platform.OS == 'web' ? 10 : 0,
        opacity: pressed ? 0.5 : 1,
      })}>
      <Ionicons color="black" name="close" size={24} />
    </Pressable>
  ),
  headerShown: true,
  title,
});
