import {
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from 'firebase/firestore';

import {UserPost} from '../types';

export default {
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): UserPost {
    const data = snapshot.data(options);
    return {
      description: data.description,
      id: data.id,
      imageUrl: data.imageUrl,
      storageRef: data.storageRef,
      title: data.title,
      uploadDate: data.uploadDate,
    };
  },
  toFirestore(userPost: WithFieldValue<UserPost>): DocumentData {
    return {...userPost};
  },
};
