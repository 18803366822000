import {Ionicons, MaterialCommunityIcons} from '@expo/vector-icons';
import {useNavigation} from '@react-navigation/native';
import {doc, getDoc, getFirestore} from 'firebase/firestore';
import {useEffect, useLayoutEffect, useState} from 'react';
import {
  Clipboard,
  FlatList,
  Platform,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import Colors from '../../constants/Colors';
import {
  SHARE_LINK_BASE_URL,
  USE_PRODUCTION_BUILD,
} from '../../constants/config';
import {
  EXPIRATION_PERIOD_IN_DAYS,
  SYSTEM_COUPON,
} from '../../constants/Discount';
import {useAuthContext} from '../../context/AuthContext';
import discountCodeConverter from '../../firestoreConverters/discountCodeConverter';
import userConverter from '../../firestoreConverters/userConverter';
import {isSystemCouponAvailable, shareLink} from '../../helpers/util';
import LoadingScreen from '../../screens/LoadingScreen';
import alert from '../container/alert';
import TabBar from '../container/TabBar';
import FocusAwareStatusBar from '../misc/FocusAwareStatusBar';

type LocalCoupon = {
  code: string;
  description: string;
  discountValue: string;
  expiration: string;
};

export default function ReferAndDiscount() {
  const {currentUser} = useAuthContext();
  const navigation = useNavigation();
  const [selectedTab, setSelectedTab] = useState(0);
  const tabs = [{title: '邀请'}, {title: '折扣券'}];
  const [coupons, setCoupons] = useState<LocalCoupon[]>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const eligibleCoupons: LocalCoupon[] = [];
    const fetchSystemCoupon = async () => {
      for (let index = 0; index < SYSTEM_COUPON.length; index++) {
        try {
          const dc = SYSTEM_COUPON[index];
          const ref = doc(getFirestore(), 'discountCodes', dc).withConverter(
            discountCodeConverter,
          );
          const coupon = (await getDoc(ref)).data();
          if (!coupon) {
            continue;
          }
          if (index == 9) {
            if (!currentUser?.created) {
              continue;
            }
            // check WELCOME5
            const userRegistered = currentUser?.created.toDate();
            userRegistered.setDate(
              userRegistered.getDate() + EXPIRATION_PERIOD_IN_DAYS,
            );
            coupon.expiresAt = userRegistered;
            if (
              isSystemCouponAvailable(coupon, currentUser?.phoneNumber ?? '')
            ) {
              eligibleCoupons.push({
                code: coupon.code,
                description: '新人奖励',
                discountValue: '$5',
                expiration: coupon.expiresAt.toLocaleString(),
              });
            }
          } else {
            if (
              !currentUser?.referredUsersList ||
              currentUser?.referredUsersList.length <= index
            ) {
              continue;
            }
            // check REFER_X
            const referedUserId = currentUser?.referredUsersList[index];
            const referredUserRef = doc(
              getFirestore(),
              'users',
              referedUserId,
            ).withConverter(userConverter);
            const referredUser = (await getDoc(referredUserRef)).data();
            if (!referredUser || !referredUser.created) {
              continue;
            }
            const userRegistered = referredUser?.created.toDate();
            userRegistered.setDate(
              userRegistered.getDate() + EXPIRATION_PERIOD_IN_DAYS,
            );
            coupon.expiresAt = userRegistered;
            if (
              isSystemCouponAvailable(coupon, currentUser?.phoneNumber ?? '')
            ) {
              eligibleCoupons.push({
                code: coupon.code,
                description: '推荐奖励',
                discountValue: '$5',
                expiration: coupon.expiresAt.toLocaleString(),
              });
            }
          }
        } catch (error) {
          if (!USE_PRODUCTION_BUILD) {
            alert('查询出错', SYSTEM_COUPON[index] + error);
          }
          console.log('查询出错', SYSTEM_COUPON[index], error);
        }
      }
      setCoupons(eligibleCoupons);
      setLoading(false);
    };
    fetchSystemCoupon();
  }, [
    currentUser?.created,
    currentUser?.id,
    currentUser?.phoneNumber,
    currentUser?.referredUsersList,
  ]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerBackVisible: false,
      headerLeft: () => (
        <TouchableOpacity onPress={() => navigation.goBack()}>
          <Ionicons
            color="black"
            name="arrow-back"
            size={24}
            style={{
              marginLeft: Platform.OS === 'web' ? 16 : 2,
              marginRight: 10,
              marginVertical: 5,
            }}
          />
        </TouchableOpacity>
      ),
      headerRight: () => (
        <TouchableOpacity onPress={() => {}}>
          <Ionicons
            color="black"
            name="information-circle-outline"
            size={24}
            style={{
              marginLeft: 10,
              marginRight: Platform.OS === 'web' ? 16 : 2,
              marginVertical: 5,
            }}
          />
        </TouchableOpacity>
      ),
      headerShadowVisible: false,
      headerTitle: () => (
        <Text style={{fontSize: 18, fontWeight: 'bold'}}>推荐</Text>
      ),
    });
  }, [navigation]);

  return (
    <SafeAreaView style={styles.safeAreaStyle}>
      <View style={styles.container}>
        <FocusAwareStatusBar style="dark" />
        <TabBar
          onTabPress={setSelectedTab}
          selectedTab={selectedTab}
          tabs={tabs}
        />
      </View>
      {selectedTab === 0 && <ReferTab />}
      {selectedTab === 1 && <DiscountTab data={coupons} loading={loading} />}
    </SafeAreaView>
  );
}

function ReferTab() {
  const {currentUser} = useAuthContext();
  return (
    <ScrollView style={{paddingHorizontal: 20}}>
      <View style={styles.tabContainer}>
        <Text
          style={{
            fontSize: 24,
            fontWeight: 'bold',
            marginBottom: 16,
            marginTop: 32,
            textAlign: 'center',
          }}>
          Give $5. Get $5
        </Text>
        <View
          style={{
            flexDirection: 'row',
            marginVertical: 16,
          }}>
          <View style={styles.referIcon}>
            <Ionicons color={Colors.mainAppColor} name="logo-usd" size={20} />
          </View>
          <Text style={styles.referText}>
            邀请您的朋友，每位成功注册后您将获得$5奖励，可在独家活动中使用。
          </Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            marginVertical: 16,
          }}>
          <View style={styles.referIcon}>
            <MaterialCommunityIcons
              color={Colors.mainAppColor}
              name="party-popper"
              size={20}
            />
          </View>
          <Text style={styles.referText}>
            当您成功邀请朋友注册后，您的朋友将获得$5的优惠，可在独家活动中使用。
          </Text>
        </View>
        <Text style={{marginVertical: 16}}>
          祝您和您的朋友在野兔活动中度过愉快时光！如有更多问题，请随时向野兔小助手提问。
        </Text>
        <Text
          style={{
            fontSize: 16,
            fontWeight: 'bold',
            marginBottom: 8,
            marginTop: 16,
          }}>
          你的推荐码
        </Text>
        <TouchableOpacity style={styles.referCode}>
          <Text selectable={true} style={{flex: 1, fontSize: 16}}>
            {currentUser?.id}
          </Text>
          <Ionicons color="black" name="copy-outline" size={16} />
        </TouchableOpacity>
      </View>

      <TouchableOpacity
        onPress={() => {
          shareLink(
            `使用我的邀请链接加入野兔组队吧！`,
            SHARE_LINK_BASE_URL + '/input/' + currentUser?.id,
          );
        }}
        style={styles.shareButton}>
        <Text style={styles.shareButtonText}>分享链接</Text>
      </TouchableOpacity>
    </ScrollView>
  );
}

function DiscountTab(data: {
  data: LocalCoupon[] | undefined;
  loading: boolean;
}) {
  const DiscountCodeView = (lc: {lc: LocalCoupon}) => (
    <View style={styles.discountCodeContainer}>
      <View style={styles.discountContent}>
        <Text style={styles.discountCodeDescription}>{lc.lc.description}</Text>
        <Text style={styles.discountCode}>折扣码：{lc.lc.code}</Text>
      </View>
      <View style={styles.discountAmountContainer}>
        <Text style={styles.discountAmount}>{lc.lc.discountValue}</Text>
        <Text style={styles.expirationText}>过期：{lc.lc.expiration}</Text>
      </View>
    </View>
  );
  const couponNotEmpty = data.data && data.data.length > 0;
  const listFooter = () => {
    return <View style={{height: 50}} />;
  };
  return (
    <View style={styles.tabContainer}>
      {data.loading ? (
        <LoadingScreen />
      ) : couponNotEmpty ? (
        <FlatList
          ListFooterComponent={listFooter}
          data={data.data}
          renderItem={({item}) => (
            <TouchableOpacity
              key={item.code}
              onPress={() => {
                Clipboard.setString(item.code);
              }}
              style={{marginHorizontal: 16}}>
              <DiscountCodeView lc={item} />
            </TouchableOpacity>
          )}
          showsVerticalScrollIndicator={false}
        />
      ) : (
        <Text style={styles.emptyView}>还没有折扣券可用</Text>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'stretch',
    backgroundColor: 'yellow',
    justifyContent: 'center',
  },
  discountAmount: {
    alignItems: 'center',
    color: Colors.mainAppColor,
    fontSize: 30,
    fontWeight: 'bold',
  },
  discountAmountContainer: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 4,
  },
  discountCode: {
    color: 'grey',
    fontSize: 14,
    marginTop: 8,
  },
  discountCodeContainer: {
    alignItems: 'center',
    borderColor: 'lightgrey',
    borderRadius: 10,
    borderWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 10,
    padding: 10,
    paddingHorizontal: 20,
    paddingVertical: 10,
    width: '100%',
  },
  discountCodeDescription: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  discountContent: {flex: 1, flexDirection: 'column', marginRight: 4},
  emptyView: {
    fontSize: 16,
    marginVertical: 30,
    textAlign: 'center',
    textAlignVertical: 'center',
  },
  expirationText: {
    color: 'grey',
    fontSize: 12,
    marginTop: 8,
  },
  referCode: {
    borderColor: 'black',
    borderRadius: 5,
    borderWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 8,
    paddingVertical: 4,
  },
  referIcon: {
    includeFontPadding: true,
    paddingRight: 2,
    paddingTop: Platform.OS === 'android' ? 5 : 0,
  },
  referText: {flex: 1, flexWrap: 'wrap', fontSize: 16, textAlign: 'left'},
  safeAreaStyle: {
    backgroundColor: 'white',
    flex: 1,
  },
  shareButton: {
    backgroundColor: '#66BB6A',
    borderRadius: 5,
    marginBottom: 10,
    marginTop: 32,
    paddingVertical: 8,
    width: '100%',
  },
  shareButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  tabContainer: {
    flexDirection: 'column',
    width: '100%',
  },
});
