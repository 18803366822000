import {Ionicons} from '@expo/vector-icons';
import {useState} from 'react';
import {Image, StyleSheet, Text, TouchableOpacity, View} from 'react-native';

import Colors from '../../constants/Colors';

export default function Navbar({
  isScreenWide,
  navigation,
  language,
  toggleLanguage,
}: any) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <View style={styles.container}>
      <View style={styles.navbar}>
        <TouchableOpacity
          onPress={() => {
            navigation.navigate('LandingPage');
          }}>
          <Image
            source={require('../../assets/images/logo.png')}
            style={styles.logo}
          />
        </TouchableOpacity>

        {isScreenWide ? (
          <View style={styles.navbarItems}>
            <View style={styles.navbarItemsLeft}>
              <TouchableOpacity
                onPress={() => navigation.navigate('Root')}
                style={styles.navbarItemsTextContainer}>
                <Text style={styles.navbarItemsText}>
                  {language === 'english' ? 'Activity' : '活动'}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => navigation.navigate('Root', {screen: 'TourTab'})}
                style={styles.navbarItemsTextContainer}>
                <Text style={styles.navbarItemsText}>
                  {language === 'english' ? 'Discover' : '发现'}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => navigation.navigate('Root', {screen: 'ChatTab'})}
                style={styles.navbarItemsTextContainer}>
                <Text style={styles.navbarItemsText}>
                  {language === 'english' ? 'Chat' : '聊天'}
                </Text>
              </TouchableOpacity>
            </View>
            <View style={styles.navbarItemsRight}>
              <TouchableOpacity onPress={toggleLanguage}>
                <Text style={styles.navbarItemsText}>中文/English</Text>
              </TouchableOpacity>

              <TouchableOpacity
                onPress={() => navigation.navigate('LoginModal')}>
                <Text style={styles.getStartedBtn}>
                  {language === 'english' ? 'Log In' : '登录'}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        ) : (
          <TouchableOpacity onPress={toggleMenu}>
            <View style={styles.menuIcon}>
              <Ionicons color="black" name="menu" size={24} />
            </View>
          </TouchableOpacity>
        )}
      </View>
      {/* Dropdown content */}
      {isMenuOpen && !isScreenWide && (
        <View style={styles.dropdownContent}>
          <TouchableOpacity onPress={() => navigation.navigate('Root')}>
            <Text style={styles.menuItem}>
              {language === 'english' ? 'Activity' : '活动'}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => navigation.navigate('Root', {screen: 'TourTab'})}>
            <Text style={styles.menuItem}>
              {language === 'english' ? 'Discover' : '发现'}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => navigation.navigate('Root', {screen: 'ChatTab'})}>
            <Text style={styles.menuItem}>
              {language === 'english' ? 'Chat' : '聊天'}
            </Text>
          </TouchableOpacity>
          <View style={styles.separator}></View>
          <TouchableOpacity onPress={toggleLanguage}>
            <Text style={styles.menuItem}>中文/English</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => navigation.navigate('LoginModal')}>
            <Text style={styles.menuItem}>
              {language === 'english' ? 'Log In' : '登录'}
            </Text>
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    paddingVertical: 15,
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 1,
  },
  dropdownContent: {
    backgroundColor: 'white',
    boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
    left: 0,
    position: 'absolute',
    right: 0,
    top: '100%',
    zIndex: 2,
  },
  getStartedBtn: {
    backgroundColor: Colors.mainAppColor,
    borderRadius: 8,
    color: 'white',
    fontWeight: '600',
    paddingVertical: 10,
    textAlign: 'center',
    width: 80,
  },
  logo: {
    height: 40,
    width: 40,
  },
  menuIcon: {
    alignSelf: 'center',
  },
  menuItem: {
    padding: 10,
    textAlign: 'center',
  },
  navbar: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 80,
    justifyContent: 'space-between',
    margin: 'auto',
    paddingHorizontal: 30,
    width: '100%',
  },
  navbarItems: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  navbarItemsLeft: {
    flexDirection: 'row',
    width: 350,
  },
  navbarItemsRight: {
    flexDirection: 'row',
    gap: 50,
  },
  navbarItemsText: {
    fontWeight: '600',
    margin: 'auto',
  },
  navbarItemsTextContainer: {
    flex: 1,
  },
  separator: {
    borderBottomWidth: 1,
    borderColor: 'lightgray',
    marginHorizontal: 20,
  },
});
