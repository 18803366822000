// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// !!!Important: Change this flag to ture for production builds!!!
export const USE_PRODUCTION_BUILD = true;

// DEV instance
const FIREBASE_CONFIG_STAGING = {
  apiKey: 'AIzaSyAlYJQAaiQEC3gAYZaasDrRIcQQ1Mu5rjI',
  appId: '1:507396284948:web:2602085059b122ed0a3d01',
  authDomain: 'app.yaytour.com',
  measurementId: 'G-FLMVFDFQYM',
  messagingSenderId: '507396284948',
  projectId: 'rabbit-tour',
  storageBucket: 'rabbit-tour.appspot.com',
};

// Below is connection config for PROD instance
// !!! DO NOT USE PROD INSTANCE FOR LOCAL DEVELOPMENT UNLESS NECESSARY !!!
export const FIREBASE_CONFIG_PROD = {
  apiKey: 'AIzaSyDdoxA7l7_q9lp9RK5UTRCv5T_fQmgZkHA',
  appId: '1:552504711264:web:db668ee18a6bf9d7a8cb05',
  authDomain: 'app.yaytour.com',
  measurementId: 'G-C2GGBTKFES',
  messagingSenderId: '552504711264',
  projectId: 'yaytour-prod',
  storageBucket: 'yaytour-prod.appspot.com',
};

export const FIREBASE_CONFIG = USE_PRODUCTION_BUILD
  ? FIREBASE_CONFIG_PROD
  : FIREBASE_CONFIG_STAGING;

export const OFFICIAL_ACCOUNT = USE_PRODUCTION_BUILD
  ? '5i3mwbfQIVa0Dnv5FcG4kvUJkDz1' //6508851234
  : 'YtZ05Wk3Y4Qr2cTXpzpNkfBrsN12'; //3143660417

export const AI_ACCOUNT = USE_PRODUCTION_BUILD
  ? 'otq72PA4aeadxPWhBC6BOQZu6Fw2' //6508854141
  : 'EAHXzNoJKHZiLCrPkqAamCi2sKM2'; //6508854141

export const SHARE_LINK_BASE_URL = USE_PRODUCTION_BUILD
  ? 'https://app.yaytour.com'
  : 'https://rabbit-tour.web.app';

export const STRIPE_PUBLISHABLE_KEY = USE_PRODUCTION_BUILD
  ? 'pk_live_51N2GnSAq3BwScmRxMErWlxELciOyLWdmKcRFdtRiDaJwAOQnN5wSh7h5ix1FqH5GcOkKPyqjZBcpsqJrCjJHkWgq00etw1WECf'
  : 'pk_test_51N2GnSAq3BwScmRxwWd3lYW7MjvTeeZWn0BJOXqP2cjX3rKhMwmlyF5lpglj7gmf2LBOmwQpU2iUA4E4fYzSqzjf00M3vMRpgs';

export const YAYTOUR_WECHAT_SUPPORT = `yaytour_assist`;

export const RATING_POP_UP_LIFETIME_IN_DAYS = 30;
export const ONE_DAY_IN_MILLIS = 86400000;

export const NEW_AI_CHAT_SESSION_THRESHOLD_IN_HOUR = 6;

export const AI_CHAT_ENDPOINT = USE_PRODUCTION_BUILD
  ? 'https://us-central1-yaytour-prod.cloudfunctions.net/pyaichat'
  : 'https://us-central1-rabbit-tour.cloudfunctions.net/pyaichat';

export const AI_GROUP_CHAT_ENDPOINT = USE_PRODUCTION_BUILD
  ? 'https://us-central1-yaytour-prod.cloudfunctions.net/pyaigroupchat'
  : 'https://us-central1-rabbit-tour.cloudfunctions.net/pyaigroupchat';
