import {StyleSheet, Text, View} from 'react-native';

import {AccountAskForLogin} from '../../components/account/AccountAskLogIn';
import AccountTabNavigator from '../../components/account/AccountTabsNavigator';
import AvatarSelector from '../../components/account/AvatarSelector';
import {useAuthContext} from '../../context/AuthContext';

export default function AccountProfile({
  navigation,
  userId,
}: {
  navigation: any;
  userId: any;
}) {
  const currentUserId = useAuthContext().currentUser?.id;
  if (currentUserId == null || currentUserId == '') {
    return (
      <View>
        <AccountAskForLogin navigation={navigation} />
      </View>
    );
  }
  return (
    <View style={styles.container}>
      <AvatarSelector navigation={navigation} userId={userId} />
      <View style={styles.separator} />
      <AccountTabNavigator navigation={navigation} userId={userId} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flex: 1,
  },
  separator: {
    color: '#eee',
    height: 1,
    marginVertical: 10,
    width: '80%',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
});
