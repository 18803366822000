import {AntDesign, Feather} from '@expo/vector-icons';
import React, {useEffect, useState} from 'react';
import {Dimensions, StyleSheet, Text, View} from 'react-native';

import Layout from '../../constants/Layout';
import {useAuthContext} from '../../context/AuthContext';
import FastImage from '../../platforms/FastImage';
import {Tour} from '../../types';

const {width: WINDOW_WIDTH} = Dimensions.get('window');

type DiscoverCardProps = {
  tour: Tour;
};

export default function GroupCardViewVertical({tour}: DiscoverCardProps) {
  const {currentUser} = useAuthContext();
  const [postLiked, setPostLiked] = useState(false);
  useEffect(() => {
    if (
      currentUser !== null &&
      currentUser.likedPostsList &&
      currentUser.likedPostsList.includes(tour.id)
    ) {
      setPostLiked(true);
    } else {
      setPostLiked(false);
    }
  }, [currentUser, tour.id]);

  return (
    <View style={styles.cardContainer}>
      <FastImage source={{uri: tour.headerImage}} style={styles.headerImage} />
      <View style={styles.rightContent}>
        <Text ellipsizeMode={'tail'} numberOfLines={3} style={styles.title}>
          {tour.title}
        </Text>
        {tour.price != null && (
          <View style={styles.price}>
            {tour.oldPrice != null && (
              <Text style={styles.priceTextOld}>${tour.oldPrice}</Text>
            )}
            <Text style={styles.priceTextNew}>${tour.price}</Text>
          </View>
        )}

        {/* <View style={styles.creatorsInfo}>
          <Text style={styles.creatorsInfoText}>未发起组队</Text>
        </View> */}

        <View style={styles.likesRow}>
          {postLiked ? (
            <AntDesign color="red" name="heart" size={16} />
          ) : (
            <Feather color="black" name="heart" size={16} />
          )}
          <Text style={styles.likesText}>{tour.likes}</Text>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  cardContainer: {
    aspectRatio: 36 / 14,
    backgroundColor: '#ffffff',
    borderRadius: 5,
    elevation: 5,
    flexDirection: 'row',
    marginHorizontal: 8,
    shadowColor: '#000',
    shadowOffset: {
      height: 4,
      width: 1,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    width: WINDOW_WIDTH - 20,
  },
  column: {
    flex: 1,
    justifyContent: 'space-between',
  },
  creatorsInfo: {
    flexDirection: 'row',
    height: 24 * Layout.fontScale,
    marginVertical: 2,
  },
  creatorsInfoText: {
    color: '#616161',
    fontSize: 12,
    lineHeight: 18,
  },
  headerImage: {
    borderRadius: 5,
    height: '100%',
    width: '50%',
  },
  likesRow: {
    alignItems: 'center',
    flexDirection: 'row',
    height: 20 * Layout.fontScale,
    marginVertical: 2,
  },
  likesText: {
    alignContent: 'center',
    fontSize: 12,
    lineHeight: 18,
    marginHorizontal: 6,
  },
  price: {
    flexDirection: 'row',
    height: 24 * Layout.fontScale,
    marginVertical: 4,
  },
  priceTextNew: {
    color: '#66BB6A',
    fontSize: 14,
    fontWeight: '700',
    lineHeight: 18,
  },
  priceTextOld: {
    color: '#616161',
    fontSize: 14,
    fontWeight: '700',
    lineHeight: 18,
    marginRight: 2,
    textDecorationLine: 'line-through',
  },
  rightContent: {
    flex: 1,
    flexDirection: 'column',
    padding: 8,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    height: 23 * 3 * Layout.fontScale,
    lineHeight: 18,
  },
});
