import {FontAwesome, Ionicons} from '@expo/vector-icons';
import {useNavigation} from '@react-navigation/native';
import * as ImagePicker from 'expo-image-picker'; // requires Expo
import {
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import {
  getDownloadURL,
  getStorage,
  ref,
  updateMetadata,
  uploadBytesResumable,
} from 'firebase/storage';
import React, {useEffect, useState} from 'react';
import {useCollection} from 'react-firebase-hooks/firestore';
import {
  ActivityIndicator,
  Dimensions,
  Image,
  Platform,
  ScrollView,
  StyleSheet,
  Switch,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import DatePicker from 'react-native-date-picker';
import {SelectList} from 'react-native-dropdown-select-list';

import PrimaryButton from '../components/buttons/PrimaryButton';
import alert from '../components/container/alert';
import GroupTagSelectionView from '../components/group/GroupTagSelectionView';
import PaymentSelectionView from '../components/group/PaymentSelectionView';
import WebDatePickerField from '../components/group/WebDatePickerField';
import {OFFICIAL_ACCOUNT} from '../constants/config';
import {useAuthContext} from '../context/AuthContext';
import {useFeaturesContext} from '../context/FeaturesContext';
import {getFirestore} from '../firebase';
import groupConverter from '../firestoreConverters/groupConverter';
import groupTagConverter from '../firestoreConverters/groupTagConverter';
import userConverter from '../firestoreConverters/userConverter';
import {emptyUser, Group, GroupTag, User} from '../types';

export default function CreateGroupScreen({route}: {route: any}) {
  const navigation: any = useNavigation();

  const {features} = useFeaturesContext();
  const [groupId, setGroupId] = useState('');
  const {currentUser} = useAuthContext();
  const [groupType, setGroupType] = useState('');
  const [groupSubType, setGroupSubType] = useState('');
  const [genderFlexible, setGenderFlexible] = useState('');
  const [title, setTitle] = useState('');
  const [meetUpLocation, setMeetUpLocation] = useState('');
  const [destination, setDestination] = useState('');
  const [budget, setBudget] = useState('');
  const [paymentInstr, setPaymentInstr] = useState('');
  const [venmoAccountId, setVenmoAccountId] = useState('');
  const [zelleAccountId, setZelleAccountId] = useState('');
  const [customizedPayment, setCustomizedPayment] = useState('');
  const [details, setDetails] = useState('');
  const [ticketOptions, setTicketOptions] = useState<
    {
      maxQtyPB: string;
      minQtyPB: string;
      optionName: string;
      optionPrice: string;
      optionQuantity: string;
    }[]
  >([]);
  const [numRequired, setNumRequired] = useState('');
  const [numJoined, setNumJoined] = useState('');
  const [minGroupMembers, setMinGroupMembers] = useState('');
  const [isMeetUpLocaltionRequired, setIsMeetUpLocaltionRequired] =
    useState(false);
  const toggleMeetUpLocationRequiredSwitch = () => {
    setIsMeetUpLocaltionRequired(!isMeetUpLocaltionRequired);
  };
  const [isPast, setIsPast] = useState(false);
  const [isPrivateGroup, setIsPrivateGroup] = useState(false);
  const togglePrivateGroupSwitch = () => {
    setIsPrivateGroup(!isPrivateGroup);
  };
  const [isSubmitting, setIsSubmitting] = useState(false);

  const defaultGroupType = () => {
    if (groupType) {
      return groupType;
    } else if (currentUser?.location) {
      switch (currentUser?.location) {
        case 'NewYork':
          return 'around_ny';
        case 'LA':
          return 'around_la';
        case 'Boston':
          return 'around_bos';
        case 'BayArea':
          return 'around_bay_area';
      }
    }
    return 'around_ny';
  };

  type GroupTypeOption = {
    key: string;
    value: string;
  };

  const groupTypeOptions: Record<string, GroupTypeOption> = {
    around_bay_area: {key: 'around_bay_area', value: '湾区一日游'},
    around_bos: {key: 'around_bos', value: '波士顿地区一日游'},
    around_ny: {key: 'around_ny', value: '纽约地区一日游'},
    tour: {key: 'tour', value: '多日旅行'},
  };

  const defaultDate = new Date(2000, 0, 1, 0, 0, 0); // 2000/01/01 00:00:00
  const todayDate = new Date();
  todayDate.setSeconds(0);
  const [startDate, setStartDate] = useState(defaultDate);
  const [openStartDatePicker, setOpenStartDatePicker] = useState(false);
  const [endDate, setEndDate] = useState(defaultDate);
  const [openEndDatePicker, setOpenEndDatePicker] = useState(false);
  const [startTime, setStartTime] = useState<Date | undefined>(undefined);
  const [endTime, setEndTime] = useState<Date | undefined>(undefined);
  const [openStartTimePicker, setOpenStartTimePicker] = useState(false);
  const [openEndTimePicker, setOpenEndTimePicker] = useState(false);
  const [registrationDeadline, setRegistrationDeadline] = useState<
    Date | undefined
  >(undefined);
  const [openRegistrationDeadlinePicker, setOpenRegistrationDeadlinePicker] =
    useState(false);
  const [existingGroup, setExistingGroup] = useState<Group | undefined>(
    undefined,
  );
  const [imageUrlList, setImageUrlList] = useState<string[] | undefined>(
    undefined,
  );
  const [isSelectingImage, setIsSelectingImage] = useState<boolean>(false);
  const [postAsUser, setPostAsUser] = useState('');

  const [isFilterVisible, setFilterVisible] = useState(false);
  const [userSelectedFilters, setUserSelectedFilters] = useState<Set<string>>(
    new Set(),
  );
  const [groupTagsSnapshot] = useCollection(
    collection(getFirestore(), 'groupTags').withConverter(groupTagConverter),
  );
  let filterListForModal: GroupTag[] = [];
  if (groupTagsSnapshot) {
    groupTagsSnapshot?.docs.forEach((tags) => {
      const tag = tags.data() as GroupTag;
      if (
        tag.tagValue !== 'all' &&
        tag.tagValue !== 'selected' &&
        tag.tagValue !== 'following'
      ) {
        filterListForModal.push(tag);
      }
    });
  }

  useEffect(() => {
    // TODO: Pass in Default value in fields
    if (route.params == null) {
      // This means the group is created from scratch, entry by 'create group button'
      console.log(
        'route.params is undefined. Only default payment value for creating a group',
      );
      setPaymentInstr(currentUser?.defaultPayment ?? '');
      setVenmoAccountId(currentUser?.venmoAccountId ?? '');
      setZelleAccountId(currentUser?.zelleAccountId ?? '');
      setCustomizedPayment(currentUser?.customizedPayment ?? '');
    } else if (
      route.params.groupId != undefined &&
      route.params.groupId != ''
    ) {
      // This means user is editing existing group
      const fetchGroup = async () => {
        setGroupId(route.params.groupId);
        setIsPast(route.params?.isPast);
        const groupRef = doc(
          getFirestore(),
          'groups',
          route.params.groupId,
        ).withConverter(groupConverter);

        const groupSnap = await getDoc(groupRef);
        setExistingGroup(groupSnap.data());
        setTitle(groupSnap.data()?.title ?? '');
        setImageUrlList(groupSnap.data()?.headerImage ?? []);
        setDetails(groupSnap.data()?.details ?? '');
        setDestination(groupSnap.data()?.destination ?? '');
        setMeetUpLocation(groupSnap.data()?.meetUpLocation ?? '');
        setBudget(
          groupSnap.data()?.bugetPerPerson?.toString() === '0'
            ? ''
            : groupSnap.data()?.bugetPerPerson?.toString() ?? '',
        );
        setIsPrivateGroup(groupSnap.data()?.privateGroup ?? false);
        setPaymentInstr(groupSnap.data()?.paymentInstruction ?? '');
        setVenmoAccountId(groupSnap.data()?.creator?.venmoAccountId ?? '');
        setZelleAccountId(groupSnap.data()?.creator?.zelleAccountId ?? '');
        setCustomizedPayment(
          groupSnap.data()?.creator?.customizedPayment ?? '',
        );
        setNumRequired(groupSnap.data()?.numMemberRequired.toString() ?? '');
        setNumJoined(
          isPast
            ? '1'
            : groupSnap
                .data()
                ?.currentMembers.filter((m) => m.id == currentUser?.id)
                .length?.toString() ?? '',
        );
        const minGroupMembersValue = groupSnap.data()?.minGroupMembers;
        setMinGroupMembers(
          typeof minGroupMembersValue === 'number' &&
            !isNaN(minGroupMembersValue)
            ? minGroupMembersValue.toString()
            : '',
        );
        // console.log('!!! group: ' + JSON.stringify(groupSnap.data()));
        setStartDate(groupSnap.data()?.dateStart?.toDate() ?? defaultDate);
        setEndDate(groupSnap.data()?.dateEnd?.toDate() ?? defaultDate);
        setStartTime(groupSnap.data()?.dateStart?.toDate() ?? defaultDate);
        setEndTime(groupSnap.data()?.dateEnd?.toDate() ?? defaultDate);
        setRegistrationDeadline(
          groupSnap.data()?.registrationDeadline?.toDate() ?? undefined,
        );
        setGroupType(groupSnap.data()?.groupType ?? defaultGroupType());
        setGroupSubType(groupSnap.data()?.groupSubType ?? '');
        setGenderFlexible(groupSnap.data()?.genderFlexible ?? 'flexible');
        setUserSelectedFilters(
          new Set(groupSnap.data()?.userGeneratedTag) ?? new Set(),
        );
        setTicketOptions(groupSnap.data()?.ticketOptions ?? []);
      };

      fetchGroup();
    } else {
      // This means user create from discover tab (tour), so fill in default values
      console.log('Group created from tour! route.params: ', route.params);
      const {tourDefaultDescription, tourDefaultImageURL, tourDefaultTitle} =
        route.params;

      setTitle(tourDefaultTitle);
      setImageUrlList([tourDefaultImageURL]);
      setDetails(tourDefaultDescription);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.id, groupId, route.params]);

  const handleImagePick = async () => {
    let result;
    try {
      result = await ImagePicker.launchImageLibraryAsync({
        allowsMultipleSelection: true,
        aspect: [16, 9],
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        quality: 0.5,
      });
    } catch (error) {
      console.error('error occurs when picking image: ', error);
    }

    setIsSelectingImage(true);
    if (result != null && !result.canceled) {
      console.log('selcted picture number: ' + result.assets.length);
      const headerImageList: string[] = [];
      for (let index = 0; index < result.assets.length; index++) {
        try {
          const response = await fetch(result.assets[index].uri);
          const imageTimestamp = Timestamp.now();
          const newFileName =
            currentUser?.id + '_' + imageTimestamp + '_' + index;
          const blob = await response.blob();
          const refLocation = ref(getStorage(), 'GroupImages/' + newFileName);
          const uploadTask = uploadBytesResumable(refLocation, blob);
          uploadTask.on(
            'state_changed',
            () => {},
            () => {},
            async () => {
              const downloadURL = (
                await getDownloadURL(refLocation)
              ).toString();
              headerImageList.push(downloadURL);
              setImageUrlList(headerImageList);
              setIsSelectingImage(false);
            },
          );
        } catch (error) {
          alert('发送错误', '上传图片时发生错误: ' + error, [
            {onPress: () => {}, text: '确定'},
          ]);
        }
      }
    } else {
      setIsSelectingImage(false);
    }
  };

  async function updateImageMetadata(imageURL: string, imageGroupId: string) {
    const refLocation = ref(getStorage(), imageURL);

    await updateMetadata(refLocation, {
      customMetadata: {
        groupId: imageGroupId,
      },
    });
  }

  const [showHighlightFields, setShowHighlightFields] = useState(false);
  function getMeetUpLocationString() {
    if (groupType === 'tour') {
      return '出发地';
    }
    return '集合地点';
  }
  function getStartDateString() {
    if (groupType === 'tour') {
      return '出发日期';
    }
    return '日期';
  }

  // Not used as we're not using QR code
  async function getNextQrCodeId() {
    const groupQrCodes = await getDocs(
      collection(getFirestore(), 'groupQrCode'),
    );
    const allQrCodes = groupQrCodes?.docs;
    const next = allQrCodes?.find(
      (q) =>
        q.data().groupId == undefined &&
        q.data().groupName == undefined &&
        q.data().qr.includes('AdHocImage'),
    );
    return next;
  }

  function startDatePickerOnChange(text: any) {
    // React bug '2023-05-05' will be created as '2023-05-04' while '2023/05/05' does not have this bug
    const strInputValue = text.target.value
      .replace(/-/, '/') // replace 1st "-" with "/"
      .replace(/-/, '/'); // replace 2nd "-" with "/"

    const newDate = new Date(strInputValue);
    setStartDate(newDate);
  }

  function endDatePickerOnChange(text: any) {
    // React bug '2023-05-05' will be created as '2023-05-04' while '2023/05/05' does not have this bug
    const strInputValue = text.target.value
      .replace(/-/, '/') // replace 1st "-" with "/"
      .replace(/-/, '/'); // replace 2nd "-" with "/"
    const newDate = new Date(strInputValue);
    setEndDate(newDate);
  }

  function startTimePickerOnChange(text: any) {
    const [newHour, newMinute] = text.target.value.split(':', 2);
    const newDate = new Date(
      0,
      0,
      0,
      parseInt(newHour),
      parseInt(newMinute),
      0,
    );
    setStartTime(newDate);
  }

  function endTimePickerOnChange(text: any) {
    const [newHour, newMinute] = text.target.value.split(':', 2);
    const newDate = new Date(
      0,
      0,
      0,
      parseInt(newHour),
      parseInt(newMinute),
      0,
    );
    setEndTime(newDate);
  }

  function deadlineTimePickerOnChange(text: any) {
    const strInputValue = text.target.value;
    const newDate = new Date(strInputValue);
    setRegistrationDeadline(newDate);
  }

  const isUserOfficialAccount = currentUser?.id === OFFICIAL_ACCOUNT;

  const addTicketOption = () => {
    setTicketOptions([
      ...ticketOptions,
      {
        maxQtyPB: '',
        minQtyPB: '',
        optionName: '',
        optionPrice: '',
        optionQuantity: '',
      },
    ]);
  };

  const removeTicketOption = (index: any) => {
    const updatedOptions = [...ticketOptions];
    updatedOptions.splice(index, 1);
    setTicketOptions(updatedOptions);
  };

  const handleSubmitButton = async () => {
    const startDateTime = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate(),
      startTime == null ? 0 : startTime.getHours(),
      startTime == null ? 0 : startTime.getMinutes(),
      startTime == null ? 0 : startTime.getSeconds(),
    );
    const endDateTime = new Date(
      groupType === 'tour' ? endDate.getFullYear() : startDate.getFullYear(),
      groupType === 'tour' ? endDate.getMonth() : startDate.getMonth(),
      groupType === 'tour' ? endDate.getDate() : startDate.getDate(),
      endTime == null ? 0 : endTime.getHours(),
      endTime == null ? 0 : endTime.getMinutes(),
      endTime == null ? 0 : endTime.getSeconds(),
    );
    const budgetPerPerson =
      ticketOptions.length > 0
        ? Math.min(
            ...ticketOptions.map((option) => parseFloat(option.optionPrice)),
          )
        : budget !== ''
        ? parseFloat(budget)
        : 0;
    if (
      groupType === '' ||
      groupSubType === '' ||
      title === '' ||
      startDate.getTime() === defaultDate.getTime() ||
      (groupType === 'tour' && endDate.getTime() === defaultDate.getTime()) ||
      (groupType !== 'tour' && startTime == null) ||
      (groupType !== 'tour' && endTime == null) ||
      (groupType === 'tour' && destination === '') ||
      (meetUpLocation === '' && isMeetUpLocaltionRequired) ||
      details === '' ||
      numRequired === '' ||
      numJoined === '' ||
      genderFlexible === '' ||
      ticketOptions.some(
        (option) => option.optionPrice === '' || option.optionName === '',
      )
    ) {
      alert(
        '',
        '请检查所有标星号选项',
        [
          {
            onPress: () => {},
            text: '确定',
          },
        ],
        {cancelable: true},
      );
      setShowHighlightFields(true);
      return;
    } else if (
      startDateTime < todayDate ||
      endDateTime < startDateTime ||
      (registrationDeadline && registrationDeadline > startDateTime)
    ) {
      alert(
        '',
        '你填写的日期或时间信息有误，请修改后重新提交',
        [
          {
            onPress: () => {},
            text: '确定',
          },
        ],
        {cancelable: true},
      );
      setShowHighlightFields(true);
      return;
    } else if (
      isNaN(parseInt(numRequired)) ||
      isNaN(parseInt(numJoined)) ||
      parseInt(numRequired) < parseInt(numJoined) ||
      parseInt(minGroupMembers) > parseInt(numRequired)
    ) {
      alert(
        '',
        '你填写的人数信息有误，请修改后重新提交, 总人数和现有人数必须是整数，且现有人数和最小组队人数需要小与或者等于总人数',
        [
          {
            onPress: () => {},
            text: '确定',
          },
        ],
        {cancelable: true},
      );
      setShowHighlightFields(true);
      return;
    } else if (
      (budgetPerPerson !== 0 && budgetPerPerson < 0.5) ||
      isNaN(budgetPerPerson)
    ) {
      alert(
        '',
        '你填写的人均费用有误，请修改后重新提交，人均费用必须为大于0.5的数字',
        [
          {
            onPress: () => {},
            text: '确定',
          },
        ],
        {cancelable: true},
      );
      setShowHighlightFields(true);
      return;
    } else if (
      !ticketOptions.every((option) => !isNaN(parseFloat(option.optionPrice)))
    ) {
      alert(
        '',
        '票务价格必须为数字',
        [
          {
            onPress: () => {},
            text: '确定',
          },
        ],
        {cancelable: true},
      );
      return;
    } else if (
      ticketOptions.some(
        (option) =>
          option.optionQuantity !== '' &&
          !Number.isInteger(parseFloat(option.optionQuantity)),
      )
    ) {
      alert(
        '',
        '总数量必须为整数',
        [
          {
            onPress: () => {},
            text: '确定',
          },
        ],
        {cancelable: true},
      );
      return;
    } else if (
      !ticketOptions.every(
        (option) =>
          (option.minQtyPB === '' ||
            (Number.isInteger(parseFloat(option.minQtyPB)) &&
              parseInt(option.minQtyPB, 10) >= 0)) &&
          (option.maxQtyPB === '' ||
            (Number.isInteger(parseFloat(option.maxQtyPB)) &&
              parseInt(option.maxQtyPB, 10) >= 0)),
      )
    ) {
      alert(
        '',
        '每单最少/最多订票数必须为大于等于0的整数',
        [
          {
            onPress: () => {},
            text: '确定',
          },
        ],
        {cancelable: true},
      );
      return;
    } else if (
      ticketOptions.length > 0 &&
      ticketOptions.some(
        (option) =>
          option.maxQtyPB &&
          option.minQtyPB &&
          parseInt(option.maxQtyPB) < parseInt(option.minQtyPB),
      )
    ) {
      alert(
        '',
        '每单最少订票数必须小于等于最大订票数',
        [
          {
            onPress: () => {},
            text: '确定',
          },
        ],
        {cancelable: true},
      );
      return;
    } else if (
      paymentInstr === 'venmo_or_zelle' &&
      venmoAccountId === '' &&
      zelleAccountId === ''
    ) {
      alert(
        '',
        '请填写Venmo或者Zelle ID',
        [
          {
            onPress: () => {},
            text: '确定',
          },
        ],
        {cancelable: true},
      );
      return;
    } else if (paymentInstr === 'customized' && customizedPayment === '') {
      alert(
        '',
        '请填写自定义付款方式',
        [
          {
            onPress: () => {},
            text: '确定',
          },
        ],
        {cancelable: true},
      );
      return;
    }

    let groupRef = doc(collection(getFirestore(), 'groups'));
    var postAsUserSnap = undefined;
    if (postAsUser != '') {
      const postAsUserRef = doc(
        getFirestore(),
        'users',
        postAsUser,
      ).withConverter(userConverter);
      postAsUserSnap = (await getDoc(postAsUserRef)).data();
    }
    var realCreatorShort = emptyUser;
    if (postAsUserSnap == undefined) {
      if (currentUser != null) {
        realCreatorShort.id = currentUser.id;
        realCreatorShort.userName = currentUser.userName;
        realCreatorShort.gender = currentUser.gender;
        realCreatorShort.profileImageUrl = currentUser.profileImageUrl;
        realCreatorShort.venmoAccountId =
          venmoAccountId ?? currentUser.venmoAccountId;
        realCreatorShort.zelleAccountId =
          zelleAccountId ?? currentUser.zelleAccountId;
        realCreatorShort.customizedPayment =
          customizedPayment ?? currentUser.customizedPayment;
      }
    } else {
      realCreatorShort.id = postAsUserSnap.id;
      realCreatorShort.userName = postAsUserSnap.userName;
      realCreatorShort.gender = postAsUserSnap.gender;
      realCreatorShort.profileImageUrl = postAsUserSnap.profileImageUrl;
      realCreatorShort.venmoAccountId =
        venmoAccountId ?? postAsUserSnap.venmoAccountId;
      realCreatorShort.zelleAccountId =
        zelleAccountId ?? postAsUserSnap.zelleAccountId;
      realCreatorShort.customizedPayment =
        customizedPayment ?? postAsUserSnap.customizedPayment;
    }
    let currentUserArray: (User | null)[] = [];
    if (groupId != '' && !isPast) {
      groupRef = doc(getFirestore(), 'groups', groupId);
      currentUserArray =
        existingGroup?.currentMembers?.filter((m) => m.id != currentUser?.id) ??
        [];
    }
    for (let i = 0; i < parseInt(numJoined); i++) {
      currentUserArray.push(realCreatorShort);
    }

    // Update User's default payment in user collection
    if (realCreatorShort.id) {
      const userDocRef = doc(getFirestore(), 'users', realCreatorShort.id);
      const userUpdateData = {
        customizedPayment: customizedPayment ?? '',
        defaultPayment: paymentInstr ?? '',
        venmoAccountId: venmoAccountId ?? '',
        zelleAccountId: zelleAccountId ?? '',
      };

      await updateDoc(userDocRef, userUpdateData);
    }

    setIsSubmitting(true);
    if (groupId != '' && !isPast) {
      try {
        await updateDoc(groupRef, {
          bugetPerPerson: budgetPerPerson,
          creator: realCreatorShort,
          currentMembers: currentUserArray,
          date: [
            Timestamp.fromDate(startDateTime),
            Timestamp.fromDate(endDateTime),
          ],
          dateEnd: Timestamp.fromDate(endDateTime),
          dateStart: Timestamp.fromDate(startDateTime),
          destination: destination,
          details: details,
          genderFlexible: genderFlexible,
          groupSubType: groupSubType,
          groupType: groupType,
          headerImage: imageUrlList == null ? [] : imageUrlList,
          meetUpLocation: meetUpLocation,
          meetUpLocationRequired: isMeetUpLocaltionRequired,
          minGroupMembers: parseInt(minGroupMembers),
          numMemberRequired: parseInt(numRequired),
          paymentInstruction: paymentInstr,
          privateGroup: isPrivateGroup,
          registrationDeadline:
            registrationDeadline == null
              ? null
              : Timestamp.fromDate(registrationDeadline),
          ticketOptions: ticketOptions,
          title: title,
          userGeneratedTag: Array.from(userSelectedFilters),
        });

        // update group images' metadata: groupId
        imageUrlList?.forEach((imageUrl) => {
          updateImageMetadata(imageUrl, groupId);
        });

        setIsSubmitting(false);
        navigation.navigate('AccountTab', {screen: 'AccountHome'});
        navigation.push('GroupDetails', {
          groupId: groupId,
          isPast: isPast,
        });
      } catch (error: unknown) {
        setIsSubmitting(false);
        console.error('Error happens when updating group doc, ' + error);
        const alertTitle = '发生错误';
        const alertDescription = '修改组队发生错误' + error;
        alert(alertTitle, alertDescription, [
          {onPress: () => {}, text: '确定'},
        ]);
      }
    } else {
      try {
        let groupRef = doc(collection(getFirestore(), 'groups'));
        await setDoc(groupRef, {
          bugetPerPerson: budgetPerPerson,
          created: Timestamp.now(),
          createdByStripeEligibleUser:
            features?.stripe_payment_enabled ?? false,
          creator: realCreatorShort,
          currentMembers: currentUserArray,
          date: [
            Timestamp.fromDate(startDateTime),
            Timestamp.fromDate(endDateTime),
          ],
          dateEnd: Timestamp.fromDate(endDateTime),
          dateStart: Timestamp.fromDate(startDateTime),
          destination: destination,
          details: details,
          genderFlexible: genderFlexible,
          groupSubType: groupSubType,
          groupType: groupType,
          headerImage: imageUrlList == null ? [] : imageUrlList,
          id: groupRef.path.split('/')[1],
          meetUpLocation: meetUpLocation,
          meetUpLocationRequired: isMeetUpLocaltionRequired,
          minGroupMembers: parseInt(minGroupMembers),
          numMemberRequired: parseInt(numRequired),
          paymentInstruction: paymentInstr,
          privateGroup: isPrivateGroup,
          registrationDeadline:
            registrationDeadline == null
              ? null
              : Timestamp.fromDate(registrationDeadline),
          status: 'pending',
          ticketOptions: ticketOptions,
          title: title,
          userGeneratedTag: Array.from(userSelectedFilters),
        });

        const newGroupId = groupRef.path.split('/')[1];
        // update group images' metadata: groupId
        imageUrlList?.forEach((imageUrl) => {
          updateImageMetadata(imageUrl, newGroupId);
        });

        setIsSubmitting(false);
        navigation.goBack();
        navigation.navigate('AccountTab', {screen: 'AccountHome'});
        navigation.navigate('JoinGroupModal', {
          params: {
            groupId: newGroupId,
            numLeft: parseInt(numRequired) - parseInt(numJoined),
            qrCodeId: '',
            title: title,
          },
          screen: 'CreateGroupSuccessfulScreen',
        });
      } catch (error: unknown) {
        setIsSubmitting(false);
        console.error('Error happens when creating group doc, ' + error);
        const alertTitle = '发生错误';
        const alertDescription = '创建组队发生错误' + error;
        alert(alertTitle, alertDescription, [
          {onPress: () => {}, text: '确定'},
        ]);
      }
    }
  };

  return (
    <ScrollView style={{backgroundColor: 'white'}}>
      <View style={styles.container}>
        <Text style={[styles.title, {marginBottom: 8}]}>活动信息</Text>
        <View style={{flexDirection: 'row'}}>
          <Text style={styles.subTitle}>活动类型</Text>
          <Text style={styles.asterisk}> *</Text>
        </View>
        <SelectList
          data={[
            {key: 'tour', value: '多日旅行'},
            {key: 'around_ny', value: '纽约地区一日游'},
            {key: 'around_bos', value: '波士顿地区一日游'},
            {key: 'around_bay_area', value: '湾区一日游'},
          ]}
          defaultOption={
            groupTypeOptions[defaultGroupType()] || groupTypeOptions.around_ny
          }
          inputStyles={{
            color: groupType === '' ? 'grey' : '#222',
          }}
          search={false}
          setSelected={(val: string) => setGroupType(val)}
        />
        {groupType !== 'tour' && (
          <View>
            <View style={{flexDirection: 'row'}}>
              <Text style={styles.subTitle}>活动形式</Text>
              <Text style={styles.asterisk}> *</Text>
            </View>
            <SelectList
              boxStyles={{
                borderColor:
                  showHighlightFields && groupSubType === '' ? 'red' : 'gray',
              }}
              data={[
                {key: 'indoor', value: '室内活动'},
                {key: 'outdoor', value: '室外活动'},
              ]}
              defaultOption={
                groupSubType === 'indoor'
                  ? {key: 'indoor', value: '室内活动'}
                  : {key: 'outdoor', value: '室外活动'}
              }
              inputStyles={{
                color: groupSubType === '' ? 'grey' : '#222',
              }}
              placeholder={'活动形式'}
              search={false}
              setSelected={(val: string) => setGroupSubType(val)}
            />
          </View>
        )}
        <View style={{flexDirection: 'row'}}>
          <Text style={styles.subTitle}>活动名称</Text>
          <Text style={styles.asterisk}> *</Text>
        </View>
        <TextInput
          defaultValue={title}
          onChangeText={setTitle}
          placeholder={'活动名称'}
          placeholderTextColor="#777"
          style={[
            styles.textInput,
            showHighlightFields && title === '' && {borderColor: 'red'},
          ]}
        />
        <View style={{flexDirection: 'row'}}>
          <Text style={styles.subTitle}>{getStartDateString()}</Text>
          <Text style={styles.asterisk}> *</Text>
        </View>
        {Platform.OS === 'web' && (
          <WebDatePickerField
            datePickerType={'date'}
            defaultValue={
              // Fixed start date always equal to default date bug
              startDate.getTime() !== defaultDate.getTime()
                ? startDate
                : undefined
            }
            onChange={startDatePickerOnChange}
            shouldHighlight={
              showHighlightFields &&
              startDate.getTime() === defaultDate.getTime()
            }
          />
        )}
        {Platform.OS !== 'web' && (
          <View>
            <TextInput
              onTouchEnd={() => setOpenStartDatePicker(true)}
              style={[
                styles.textInput,
                startDate.getTime() === defaultDate.getTime() && {
                  color: '#777',
                },
                showHighlightFields &&
                  startDate.getTime() === defaultDate.getTime() && {
                    borderColor: 'red',
                  },
              ]}>
              {startDate.getTime() === defaultDate.getTime()
                ? getStartDateString()
                : startDate.toLocaleDateString()}
            </TextInput>
            <DatePicker
              date={startDate}
              minimumDate={new Date()}
              modal={true}
              mode={'date'}
              onCancel={() => {
                setOpenStartDatePicker(false);
              }}
              onConfirm={(date: React.SetStateAction<Date>) => {
                setOpenStartDatePicker(false);
                setStartDate(date);
              }}
              open={openStartDatePicker}
            />
          </View>
        )}
        {groupType === 'tour' && (
          <View>
            <View style={{flexDirection: 'row'}}>
              <Text style={styles.subTitle}>回程日期</Text>
              <Text style={styles.asterisk}> *</Text>
            </View>
            {Platform.OS === 'web' && (
              <WebDatePickerField
                datePickerType={'date'}
                defaultValue={
                  endDate.getTime() !== defaultDate.getTime()
                    ? endDate
                    : undefined
                }
                onChange={endDatePickerOnChange}
                shouldHighlight={
                  showHighlightFields &&
                  endDate.getTime() === defaultDate.getTime()
                }
              />
            )}
            {Platform.OS !== 'web' && (
              <View>
                <TextInput
                  onTouchEnd={() => setOpenEndDatePicker(true)}
                  style={[
                    styles.textInput,
                    endDate.getTime() === defaultDate.getTime() && {
                      color: '#777',
                    },
                    showHighlightFields &&
                      endDate.getTime() === defaultDate.getTime() && {
                        borderColor: 'red',
                      },
                  ]}>
                  {endDate.getTime() === defaultDate.getTime()
                    ? '回程日期'
                    : endDate.toLocaleDateString()}
                </TextInput>
                <DatePicker
                  date={endDate}
                  minimumDate={startDate}
                  modal={true}
                  mode={'date'}
                  onCancel={() => {
                    setOpenEndDatePicker(false);
                  }}
                  onConfirm={(date: React.SetStateAction<Date>) => {
                    setOpenEndDatePicker(false);
                    setEndDate(date);
                  }}
                  open={openEndDatePicker}
                />
              </View>
            )}
          </View>
        )}

        {groupType !== 'tour' && (
          <View>
            <View style={{flexDirection: 'row'}}>
              <Text style={styles.subTitle}>集合时间</Text>
              <Text style={styles.asterisk}> *</Text>
            </View>
            {Platform.OS === 'web' ? (
              <WebDatePickerField
                datePickerType={'time'}
                defaultValue={startTime}
                onChange={startTimePickerOnChange}
                shouldHighlight={showHighlightFields && startTime == null}
              />
            ) : (
              <View>
                <TextInput
                  onTouchEnd={() => setOpenStartTimePicker(true)}
                  style={[
                    styles.textInput,
                    startTime == null && {
                      color: '#777',
                    },
                    showHighlightFields &&
                      startTime == null && {
                        borderColor: 'red',
                      },
                  ]}>
                  {startTime == null
                    ? '集合时间'
                    : startTime.toLocaleTimeString()}
                </TextInput>
                <DatePicker
                  date={startTime == null ? todayDate : startTime}
                  modal={true}
                  mode={'time'}
                  onCancel={() => {
                    setOpenStartTimePicker(false);
                  }}
                  onConfirm={(date: React.SetStateAction<Date | undefined>) => {
                    setOpenStartTimePicker(false);
                    setStartTime(date);
                  }}
                  open={openStartTimePicker}
                />
              </View>
            )}
          </View>
        )}

        {groupType !== 'tour' && (
          <View>
            <View style={{flexDirection: 'row'}}>
              <Text style={styles.subTitle}>结束时间</Text>
              <Text style={styles.asterisk}> *</Text>
            </View>
            {Platform.OS === 'web' ? (
              <WebDatePickerField
                datePickerType={'time'}
                defaultValue={endTime}
                onChange={endTimePickerOnChange}
                shouldHighlight={showHighlightFields && endTime == null}
              />
            ) : (
              <View>
                <TextInput
                  onTouchEnd={() => setOpenEndTimePicker(true)}
                  style={[
                    styles.textInput,
                    endTime == null && {
                      color: '#777',
                    },
                    showHighlightFields &&
                      endTime == null && {
                        borderColor: 'red',
                      },
                  ]}>
                  {endTime == null ? '结束时间' : endTime.toLocaleTimeString()}
                </TextInput>
                <DatePicker
                  date={endTime == null ? todayDate : endTime}
                  minimumDate={startTime}
                  modal={true}
                  mode={'time'}
                  onCancel={() => {
                    setOpenEndTimePicker(false);
                  }}
                  onConfirm={(date: React.SetStateAction<Date | undefined>) => {
                    setOpenEndTimePicker(false);
                    setEndTime(date);
                  }}
                  open={openEndTimePicker}
                />
              </View>
            )}
          </View>
        )}

        <View style={{flexDirection: 'row'}}>
          <Text style={styles.subTitle}>{getMeetUpLocationString()}</Text>
          <Text style={styles.asterisk}> *</Text>
        </View>
        <TextInput
          defaultValue={meetUpLocation}
          onChangeText={setMeetUpLocation}
          placeholder={getMeetUpLocationString()}
          placeholderTextColor="#777"
          style={[
            styles.textInput,
            showHighlightFields &&
              meetUpLocation === '' &&
              (groupType !== 'tour' ||
                (groupType === 'tour' && isMeetUpLocaltionRequired)) && {
                borderColor: 'red',
              },
          ]}
        />
        {groupType === 'tour' && (
          <View
            style={{
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
            <Text style={styles.subTitle}>不限制出发地</Text>
            <Switch
              onValueChange={toggleMeetUpLocationRequiredSwitch}
              thumbColor="white"
              trackColor={{false: '#d3d3d3', true: '#66BB6A'}}
              value={!isMeetUpLocaltionRequired}
            />
          </View>
        )}
        {groupType === 'tour' && (
          <View>
            <View style={{flexDirection: 'row'}}>
              <Text style={styles.subTitle}>目的地</Text>
              <Text style={styles.asterisk}> *</Text>
            </View>
            <TextInput
              onChangeText={setDestination}
              placeholder={'目的地'}
              placeholderTextColor="#777"
              style={[
                styles.textInput,
                showHighlightFields &&
                  destination === '' && {borderColor: 'red'},
              ]}
            />
          </View>
        )}
        <Text style={styles.subTitle}>人均费用</Text>
        <TextInput
          keyboardType="numeric"
          onChangeText={setBudget}
          placeholder={'人均费用（选填）'}
          placeholderTextColor="#777"
          style={[
            styles.textInput,
            showHighlightFields &&
              budget != '' &&
              (isNaN(parseFloat(budget)) || parseFloat(budget) < 0.5) && {
                borderColor: 'red',
              },
          ]}
          value={budget}
        />

        {features?.enable_payment_dropdown ? (
          <PaymentSelectionView
            customizedPayment={customizedPayment}
            paymentInstr={paymentInstr}
            setCustomizedPayment={setCustomizedPayment}
            setPaymentInstr={setPaymentInstr}
            setVenmoAccountId={setVenmoAccountId}
            setZelleAccountId={setZelleAccountId}
            venmoAccountId={venmoAccountId}
            zelleAccountId={zelleAccountId}
          />
        ) : (
          <View>
            <Text style={styles.subTitle}>付款方式</Text>
            <TextInput
              onChangeText={setPaymentInstr}
              placeholder={'付款方式（选填）'}
              placeholderTextColor="#777"
              style={styles.textInput}
              value={paymentInstr}
            />
          </View>
        )}

        {features?.enable_registration_deadline && (
          <View>
            <View style={{flexDirection: 'row'}}>
              <Text style={styles.subTitle}>报名结束时间</Text>
            </View>
            {Platform.OS === 'web' ? (
              <WebDatePickerField
                datePickerType={'datetime-local'}
                defaultValue={registrationDeadline}
                onChange={deadlineTimePickerOnChange}
                shouldHighlight={false}
              />
            ) : (
              <View>
                <TextInput
                  onTouchEnd={() => setOpenRegistrationDeadlinePicker(true)}
                  style={[
                    styles.textInput,
                    registrationDeadline == null && {
                      color: '#777',
                    },
                  ]}>
                  {registrationDeadline == null
                    ? '报名结束时间'
                    : registrationDeadline.toLocaleString()}
                </TextInput>
                <DatePicker
                  date={
                    registrationDeadline == null
                      ? todayDate
                      : registrationDeadline
                  }
                  minimumDate={todayDate}
                  modal={true}
                  mode={'datetime'}
                  onCancel={() => {
                    setOpenRegistrationDeadlinePicker(false);
                  }}
                  onConfirm={(date: React.SetStateAction<Date | undefined>) => {
                    setOpenRegistrationDeadlinePicker(false);
                    setRegistrationDeadline(date);
                  }}
                  open={openRegistrationDeadlinePicker}
                />
              </View>
            )}
          </View>
        )}

        <View style={{flexDirection: 'row'}}>
          <Text style={styles.subTitle}>活动细节</Text>
          <Text style={styles.asterisk}> *</Text>
        </View>
        <TextInput
          defaultValue={details}
          multiline={true}
          numberOfLines={5}
          onChangeText={setDetails}
          placeholder={'活动细节'}
          placeholderTextColor="#777"
          style={[
            styles.textInput,
            {minHeight: 90, textAlignVertical: 'top'},
            showHighlightFields && details === '' && {borderColor: 'red'},
          ]}
        />
        <View
          style={{
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
          <View
            style={{
              alignItems: 'flex-start',
              flexDirection: 'column',
            }}>
            <Text style={styles.subTitle}>私家组队</Text>
            <Text style={{color: '#616161', fontSize: 12, lineHeight: 18}}>
              只能通过你的链接参与组队
            </Text>
          </View>
          <Switch
            onValueChange={togglePrivateGroupSwitch}
            thumbColor="white"
            trackColor={{false: '#d3d3d3', true: '#66BB6A'}}
            value={isPrivateGroup}
          />
        </View>

        {features?.enable_group_tags && (
          <View
            style={{
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
            <View
              style={{
                alignItems: 'flex-start',
                flexDirection: 'column',
              }}>
              <Text style={styles.subTitle}>标签</Text>
              <Text style={{color: '#616161', fontSize: 12, lineHeight: 18}}>
                选择标签可以吸引更多小伙伴哦
              </Text>
            </View>

            <FontAwesome
              color={userSelectedFilters.size === 0 ? 'black' : 'green'}
              name="filter"
              onPress={() => setFilterVisible(!isFilterVisible)}
              size={24}
            />

            <GroupTagSelectionView
              filterListForModal={filterListForModal}
              isFilterVisible={isFilterVisible}
              setFilterVisible={setFilterVisible}
              setUserSelectedFilters={setUserSelectedFilters}
              userSelectedFilters={userSelectedFilters}
            />
          </View>
        )}

        {features?.enable_ticket_options && (
          <>
            <Text style={[styles.title, {marginBottom: 16, marginTop: 32}]}>
              购票选项
            </Text>

            {ticketOptions.map((option, index) => (
              <View style={{marginBottom: 30}}>
                <View
                  style={{
                    alignItems: 'center',
                    borderBottomColor: 'lightgray',
                    borderBottomWidth: 1,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}>
                  <Text style={styles.subTitle}>{`选项 ${index + 1}`}</Text>
                  <TouchableOpacity
                    onPress={() => {
                      removeTicketOption(index);
                    }}
                    style={styles.deleteTicketOptionBtn}>
                    <Ionicons
                      color="red"
                      name="trash-outline"
                      size={18}
                      type="AntDesign"
                    />
                  </TouchableOpacity>
                </View>
                <View style={{flexDirection: 'row'}}>
                  <Text style={styles.subTitle}>票务名称</Text>
                  <Text style={styles.asterisk}> *</Text>
                </View>
                <TextInput
                  onChangeText={(text) => {
                    const updatedOptions = [...ticketOptions];
                    updatedOptions[index].optionName = text;
                    setTicketOptions(updatedOptions);
                  }}
                  placeholder={`票务名称，如单人票，团体票`}
                  placeholderTextColor="#777"
                  style={[
                    styles.textInput,
                    showHighlightFields &&
                      option.optionName === '' && {borderColor: 'red'},
                  ]}
                  value={option.optionName}
                />
                <View style={{flexDirection: 'row'}}>
                  <View style={{flex: 1, marginRight: 10}}>
                    <View style={{flexDirection: 'row'}}>
                      <Text style={styles.subTitle}>票务价格</Text>
                      <Text style={styles.asterisk}> *</Text>
                    </View>
                    <TextInput
                      keyboardType="numeric"
                      onChangeText={(text) => {
                        const updatedOptions = [...ticketOptions];
                        updatedOptions[index].optionPrice = text;
                        setTicketOptions(updatedOptions);
                      }}
                      placeholder={`每张票的价格`}
                      placeholderTextColor="#777"
                      style={[
                        styles.textInput,
                        showHighlightFields &&
                          option.optionPrice === '' && {borderColor: 'red'},
                      ]}
                      value={option.optionPrice}
                    />
                  </View>
                  <View style={{flex: 1, marginLeft: 10}}>
                    <Text style={styles.subTitle}>总数量</Text>
                    <TextInput
                      keyboardType="numeric"
                      onChangeText={(text) => {
                        const updatedOptions = [...ticketOptions];
                        updatedOptions[index].optionQuantity = text;
                        setTicketOptions(updatedOptions);
                      }}
                      placeholder={`总数量，默认总人数`}
                      placeholderTextColor="#777"
                      style={styles.textInput}
                      value={option.optionQuantity}
                    />
                  </View>
                </View>
                <View style={{flexDirection: 'row'}}>
                  <View style={{flex: 1, marginRight: 10}}>
                    <Text style={styles.subTitle}>每单最少订票数</Text>
                    <TextInput
                      keyboardType="numeric"
                      onChangeText={(text) => {
                        const updatedOptions = [...ticketOptions];
                        updatedOptions[index].minQtyPB = text;
                        setTicketOptions(updatedOptions);
                      }}
                      placeholder={`默认1`}
                      placeholderTextColor="#777"
                      style={styles.textInput}
                      value={option.minQtyPB}
                    />
                  </View>
                  <View style={{flex: 1, marginLeft: 10}}>
                    <Text style={styles.subTitle}>每单最多订票数</Text>
                    <TextInput
                      keyboardType="numeric"
                      onChangeText={(text) => {
                        const updatedOptions = [...ticketOptions];
                        updatedOptions[index].maxQtyPB = text;
                        setTicketOptions(updatedOptions);
                      }}
                      placeholder={`默认总人数`}
                      placeholderTextColor="#777"
                      style={styles.textInput}
                      value={option.maxQtyPB}
                    />
                  </View>
                </View>
              </View>
            ))}

            {/* add more options button */}
            <TouchableOpacity onPress={addTicketOption}>
              <Text style={styles.addTicketOptionText}>+ 新增套餐</Text>
            </TouchableOpacity>
          </>
        )}

        <Text style={[styles.title, {marginBottom: 16, marginTop: 32}]}>
          人数信息
        </Text>
        <View style={{flexDirection: 'row'}}>
          <Text style={styles.subTitle}>组队总人数</Text>
          <Text style={styles.asterisk}> *</Text>
        </View>
        <TextInput
          defaultValue={numRequired}
          keyboardType="numeric"
          onChangeText={setNumRequired}
          placeholder={'总人数'}
          placeholderTextColor="#777"
          style={[
            styles.textInput,
            showHighlightFields && numRequired === '' && {borderColor: 'red'},
          ]}
        />
        <View style={{flexDirection: 'row'}}>
          <Text style={styles.subTitle}>现有人数</Text>
          <Text style={styles.asterisk}> *</Text>
        </View>
        <TextInput
          defaultValue={numJoined}
          keyboardType="numeric"
          onChangeText={setNumJoined}
          placeholder={'现有人数'}
          placeholderTextColor="#777"
          style={[
            styles.textInput,
            showHighlightFields && numJoined === '' && {borderColor: 'red'},
          ]}
        />

        {features?.enable_min_group_members && (
          <>
            <Text style={styles.subTitle}>组队最少人数</Text>
            <TextInput
              defaultValue={minGroupMembers}
              keyboardType="numeric"
              onChangeText={setMinGroupMembers}
              placeholder={'组队最少人数（选填）'}
              placeholderTextColor="#777"
              style={[styles.textInput]}
            />
          </>
        )}

        <View style={{flexDirection: 'row'}}>
          <Text style={styles.subTitle}>性别要求</Text>
          <Text style={styles.asterisk}> *</Text>
        </View>
        <SelectList
          boxStyles={{
            borderColor:
              showHighlightFields && genderFlexible === '' ? 'red' : 'gray',
          }}
          data={[
            {key: 'flexible', value: '男女不限'},
            {key: 'male_only', value: '仅限男生'},
            {key: 'female_only', value: '仅限女生'},
          ]}
          defaultOption={
            genderFlexible === 'female_only'
              ? {key: 'female_only', value: '仅限女生'}
              : genderFlexible === 'male_only'
              ? {key: 'male_only', value: '仅限男生'}
              : {key: 'flexible', value: '男女不限'}
          }
          inputStyles={{
            color: genderFlexible === '' ? 'grey' : '#222',
          }}
          placeholder={'性别要求'}
          search={false}
          setSelected={(val: string) => setGenderFlexible(val)}
        />
        {(currentUser?.id == '3s23WdhrtShWsXSPSJyI6pd6VIG3' ||
          currentUser?.id == OFFICIAL_ACCOUNT) && (
          <View>
            <View style={{flexDirection: 'row'}}>
              <Text style={styles.subTitle}>代替发布的用户Id</Text>
            </View>
            <View>
              <TextInput
                defaultValue={postAsUser}
                onChangeText={setPostAsUser}
                placeholder={''}
                placeholderTextColor="#777"
                style={[
                  styles.textInput,
                  showHighlightFields &&
                    numJoined === '' && {borderColor: 'red'},
                ]}
              />
            </View>
          </View>
        )}

        <View style={{flexDirection: 'row', marginBottom: 16, marginTop: 32}}>
          <Text style={[styles.title]}>封面信息 </Text>
        </View>
        <TouchableOpacity onPress={handleImagePick} style={{marginBottom: 16}}>
          {imageUrlList == null || imageUrlList.length === 0 ? (
            <View style={[styles.groupImagePlaceholder]}>
              <Ionicons
                color={'rgba(228, 228, 228, 1)'}
                name="add"
                size={Dimensions.get('window').width / 3}
              />
            </View>
          ) : isSelectingImage ? (
            <View
              style={[
                styles.groupImageContainer,
                {alignItems: 'center', justifyContent: 'center'},
              ]}>
              <ActivityIndicator size="large" />
            </View>
          ) : (
            <Image
              source={{uri: imageUrlList[0]}}
              style={styles.groupImageContainer}
            />
          )}
        </TouchableOpacity>
        <PrimaryButton
          disabled={isSubmitting}
          onPress={handleSubmitButton}
          text={groupId != '' && !isPast ? '保存编辑' : '发起组队'}
        />
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  addTicketOptionBtn: {
    backgroundColor: '#66BB6A',
    borderRadius: 12,
  },
  addTicketOptionText: {
    paddingVertical: 10,
  },
  asterisk: {
    color: 'red',
    paddingTop: 16,
  },
  container: {
    flex: 1,
    flexDirection: 'column',
    paddingHorizontal: 16,
  },
  createGroupButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  deleteTicketOptionBtn: {
    marginTop: 10,
  },
  deleteTicketOptionText: {
    color: 'red',
    paddingVertical: 10,
  },
  filterCatHeader: {
    fontSize: 20,
    padding: 10,
  },
  footer: {
    bottom: 0,
    flex: 1,
    left: 0,
    right: 0,
    zIndex: 10,
  },
  groupImageContainer: {
    alignSelf: 'center',
    aspectRatio: 16 / 9,
    borderRadius: 5,
    marginVertical: 4,
    resizeMode: 'cover',
    width: Dimensions.get('window').width - 32,
  },
  groupImagePlaceholder: {
    alignSelf: 'center',
    aspectRatio: 1,
    backgroundColor: 'rgba(228, 228, 228, 0.4)',
    borderRadius: 5,
    justifyContent: 'center',
    marginVertical: 4,
    width: Dimensions.get('window').width / 3,
  },
  groupTagItemContainer: {
    borderRadius: 10,
    margin: 10,
    paddingHorizontal: 14,
    paddingVertical: 6,
  },
  modalButton: {
    alignSelf: 'center',
    borderRadius: 8,
    flex: 1,
    marginHorizontal: 16,
    paddingVertical: 8,
  },
  modalButtonList: {
    flexDirection: 'row',
    paddingBottom: 30,
  },
  modalView: {
    backgroundColor: '#ddd',
    borderRadius: 10,
    height: '30%',
    marginTop: 'auto',
  },
  subTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: 18,
    marginBottom: 6,
    marginTop: 16,
  },
  textInput: {
    borderColor: 'gray',
    borderRadius: 10,
    borderWidth: 1,
    color: '#222',
    fontSize: 14,
    lineHeight: 18,
    paddingHorizontal: 20,
    paddingVertical: 12,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: 23,
  },
});
