import {AntDesign, Feather, Ionicons} from '@expo/vector-icons';
import {useNavigation} from '@react-navigation/native';
import {doc, getDoc, updateDoc} from 'firebase/firestore';
import React, {memo, useEffect, useState} from 'react';
import {
  ActivityIndicator,
  Dimensions,
  Image,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';

import {SHARE_LINK_BASE_URL} from '../../constants/config';
import {useAuthContext} from '../../context/AuthContext';
import {getFirestore} from '../../firebase';
import userConverter from '../../firestoreConverters/userConverter';
import {profileImagePreview, shareLink} from '../../helpers/util';
import FastImage from '../../platforms/FastImage';
import {emptyUser, Tour, TourComment, User} from '../../types';

const {width: WINDOW_WIDTH} = Dimensions.get('window');

type ProfileImageSectionProps = {
  navigation: any;
  poster: User;
};

type ImageURLProps = {
  imageURL: string;
};

type TourCommentSectionProps = {
  tour: Tour;
};

type CommentsProps = {
  comments: [TourComment];
};

// Sole purpose of isolating this component is to prevent unnecessary re-render
const CommentInputSection = memo(
  function CommentInputSection({imageURL}: ImageURLProps) {
    return (
      <View
        style={{
          alignItems: 'center',
          flex: 2,
          flexDirection: 'row',
        }}>
        <View style={styles.avatarContainer}>
          {imageURL !== '' ? (
            <Image source={{uri: imageURL}} style={styles.avatarImage} />
          ) : (
            <Image
              source={require('../../assets/images/yay_logo.png')}
              style={styles.avatarImage}
            />
          )}
        </View>

        <View style={styles.commentInputTextContainer}>
          <TextInput
            onSubmitEditing={() => {}}
            placeholder="Say something..."
          />
        </View>
      </View>
    );
  },
  (oldProps, newProps) => {
    // console.log('Equality test: ', oldProps.imageURL === newProps.imageURL);
    return oldProps.imageURL === newProps.imageURL;
  },
);

// Sole purpose of isolating this component is to prevent unnecessary re-render
// See: https://react.dev/reference/react/memo
const ProfileImageSection = memo(
  function ProfileImageSection({navigation, poster}: ProfileImageSectionProps) {
    const handleTapToOpenProfile = (tappedUserId: string | undefined) => {
      if (typeof tappedUserId != 'string' || tappedUserId == '') {
        return;
      }
      navigation.push('OtherAccountView', {
        userId: tappedUserId,
      });
    };

    return (
      <TouchableOpacity onPress={() => handleTapToOpenProfile(poster?.id)}>
        <View
          style={{
            alignItems: 'center',
            flexDirection: 'row',
          }}>
          <View style={styles.avatarContainer}>
            <FastImage
              source={profileImagePreview(poster)}
              style={styles.avatarImage}
            />
          </View>
          <Text style={styles.userText}>{poster?.userName}</Text>
        </View>
      </TouchableOpacity>
    );
  },
  (oldProps, newProps) => {
    // console.log('Equality test: ', oldProps.imageURL === newProps.imageURL);
    return oldProps.poster === newProps.poster;
  },
);

function SingleComment({comment}: {comment: TourComment}) {
  const [commentUser, setCommentUser] = useState<User>();

  useEffect(() => {
    const fetchData = async () => {
      const ref = doc(getFirestore(), 'users', comment.posterID).withConverter(
        userConverter,
      );

      const userSnapshot = await getDoc(ref);
      if (userSnapshot.exists()) {
        const user = userSnapshot.data();
        console.log('load data: ' + user.userName);
        setCommentUser(user);
      } else {
        console.log('Data not exists');
      }
    };
    fetchData();
  }, [comment.posterID]);

  if (commentUser === undefined) {
    return <ActivityIndicator />;
  } else {
    return (
      <View>
        <View style={styles.avatarNameContainer}>
          <View style={styles.avatarContainer}>
            {commentUser.profileImageUrl ? (
              <Image
                source={{uri: commentUser.profileImageUrl}}
                style={styles.avatarImage}
              />
            ) : (
              <Image
                source={require('../../assets/images/yay_logo.png')}
                style={styles.avatarImage}
              />
            )}
          </View>

          <View>
            <Text style={{fontWeight: '500'}}>{commentUser.userName}</Text>
          </View>
        </View>

        <View style={[{marginLeft: 40}, styles.commentTextContainer]}>
          <Text>{comment.content}</Text>
        </View>
      </View>
    );
  }
}

function UserGeneratedCommentSection({comments}: CommentsProps) {
  return (
    <View style={{paddingVertical: 5}}>
      {comments.map((comment) => (
        <SingleComment comment={comment} key={comment.id} />
      ))}
    </View>
  );
}

export default function DiscoverCommentSection({
  tour,
}: TourCommentSectionProps) {
  const navigation: any = useNavigation();
  const {currentUser} = useAuthContext();
  const [postLiked, setPostLiked] = useState(false);
  // const [commentText, setCommentText] = React.useState('');

  const tourPostRef = doc(getFirestore(), 'tours', tour.id);

  // const commentSubmitted = (event: {nativeEvent: {text: any}}) => {
  //   console.log('comment submitted: ', event.nativeEvent.text);
  // };

  // Check if user already liked the post on init
  useEffect(() => {
    if (
      currentUser !== null &&
      currentUser.likedPostsList &&
      currentUser.likedPostsList.includes(tour.id)
    ) {
      setPostLiked(true);
    }
  }, [currentUser, postLiked, tour.id]);

  const handleLikeButton = async () => {
    console.log('Like button pressed');
    if (currentUser === null) {
      navigation.push('LoginModal');
      return;
    } else {
      // const tourPostRef = doc(getFirestore(), 'tours', tour.id);

      if (postLiked) {
        // Cancel like
        tour.likes -= 1;
        setPostLiked(false);

        if (currentUser.likedPostsList) {
          const index = currentUser.likedPostsList.indexOf(tour.id);
          console.log('index: ' + index);
          if (index > -1) {
            currentUser.likedPostsList?.splice(index, 1);
          }
        }
      } else {
        // Like
        tour.likes += 1;
        setPostLiked(true);

        if (currentUser.likedPostsList) {
          currentUser.likedPostsList.push(tour.id);
        } else {
          currentUser.likedPostsList = [tour.id];
        }
      }

      // Update likes
      await updateDoc(tourPostRef, {
        likes: tour.likes,
      });

      // Update user liked posts list
      const userRef = doc(getFirestore(), 'users', currentUser.id);
      await updateDoc(userRef, {
        likedPostsList: currentUser.likedPostsList,
      });
    }
  };

  const getDateString = () => {
    const upload = new Date(tour.uploadDate.toMillis());
    const now = new Date();

    if (upload.getFullYear() != now.getFullYear()) {
      // years apart
      return `${now.getFullYear() - upload.getFullYear()} 年前`;
    } else if (upload.getMonth() != now.getMonth()) {
      const monthDiff = now.getMonth() - upload.getMonth();
      return monthDiff === 1 ? '上个月' : monthDiff + '月前';
    } else if (upload.getDay() != now.getDay()) {
      const dayDiff = Math.abs(now.getDay() - upload.getDay());
      return dayDiff === 1 ? '昨天' : dayDiff + '天前';
    } else if (upload.getHours() != now.getHours()) {
      return `${now.getHours() - upload.getHours()} 小时前`;
    } else if (upload.getMinutes() != now.getMinutes()) {
      return `${now.getMinutes() - upload.getMinutes()} 分钟前`;
    } else {
      return '刚刚';
    }
  };

  const [poster, setPoster] = useState(emptyUser);

  // Fetch poster's information, including name and profile image
  useEffect(() => {
    const fetchPoster = async (posterID: string) => {
      const posterRef = doc(getFirestore(), 'users', posterID).withConverter(
        userConverter,
      );
      const posterSnapshot = await getDoc(posterRef);
      const fetchedPoster = posterSnapshot.data() || emptyUser;
      setPoster(fetchedPoster);
    };

    fetchPoster(tour.poster);
  }, [tour.poster]);

  function InteractiveSection() {
    return (
      <View
        style={{
          alignItems: 'center',
          flex: 1,
          flexDirection: 'row',
          opacity: 0.75,
        }}>
        <>
          <TouchableOpacity onPress={handleLikeButton}>
            {postLiked ? (
              <AntDesign
                color="red"
                name="heart"
                size={22}
                style={styles.icon}
              />
            ) : (
              <Feather
                color="black"
                name="heart"
                size={22}
                style={styles.icon}
              />
            )}
          </TouchableOpacity>
          <Text>{tour.likes}</Text>
        </>

        <TouchableOpacity
          onPress={() => {
            shareLink(
              '我在野兔上发现了一篇有趣的帖子，快来看吧～',
              SHARE_LINK_BASE_URL + '/tour?tourID=' + tour.id,
            );
          }}>
          <Ionicons
            color="black"
            name="paper-plane-outline"
            size={22}
            style={styles.icon}
          />
        </TouchableOpacity>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.commentHeader}>
        {/* MARK: - No Comment Functionality in P0 */}
        {/*<CommentInputSection imageURL={currentUser?.profileImageUrl || ''} />*/}
        <View style={{alignItems: 'center', flex: 1, flexDirection: 'row'}}>
          <ProfileImageSection navigation={navigation} poster={poster} />
          <Text style={{opacity: 0.5}}>{getDateString() + ' 发布'}</Text>
          <View style={{flex: 1}}></View>
          <View style={{marginHorizontal: 6}}>
            <InteractiveSection />
          </View>
        </View>
      </View>

      {/* MARK: - No Comment Functionality in P0 */}
      {/*{tour.comments === undefined || tour.comments === null ? (*/}
      {/*  <View>*/}
      {/*    <Text style={{alignSelf: 'center', opacity: 0.75, paddingTop: 15}}>*/}
      {/*      暂时还没有评论哦，快来发起讨论吧～*/}
      {/*    </Text>*/}
      {/*  </View>*/}
      {/*) : (*/}
      {/*  <UserGeneratedCommentSection comments={tour.comments} />*/}
      {/*)}*/}
    </View>
  );
}

const styles = StyleSheet.create({
  avatarContainer: {
    borderRadius: 50,
    height: 30,
    margin: 5,
    overflow: 'hidden',
    width: 30,
  },
  avatarImage: {
    height: '100%',
    width: '100%',
  },
  avatarNameContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  avatarPlaceholder: {
    backgroundColor: 'lightgrey',
    height: '100%',
    width: '100%',
  },
  commentContainer: {
    alignItems: 'center',
    flex: 1,
  },
  commentHeader: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
  },
  commentInputTextContainer: {
    borderRadius: 10,
    borderWidth: 1,
    flex: 1,
    marginHorizontal: 4,
    opacity: 0.75,
    paddingHorizontal: 8,
    paddingVertical: 6,
  },
  commentTextContainer: {
    alignSelf: 'center',
    borderRadius: 10,
    borderWidth: 1,
    padding: 6,
    width: WINDOW_WIDTH - 80,
  },
  container: {
    width: '100%',
  },
  icon: {
    marginHorizontal: 10,
    marginVertical: 8,
  },
  userText: {
    flexGrow: 10,
    flexShrink: 10,
    fontSize: 16,
    fontWeight: 'bold',
    paddingHorizontal: 5,
  },
});
