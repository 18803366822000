import {Ionicons} from '@expo/vector-icons';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {Platform, Pressable} from 'react-native';

import AiChat from '../components/chat/AiChat';
import Chat from '../components/chat/Chat';
import ChatDetails from '../components/chat/ChatDetails';
import GroupNoticeModal from '../components/chat/GroupNoticeModal';
import ChatHomeScreen from '../screens/ChatHomeScreen';

const Stack = createNativeStackNavigator<{
  AiChatScreen: undefined;
  ChatDetails: {
    groupId: string;
    isCreatorEnabled: boolean;
    isGroupAssistantEnabled: boolean;
  };
  ChatHome: undefined;
  ChatScreen: {
    fromTab: string;
    groupId: string;
    groupTitle: string;
  };
  GroupNoticeModal: {
    content: string;
    timeString: string;
  };
}>();

export default function ChatTabStackNavigator() {
  return (
    <Stack.Navigator>
      <Stack.Group
        screenOptions={({navigation}) => ({
          contentStyle: {
            backgroundColor: 'white',
            paddingTop: 8,
          },
          headerLeft: () => (
            <Pressable
              onPress={() => {
                navigation.goBack();
              }}
              style={({pressed}) => ({
                opacity: pressed ? 0.5 : 1,
              })}>
              <Ionicons
                color="black"
                name="arrow-back"
                size={24}
                style={{
                  marginLeft: Platform.OS === 'web' ? 16 : 0,
                }}
              />
            </Pressable>
          ),
          headerShadowVisible: false,
        })}>
        <Stack.Screen
          component={ChatHomeScreen}
          name="ChatHome"
          options={{headerShown: false}}
        />
        <Stack.Screen component={ChatDetails} name="ChatDetails" />
        <Stack.Screen component={AiChat} name="AiChatScreen" />
        <Stack.Screen component={Chat} name="ChatScreen" />
      </Stack.Group>
      <Stack.Group
        screenOptions={({navigation}) => ({
          contentStyle: {
            backgroundColor: 'white',
            paddingTop: 8,
          },
          headerLeft: () => (
            <Pressable
              onPress={() => {
                navigation.goBack();
              }}
              style={({pressed}) => ({
                opacity: pressed ? 0.5 : 1,
              })}>
              <Ionicons
                color="black"
                name="arrow-back"
                size={24}
                style={{
                  marginLeft: Platform.OS === 'web' ? 16 : 0,
                }}
              />
            </Pressable>
          ),
          headerShadowVisible: false,
          presentation: 'modal',
          title: '群公告',
        })}>
        <Stack.Screen component={GroupNoticeModal} name="GroupNoticeModal" />
      </Stack.Group>
    </Stack.Navigator>
  );
}
