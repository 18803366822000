import 'firebase/database';

import React, {useState} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';

import Colors from '../../constants/Colors';
import TabBar from '../container/TabBar';
import AccountListTripView from './AccountListTripView';
import {PersonalPost} from './PersonalPost';

export function AccountTabNavigator({
  navigation,
  userId,
}: {
  navigation: any;
  userId: any;
}) {
  const [selectedTab, setSelectedTab] = useState(1);

  const tabs = [{title: '主页'}, {title: '当前活动'}, {title: '过去活动'}];

  const screens = [
    <PersonalPost navigation={navigation} userId={userId} />,
    <AccountListTripView
      isPast={false}
      navigation={navigation}
      userId={userId}
    />,
    <AccountListTripView
      isPast={true}
      navigation={navigation}
      userId={userId}
    />,
  ];

  return (
    <View style={styles.container}>
      <TabBar
        onTabPress={setSelectedTab}
        selectedTab={selectedTab}
        tabs={tabs}
      />
      {screens[selectedTab]}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    width: '100%',
  },
  selectedTabText: {
    color: 'black',
  },
  tab: {
    alignItems: 'center',
    borderBottomWidth: 2,
    borderColor: 'transparent',
    flex: 1,
    paddingVertical: 10,
  },
  tabBar: {
    backgroundColor: 'white',
    flexDirection: 'row',
  },
  tabContent: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  tabIndicator: {
    backgroundColor: Colors.mainAppColor,
    bottom: 0,
    height: 2,
    position: 'absolute',
    width: '100%',
  },
  tabText: {
    color: 'grey',
    fontSize: 14,
    fontWeight: 'bold',
  },
});

export default AccountTabNavigator;
