import {doc, getDoc, updateDoc} from 'firebase/firestore';
import {getFunctions, httpsCallable} from 'firebase/functions';
import {useEffect, useState} from 'react';
import {FlatList, SafeAreaView} from 'react-native';

import alert from '../components/container/alert';
import PeopleListCell from '../components/container/PeopleListCell';
import {getFirestore} from '../firebase';
import groupConverter from '../firestoreConverters/groupConverter';
import {PaidMember} from '../types';

export default function PeopleListScreen({route}: {route: any}) {
  const [checkinMap, setCheckinMap] = useState<{[userId: string]: boolean}>({});
  const [allowUserRemoveParticipant, setAllowUserRemoveParticipant] =
    useState(false);

  const {
    allowDuplicate,
    creatorID,
    groupId,
    isUserCreator,
    isUserOfficialAccount,
    paymentMap,
    peopleList,
    showPayment,
    stripePaymentMap,
  } = route.params;

  const [data, setData] = useState<Array<any>>([]);
  const [paidTicketOptions, setPaidTicketOptions] = useState<{
    [userId: string]: any;
  }>({});

  const [paidMembers, setPaidMembers] = useState<Array<PaidMember>>([]);

  const fetchUserList = () => {
    const uniqueUsers = new Set();
    const newData = [];
    for (let index = 0; index < peopleList.length; index++) {
      const currentUser = peopleList[index];
      if (uniqueUsers.has(currentUser)) {
        if (allowDuplicate) {
          // This is a dummy user. Others RSVP for him/her
          newData.push({id: index, unique: false, userId: currentUser});
        } else {
          // In follower/following list, which does not allow duplicate, throw error
          console.warn('Duplicated followers or following user');
        }
      } else {
        newData.push({id: index, unique: true, userId: currentUser});
        uniqueUsers.add(currentUser);
      }
    }
    setData([...data, ...newData]);
  };

  const fetchGroupData = async () => {
    const ref = doc(getFirestore(), 'groups', groupId).withConverter(
      groupConverter,
    );
    const groupSnap = await getDoc(ref);
    if (!groupSnap.exists()) {
      console.log('Data not exists');
      return;
    }

    const groupData = groupSnap.data();

    if (groupData?.checkin) {
      // pull data from the database
      setCheckinMap(groupData?.checkin);
    } else {
      // push data to database, setup the inital checkin map

      // set up new check in map in database
      setupCheckinStatus();
    }

    // If the group did not use stripe to collect money, we allow creator to remove participants
    if (
      ((isUserCreator || isUserOfficialAccount) &&
        !groupData.createdByStripeEligibleUser) ||
      groupData.bugetPerPerson === 0
    ) {
      setAllowUserRemoveParticipant(true);
    }
    // set up paid ticket options
    setPaidTicketOptions(groupData?.paidTicketOptions);
    // set up paid members
    setPaidMembers(groupData?.paidMembers);
  };

  const getUserCheckinStatus = (userId: string): boolean => {
    return checkinMap[userId] || false;
  };

  const handleDelete = async (userId: string) => {
    alert(
      '',
      '确认移除该用户?',
      [
        {
          onPress: async () => {
            try {
              const docRef = doc(getFirestore(), 'groups', groupId);
              const docSnap = await getDoc(docRef);

              if (docSnap.exists()) {
                const groupData = docSnap.data();
                const currentMembers = groupData.currentMembers || [];

                const updatedMembers = currentMembers.filter(
                  (user: any) => user.id !== userId,
                );

                await updateDoc(docRef, {currentMembers: updatedMembers});

                const newData = data.filter((item) => item.userId !== userId);
                setData(newData);

                // get phone number
                const userRef = doc(getFirestore(), 'users', userId);
                const userSnap = await getDoc(userRef);

                if (userSnap.exists()) {
                  const userData = userSnap.data();
                  const userPhoneNumber = userData.phoneNumber;

                  if (userPhoneNumber === '') return;

                  // Send a message to the user
                  const functions = getFunctions();
                  const sendMessage = httpsCallable(
                    functions,
                    'sendsmsmessage',
                  );

                  const messageText = `您好，野兔组队提醒您，您先前报名的活动:${groupData.title}发生变化。活动组织者${groupData.creator.userName}已将你从活动中移除，请登陆“野兔组队”App了解详情，谢谢理解。`;
                  const messageData = {
                    message: messageText,
                    phone_number: userPhoneNumber,
                  };

                  sendMessage(messageData)
                    .then((result) => {
                      console.log('Function call result:', result);
                    })
                    .catch((error) => {
                      console.error('Function call error:', error);
                    });
                }
              }

              console.log(` ${userId} was deleted successfully.`);
            } catch (error) {
              alert('发送错误', '移出组队时发生错误: ' + error, [
                {onPress: () => {}, text: '确定'},
              ]);
              console.error(`Error deleting user from group: ${error}`);
            }
          },
          text: '确定',
        },
        {
          onPress: () => {},
          style: 'cancel',
          text: '取消',
        },
      ],
      {cancelable: true},
    );
  };

  function getPaymentIdOrNull(userId: string) {
    if (
      showPayment &&
      paymentMap != undefined &&
      paymentMap != null &&
      paymentMap instanceof Map &&
      paymentMap.has(userId)
    ) {
      return paymentMap.get(userId);
    }
    return '';
  }

  function paidWithStripe(userId: string) {
    return (
      stripePaymentMap != undefined &&
      stripePaymentMap != null &&
      stripePaymentMap.has(userId)
    );
  }

  function getPaidTicketOption(userId: string) {
    const ticketOption = paidTicketOptions[userId];
    return ticketOption;
  }

  function getDiscountCode(userId: string) {
    const userWithMatchingId = paidMembers.find((user) => user.id === userId);
    if (userWithMatchingId) {
      return userWithMatchingId?.discountCode;
    }
    return undefined;
  }

  const renderItem = ({
    item,
  }: {
    item: {id: number; unique: boolean; userId: any};
  }) => {
    return (
      <PeopleListCell
        allowUserRemoveParticipant={allowUserRemoveParticipant}
        checkinStatus={getUserCheckinStatus(item.userId)}
        creatorID={creatorID}
        discountCode={getDiscountCode(item.userId) || ''}
        handleDelete={() => handleDelete(item.userId)}
        isUserCreator={isUserCreator}
        isUserOfficialAccount={isUserOfficialAccount}
        paidTicketOption={getPaidTicketOption(item.userId)}
        paymentId={getPaymentIdOrNull(item.userId)}
        recordPaid={
          paidWithStripe(item.userId) || getPaymentIdOrNull(item.userId)
        }
        toggleCheckIn={() => toggleCheckIn(item.userId)}
        unique={item.unique}
        userId={item.userId}
      />
    );
  };

  const setupCheckinStatus = async () => {
    interface CheckinObject {
      [personId: string]: boolean;
    }

    const checkinObject: CheckinObject = {};

    for (const person of peopleList) {
      checkinObject[person] = false;
    }
    setCheckinMap(checkinObject);

    try {
      const docRef = doc(getFirestore(), 'groups', groupId);
      await updateDoc(docRef, {checkin: checkinObject});
    } catch (error) {
      {
        console.error('error occurs when setup checkin Status: ', error);
      }
    }
  };

  const toggleCheckIn: Function = async (userId: string) => {
    try {
      const docRef = doc(getFirestore(), 'groups', groupId);
      const groupSnapshot = await getDoc(docRef);

      if (groupSnapshot.exists()) {
        const groupData = groupSnapshot.data();

        const existingCheckin = groupData.checkin || {};
        const newCheckinStatus = !existingCheckin[userId];

        const confirmed = await new Promise((resolve) => {
          alert(
            '',
            newCheckinStatus ? '是否帮用户签到?' : '是否帮用户取消签到',
            [
              {
                onPress: async () => {
                  const updatedCheckin = {
                    ...existingCheckin,
                    [userId]: newCheckinStatus,
                  };
                  await updateDoc(docRef, {checkin: updatedCheckin});
                  setCheckinMap(updatedCheckin);
                  resolve(true);
                },
                text: '确定',
              },
              {
                onPress: () => {
                  resolve(false);
                },
                text: '取消',
              },
            ],
            {cancelable: true},
          );
        });

        return confirmed;
      } else {
        console.log('Group document does not exist');
      }
    } catch (error) {
      console.error('Error occurred while updating checkin: ', error);
    }
    return false;
  };

  // Run only once after the initial render of the component
  useEffect(() => {
    fetchUserList();
    if (isUserCreator || isUserOfficialAccount) {
      console.log('This is event creator or official account');
      fetchGroupData();
    }
  }, []);

  return (
    <SafeAreaView style={{flex: 1}}>
      <FlatList
        data={data} //pass in our data array
        renderItem={renderItem}
      />
    </SafeAreaView>
  );
}
