import {collection} from 'firebase/firestore';
import React, {useState} from 'react';
import {useCollection} from 'react-firebase-hooks/firestore';
import {
  FlatList,
  Platform,
  Pressable,
  SafeAreaView,
  StatusBar,
  StyleSheet,
  View,
} from 'react-native';

import {useAuthContext} from '../../context/AuthContext';
import {useFeaturesContext} from '../../context/FeaturesContext';
import {getFirestore} from '../../firebase';
import tourConverter from '../../firestoreConverters/tourConverter';
import {judgeClient} from '../../helpers/util';
import LoadingScreen from '../../screens/LoadingScreen';
import {Tour} from '../../types';
import TabBar from '../container/TabBar';
import WebDeeplinkHeaderView from '../container/WebDeeplinkHeaderView';
import FocusAwareStatusBar from '../misc/FocusAwareStatusBar';
import DiscoverCardView from './DiscoverCardView';
import DiscoverCardViewCompact from './DiscoverCardViewCompact';

function DiscoverList({navigation, tours}: {navigation: any; tours: Tour[]}) {
  const {features} = useFeaturesContext();
  return (
    <View style={styles.tourCardList}>
      <FlatList
        contentContainerStyle={styles.content}
        data={tours}
        renderItem={({item}) => (
          <Pressable
            key={item.id}
            onPress={() => navigation.push('TourDetails', {tourID: item.id})}
            style={styles.item}>
            {features?.enable_tour_compact_view ? (
              <DiscoverCardViewCompact tour={item} />
            ) : (
              <DiscoverCardView tour={item} />
            )}
          </Pressable>
        )}
        showsVerticalScrollIndicator={false}
      />
    </View>
  );
}

export default function DiscoverHomeScreen({navigation}: {navigation: any}) {
  const {currentUser} = useAuthContext();
  const {features} = useFeaturesContext();
  // TODO: the third parameter should be "LoadingError" which contains firestore
  // related error. But it is not triggered correctly.
  const [tourSnapshot, isLoading] = useCollection(
    collection(getFirestore(), 'tours').withConverter(tourConverter),
  );

  // Two necessary conditions: 1. loading finished. 2. no data
  const LoadingFailed =
    !isLoading && (tourSnapshot === undefined || tourSnapshot?.empty);

  const allTours: Tour[] = [];
  if (tourSnapshot) {
    tourSnapshot.docs.forEach((doc) => {
      const tour = doc.data();
      if (
        currentUser === null ||
        !currentUser.blockedUsersList?.includes(tour.poster)
      ) {
        allTours.push(tour);
      }
    });
  }

  const [selectedTab, setSelectedTab] = useState(0);
  const followingCreators = new Set(currentUser?.followingList);
  const tabs =
    followingCreators.size >= 1
      ? [{title: '热门'}, {title: '关注'}]
      : [{title: '热门'}];

  let selectedTours: Tour[] = [];
  if (selectedTab == 0) {
    // '热门' only display selected merchants' post
    selectedTours = allTours
      .filter((tour) => tour.postedBySelectedMerchant)
      .sort((a, b) => (a.uploadDate <= b.uploadDate ? 1 : -1));
  } else if (selectedTab == 1) {
    // '关注' is all post from following creator's
    selectedTours = allTours
      .filter((tour) => {
        return followingCreators.has(tour.poster);
      })
      .sort((a, b) => (a.uploadDate <= b.uploadDate ? 1 : -1));
  }

  if (isLoading || LoadingFailed) {
    return (
      <SafeAreaView style={styles.safeAreaStyle}>
        <LoadingScreen />
      </SafeAreaView>
    );
  } else {
    return (
      <SafeAreaView style={styles.safeAreaStyle}>
        <View style={styles.container}>
          {features?.web_deeplink_feature &&
            (judgeClient() === 'web-android' ||
              judgeClient() === 'web-ios') && (
              <WebDeeplinkHeaderView path="tours" />
            )}
          <FocusAwareStatusBar style="dark" />
          <TabBar
            onTabPress={setSelectedTab}
            selectedTab={selectedTab}
            tabs={tabs}
          />
          <DiscoverList navigation={navigation} tours={selectedTours} />
        </View>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'stretch',
    flex: 1,
    justifyContent: 'center',
  },
  content: {
    alignItems: 'center',
  },
  description: {
    fontSize: 15,
    fontWeight: 'normal',
  },
  filterBarContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    marginHorizontal: 4,
  },
  filterButton: {
    borderBottomColor: 'transparent',
    borderBottomWidth: 1,
    justifyContent: 'center',
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  item: {
    alignItems: 'flex-start',
    // height: 250,
    paddingVertical: 10,
  },
  safeAreaStyle: {
    backgroundColor: 'white',
    flex: 1,
    paddingTop: Platform.OS === 'android' ? StatusBar.currentHeight : 0,
  },
  separator: {
    color: '#eee',
    height: 1,
    marginVertical: 30,
    width: '80%',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  tourCardList: {
    flex: 1,
    flexDirection: 'column',
  },
});
