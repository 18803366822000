import {Entypo} from '@expo/vector-icons';
import React, {useEffect, useState} from 'react';
import {
  Modal,
  Pressable,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import Colors from '../../constants/Colors';
import {GroupTag} from '../../types';

function GroupTagItem({
  tag,
  key,
  userSelectedFilters,
  setUserSelectedFilters,
}: {
  key: number;
  setUserSelectedFilters: (userSelectedFilters: Set<string>) => void;
  tag: GroupTag;
  userSelectedFilters: Set<string>;
}) {
  const [selected, setSelected] = useState(
    userSelectedFilters.has(tag.tagValue),
  );

  useEffect(() => {
    setSelected(userSelectedFilters.has(tag.tagValue));
  }, [tag.tagValue, userSelectedFilters]);

  return (
    <Pressable
      onPress={() => {
        if (userSelectedFilters.has(tag.tagValue)) {
          userSelectedFilters.delete(tag.tagValue);
          setSelected(false);
        } else {
          setUserSelectedFilters(userSelectedFilters.add(tag.tagValue));
          setSelected(true);
        }
      }}>
      <View
        style={[
          styles.groupTagItemContainer,
          {
            backgroundColor: selected ? '#66BB6A' : 'white',
          },
        ]}>
        <Text
          style={{
            color: selected ? 'white' : 'black',
          }}>
          {tag.title}
        </Text>
      </View>
    </Pressable>
  );
}

export default function GroupTagSelectionView({
  isFilterVisible,
  setFilterVisible,
  filterListForModal,
  userSelectedFilters,
  setUserSelectedFilters,
}: {
  filterListForModal: GroupTag[];
  isFilterVisible: boolean;
  setFilterVisible: (modalVisible: boolean) => void;
  setUserSelectedFilters: (userSelectedFilters: Set<string>) => void;
  userSelectedFilters: Set<string>;
}) {
  return (
    <Modal
      animationType="slide"
      testID={`group_tag_modal_container`}
      transparent={true}
      visible={isFilterVisible}>
      <View style={styles.modalView}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: 15,
          }}>
          <Text style={{fontSize: 20, padding: 10}}>类别</Text>
          <TouchableOpacity
            onPress={() => {
              setFilterVisible(!isFilterVisible);
            }}
            style={styles.crossContainer}>
            <Entypo color="black" name="cross" size={26} />
          </TouchableOpacity>
        </View>

        <View style={styles.row}>
          {filterListForModal.map((tag, key) => (
            <GroupTagItem
              key={key}
              setUserSelectedFilters={setUserSelectedFilters}
              tag={tag}
              userSelectedFilters={userSelectedFilters}
            />
          ))}
        </View>
        <View style={[{flexGrow: 1}]}></View>

        <View style={styles.modalButtonList}>
          <TouchableOpacity
            onPress={() => {
              setUserSelectedFilters(new Set());
            }}
            style={[
              styles.modalButton,
              {
                backgroundColor: 'white',
              },
            ]}>
            <Text style={[styles.createGroupButtonText, {color: 'black'}]}>
              重置
            </Text>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => {
              setFilterVisible(!isFilterVisible);
            }}
            style={[
              styles.modalButton,
              {
                backgroundColor: Colors.mainAppColor,
              },
            ]}>
            <Text style={[styles.createGroupButtonText, {color: 'white'}]}>
              确定
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  addButton: {
    alignItems: 'center',
    backgroundColor: '#98B3B7',
    borderRadius: 35,
    bottom: 90,
    elevation: 8,
    height: 70,
    justifyContent: 'center',
    position: 'absolute',
    right: 20,
    width: 70,
    zIndex: 11,
  },
  addButtonText: {
    color: '#fff',
    fontSize: 18,
  },
  container: {
    backgroundColor: '#98B3B7',
    flex: 1,
    justifyContent: 'center',
  },
  createGroupButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  crossContainer: {
    paddingTop: 12,
  },
  groupTagItemContainer: {
    borderRadius: 10,
    margin: 10,
    paddingHorizontal: 14,
    paddingVertical: 6,
  },
  header: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  headerText: {
    color: 'black',
    fontSize: 18,
  },
  modalButton: {
    alignSelf: 'center',
    borderRadius: 8,
    flex: 1,
    marginHorizontal: 16,
    paddingVertical: 8,
  },
  modalButtonList: {
    flexDirection: 'row',
    paddingBottom: 30,
  },
  modalView: {
    backgroundColor: '#ddd',
    borderRadius: 10,
    height: '50%',
    marginTop: 'auto',
  },
  noteHeader: {
    alignItems: 'center',
    backgroundColor: '#42f5aa',
    borderTopLeftRadius: 50,
    borderTopRightRadius: 50,
    justifyContent: 'center',
  },
  row: {
    alignItems: 'center',
    alignSelf: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginHorizontal: 15,
  },
  textInput: {
    alignSelf: 'stretch',
    backgroundColor: '#ddd',
    borderTopColor: '#ddd',
    borderTopWidth: 2,
    color: 'black',
    padding: 20,
  },
});
