import {getAnalytics, logEvent, setUserId} from 'firebase/analytics';
import {getAuth, PhoneAuthProvider, signInWithCredential} from 'firebase/auth';
import {doc, getDoc, serverTimestamp, setDoc} from 'firebase/firestore';
import React, {useState} from 'react';
import {
  Platform,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';

import {useFeaturesContext} from '../context/FeaturesContext';
import {getFirestore} from '../firebase';

const CONFIRMATION_CODE_REGEX = /^\d{6}$/;

const db = getFirestore();

export default function LoginVerifyScreen({
  route,
  navigation,
}: {
  navigation: any;
  route: {
    params: {
      formattedPhoneNumber: string;
      referCode: string;
      verificationId: string;
    };
  };
}) {
  const {features} = useFeaturesContext();
  const [code, setCode] = useState('');
  const auth = getAuth();
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  //TODO(chenfada) add error handling

  const isValidCode = CONFIRMATION_CODE_REGEX.test(code);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      if (!isValidCode) {
        return;
      }

      const credential = PhoneAuthProvider.credential(
        route.params.verificationId,
        code,
      );
      const userCredential = await signInWithCredential(auth, credential);
      const firebaseUser = userCredential.user;
      const userId = firebaseUser.uid;
      const userRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userRef);

      // If user date does not exist yet => new user
      if (!userDoc.exists()) {
        try {
          await setDoc(userRef, {
            created: serverTimestamp(),
            id: userId,
          });
          if (features?.enable_location_page) {
            navigation.navigate('Location', {
              referCode: route.params.referCode,
            });
          } else {
            navigation.navigate('Profile', {
              mode: 'register',
              referCode: route.params.referCode,
            });
          }
        } catch (error) {
          //TODO(chenfada) handle error
          console.error('error occurs when verifying code: ', error);
        }
      } else if (
        // If user exists and location flag is on, check if they have location set up
        features?.enable_location_page &&
        !userDoc.data().location
      ) {
        navigation.navigate('Location', {
          gender: userDoc.data().gender,
          referCode: route.params.referCode,
          username: userDoc.data().userName,
          wechat: userDoc.data().socialWechat,
        });
      } else if (
        // If user exists and skip location page, check if gender/userName/wechat are fully set up
        [
          userDoc.data().gender,
          userDoc.data().userName,
          userDoc.data().socialWechat,
        ].some((field) => field == null || field === '')
      ) {
        navigation.navigate('Profile', {
          gender: userDoc.data().gender,
          image: userDoc.data().profileImageUrl,
          location: userDoc.data().location,
          mode: 'register',
          name: userDoc.data().name,
          referCode: route.params.referCode,
          username: userDoc.data().userName,
          wechat: userDoc.data().socialWechat,
        });
      } else {
        // if profile has been set up, dismiss the modal.
        navigation.navigate('Root', {
          screen: 'AccountTab',
        });
      }
      setMessage('Yay! Logged In Successfully!');
      if (Platform.OS === 'web') {
        const analytics = getAnalytics();
        console.log('login metrics: ' + userDoc?.data()?.id);
        setUserId(analytics, userDoc == undefined ? null : userDoc?.data()?.id);
        logEvent(analytics, 'user_login');
      }
    } catch (err) {
      // TODO(chenfada) implement it
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <View style={styles.container}>
      <Text
        style={{
          fontSize: 16,
          fontWeight: '600',
          marginBottom: 16,
          textAlign: 'left',
          width: '100%',
        }}>
        请输入验证码
      </Text>
      <Text style={{marginBottom: 16, textAlign: 'left', width: '100%'}}>
        {'短信验证码已发送至 ' + route.params.formattedPhoneNumber}
      </Text>
      <TextInput
        autoFocus
        keyboardType="phone-pad"
        maxLength={6}
        onBlur={() => {}}
        onChangeText={(text) => {
          setCode(text);
        }}
        placeholder="请输入验证码"
        placeholderTextColor="rgba(51, 51, 51, 0.6)"
        style={styles.textInput}
        textContentType="telephoneNumber"
        value={code}
      />
      <View
        style={{
          alignItems: 'flex-start',
          flexDirection: 'row',
        }}>
        <Text style={styles.info}>没有收到验证码？</Text>
        <TouchableOpacity
          onPress={() => {
            navigation.goBack();
          }}
          style={{flex: 1}}>
          <Text style={[styles.info, {color: '#66BB6A'}]}>重新获取</Text>
        </TouchableOpacity>
      </View>
      <TouchableOpacity
        disabled={!isValidCode || isSubmitting}
        onPress={handleSubmit}
        style={[styles.button, !isValidCode && {opacity: 0.5}]}>
        <Text style={styles.buttonText}>
          {isSubmitting ? '登陆中...' : '登陆'}
        </Text>
      </TouchableOpacity>
      <Text style={{marginVertical: 8}}>{message}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    backgroundColor: '#66BB6A',
    borderRadius: 8,
    height: 44,
    justifyContent: 'center',
    marginBottom: 24,
    width: '100%',
  },
  buttonText: {
    color: 'white',
    fontSize: 16,
    fontWeight: '700',
    lineHeight: 18,
  },
  container: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'flex-start',
    padding: 16,
    paddingTop: 28,
  },
  info: {
    marginBottom: 16,
    textAlign: 'left',
  },
  separator: {
    color: '#eee',
    height: 1,
    marginVertical: 30,
    width: '80%',
  },
  textInput: {
    borderRadius: 4,
    borderWidth: 1,
    fontSize: 14,
    fontWeight: '700',
    height: 48,
    letterSpacing: 24,
    marginBottom: 24,
    padding: 15,
    paddingLeft: 39,
    textAlign: 'center',
    width: '100%',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
});
