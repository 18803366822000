import {doc, updateDoc} from 'firebase/firestore';
import {useState} from 'react';
import {Modal, StyleSheet, Text, TouchableOpacity, View} from 'react-native';

import {getFirestore} from '../../firebase';

type Props = {
  location: string;
  setCurrentLocation: (answer: string) => void;
  userId: string | undefined;
};

const db = getFirestore();

export default function ChangeLocationBar({
  location,
  setCurrentLocation,
  userId,
}: Props) {
  const [modalVisible, setModalVisible] = useState(false);
  const locationOptions = ['纽约地区', '波士顿地区', '湾区'];

  const handleLocationChanged = async (currentLocation: string) => {
    setCurrentLocation(currentLocation);
    // updating user location in database
    // convert to data value
    const convertToDataValue = (userFriendlyLocation: string) => {
      switch (userFriendlyLocation) {
        case '纽约地区':
          return 'NewYork';
        case '波士顿地区':
          return 'Boston';
        case '湾区':
          return 'BayArea';
        default:
          return 'NewYork';
      }
    };

    if (userId) {
      const userRef = doc(db, 'users', userId);
      try {
        await updateDoc(userRef, {
          location: convertToDataValue(currentLocation),
        });
      } catch (error) {
        console.error('Error updating user location in Firestore:', error);
      }
    }
  };

  const renderModal = () => {
    return (
      <Modal
        animationType="fade"
        onDismiss={() => setModalVisible(false)}
        onRequestClose={() => setModalVisible(false)}
        transparent={true}
        visible={modalVisible}>
        <View style={styles.modalBackground}>
          <View style={styles.modalContainer}>
            <View style={styles.modalContent}>
              <Text style={styles.modalTitle}>请选择想要切换的地区</Text>
              {locationOptions.map((locationOption, index) => (
                <TouchableOpacity
                  key={index}
                  onPress={() => {
                    handleLocationChanged(locationOption);
                    setModalVisible(false);
                  }}>
                  <Text style={styles.modalOption}>{locationOption}</Text>
                </TouchableOpacity>
              ))}
              <TouchableOpacity onPress={() => setModalVisible(false)}>
                <Text style={styles.closeButton}>关闭</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    );
  };

  return (
    <View style={styles.barContainer}>
      <TouchableOpacity
        onPress={() => setModalVisible(true)}
        style={styles.changeButton}>
        <Text style={styles.location}>{location}</Text>
      </TouchableOpacity>
      {renderModal()}
    </View>
  );
}

const styles = StyleSheet.create({
  barContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingHorizontal: 16,
  },
  changeButton: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: 8,
  },
  closeButton: {
    alignSelf: 'flex-end',
    color: '#66BB6A',
    marginBottom: 10,
  },
  container: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  location: {
    fontSize: 24,
    fontWeight: 'bold',
    paddingHorizontal: 4,
  },
  modalBackground: {
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    flex: 1,
    justifyContent: 'center',
  },
  modalContainer: {
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: 5,
    padding: 20,
    width: '70%',
  },
  modalContent: {
    marginTop: 10,
    width: '100%',
  },
  modalOption: {
    paddingHorizontal: 20,
    paddingVertical: 10,
    textAlign: 'center',
    width: '100%',
  },
  modalTitle: {
    alignSelf: 'flex-start',
    fontWeight: 'bold',
    marginBottom: 10,
  },
});
