import {FirebaseRecaptchaVerifierModal} from 'expo-firebase-recaptcha';
import {FirebaseError} from 'firebase/app';
import {PhoneAuthProvider} from 'firebase/auth';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
  Image,
  Linking,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import PhoneInput from 'react-native-phone-number-input';

import Checkbox from '../../components/buttons/Checkbox';
import alert from '../../components/container/alert';
import Colors from '../../constants/Colors';
import {FIREBASE_CONFIG} from '../../constants/config';
import {getAuth} from '../../firebase';

export default function Navbar({isScreenWide, navigation, language}: any) {
  const referCode = '';
  // no referCode for now
  const [isInit, setisInit] = useState(false);
  const [value, setValue] = useState('');
  const [formattedValue, setFormattedValue] = useState('');
  const [requestCodeDisabled, setRequestCodeDisabled] =
    useState<boolean>(false);
  const [isChecked, setIsChecked] = useState(true);

  const phoneInput = useRef<PhoneInput>(null);
  const recaptchaVerifier = useRef(null);

  const isWeChat =
    Platform.OS === 'web' && navigator.userAgent.indexOf('MicroMessenger') > -1;

  const auth = getAuth();

  const onRequestCode = useCallback(async () => {
    setRequestCodeDisabled(true);
    try {
      const phoneProvider = new PhoneAuthProvider(auth);
      const phoneVerifyId = await phoneProvider.verifyPhoneNumber(
        formattedValue,
        // @ts-ignore null value will be caught in the catch statement
        recaptchaVerifier.current,
      );
      setRequestCodeDisabled(false);
      navigation.navigate('LoginModal', {
        params: {
          formattedPhoneNumber: formattedValue,
          referCode: referCode ?? '',
          verificationId: phoneVerifyId,
        },
        screen: 'Verify',
      });
    } catch (error: unknown) {
      const title = '登陆失败';
      let description = '请稍后再试';
      if (error instanceof FirebaseError) {
        description = '错误代码: ' + error.code;
        if (error.code === 'auth/too-many-requests') {
          description += '; 尝试登陆太多次，请稍后再试';
        }
      }
      if (isWeChat) {
        description +=
          ' 如果您在微信内置网页登陆遇到问题, 请点击右上角，在默认浏览器打开';
      }
      alert(title, description, [{onPress: () => {}, text: '确定'}]);
      setRequestCodeDisabled(false);
      console.log('Error happens when verifying the phone number, ' + error);
    }
  }, [auth, formattedValue, isWeChat, navigation, referCode]);

  const disabled =
    requestCodeDisabled ||
    !phoneInput.current?.isValidNumber(value) ||
    !isChecked;

  let description = '请重新登陆';
  if (!phoneInput.current?.isValidNumber(value)) {
    description = '请输入有效的电话号码';
  } else if (isWeChat) {
    description =
      '如果您在微信内置网页登陆遇到问题, 请点击右上角，在默认浏览器打开';
  } else if (!isChecked) {
    description = '请阅读并同意用户协议，隐私政策和接受服务短信';
  }

  const alertUser = () => {
    alert(
      '',
      description,
      [{onPress: () => console.log('OK pressed'), text: '确定'}],
      {cancelable: true},
    );
  };

  useEffect(() => {
    setTimeout(function () {
      setisInit(true);
    }, 1000);
    return () => {
      setisInit(false);
    };
  }, []);

  useEffect(() => {
    console.log(formattedValue);
  }, [formattedValue]);

  return (
    <View
      style={[
        styles.hero,
        isScreenWide && {
          flexDirection: 'row',
          paddingBottom: 30,
        },
      ]}>
      {/* Hero Image */}
      <View>
        <Image
          source={require('../../assets/images/AI_avatar.png')}
          style={{height: 430, width: 430}}
        />
      </View>
      {/* Main header */}
      <View
        style={[
          styles.header,
          isScreenWide && {
            flexBasis: '60%',
          },
        ]}>
        {/* Headings */}
        <View style={styles.heading}>
          <Text style={[styles.headingTitle, isScreenWide && {fontSize: 52}]}>
            {language === 'english'
              ? 'YOUR BEST AI BESTIE'
              : '新人不迷路，单人不孤独'}
          </Text>

          <Text style={styles.headingSubtitle}>
            {language === 'english'
              ? 'Discover Your Destination: Get messages for the Hottest Local Parties and Events.'
              : '北美华人组队工具，会玩爱玩的年轻人都在这里 \n 运动/户外/旅游/城市活动/文化艺术/桌游唱k'}
          </Text>
          <View
            style={[
              styles.signUpBtnContainer,
              isScreenWide && styles.signUpBtnContainerWide,
            ]}>
            <View style={styles.phoneInputContainer}>
              <PhoneInput
                autoFocus={false}
                containerStyle={{
                  borderColor: 'black',
                  borderRadius: 10,
                  borderWidth: 1,
                  flex: 1,
                  height: 45,
                }}
                defaultCode="US"
                defaultValue={value}
                flagButtonStyle={{
                  width: 65,
                }}
                layout="second"
                onChangeFormattedText={(text) => {
                  setFormattedValue(text);
                }}
                onChangeText={(text) => {
                  setValue(text);
                }}
                placeholder=" "
                ref={phoneInput}
                textContainerStyle={{
                  backgroundColor: 'transparent',
                  paddingLeft: 10,
                  paddingRight: 60,
                }}
                textInputProps={{returnKeyType: 'done'}}
                textInputStyle={{
                  width: 160,
                }}
              />
            </View>

            <TouchableOpacity
              disabled={disabled}
              onPress={disabled ? alertUser : onRequestCode}
              style={[
                styles.signUpBtn,
                !isScreenWide && {marginTop: 15},
                disabled && {opacity: 0.5},
              ]}>
              <Text style={styles.signUpBtnText}>
                {language === 'english' ? 'Sign Up' : '注册'}
              </Text>
            </TouchableOpacity>
          </View>
          {isInit && (
            <FirebaseRecaptchaVerifierModal
              androidHardwareAccelerationDisabled
              androidLayerType="software"
              attemptInvisibleVerification
              firebaseConfig={FIREBASE_CONFIG}
              ref={recaptchaVerifier}
            />
          )}
          <View
            style={{
              flexDirection: 'row',
              marginTop: 10,
              paddingHorizontal: 50,
            }}>
            <View style={styles.checkbox}>
              <Checkbox onChange={setIsChecked} value={isChecked} />
            </View>
            {language === 'english' ? (
              <Text style={[styles.checkboxText, {color: '#616161'}]}>
                By signing up, I agree to and am bound by{' '}
                <Text
                  onPress={() => {
                    navigation.navigate('UserTerms');
                  }}
                  style={styles.linkText}>
                  the User Terms
                </Text>
                {', '}
                <Text
                  onPress={() => {
                    navigation.navigate('PrivacyPolicy');
                  }}
                  style={styles.linkText}>
                  Privacy Policy
                </Text>
                {', '}
                <Text
                  onPress={() => {
                    navigation.navigate('ResponsibilityTerms');
                  }}
                  style={styles.linkText}>
                  Responsibility Terms
                </Text>{' '}
                and consent to receive SMS messages for service purposes
              </Text>
            ) : (
              <Text style={[styles.checkboxText, {color: '#616161'}]}>
                登陆代表您同意野兔组队
                <Text
                  onPress={() => {
                    navigation.navigate('UserTerms');
                  }}
                  style={styles.linkText}>
                  用户服务协议
                </Text>
                {', '}
                <Text
                  onPress={() => {
                    navigation.navigate('PrivacyPolicy');
                  }}
                  style={styles.linkText}>
                  隐私协议
                </Text>
                {', '}
                <Text
                  onPress={() => {
                    navigation.navigate('ResponsibilityTerms');
                  }}
                  style={styles.linkText}>
                  免责声明
                </Text>
                和接受服务短信
              </Text>
            )}
          </View>
          <View
            style={[
              styles.downloadBtnContainer,
              isScreenWide && styles.downloadBtnContainerWide,
            ]}>
            <TouchableOpacity
              onPress={() => {
                Linking.openURL(
                  'https://play.google.com/store/apps/details?id=com.yaytour.androidapp',
                );
              }}>
              <Image
                source={require('../../assets/images/google_download.png')}
                style={styles.downloadImage}
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                Linking.openURL(
                  'https://apps.apple.com/us/app/%E9%87%8E%E5%85%94%E7%BB%84%E9%98%9F/id6448244192',
                );
              }}>
              <Image
                source={require('../../assets/images/ios_download.png')}
                style={styles.downloadImage}
              />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  checkbox: {
    justifyContent: 'flex-start',
    marginRight: 8,
    marginTop: -2,
  },
  checkboxText: {
    flex: 1,
    flexDirection: 'row',
    fontSize: 12,
    lineHeight: 18,
    marginBottom: 24,
    textAlign: 'left',
  },
  downloadBtnContainer: {
    alignItems: 'center',
    flexDirection: 'column',
    gap: 20,
    marginTop: 30,
    paddingHorizontal: 30,
    width: 450,
  },
  downloadBtnContainerWide: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  downloadImage: {
    height: 60,
    width: 185,
  },
  header: {
    width: '100%',
  },
  heading: {
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 'auto',
    maxWidth: 680,
    paddingHorizontal: 20,
    paddingVertical: 45,
    width: '100%',
  },
  headingSubtitle: {
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 24,
    marginTop: 10,
    paddingVertical: 20,
    textAlign: 'center',
  },
  headingTitle: {
    color: Colors.mainAppColor,
    fontSize: 36,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  hero: {
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 'auto',
    marginTop: 120,
    width: '100%',
  },
  heroImage: {
    flex: 1,
    maxHeight: 450,
    maxWidth: 450,
  },
  landingPageContainer: {
    backgroundColor: 'white',
    flex: 1,
  },
  linkText: {
    color: '#66BB6A',
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: 18,
    textDecorationLine: 'underline',
  },
  phoneInputContainer: {
    flexDirection: 'row',
    width: '48%',
  },
  signUpBtn: {
    backgroundColor: Colors.mainAppColor,
    borderRadius: 8,
    height: 44,
    justifyContent: 'center',
    width: '48%',
  },
  signUpBtnContainer: {
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 30,
    paddingHorizontal: 30,
    width: 600,
  },
  signUpBtnContainerWide: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  signUpBtnText: {
    color: 'white',
    fontWeight: '600',
    margin: 'auto',
    padding: 24,
  },
});
