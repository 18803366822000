import {Ionicons} from '@expo/vector-icons';
import {useNavigation} from '@react-navigation/native';
import {getFunctions, httpsCallable} from 'firebase/functions';
import React, {useLayoutEffect, useState} from 'react';
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import {CreatePaymentIntentResult} from '../../functions/src';
import alert from '../components/container/alert';
import Colors from '../constants/Colors';
import {YAYTOUR_WECHAT_SUPPORT} from '../constants/config';
import {addJoinedMember} from '../helpers/util';

export default function TicketOptionModalScreen({route}: {route: any}) {
  const navigation: any = useNavigation();
  const {group, currentUser} = route.params;
  const [ticketOptions, setTicketOptions] = useState(group.ticketOptions);
  const [quantities, setQuantities] = useState(
    group.ticketOptions.map(() => 0),
  );

  // Plus and Minus buttons handlers

  const handleMinusPress = (index: any) => {
    const minQtyPB = parseInt(ticketOptions[index].minQtyPB, 10) || 0;
    const updatedQuantities = [...quantities];
    if (quantities[index] === minQtyPB) {
      updatedQuantities[index] -= minQtyPB;
    } else {
      updatedQuantities[index] -= 1;
    }
    setQuantities(updatedQuantities);
  };

  const handlePlusPress = (index: number) => {
    const updatedQuantities = [...quantities];
    const minQtyPB = parseInt(ticketOptions[index].minQtyPB, 10) || 0;
    updatedQuantities[index] = Math.max(updatedQuantities[index] + 1, minQtyPB);
    setQuantities(updatedQuantities);
  };

  // Functions for button disabling

  function disableMinusButton(index: number) {
    return quantities[index] <= 0;
  }

  function isMaxQtyPB(index: number) {
    return (
      ticketOptions[index].maxQtyPB !== '' &&
      quantities[index] >= ticketOptions[index].maxQtyPB
    );
  }

  function isMaxOptionTickets(index: number) {
    const optionTotalTickets = ticketOptions[index].optionQuantity;
    return quantities[index] === parseInt(optionTotalTickets);
  }

  function disableOnePlusButton(index: number) {
    return isMaxOptionTickets(index) || isMaxQtyPB(index);
  }

  function disableAllPlusButton() {
    const maximum = group?.numMemberRequired - group?.currentMembers.length;
    const joinedNumber = quantities.reduce((a: number, b: number) => a + b, 0);
    return joinedNumber === maximum;
  }

  function disableContinueButton() {
    const joinedNumber = quantities.reduce((a: number, b: number) => a + b, 0);
    return joinedNumber === 0;
  }

  // Calculate total sum

  const getTotalSum = () => {
    let totalSum = 0;
    ticketOptions.forEach((option: any, index: number) => {
      totalSum += quantities[index] * option.optionPrice;
    });
    return totalSum.toFixed(2);
  };

  const handlePayment = async () => {
    if (group.paymentInstruction === 'venmo_or_zelle') {
      handleVenmoPayment();
    } else {
      handleStripePayment();
    }
  };

  const handleStripePayment = async () => {
    const requestData = {
      email: currentUser?.email,
      group_id: group.id,
      phone_number: currentUser?.phoneNumber,
      // price_after_discount: totalAmountAfterDiscount,
      quantities: quantities,
      ticketOptions: ticketOptions,
      user_name: currentUser?.userName,
      yaytour_user_id: currentUser?.id,
    };

    const functions = getFunctions();
    const ticketPaymentIntent = httpsCallable(
      functions,
      'createticketpaymentintent',
    );

    try {
      const result = await ticketPaymentIntent(requestData);
      const paymentIntentResult = result.data as CreatePaymentIntentResult;

      navigation.navigate('JoinGroupModal', {
        params: {
          clientSecret: paymentIntentResult.client_secret,
          customerEphemeralKey: paymentIntentResult.customer_ephemeral_key,
          group: group,
          hasTicketOptions: true,
          numberOfPeople: quantities.reduce((a: number, b: number) => a + b, 0),
          paymentIntentId: paymentIntentResult.payment_intent_id,
          priceWithoutFee: paymentIntentResult.price_before_fee,
          quantities: quantities,
          serviceFee: paymentIntentResult.tax_and_fees,
          stripeCustomerID: paymentIntentResult.customer,
        },
        screen: 'PaymentModalScreen',
      });
    } catch (error) {
      alert(
        '付款时发生错误',
        `请稍后重试或联系野兔小助手～ 微信号：${YAYTOUR_WECHAT_SUPPORT}`,
        [
          {
            onPress: () => {},
            text: '确定',
          },
        ],
      );
      console.error('error occurs when getting stripe payment data: ', error);
    }
  };

  const handleVenmoPayment = async () => {
    const joinedNumber = quantities.reduce((a: number, b: number) => a + b, 0);
    // add user to paid members
    const paidTicketOptions: any = [];
    // For updating group ticket options with new ticket counts
    const updatedTicketOptions = group.ticketOptions || [];

    group.ticketOptions.forEach((option: any, index: number) => {
      if (quantities[index] > 0) {
        paidTicketOptions.push({
          optionName: option.optionName,
          optionPrice: option.optionPrice,
          quantity: quantities[index],
        });

        updatedTicketOptions[index].optionQuantity = (
          parseInt(updatedTicketOptions[index].optionQuantity) -
          quantities[index]
        ).toString();
      }
    });

    try {
      // Update paid members in group details
      await addJoinedMember(
        group.id,
        currentUser?.id ?? '',
        currentUser?.userName ?? '',
        joinedNumber,
        true,
        true,
        paidTicketOptions ?? [],
        '',
      );
      await navigation.navigate('JoinGroupModal', {
        params: {
          group: group,
          hasTicketOptions: true,
          numOfNew: joinedNumber,
          standAlone: false,
          totalSum: getTotalSum(),
        },
        screen: 'JoinGroupPaymentScreen',
      });
    } catch (error) {
      alert('发送错误', ' 加入组队时发生错误: ' + error, [
        {
          onPress: () => {},
          text: '确定',
        },
      ]);
      console.error('error occurs when joining group: ', error);
    }
  };

  const TitleView = () => {
    return (
      <View style={styles.titleView}>
        <Text style={styles.title}>加入组队</Text>
      </View>
    );
  };

  const OptionCard = ({option, index}: any) => {
    return (
      <View style={styles.cardContainer}>
        <View style={styles.cardRow}>
          <Text style={styles.cardTitle}>{option.optionName}</Text>

          <View style={styles.quantityContainer}>
            <View style={styles.quantity}>
              <TouchableOpacity
                disabled={disableMinusButton(index)}
                onPress={() => handleMinusPress(index)}
                style={styles.button}>
                <Ionicons
                  color={disableMinusButton(index) ? 'lightgrey' : 'black'}
                  name="remove-outline"
                  size={20}
                  type="AntDesign"
                />
              </TouchableOpacity>
              <Text style={styles.number}>{quantities[index]}</Text>
              <TouchableOpacity
                disabled={disableAllPlusButton() || disableOnePlusButton(index)}
                onPress={() => handlePlusPress(index)}
                style={styles.button}>
                <Ionicons
                  color={
                    disableAllPlusButton() || disableOnePlusButton(index)
                      ? 'lightgrey'
                      : 'black'
                  }
                  name="add-outline"
                  size={20}
                  type="AntDesign"
                />
              </TouchableOpacity>
            </View>

            {(option.minQtyPB && parseInt(option.minQtyPB, 10) > 0) ||
            (option.maxQtyPB && parseInt(option.maxQtyPB, 10) > 0) ? (
              <Text style={{marginVertical: 5}}>
                每订单
                {option.minQtyPB && parseInt(option.minQtyPB, 10) > 0
                  ? `最少${option.minQtyPB}张`
                  : ''}
                {option.minQtyPB && option.maxQtyPB ? '，' : ''}
                {option.maxQtyPB && parseInt(option.maxQtyPB, 10) > 0
                  ? `最多${option.maxQtyPB}张`
                  : ''}
              </Text>
            ) : null}

            {isMaxQtyPB(index) && (
              <Text style={styles.alertText}>已达每单购票上限</Text>
            )}
            {isMaxOptionTickets(index) && (
              <Text style={styles.alertText}>门票库存不足</Text>
            )}
            {disableAllPlusButton() && (
              <Text style={styles.alertText}>已达组队人数上限</Text>
            )}
          </View>
        </View>

        <View style={styles.divider} />

        <View style={styles.cardRow}>
          <View>
            <Text style={styles.optionPrice}>${option.optionPrice}</Text>
            {/* <Text style={styles.optionTax}>+ $22 手续费和税</Text> */}
            <Text style={styles.optionTax}>手续费和税费将在支付页面显示</Text>
          </View>
        </View>
      </View>
    );
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      headerBackVisible: false,
      headerTitle: () => <TitleView />,
    });
  });

  return (
    <ScrollView contentContainerStyle={{flexGrow: 1}}>
      <View style={styles.container}>
        <Text style={styles.text}>您可以选择如下的活动套餐：</Text>

        {ticketOptions.map((option: any, index: number) => (
          <OptionCard index={index} option={option} />
        ))}

        <Text style={styles.totalPrice}>总共：${getTotalSum()}</Text>
        <TouchableOpacity
          disabled={disableContinueButton()}
          onPress={() => handlePayment()}
          style={[
            styles.continueButton,
            disableContinueButton() && {opacity: 0.5},
          ]}>
          <Text style={styles.continueButtonText}>继续付款</Text>
        </TouchableOpacity>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  alertText: {
    color: 'red',
    marginTop: 5,
    textAlign: 'center',
  },
  button: {
    alignContent: 'center',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    paddingHorizontal: 15,
  },
  cardContainer: {
    backgroundColor: 'white',
    borderColor: 'gray',
    borderRadius: 12,
    borderWidth: 0.5,
    margin: 10,
    width: '100%',
  },
  cardRow: {
    alignContent: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 20,
  },
  cardTitle: {
    alignSelf: 'center',
    fontSize: 16,
    fontWeight: 'bold',
    maxWidth: '50%',
  },
  container: {
    alignItems: 'center',
    backgroundColor: 'white',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingHorizontal: 16,
  },
  continueButton: {
    alignContent: 'center',
    backgroundColor: Colors.mainAppColor,
    borderRadius: 5,
    flex: 1,
    justifyContent: 'center',
    margin: 8,
    padding: 8,
    width: '100%',
  },
  continueButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  divider: {
    alignSelf: 'center',
    backgroundColor: 'lightgray',
    flexDirection: 'column',
    height: 0.5,
    width: '100%',
  },
  number: {
    color: 'black',
    fontSize: 16,
    paddingHorizontal: 20,
    textAlign: 'center',
  },
  optionPrice: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  optionTax: {
    color: 'gray',
    fontSize: 14,
  },
  quantity: {
    alignItems: 'center',
    alignSelf: 'center',
    borderColor: 'black',
    borderRadius: 10,
    borderWidth: 1,
    flexDirection: 'row',
    height: 35,
    justifyContent: 'space-between',
    paddingHorizontal: 5,
    width: 180,
  },
  quantityContainer: {
    flexDirection: 'column',
  },
  text: {
    flexDirection: 'row',
    fontSize: 18,
    paddingVertical: 8,
    width: '100%',
  },
  title: {
    fontSize: 20,
  },
  titleView: {
    alignItems: 'center',
    alignSelf: 'center',
    flexDirection: 'row',
  },
  totalPrice: {
    fontSize: 20,
    fontWeight: 'bold',
    paddingVertical: 20,
    textAlign: 'right',
    width: '100%',
  },
});
