import {useNavigation} from '@react-navigation/native';
import {collection, limit, query, Timestamp, where} from 'firebase/firestore';
import {useCollection} from 'react-firebase-hooks/firestore';
import {
  FlatList,
  Pressable,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import Colors from '../../constants/Colors';
import Layout from '../../constants/Layout';
import {useAuthContext} from '../../context/AuthContext';
import {getFirestore} from '../../firebase';
import groupConverter from '../../firestoreConverters/groupConverter';
import {Group} from '../../types';
import GroupCardViewVertical from './GroupCardViewVertical';

export default function SimilarGroupSection({
  originalGroup,
}: {
  originalGroup: Group;
}) {
  const groupRef = collection(getFirestore(), 'groups');
  const {currentUser} = useAuthContext();

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const q = query(
    groupRef,
    where('privateGroup', '==', false),
    where('dateStart', '>=', today),
    limit(6),
  );
  const [similarGroups] = useCollection(q.withConverter(groupConverter));
  const groupList =
    similarGroups != undefined
      ? similarGroups?.docs.map((post) => {
          return post.data();
        })
      : [];

  const filteredGroup = groupList.filter(
    (item: Group) =>
      item.dateStart &&
      Timestamp.now().seconds - item.dateStart.seconds < 0 &&
      item.id !== originalGroup?.id &&
      item.status !== 'deleted' &&
      !currentUser?.blockedUsersList?.includes(item?.creator?.id),
  );

  const navigation: any = useNavigation();

  if (filteredGroup.length < 1) {
    return null;
  }

  const renderItem = ({item}: {item: Group}) => {
    return (
      <View style={{flexDirection: 'row'}}>
        <View style={{width: 10}} />
        <Pressable
          key={item.id}
          onPress={() => {
            navigation.push('GroupDetails', {groupId: item.id});
          }}>
          <GroupCardViewVertical group={item} />
        </Pressable>
        <View style={{width: 10}} />
      </View>
    );
  };

  const handleGoGroupHome = () => {
    navigation.push('GroupHome');
  };

  return (
    <View>
      <View
        style={{
          alignItems: 'center',
          flexDirection: 'row',
          marginVertical: 15,
        }}>
        <View style={{backgroundColor: '#e4e4e4', flex: 1, height: 1}} />
        <View>
          <Text style={{textAlign: 'center', width: 100}}>周边活动</Text>
        </View>
        <View style={{backgroundColor: '#e4e4e4', flex: 1, height: 1}} />
      </View>
      <View style={{alignItems: 'stretch', flexDirection: 'row'}}>
        <TouchableOpacity
          onPress={handleGoGroupHome}
          style={styles.groupHomeButton}>
          <View style={{flex: 1}} />
          <Text style={styles.groupHomeButtonText}>查看</Text>
          <Text style={styles.groupHomeButtonText}>所有</Text>
          <Text style={styles.groupHomeButtonText}>活动</Text>
          <View style={{flex: 1}} />
        </TouchableOpacity>
        <FlatList
          data={filteredGroup}
          horizontal={true}
          renderItem={renderItem}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  groupHomeButton: {
    borderColor: Colors.mainAppColor,
    borderRadius: Layout.groupCardBorderRadius,
    borderWidth: 1,
    marginHorizontal: 10,
    marginVertical: Layout.groupCardMarginVertical,
    paddingHorizontal: 20,
    paddingVertical: 20,
  },
  groupHomeButtonText: {},
});
