// Import the functions you need from the SDKs you need
import {Analytics, getAnalytics} from 'firebase/analytics';
import {FirebaseApp, initializeApp} from 'firebase/app';
import type {Auth} from 'firebase/auth';
import {getAuth as getFirebaseAuth} from 'firebase/auth';
import {Firestore, initializeFirestore} from 'firebase/firestore';
import {
  Functions,
  getFunctions as getFirebaseFunctions,
} from 'firebase/functions';
import {getStorage} from 'firebase/storage';

import {FIREBASE_CONFIG} from './constants/config';

export const getFirebaseConfig = () => {
  // TODO(chenfada) Add staging
  return FIREBASE_CONFIG;
};

let app: FirebaseApp;
export function initializeFirebase(): FirebaseApp {
  if (!app) {
    app = initializeApp(getFirebaseConfig());
  }
  return app;
}

export function getAnalyticsInstance(): Analytics {
  if (!app) {
    initializeFirebase();
  }

  return getAnalytics(app);
}

export function getAuth(): Auth {
  if (!app) {
    initializeFirebase();
  }

  return getFirebaseAuth(app);
}

// TODO(chenfada) set up devlopment in emulator
export function getFirestore(): Firestore {
  if (!app) {
    initializeFirebase();
  }

  // see https://github.com/firebase/firebase-js-sdk/issues/6993
  const firestore = initializeFirestore(app, {
    experimentalForceLongPolling: true,
  });
  return firestore;
}

export function getFireStorage() {
  if (!app) {
    initializeFirebase();
  }

  const fireStorage = getStorage(app);
  return fireStorage;
}

export function getFunctions(): Functions {
  if (!app) {
    initializeFirebase();
  }
  const functions = getFirebaseFunctions(app);
  return functions;
}
