import {StyleSheet, Text, TextProps} from 'react-native';

export function InfoText(props: TextProps) {
  return <Text {...props} style={[styles.default, props.style]} />;
}

const styles = StyleSheet.create({
  default: {
    color: '#616161',
    fontSize: 12,
    fontWeight: '500',
    lineHeight: 18,
    width: '100%',
  },
});
