import {FontAwesome} from '@expo/vector-icons';
import {useNavigation} from '@react-navigation/native';
import {Overlay} from '@rneui/themed';
import {getAnalytics, logEvent, setUserId} from 'firebase/analytics';
import {
  collection,
  doc,
  getDoc,
  query,
  Timestamp,
  where,
} from 'firebase/firestore';
import {httpsCallable} from 'firebase/functions';
import React, {useEffect, useLayoutEffect, useMemo, useState} from 'react';
import equal from 'react-fast-compare';
import {useCollection} from 'react-firebase-hooks/firestore';
import {
  AppState,
  Dimensions,
  FlatList,
  Platform,
  Pressable,
  SafeAreaView,
  ScrollView,
  StatusBar,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import {
  FetchGroupsForRatingRequest,
  FetchGroupsForRatingResult,
} from '../../functions/src';
import WebDeeplinkHeaderView from '../components/container/WebDeeplinkHeaderView';
import CarouselBannerView from '../components/group/CarouselBannerView';
import ChangeLocationBar from '../components/group/ChangeLocationBar';
import GroupCardViewVertical from '../components/group/GroupCardViewVertical';
import GroupTagSelectionView from '../components/group/GroupTagSelectionView';
import FocusAwareStatusBar from '../components/misc/FocusAwareStatusBar';
import OpenInDefaultBrowserScreen from '../components/misc/OpenInDefaultBrowser';
import {
  ONE_DAY_IN_MILLIS,
  RATING_POP_UP_LIFETIME_IN_DAYS,
} from '../constants/config';
import {ENABLE_WECHAT_REDIRECT} from '../constants/dev';
import Layout from '../constants/Layout';
import {useAuthContext} from '../context/AuthContext';
import {useChatContext} from '../context/ChatContext';
import {useFeaturesContext} from '../context/FeaturesContext';
import {getFirestore, getFunctions} from '../firebase';
import groupConverter from '../firestoreConverters/groupConverter';
import groupTagConverter from '../firestoreConverters/groupTagConverter';
import isUserProfileCompeleted from '../helpers/isUserProfileCompeleted';
import {isWeChat, judgeClient} from '../helpers/util';
import {Group, GroupTag} from '../types';
import LoadingScreen from './LoadingScreen';

const {width: WINDOW_WIDTH} = Dimensions.get('window');

export default function GroupHomeScreen({navigation}: {navigation: any}) {
  const {currentUser} = useAuthContext();
  const {setSkipLoadingChatContext} = useChatContext();
  if (Platform.OS === 'web') {
    const analytics = getAnalytics();
    setUserId(analytics, currentUser == undefined ? null : currentUser.id);
    logEvent(analytics, 'user_view_grouphome');
  }
  const {features} = useFeaturesContext();
  const [showWechatRedirect, setShowWechatRedirect] = useState(false);
  const toggleOverlay = () => {
    setShowWechatRedirect(!showWechatRedirect);
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const [groupsSnapshot, isGroupLoading] = useCollection(
    query(
      collection(getFirestore(), 'groups').withConverter(groupConverter),
      where('dateStart', '>=', today),
    ),
  );
  const allGroups: Group[] = [];
  if (groupsSnapshot) {
    groupsSnapshot.docs.forEach((doc) => {
      const group = doc.data();
      const registrationDeadline =
        group.registrationDeadline == null
          ? group.dateStart
          : group.registrationDeadline;
      if (
        group.privateGroup === false &&
        Timestamp.now().seconds - registrationDeadline.seconds < 0 &&
        group.status !== 'deleted' &&
        !currentUser?.blockedUsersList?.includes(group?.creator?.id)
      ) {
        allGroups.push(group);
      }
    });
  }

  const popUpGroupsForRating = async () => {
    if (!features?.enable_rating_pop_up || !currentUser?.id) {
      return;
    }
    const functions = getFunctions();
    const fetchGroupsForRatingIntent = httpsCallable(
      functions,
      'fetchgroupsforrating',
    );
    var showDateFromInMillis =
      Timestamp.now().toMillis() -
      RATING_POP_UP_LIFETIME_IN_DAYS * ONE_DAY_IN_MILLIS;
    if (
      currentUser?.lastRatingTime &&
      currentUser?.lastRatingTime?.toMillis() > showDateFromInMillis
    ) {
      showDateFromInMillis = currentUser?.lastRatingTime.toMillis();
    }
    const fetchGroupsForRatingRequest: FetchGroupsForRatingRequest = {
      show_from_timestamp_millis: showDateFromInMillis,
      user_id: currentUser?.id ?? '',
    };

    const result = await fetchGroupsForRatingIntent(
      fetchGroupsForRatingRequest,
    );
    const fetchGroupsForRatingResult =
      result.data as FetchGroupsForRatingResult;

    const ratingGroups: Group[] = [];
    for (
      let index = 0;
      index < fetchGroupsForRatingResult.group_id_list.length;
      index++
    ) {
      const groupId = fetchGroupsForRatingResult.group_id_list[index];
      const ref = doc(getFirestore(), 'groups', groupId).withConverter(
        groupConverter,
      );
      const groupSnap = await getDoc(ref);
      if (!groupSnap.exists()) {
        continue;
      }
      ratingGroups.push(groupSnap.data());
    }

    if (ratingGroups.length > 0) {
      navigation.push('RatingPopUpScreen', {
        groups: ratingGroups,
      });
    }
  };

  useEffect(() => {
    // hook for ios/android app only, execute when app state changed from background to foreground
    if (Platform.OS === 'android' || Platform.OS === 'ios') {
      const handleAppStateChange = (nextAppState) => {
        if (nextAppState === 'active') {
          // App is now in the foreground, trigger your action here
          popUpGroupsForRating();
        }
      };
      AppState.addEventListener('change', handleAppStateChange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array ensures effect runs only once

  useEffect(() => {
    // hook for web, ios and android, execute when feature flag or user is loaded
    popUpGroupsForRating();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [features?.enable_rating_pop_up, currentUser?.id]);

  // Update current location based on the user's location
  useEffect(() => {
    let currentUserLocation;
    switch (currentUser?.location) {
      case 'Boston':
        currentUserLocation = '波士顿地区';
        break;
      case 'BayArea':
        currentUserLocation = '湾区';
        break;
      default:
        currentUserLocation = '纽约地区';
    }
    setSelectedArea(currentUserLocation);
  }, [currentUser?.location]);

  // Note for Group sorting/filtering. It's a three-step process:
  // 1. Sort groups by rankingFactor, then by startDate
  //    This allows us to rank arbitrary groups to the top of the list
  // 2. Filter groups by selectedArea and selectedLabel from filter bar
  //    selectedArea and labels in filter bar has a higher priority than tags
  // 3. Filter groups by user selected tags from filter modal

  // This is Step 1
  const sortedGroups = allGroups.slice().sort((a, b) => {
    const startDataOfA = a.dateStart;
    const startDataOfB = b.dateStart;

    if (a.rankingFactor > b.rankingFactor) {
      return -1;
    } else if (a.rankingFactor < b.rankingFactor) {
      return 1;
    } else {
      return startDataOfA.toMillis() - startDataOfB.toMillis();
    }
  });

  const [selectedArea, setSelectedArea] = useState('纽约地区');
  const [selectedLabel, setSelectedLabel] = useState('all');
  const handleLabelChanged = (label: string) => {
    setSelectedLabel(label);
  };
  const followingCreators = new Set(currentUser?.followingList);

  const [groupTagsSnapshot, isTagsLoading] = useCollection(
    collection(getFirestore(), 'groupTags').withConverter(groupTagConverter),
  );
  let filterList: GroupTag[] = [];
  let filterListForModal: GroupTag[] = [];
  if (groupTagsSnapshot) {
    groupTagsSnapshot?.docs.forEach((doc) => {
      const tag = doc.data() as GroupTag;
      if (tag.shownInFilterBar) {
        filterList.push(tag);
      }

      if (
        tag.tagValue !== 'all' &&
        tag.tagValue !== 'selected' &&
        tag.tagValue !== 'following'
      ) {
        filterListForModal.push(tag);
      }
    });
    filterList.sort((a, b) => (a.index ?? 0) - (b.index ?? 0));
  }

  // This is Step 2
  const groupsListed = sortedGroups.filter((group) => {
    // following has the highest priority, ignore area
    if (selectedLabel === 'following') {
      return followingCreators.has(group.creator.id);
    }

    const areasToGroupTypes: Record<string, string> = {
      波士顿地区: 'around_bos',
      湾区: 'around_bay_area',
      纽约地区: 'around_ny',
    };

    if (selectedLabel === 'all') {
      return (
        group.groupType === areasToGroupTypes[selectedArea] ||
        group.groupType === 'tour'
      );
    }

    if (selectedLabel === 'selected') {
      return group.officialTag === 'selected';
    }

    return group.userGeneratedTag?.includes(selectedLabel);
  });

  const [isFilterVisible, setFilterVisible] = useState(false);
  const [userSelectedFilters, setUserSelectedFilters] = useState<Set<string>>(
    new Set(),
  );

  const filteredGroups: Group[] = useMemo(() => {
    function filterGroupsUsingTags() {
      if (userSelectedFilters.size === 0) {
        return groupsListed;
      } else {
        let newGroupsListed: Group[] = [];

        userSelectedFilters.forEach((filter) => {
          const filteredOut = groupsListed.filter((group) => {
            return group.userGeneratedTag?.includes(filter);
          });

          const ids = new Set(newGroupsListed.map((d) => d.id));
          newGroupsListed = [
            ...newGroupsListed,
            ...filteredOut.filter((d) => !ids.has(d.id)),
          ];
        });

        return newGroupsListed;
      }
    }

    return filterGroupsUsingTags();
  }, [groupsListed, userSelectedFilters]);

  // To prevent BannerView from flickering
  const [flatListHeader, setFlatListHeader] = useState(<View />);
  useLayoutEffect(() => {
    if (features?.enable_banner_ad) {
      setFlatListHeader(
        <View style={{marginTop: 10}} testID={`carousel_banner_container`}>
          <CarouselBannerView />
        </View>,
      );
    }
  }, [features?.enable_banner_ad]);

  if (isGroupLoading || isTagsLoading) {
    return (
      <SafeAreaView style={styles.safeAreaStyle}>
        <LoadingScreen />
      </SafeAreaView>
    );
  }

  setSkipLoadingChatContext(false);
  return (
    <SafeAreaView style={styles.safeAreaStyle} testID={`group_home_container`}>
      <View style={styles.container}>
        {showWechatRedirect && (
          <Overlay
            fullScreen={true}
            isVisible={showWechatRedirect}
            onBackdropPress={toggleOverlay}>
            <OpenInDefaultBrowserScreen />
          </Overlay>
        )}
        {features?.web_deeplink_feature &&
          (judgeClient() === 'web-android' || judgeClient() === 'web-ios') && (
            <WebDeeplinkHeaderView path="groups" />
          )}
        <FocusAwareStatusBar style="dark" />
        <View
          style={{
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingRight: 25,
          }}>
          <ChangeLocationBar
            location={selectedArea}
            setCurrentLocation={setSelectedArea}
            userId={currentUser?.id}
          />

          {features?.enable_group_tags && (
            <View testID={`group_home_group_tag_toggle`}>
              <FontAwesome
                color={userSelectedFilters.size === 0 ? 'black' : 'green'}
                name="filter"
                onPress={() => setFilterVisible(!isFilterVisible)}
                size={24}
              />
            </View>
          )}
        </View>

        <ScrollView
          horizontal={true}
          showsHorizontalScrollIndicator={false}
          style={styles.horizontalScrollView}>
          <View style={styles.filterBarContainer}>
            {filterList.map((tag) => (
              <TouchableOpacity
                key={tag.tagValue}
                onPress={() => {
                  handleLabelChanged(tag.tagValue);
                }}
                style={[
                  styles.filterButton,
                  selectedLabel === tag.tagValue && {
                    borderBottomColor: '#66BB6A',
                  },
                ]}>
                <Text style={styles.description}>{tag.title}</Text>
              </TouchableOpacity>
            ))}
          </View>
        </ScrollView>
        <View style={styles.groupCardList}>
          <FlatList
            ListHeaderComponent={flatListHeader}
            columnWrapperStyle={styles.columnWrapper}
            contentContainerStyle={styles.content}
            data={filteredGroups}
            initialNumToRender={4}
            numColumns={2}
            renderItem={({item}) => <ItemView item={item} />}
            showsVerticalScrollIndicator={false}
          />
        </View>

        {features?.enable_group_tags && (
          <GroupTagSelectionView
            filterListForModal={filterListForModal}
            isFilterVisible={isFilterVisible}
            setFilterVisible={setFilterVisible}
            setUserSelectedFilters={setUserSelectedFilters}
            userSelectedFilters={userSelectedFilters}
          />
        )}

        <TouchableOpacity
          onPress={() => {
            if (isWeChat() && ENABLE_WECHAT_REDIRECT) {
              setShowWechatRedirect(true);
              return;
            }
            if (currentUser === null) {
              navigation.push('LoginModal');
            } else if (!isUserProfileCompeleted(currentUser)) {
              navigation.push('LoginModal', {
                params: {
                  gender: currentUser.gender || '',
                  image: currentUser.profileImageUrl || '',
                  mode: 'complete',
                  name: currentUser.name || '',
                  referCode: '',
                  username: currentUser.userName || '',
                  wechat: currentUser.socialWechat || '',
                },
                screen: 'Profile',
              });
            } else {
              navigation.push('CreateGroupScreen');
            }
          }}
          style={styles.createGroupButton}>
          <Text style={styles.createGroupButtonText}>发起组队</Text>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
}

// memorize group list item to prevent unnecessary rendering.
const ItemView = React.memo(({item}: {item: Group}) => {
  const navigation: any = useNavigation();
  return (
    <Pressable
      key={item.id}
      onPress={() => navigation.push('GroupDetails', {groupId: item.id})}>
      <GroupCardViewVertical group={item} />
    </Pressable>
  );
}, equal);

const styles = StyleSheet.create({
  columnWrapper: {
    justifyContent: 'space-between',
    marginHorizontal: 2, // Temp fix to bring card shadow back
  },
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  content: {
    paddingBottom: 52,
  },
  createGroupButton: {
    alignSelf: 'center',
    backgroundColor: '#66BB6A',
    borderRadius: 8,
    bottom: 12,
    paddingVertical: 8,
    position: 'absolute',
    width: WINDOW_WIDTH - Layout.unit * 2,
  },
  createGroupButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  description: {
    fontSize: 14,
    fontWeight: 'normal',
    lineHeight: 18,
  },
  filterBarContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    height: 18 * Layout.fontScale + 15,
    justifyContent: 'space-between',
    marginHorizontal: 4,
  },
  filterButton: {
    borderBottomColor: 'transparent',
    borderBottomWidth: 1,
    justifyContent: 'center',
    paddingBottom: 10,
    paddingHorizontal: 15,
    paddingTop: 5,
  },
  filterCatHeader: {
    fontSize: 20,
    padding: 10,
  },
  footer: {
    bottom: 0,
    flex: 1,
    left: 0,
    right: 0,
    zIndex: 10,
  },
  groupCardList: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    paddingHorizontal: Layout.unit * 2,
  },
  groupTagItemContainer: {
    // backgroundColor: 'white',
    borderRadius: 10,
    margin: 10,
    paddingHorizontal: 14,
    paddingVertical: 6,
  },
  horizontalScrollView: {
    maxHeight: 20 * Layout.fontScale + 15,
  },
  modalButton: {
    alignSelf: 'center',
    borderRadius: 8,
    flex: 1,
    marginHorizontal: 16,
    paddingVertical: 8,
  },
  modalButtonList: {
    flexDirection: 'row',
    paddingBottom: 30,
  },
  modalView: {
    backgroundColor: '#ddd',
    borderRadius: 10,
    height: '30%',
    marginTop: 'auto',
  },
  safeAreaStyle: {
    backgroundColor: 'white',
    flex: 1,
    paddingTop: Platform.OS === 'android' ? StatusBar.currentHeight : 0,
  },
});
