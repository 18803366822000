import {Feather} from '@expo/vector-icons';
import React from 'react';
import {
  Button,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import PrimaryButton from '../buttons/PrimaryButton';

interface LoginProps {
  onClose: () => void;
}

export function LoginFromAccount(loginProps: LoginProps) {
  return (
    <View style={styles.modal}>
      <Text style={styles.title}>Login</Text>
      <Button onPress={loginProps.onClose} title="Close" />
    </View>
  );
}

export function AccountAskForLogin({navigation}: {navigation: any}) {
  return (
    <ScrollView>
      <View style={styles.container}>
        <Text style={styles.loginPrompt}>
          登陆或者注册账户来开始计划你的组队！
        </Text>
        <PrimaryButton
          disabled={false}
          onPress={() => {
            navigation.push('LoginModal');
          }}
          text="登陆"
        />
        <TouchableOpacity onPress={() => navigation.push('LoginModal')}>
          <Text style={styles.registerPrompt}>
            没有账号？
            <Text
              onPress={() => navigation.push('LoginModal')}
              style={{color: '#66BB6A', textDecorationLine: 'underline'}}>
              现在注册
            </Text>
          </Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => navigation.push('UserTerms')}>
          <View style={styles.settingLink}>
            <Text style={styles.settingText}>用户协议</Text>
            <Feather color="black" name="chevron-right" size={24} />
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => navigation.push('ResponsibilityTerms')}>
          <View style={styles.settingLink}>
            <Text style={styles.settingText}>免责声明</Text>
            <Feather color="black" name="chevron-right" size={24} />
          </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => navigation.push('PrivacyPolicy')}>
          <View style={styles.settingLink}>
            <Text style={styles.settingText}>隐私条款</Text>
            <Feather color="black" name="chevron-right" size={24} />
          </View>
        </TouchableOpacity>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    padding: 16,
  },
  helpArrow: {
    textAlign: 'right',
  },
  helpLink: {
    flexDirection: 'row',
    marginBottom: 10,
  },
  helpText: {
    flexDirection: 'row',
    marginBottom: 20,
  },
  loginPrompt: {
    fontSize: 16,
    marginBottom: 30,
    marginTop: 30,
    textAlign: 'left',
  },
  modal: {
    backgroundColor: '#ffffff',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    bottom: 0,
    left: 0,
    padding: 16,
    position: 'absolute',
    right: 0,
  },
  registerPrompt: {
    fontSize: 12,
    lineHeight: 18,
    marginBottom: 50,
    textAlign: 'left',
  },
  settingLink: {
    flexDirection: 'row',
    marginBottom: 20,
  },
  settingText: {
    flex: 10,
    fontSize: 16,
    textAlign: 'left',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 16,
  },
});
