import {AntDesign} from '@expo/vector-icons';
import * as ImagePicker from 'expo-image-picker';
import {collection, query, Timestamp, where} from 'firebase/firestore';
import React from 'react';
import {useCollection} from 'react-firebase-hooks/firestore';
import {
  Dimensions,
  FlatList,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import {useAuthContext} from '../../context/AuthContext';
import {getFirestore} from '../../firebase';
import tourConverter from '../../firestoreConverters/tourConverter';
import {emptyUser, Tour} from '../../types';
import DiscoverCardView from '../discover/DiscoverCardView';

const {width: WINDOW_WIDTH} = Dimensions.get('window');

export function PersonalPost({
  navigation,
  userId,
}: {
  navigation: any;
  userId: any;
}) {
  const loggedInUser = useAuthContext().currentUser ?? emptyUser;
  let currentUserId = useAuthContext().currentUser?.id ?? '';
  let readOnly = false;
  if (userId != null && userId != undefined) {
    currentUserId = userId;
    readOnly = true;
  }
  const [userPosts] = useCollection(
    query(
      collection(getFirestore(), 'tours').withConverter(tourConverter),
      where('poster', '==', currentUserId),
    ),
  );

  const postList =
    userPosts != undefined
      ? userPosts?.docs.map((post) => {
          return post.data();
        })
      : [];
  postList.sort((a, b) => (a.uploadDate <= b.uploadDate ? 1 : -1));

  const pickImage = async () => {
    const {status} = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (status !== 'granted') {
      alert('Sorry, we need camera roll permissions to make this work!');
      return;
    }

    const result = await ImagePicker.launchImageLibraryAsync({
      allowsEditing: true,
      aspect: [16, 9],
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: 0.1,
    });

    if (!result.canceled) {
      if (result.assets != null && result.assets.length > 0) {
        navigation.navigate('CreatePostScreen', {
          imageUrl: result.assets[0].uri,
        });
      }
    }
  };

  const renderUploadDate = (time: Timestamp) => {
    if (new Date(time.toMillis()).toDateString() == new Date().toDateString()) {
      return '今天';
    }
    const dayStr = new Date(time.toMillis()).toLocaleDateString('zh-Hans-CN', {
      day: 'numeric',
    });

    const monthStr = new Date(time.toMillis()).toLocaleDateString(
      'zh-Hans-CN',
      {
        month: 'numeric',
      },
    );

    return [dayStr, monthStr];
  };

  const renderImage = (data: {item: Tour}) => {
    const dateString = renderUploadDate(data.item.uploadDate);

    const DateStrView = () => {
      // Not render '今天', just like WeChat moment's style
      if (typeof dateString == 'string') {
        return <View style={styles.dateContainer} />;
      } else {
        return (
          <View style={styles.dateContainer}>
            <Text
              style={[
                styles.timestamp,
                {
                  fontSize: 18,
                  fontWeight: 'bold',
                },
              ]}>
              {dateString[0]}{' '}
            </Text>
            <Text
              style={[
                styles.timestamp,
                {
                  fontSize: 16,
                },
              ]}>
              {dateString[1]}{' '}
            </Text>
          </View>
        );
      }
    };

    return (
      <View
        style={[
          styles.imageContainer,
          {flexDirection: 'row', marginBottom: 20},
        ]}>
        <DateStrView />

        <TouchableOpacity
          onPress={() => {
            navigation.push('TourDetails', {tourID: data.item.id});
          }}>
          <DiscoverCardView
            defaultWidth={WINDOW_WIDTH * 0.775}
            enableCardBackground={false}
            enableCommentHeader={false}
            tour={data.item}
          />
        </TouchableOpacity>
      </View>
    );
  };
  if (loggedInUser?.blockedUsersList?.includes(currentUserId)) {
    return null;
  }

  return (
    <View style={styles.container}>
      <FlatList
        ListHeaderComponent={
          !readOnly ? (
            <View
              style={{
                flexDirection: 'row',
                paddingBottom: 10,
              }}>
              <Text
                style={[
                  styles.dateContainer,
                  {
                    fontSize: 18,
                    fontWeight: 'bold',
                  },
                ]}>
                今天
              </Text>
              <TouchableOpacity
                onPress={() => pickImage()}
                style={{
                  backgroundColor: 'rgba(236, 236, 236, 0.4)',
                  borderRadius: 5,
                }}>
                <AntDesign color="lightgrey" name="camera" size={100} />
              </TouchableOpacity>
            </View>
          ) : null
        }
        contentContainerStyle={styles.flatListContainer}
        data={postList}
        keyExtractor={(item) => item.uploadDate.toString()}
        numColumns={1}
        renderItem={renderImage}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 15,
    paddingTop: 25,
    // width: '95%',
  },
  dateContainer: {
    width: '15%',
  },
  description: {
    fontSize: 16,
    paddingRight: 20,
    width: 280,
  },
  flatListContainer: {
    marginBottom: 40,
    paddingTop: 10,
    width: '100%',
  },
  image: {
    borderRadius: 5,
    height: 200,
    width: 250,
  },
  imageContainer: {
    // margin: 20,
    // marginLeft: 20,
    // width: '100%',
  },
  imagePostContainer: {
    height: 300,
  },
  input: {
    alignSelf: 'center',
    backgroundColor: 'lightgrey',
    borderRadius: 3,
    flex: 3,
    height: 35,
  },
  timestamp: {
    alignItems: 'center',
    color: 'black',
  },
  uploadButton: {
    alignSelf: 'center',
    backgroundColor: 'green',
    borderRadius: 3,
    flex: 1,
    height: 35,
    marginLeft: 10,
  },
  uploadContainer: {
    flexDirection: 'row',
    height: 60,
    marginBottom: 10,
    paddingBottom: 10,
  },
});
