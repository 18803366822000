import {Image, StyleSheet, Text, View} from 'react-native';

import Colors from '../../constants/Colors';

export default function Features({isScreenWide, language}: any) {
  return (
    <View style={styles.featuresContainer}>
      {/* Feature one */}
      <View
        style={[
          styles.featureRow,
          isScreenWide && {
            flexDirection: 'row-reverse',
          },
        ]}>
        {/* Feature Image */}
        <View style={[styles.featureImageContainer]}>
          <Image
            source={require('../../assets/images/feature1.png')}
            style={[
              {height: 360, width: 400},
              !isScreenWide && {height: 250, maxWidth: 280, width: '80vw'},
            ]}
          />
        </View>
        {/* Feature Description */}
        <View
          style={[
            styles.featureDescriptionContainer,
            isScreenWide && {
              flexBasis: '60%',
            },
          ]}>
          <View style={styles.heading}>
            <View style={styles.tag}>
              <View style={styles.tagPoint}></View>
              <Text style={styles.tagText}>
                {language === 'english'
                  ? 'Try out the AI chatbot'
                  : '试试AI活动推荐功能'}
              </Text>
            </View>
            <Text style={[styles.headingTitle, isScreenWide && {fontSize: 55}]}>
              {language === 'english'
                ? 'AI SOCIAL Platform'
                : 'AI 活动社交平台'}
            </Text>

            <Text style={styles.headingSubtitle}>
              {language === 'english'
                ? 'Discover Your Destination: Get messages for the Hottest Local Parties and Events.'
                : '获得当地最火最热的活动资讯推荐，互通式交互体验，你的私人活动助手。'}
            </Text>
          </View>
        </View>
      </View>
      {/* Feature Two */}
      <View
        style={[
          styles.featureRow,
          isScreenWide && {
            flexDirection: 'row',
            paddingBottom: 30,
          },
        ]}>
        {/* Feature Image */}
        <View style={styles.featureImageContainer}>
          <Image
            source={require('../../assets/images/feature2.png')}
            style={{height: 600, width: 300}}
          />
        </View>
        {/* Feature Description */}
        <View
          style={[
            styles.featureDescriptionContainer,
            isScreenWide && {
              flexBasis: '60%',
            },
          ]}>
          <View style={styles.heading}>
            <View style={styles.tag}>
              <View style={styles.tagPoint}></View>
              <Text style={styles.tagText}>
                {language === 'english'
                  ? 'Experience the local lifestyle'
                  : '体验当地的生活方式'}
              </Text>
            </View>
            <Text style={[styles.headingTitle, isScreenWide && {fontSize: 55}]}>
              {language === 'english' ? 'JOIN THE EVENTS' : '参与周边活动'}
            </Text>

            <Text style={styles.headingSubtitle}>
              {language === 'english'
                ? 'The chatbot not only suggests a range of nearby activities but also actively engages in interactive conversations with you.'
                : '罗列周边精彩活动，欢迎平台用户自己发布活动，招募朋友一起参与。'}
            </Text>
          </View>
        </View>
      </View>
      {/* Feature Three */}
      <View
        style={[
          styles.featureRow,
          isScreenWide && {
            flexDirection: 'row-reverse',
            paddingBottom: 30,
          },
        ]}>
        {/* Feature Image */}
        <View style={styles.featureImageContainer}>
          <Image
            source={require('../../assets/images/feature3.png')}
            style={{height: 600, width: 300}}
          />
        </View>
        {/* Feature Description */}
        <View
          style={[
            styles.featureDescriptionContainer,
            isScreenWide && {
              flexBasis: '60%',
            },
          ]}>
          <View style={styles.heading}>
            <View style={styles.tag}>
              <View style={styles.tagPoint}></View>
              <Text style={styles.tagText}>
                {language === 'english'
                  ? 'Join the local community'
                  : '加入本地社群'}
              </Text>
            </View>
            <Text style={[styles.headingTitle, isScreenWide && {fontSize: 55}]}>
              {language === 'english' ? 'MEET NEW FRIENDS' : '遇见新的朋友'}
            </Text>

            <Text style={styles.headingSubtitle}>
              {language === 'english'
                ? 'Providing the opportunity to connect with others on the platform, enabling you to find like-minded individuals to participate in activities.'
                : '在活动中遇见志同道合的新朋友！无负担，无压力交友。'}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  featureDescriptionContainer: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
  },
  featureImageContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 430,
  },
  featureRow: {
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 'auto',
    width: '100%',
  },
  featuresContainer: {
    backgroundColor: '#DDEDDB',
    paddingVertical: 50,
  },
  heading: {
    flexDirection: 'column',
    margin: 'auto',
    maxWidth: 630,
    paddingHorizontal: 40,
    paddingVertical: 45,
  },
  headingSubtitle: {
    fontSize: 16,
    fontWeight: '500',
    marginTop: 10,
    paddingVertical: 20,
  },
  headingTitle: {
    color: Colors.mainAppColor,
    fontSize: 38,
    fontWeight: 'bold',
  },
  tag: {
    alignItems: 'center',
    flexDirection: 'row',
    marginVertical: 10,
    width: '100%',
  },
  tagPoint: {
    backgroundColor: '#FFC01E',
    borderRadius: 50,
    height: 15,
    marginRight: 10,
    width: 15,
  },
  tagText: {
    color: '#626B80',
    fontSize: 16,
  },
});
