import {
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from 'firebase/firestore';

import {BannerAdCampaign} from '../types';

export default {
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): BannerAdCampaign {
    const data = snapshot.data(options);
    return {
      associatedDiscountCode: data.associatedDiscountCode,
      associatedURL: data.associatedURL,
      buttonText: data.buttonText,
      compaignImage: data.compaignImage,
      endDate: data.endDate,
      imageAssetURL: data.imageAssetURL,
      startDate: data.startDate,
      targetUserGroup: data.targetUserGroup ?? [],
      title: data.title ?? '',
      type: data.type,
    };
  },
  toFirestore(bannerAd: WithFieldValue<BannerAdCampaign>): DocumentData {
    return {...bannerAd};
  },
};
