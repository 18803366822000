import {Ionicons} from '@expo/vector-icons';
import * as ImagePicker from 'expo-image-picker'; // requires Expo
import {doc, getDoc, Timestamp, updateDoc} from 'firebase/firestore';
import {getDownloadURL, getStorage, ref, uploadBytes} from 'firebase/storage';
import {useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {
  Dimensions,
  Image,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import DropDownPicker from 'react-native-dropdown-picker';

import Checkbox from '../components/buttons/Checkbox';
import PrimaryButton from '../components/buttons/PrimaryButton';
import alert from '../components/container/alert';
import {InfoText} from '../components/texts/InfoText';
import {OFFICIAL_ACCOUNT} from '../constants/config';
import {REFER_CODES, WELCOME} from '../constants/Discount';
import {useAuthContext} from '../context/AuthContext';
import {useFeaturesContext} from '../context/FeaturesContext';
import {getFirestore} from '../firebase';
import userConverter from '../firestoreConverters/userConverter';
import {
  defaultAvatar,
  handleFollowAction,
  updateReferredUsersList,
  updateUserToDiscount,
} from '../helpers/util';

const db = getFirestore();

type ProfileData = {
  gender: string;
  image: string | null;
  location: string;
  name: string;
  referCode: string;
  username: string;
  wechat: string;
};

export default function LoginProfileScreen({
  navigation,
  route,
}: {
  navigation: any;
  route: any;
}) {
  const {currentUser, phoneNumber} = useAuthContext();
  const {features} = useFeaturesContext();
  const {
    name: defaultName,
    username: defaultUsername,
    gender: defaultGender,
    wechat: defaultWechat,
    image: defaultImage,
    referCode: defaultReferCode,
    mode,
    location: defaultLocation,
  } = route.params || {};
  const {
    control,
    handleSubmit,
    formState: {errors, isSubmitting},
  } = useForm<ProfileData>({
    defaultValues: {
      gender: defaultGender ?? '',
      image: defaultImage ?? null,
      location: defaultLocation ?? '',
      name: defaultName ?? '',
      referCode: defaultReferCode ?? '',
      username: defaultUsername ?? '',
      wechat: defaultWechat ?? '',
    },
  });

  const onSubmit = async (data: ProfileData) => {
    console.log('data submit: ' + JSON.stringify(data));
    // 检查邀请码
    if (features?.enable_refer && data.referCode != '') {
      const originalUserRef = doc(db, 'users', data.referCode).withConverter(
        userConverter,
      );
      const originalUser = await getDoc(originalUserRef);
      if (!originalUser.exists()) {
        alert('推荐码无效');
        return;
      }
    }
    const userId = currentUser?.id;
    if (userId != null) {
      const userRef = doc(db, 'users', userId);
      try {
        await updateDoc(userRef, {
          gender: data.gender,
          location: data.location,
          name: data.name,
          profileImageUrl: data.image ? data.image : defaultAvatar,
          socialWechat: data.wechat,
          userName: data.username,
        });
        if (mode === 'register') {
          handleFollowAction(userId, false, OFFICIAL_ACCOUNT);
          handleNewUserDiscount(data.referCode, userId);
        }
        navigation.navigate('Complete');
      } catch (error) {
        alert('发送错误', '注册用户时发生错误: ' + error, [
          {onPress: () => {}, text: '确定'},
        ]);
        console.error('error occurs when updating profile: ', error);
      }
    } else {
      alert('发送错误', '用户没有被授权', [{onPress: () => {}, text: '确定'}]);
      console.error('error occurs when updating profile: user not logged in');
    }
  };

  const handleNewUserDiscount = async (referCode: string, userId: string) => {
    try {
      if (features?.enable_discount) {
        // update WELCOME5 limitedUserNumbers
        if (phoneNumber) {
          updateUserToDiscount(phoneNumber, WELCOME);
        }
      }
      if (features?.enable_refer && referCode != '' && phoneNumber) {
        // update referredUsersList
        const result = await updateReferredUsersList(
          referCode,
          userId,
          phoneNumber,
        );
        // update REFER_X limitedUserNumbers
        if (
          result &&
          result != null &&
          result.referredCount > 0 &&
          result.referredCount <= REFER_CODES.length &&
          result.phoneNumber != ''
        ) {
          const referredCount = result.referredCount;
          updateUserToDiscount(
            result.phoneNumber,
            REFER_CODES[referredCount - 1],
          );
        }
      }
    } catch (error) {
      alert('发送错误', '注册用户时发生错误: ' + error, [
        {onPress: () => {}, text: '确定'},
      ]);
      console.error('error occurs when updating profile: ', error);
    }
  };

  const [genderOpen, setGenderOpen] = useState(false);
  const [locationOpen, setLocationOpen] = useState(false);
  const [isAgeChecked, setIsAgeChecked] = useState(false);
  const [isUsernameFilled, setIsUsernameFilled] = useState(false);
  const [isWechatIdFilled, setIsWechatIdFilled] = useState(false);
  const [genderValue, setGenderValue] = useState(
    control._defaultValues.gender ?? null,
  );
  const [locationValue, setLocationValue] = useState(
    control._defaultValues.location ?? null,
  );
  const [genderItems, setGenderItems] = useState([
    {label: '男', value: 'M'},
    {label: '女', value: 'F'},
  ]);

  const [locationItems, setLocationItems] = useState([
    {label: '纽约', value: 'NewYork'},
    {label: '波士顿', value: 'Boston'},
    {label: '湾区', value: 'BayArea'},
  ]);

  // TODO(chenfada) Add loading indicator
  const handleImagePick = async (onSuccess: (url: string | null) => void) => {
    const result = await ImagePicker.launchImageLibraryAsync({
      allowsEditing: true,
      aspect: [16, 9],
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: 0.2,
    });

    if (!result.canceled && result.assets.length > 0) {
      try {
        const response = await fetch(result.assets[0].uri);
        const imageTimestamp = Timestamp.now();
        const newFileName = currentUser?.id + '_' + imageTimestamp;
        const blob = await response.blob();
        const refLocation = ref(getStorage(), 'UserProfilePic/' + newFileName);

        await uploadBytes(refLocation, blob);
        const downloadURL = (await getDownloadURL(refLocation)).toString();
        onSuccess(downloadURL);
      } catch (error) {
        onSuccess(null);
        console.error('error occurs when uploading profile image: ', error);
      }
    }
  };

  const isRequiredFieldsIncomplete: boolean =
    !isAgeChecked ||
    !isWechatIdFilled ||
    !isUsernameFilled ||
    !genderValue ||
    !locationValue;

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : undefined}
      style={{flex: 1}}>
      <ScrollView>
        <View style={styles.container}>
          {mode === 'complete' ? (
            <InfoText style={{marginBottom: 16}}>
              您的信息不够完整，请先完善您的信息
            </InfoText>
          ) : null}
          <Text style={styles.label}>个人照片(选填)</Text>
          <Controller
            control={control}
            name="image"
            render={({field: {onChange, value}}) => (
              <TouchableOpacity
                onPress={() => handleImagePick(onChange)}
                style={{marginBottom: 16, marginTop: 6}}>
                {value === '' || value == null ? (
                  <View style={[styles.imagePicker]}>
                    <Ionicons color="#BEBEBE" name="add" size={44} />
                  </View>
                ) : (
                  <Image source={{uri: value}} style={styles.groupImage} />
                )}
              </TouchableOpacity>
            )}
            rules={{required: false}}
          />
          <Text style={styles.label}>姓名</Text>
          <Controller
            control={control}
            name="name"
            render={({field: {onChange, onBlur, value}}) => (
              <TextInput
                onBlur={onBlur}
                onChangeText={onChange}
                placeholder="您的名字"
                style={styles.input}
                value={value}
              />
            )}
            rules={{required: false}}
          />
          <Text style={styles.label}>
            用户名<Text style={styles.asterisk}>*</Text>
          </Text>
          <Controller
            control={control}
            name="username"
            render={({field: {onChange, onBlur, value}}) => (
              <TextInput
                onBlur={onBlur}
                onChangeText={(text) => {
                  onChange(text);
                  setIsUsernameFilled(text.trim() !== '');
                }}
                placeholder="您的用户名"
                style={styles.input}
                value={value}
              />
            )}
            rules={{required: true}}
          />
          <Text style={styles.label}>
            微信Id<Text style={styles.asterisk}>*</Text>
          </Text>
          <Controller
            control={control}
            name="wechat"
            render={({field: {onChange, onBlur, value}}) => (
              <TextInput
                onBlur={onBlur}
                onChangeText={(text) => {
                  onChange(text);
                  setIsWechatIdFilled(text.trim() !== '');
                }}
                placeholder="您的微信Id"
                style={[styles.input, {marginBottom: 4}]}
                value={value}
              />
            )}
            rules={{required: true}}
          />
          <InfoText style={{marginBottom: 16}}>
            您的联系方式是私密，不会被分享，仅用来加入组队微信群
          </InfoText>
          <Text style={styles.label}>
            性别<Text style={styles.asterisk}>*</Text>
          </Text>
          <Controller
            control={control}
            name="gender"
            render={({field: {onChange}}) => (
              <DropDownPicker
                dropDownContainerStyle={styles.dropDownContainer}
                items={genderItems}
                onChangeValue={onChange}
                open={genderOpen}
                placeholder="您的性别"
                placeholderStyle={Platform.select({
                  android: {color: '#9E9E9E'},
                  ios: {color: '#C7C7CD'},
                })}
                setItems={setGenderItems}
                setOpen={setGenderOpen}
                setValue={setGenderValue}
                style={styles.input}
                value={genderValue}
              />
            )}
            rules={{required: true}}
          />
          <InfoText style={{marginBottom: 16}}>
            您的性别注册后不能更改，部分组队有性别要求，请如实填写
          </InfoText>

          <Text style={styles.label}>
            城市<Text style={styles.asterisk}>*</Text>
          </Text>
          <Controller
            control={control}
            name="location"
            render={({field: {onChange}}) => (
              <DropDownPicker
                dropDownContainerStyle={styles.dropDownContainer}
                items={locationItems}
                onChangeValue={onChange}
                open={locationOpen}
                placeholder="您的城市"
                placeholderStyle={Platform.select({
                  android: {color: '#9E9E9E'},
                  ios: {color: '#C7C7CD'},
                })}
                setItems={setLocationItems}
                setOpen={setLocationOpen}
                setValue={setLocationValue}
                style={styles.input}
                value={locationValue}
              />
            )}
            rules={{required: true}}
          />

          {features?.enable_refer && (
            <View style={{width: '100%'}}>
              <Text style={styles.label}>推荐码(选填)</Text>
              <Controller
                control={control}
                name="referCode"
                render={({field: {onChange, onBlur, value}}) => (
                  <TextInput
                    editable={defaultReferCode.trim() == ''}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    placeholder="您朋友的推荐码"
                    style={styles.input}
                    value={value}
                  />
                )}
                rules={{required: false}}
              />
            </View>
          )}

          <View style={{flexDirection: 'row'}}>
            <View style={styles.checkbox}>
              <Checkbox onChange={setIsAgeChecked} value={isAgeChecked} />
            </View>
            <Text style={[styles.text, {color: '#616161'}]}>
              我确认我已经年满18周岁
            </Text>
          </View>
          <PrimaryButton
            disabled={
              Object.keys(errors).length > 0 ||
              isSubmitting ||
              isRequiredFieldsIncomplete
            }
            onPress={() => {
              handleSubmit(onSubmit)();
            }}
            text={mode === 'complete' ? '完善信息' : '注册'}
          />
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  asterisk: {
    color: '#B10730',
  },
  button: {
    backgroundColor: '#ec5990',
    borderRadius: 4,
    color: 'white',
    height: 40,
    marginTop: 40,
  },
  checkbox: {
    justifyContent: 'flex-start',
    marginRight: 8,
    marginTop: -2,
  },
  container: {
    alignItems: 'center',
    backgroundColor: 'white',
    flex: 1,
    marginBottom: 60,
    padding: 16,
    width: '100%',
  },
  dropDownContainer: {
    borderColor: '#E4E4E4',
    paddingHorizontal: 8,
  },
  groupImage: {
    alignSelf: 'center',
    aspectRatio: 1,
    borderRadius: Dimensions.get('window').width / 6,
    height: Dimensions.get('window').width / 3,
    marginVertical: 4,
    resizeMode: 'cover',
    width: Dimensions.get('window').width / 3,
  },
  imagePicker: {
    alignItems: 'center',
    aspectRatio: 1,
    backgroundColor: 'rgba(228, 228, 228, 0.4)',
    borderRadius: 8,
    justifyContent: 'center',
    marginVertical: 4,
    width: Dimensions.get('window').width / 3,
  },
  input: {
    alignItems: 'center',
    borderColor: '#E4E4E4',
    borderRadius: 10,
    borderWidth: 1,
    height: 50,
    marginBottom: 16,
    marginTop: 6,
    paddingHorizontal: 18,
    paddingVertical: 12,
    width: '100%',
  },
  label: {
    color: '#333333',
    fontSize: 16,
    fontWeight: '600',
    lineHeight: 23,
    width: '100%',
  },
  separator: {
    color: '#eee',
    height: 1,
    marginVertical: 30,
    width: '80%',
  },
  text: {
    flex: 1,
    flexDirection: 'row',
    fontSize: 12,
    lineHeight: 18,
    marginBottom: 24,
    textAlign: 'left',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
});
